export function developmentSvg(){
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 453.333 178.386">
  <g>
    <rect class="cls-1" width="453.333" height="178.386"/>
  </g>
  <g>
    <path class="cls-2" d="M2.074,284.052C2.074,241.5,39.086,207,84.742,207s82.669,34.5,82.669,77.052" transform="translate(141.81 -105.667)"/>
    
    <g class="development__btn" id="doskonalenie" [data-popup] data-popup="13">
      <path class="cls-3" d="M164.174,261.506a4.118,4.118,0,1,1-2.644,5.189A4.117,4.117,0,0,1,164.174,261.506Z" transform="translate(141.81 -105.667)"/>
      <text class="cls-4" transform="translate(397.763 148.086)">Dos<tspan class="cls-8" x="15.081" y="0">k</tspan><tspan x="19.482" y="0">onalenie</tspan></text>
      <path class="cls-9" d="M200.351,225.567a27.119,27.119,0,1,1-15.469,38.694l-9.588-2.038,6.559-7.285A27.121,27.121,0,0,1,200.351,225.567Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-9" d="M229.145,251.335a20.361,20.361,0,1,1-20.361-20.361A20.362,20.362,0,0,1,229.145,251.335Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M214.656,251.243a6,6,0,1,0-11.508,2.361h0l0,0a20.969,20.969,0,0,1,1.348,1.947,9.276,9.276,0,0,1,1.434,3.522H211.4a9.341,9.341,0,0,1,1.454-3.554,21.361,21.361,0,0,1,1.288-1.864l.043-.054h-.017A5.971,5.971,0,0,0,214.656,251.243Zm-9.512.117a9.354,9.354,0,0,0-.172,2.544c-.3-.436-.551-.773-.691-.955a4.7,4.7,0,0,1,4.379-6.405,4.6,4.6,0,0,1,.59.041A6.4,6.4,0,0,0,205.144,251.36Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M210.747,260.17h-4.173a.65.65,0,0,0-.648.648v.405a.649.649,0,0,0,.648.648h.263v.466a.325.325,0,0,0,.324.325h3a.326.326,0,0,0,.325-.325v-.466h.263a.65.65,0,0,0,.648-.648v-.405A.65.65,0,0,0,210.747,260.17Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M209.146,243.14c0,.357-.218.649-.486.649s-.486-.292-.486-.649v-2.484c0-.357.219-.648.486-.648s.486.291.486.648Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M205.484,243.871a.507.507,0,1,1-.842.488l-1.244-2.151a.506.506,0,1,1,.841-.487Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M202.68,246.339a.506.506,0,1,1-.484.843l-2.155-1.237a.507.507,0,1,1,.485-.844Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M201.49,249.879c.357,0,.649.217.65.484s-.29.487-.647.488l-2.484.009c-.357,0-.649-.217-.65-.484s.29-.487.646-.489Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M212.808,244.4a.507.507,0,1,1-.842-.487l1.244-2.151a.506.506,0,1,1,.841.487Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M215.344,247.331a.506.506,0,1,1-.485-.843l2.154-1.24a.506.506,0,1,1,.485.843Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M216.076,251.134c-.357,0-.648-.22-.648-.487s.293-.486.65-.485l2.484,0c.357,0,.648.22.648.487s-.293.486-.65.485Z" transform="translate(141.81 -105.667)"/>
    </g>
    
    <g class="development__btn" id="iso" [data-popup] data-popup="12">
      <path class="cls-3" d="M131.293,220.578a4.118,4.118,0,1,1,.911,5.752A4.118,4.118,0,0,1,131.293,220.578Z" transform="translate(141.81 -105.667)"/>
      <text class="cls-4" transform="translate(353.254 74.566)">ISO</text>
      <path class="cls-9" d="M139.436,170.239a27.119,27.119,0,1,1,10.229,40.4l-8.955,3.987,1.025-9.749A27.12,27.12,0,0,1,139.436,170.239Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-9" d="M181.781,186.117a20.361,20.361,0,1,1-20.361-20.36A20.361,20.361,0,0,1,181.781,186.117Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-10" d="M172.423,183.861a7.774,7.774,0,0,0,0-2.853l-1.662-.592a6.477,6.477,0,0,0-.359-.867l.757-1.594a7.957,7.957,0,0,0-2.016-2.018l-1.6.758a6.674,6.674,0,0,0-.867-.36l-.591-1.663a7.952,7.952,0,0,0-2.853,0l-.592,1.663a6.687,6.687,0,0,0-.868.36l-1.594-.758a7.928,7.928,0,0,0-2.017,2.018l.759,1.594a6.234,6.234,0,0,0-.361.867l-1.662.592a7.455,7.455,0,0,0-.081,2.268h5.043a2.905,2.905,0,0,1-.138-.842,2.937,2.937,0,1,1,3.428,2.888v4.983a7.925,7.925,0,0,0,.936-.109l.591-1.663a6.221,6.221,0,0,0,.868-.36l1.595.759a7.945,7.945,0,0,0,2.016-2.019l-.757-1.593a6.588,6.588,0,0,0,.359-.867Z" transform="translate(141.81 -105.667)"/>
      <rect class="cls-10" x="292.91" y="85.658" width="8.328" height="5.952"/>
      <rect class="cls-10" x="302.064" y="85.658" width="8.329" height="5.952"/>
      <rect class="cls-10" x="297.487" y="78.777" width="8.328" height="5.954"/>
    </g>
    
    <g class="development__btn" id="laboratorium" [data-popup] data-popup="11">
      <path class="cls-3" d="M80.634,206.794a4.118,4.118,0,1,1,4.118,4.118A4.118,4.118,0,0,1,80.634,206.794Z" transform="translate(141.81 -105.667)"/>
      <text class="cls-4" transform="translate(201.135 7.787)">Labo<tspan class="cls-6" x="19.318" y="0">r</tspan><tspan x="22.444" y="0">a</tspan><tspan class="cls-7" x="26.953" y="0">t</tspan><tspan x="30.03" y="0">orium</tspan></text>
      <path class="cls-9" d="M111.871,161.282a27.119,27.119,0,1,0-32.02,26.669l4.9,8.489,4.9-8.489A27.121,27.121,0,0,0,111.871,161.282Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-9" d="M105.113,161.206a20.361,20.361,0,1,1-20.361-20.36A20.362,20.362,0,0,1,105.113,161.206Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M89.025,153.541q-.405.64-.815,1.277a2.345,2.345,0,0,0-1.269-.375,2.3,2.3,0,0,0-.707.111,2.382,2.382,0,0,0-1.651,2.669,2.279,2.279,0,0,0,1.073,1.594c-.368.57-.734,1.138-1.1,1.7a.364.364,0,0,1-.558.1c-.912-.584-1.824-1.166-2.732-1.755-.254-.164-.274-.343-.093-.625q1.751-2.734,3.5-5.466c.309-.481.613-.966.925-1.444.2-.3.361-.331.664-.137q1.3.831,2.605,1.667C89.192,153.072,89.231,153.218,89.025,153.541Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M86.694,150.567c.388-.595.8-1.224,1.211-1.855.173-.264.338-.534.514-.8.188-.283.334-.326.611-.153.613.384,1.219.779,1.827,1.171a.373.373,0,0,1,.128.576c-.578.912-1.161,1.82-1.733,2.717Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M80.959,159.553l2.565,1.639c-.287.436-.579.888-.882,1.332a.347.347,0,0,1-.511.1q-.957-.6-1.9-1.219a.351.351,0,0,1-.112-.53C80.4,160.431,80.682,159.987,80.959,159.553Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-3" d="M91,171.4a9,9,0,0,0-1.221-.9c-.245-.151-.5-.289-.762-.419-.08-.04-.16-.08-.245-.12a14,14,0,0,0,1.47-1.332,8.054,8.054,0,0,0,1.582-8.668,7.815,7.815,0,0,0-2.767-3.6,1.079,1.079,0,0,1-.311-.392A2.009,2.009,0,1,0,87,158.822a.872.872,0,0,1,.548.107,5.145,5.145,0,0,1,1.813,5.149,5.253,5.253,0,0,1-6.183,4.174,4.191,4.191,0,0,1-2.276-1.131c.271,0,.539.008.81,0,.553-.018.878-.321.878-.8a.792.792,0,0,0-.9-.806q-1.211-.006-2.419,0c-1.105,0-2.21,0-3.314,0a.854.854,0,0,0-.891.815.809.809,0,0,0,.86.793c.271.013.547-.013.815.018a.524.524,0,0,1,.343.2,7.891,7.891,0,0,0,2.3,2.446c.058.044.116.085.182.138-.1.045-.2.094-.289.138a10.277,10.277,0,0,0-1.154.673c-.178.124-.356.254-.526.387-.093.076-.191.165-.289.25-.071.062-.169.111-.183.209-.013.12,0,.258,0,.383,0,.263,0,.526,0,.793H91.222v-1.122c0-.009,0-.018-.009-.023A.94.94,0,0,0,91,171.4Zm-4.032-13.573a1,1,0,0,1-1.047-.976.978.978,0,0,1,.976-1.033.95.95,0,0,1,1.016.98A.989.989,0,0,1,86.968,157.824Z" transform="translate(141.81 -105.667)"/>
    </g>
    
    <g class="development__btn" id="inwestycje" [data-popup] data-popup="10">
      <path class="cls-3" d="M31.549,225.419a4.118,4.118,0,1,1,5.752.911A4.118,4.118,0,0,1,31.549,225.419Z" transform="translate(141.81 -105.667)"/>
      <text class="cls-4" transform="translate(52.606 74.566)">Inwestycje</text>
      <path class="cls-9" d="M30.069,170.239a27.119,27.119,0,1,0-10.23,40.4l8.955,3.987-1.024-9.749A27.121,27.121,0,0,0,30.069,170.239Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-9" d="M28.445,186.117a20.361,20.361,0,1,1-20.361-20.36A20.362,20.362,0,0,1,28.445,186.117Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-10" d="M12.872,182.635,8.453,173.8a.429.429,0,0,0-.737,0L3.3,182.635Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-10" d="M6.726,190.052H-.412l-3,6a.41.41,0,0,0,.019.4.41.41,0,0,0,.35.2H6.726Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-10" d="M19.579,196.049l-3-6H9.443v6.594H19.21a.413.413,0,0,0,.369-.6Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-10" d="M6.726,187.79H5.775a.136.136,0,0,1-.111-.215l2.309-3.26a.141.141,0,0,1,.222,0l2.309,3.26a.133.133,0,0,1,.01.141.136.136,0,0,1-.121.074h-.95v1.438h6.726l-2.885-5.769H2.885L0,189.228H6.726Z" transform="translate(141.81 -105.667)"/>
    </g>
    
    <g class="development__btn" id="biot" [data-popup] data-popup="9">
      <path class="cls-3" d="M2.785,269.339A4.118,4.118,0,1,1,7.974,266.7,4.118,4.118,0,0,1,2.785,269.339Z" transform="translate(141.81 -105.667)"/>
      <text class="cls-4" transform="translate(38.701 148.086)">BI<tspan class="cls-5" x="8.137" y="0">O</tspan><tspan x="13.97" y="0">T</tspan></text>
      <path class="cls-9" d="M-30.846,225.567a27.118,27.118,0,0,0-34.172,17.411A27.119,27.119,0,0,0-47.607,277.15a27.122,27.122,0,0,0,32.229-12.889l9.588-2.038-6.559-7.285A27.12,27.12,0,0,0-30.846,225.567Z" transform="translate(141.81 -105.667)"/>
      <path class="cls-9" d="M-18.939,251.335A20.361,20.361,0,0,1-39.3,271.7a20.36,20.36,0,0,1-20.361-20.36A20.36,20.36,0,0,1-39.3,230.974,20.361,20.361,0,0,1-18.939,251.335Z" transform="translate(141.81 -105.667)"/>
      <rect class="cls-3" x="98.435" y="141.593" width="8.152" height="8.15" rx="0.519" ry="0.519"/>
      <path class="cls-3" d="M-30.524,246.791a.584.584,0,0,0,.583-.582.583.583,0,0,0-.583-.583h-1.419a1.7,1.7,0,0,0-1.647-1.648v-1.419a.583.583,0,0,0-.582-.582.581.581,0,0,0-.581.582v1.417h-.888v-1.417a.582.582,0,0,0-.581-.582.583.583,0,0,0-.583.582v1.417h-.886v-1.417a.584.584,0,0,0-.584-.582.584.584,0,0,0-.582.582v1.417h-.885v-1.417a.583.583,0,0,0-.582-.582.584.584,0,0,0-.584.582v1.417h-.885v-1.417a.583.583,0,0,0-.583-.582.584.584,0,0,0-.582.582v1.417h-.886v-1.417a.583.583,0,0,0-.583-.582.583.583,0,0,0-.582.582v1.419a1.7,1.7,0,0,0-1.647,1.648h-1.417a.584.584,0,0,0-.584.582.585.585,0,0,0,.584.583h1.415v.886h-1.415a.584.584,0,0,0-.584.583.584.584,0,0,0,.584.582h1.415v.885h-1.415a.585.585,0,0,0-.584.581.585.585,0,0,0,.584.585h1.415v.886h-1.415a.584.584,0,0,0-.584.58.584.584,0,0,0,.584.584h1.415v.886h-1.415a.585.585,0,0,0-.584.582.584.584,0,0,0,.584.582h1.415v.888h-1.415a.584.584,0,0,0-.584.583.585.585,0,0,0,.584.582h1.417a1.7,1.7,0,0,0,1.647,1.648v1.415a.583.583,0,0,0,.582.583.583.583,0,0,0,.583-.583V258.7h.886v1.412a.583.583,0,0,0,.582.583.583.583,0,0,0,.583-.583V258.7h.885v1.412a.583.583,0,0,0,.584.583.583.583,0,0,0,.582-.583V258.7h.885v1.412a.583.583,0,0,0,.582.583.583.583,0,0,0,.584-.583V258.7h.886v1.412a.583.583,0,0,0,.583.583.582.582,0,0,0,.581-.583V258.7h.888v1.412a.581.581,0,0,0,.581.583.583.583,0,0,0,.582-.583v-1.415a1.7,1.7,0,0,0,1.647-1.648h1.419a.584.584,0,0,0,.583-.582.583.583,0,0,0-.583-.583H-31.94v-.888h1.416a.583.583,0,0,0,.583-.582.584.584,0,0,0-.583-.582H-31.94v-.886h1.416a.583.583,0,0,0,.583-.584.583.583,0,0,0-.583-.58H-31.94v-.886h1.416a.584.584,0,0,0,.583-.585.584.584,0,0,0-.583-.581H-31.94v-.885h1.416a.583.583,0,0,0,.583-.582.583.583,0,0,0-.583-.582H-31.94v-.887Zm-3.161,10.157H-44.912V245.722h11.227Z" transform="translate(141.81 -105.667)"/>
    </g>
  </g>
  </svg>`;
}