import AOS from 'aos';

$(document).ready(function() {
  AOS.init({
    disable: 'mobile'
  });

  //video

$('.home__top__box-2 li ').on({
    mouseenter: function () {
      let id = $(this).data('id');
      $('#video-box #'+id).get(0).play();
      $('#video-box video').css({
        opacity:'1',
        display:'none'
      });
      $('#video-box #'+id).css({
        opacity:'1',
        display:'block'
      });

    },

    mouseleave: function () {
      let id = $(this).data('id');
      $('#video-box #'+id).get(0).pause();
      $('#video-box #'+id).fadeToggle('fast');
    }
}, 'a');

  

  $('.home__top__box-2 li a').hover(function(){            
    //faded
    $('.home__top__box-2 li a').not($(this)).toggleClass('faded');

  });

});