export function employeesNumberSvg(){
  return`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.6397 2.677">
<g>
  <g>
  <path class="cls-1" d="M.455.6445A.3223.3223,0,1,0,.1327.3223.3221.3221,0,0,0,.455.6445Z"/>
  <path class="cls-1" d="M.7582.6875H.5319a.0138.0138,0,0,1-.0012.013L.457.8329c-.0058.01-.0153.01-.0211,0L.3622.7005A.0138.0138,0,0,1,.3609.6875H.1517A.1519.1519,0,0,0,0,.8391v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1516.1516,0,1,0,.3032,0V1.7491A.1518.1518,0,0,0,.91,1.5974V.8391A.1518.1518,0,0,0,.7582.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M2.17.6445A.3223.3223,0,1,0,1.848.3223.3222.3222,0,0,0,2.17.6445Z"/>
  <path class="cls-1" d="M2.4736.6875H2.2472a.0135.0135,0,0,1-.0012.013L2.1723.8329c-.0058.01-.0153.01-.0211,0L2.0775.7005a.0135.0135,0,0,1-.0012-.013H1.867a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,2.4736.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M3.8856.6445A.3223.3223,0,1,0,3.5633.3223.3222.3222,0,0,0,3.8856.6445Z"/>
  <path class="cls-1" d="M4.1889.6875H3.9626a.0138.0138,0,0,1-.0013.013L3.8876.8329c-.0058.01-.0153.01-.0211,0L3.7928.7005a.0135.0135,0,0,1-.0012-.013H3.5823a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,4.1889.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M5.6009.6445A.3223.3223,0,1,0,5.2787.3223.3222.3222,0,0,0,5.6009.6445Z"/>
  <path class="cls-1" d="M5.9042.6875H5.6779a.0136.0136,0,0,1-.0013.013L5.603.8329c-.0059.01-.0154.01-.0212,0L5.5082.7005a.0136.0136,0,0,1-.0013-.013H5.2976A.1518.1518,0,0,0,5.146.8391v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,5.9042.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M7.3162.6445A.3223.3223,0,1,0,6.994.3223.3222.3222,0,0,0,7.3162.6445Z"/>
  <path class="cls-1" d="M7.62.6875H7.3932a.0136.0136,0,0,1-.0013.013L7.3183.8329c-.0059.01-.0153.01-.0211,0L7.2235.7005a.0138.0138,0,0,1-.0013-.013H7.0129a.1518.1518,0,0,0-.1516.1516v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,7.62.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M9.0316.6445A.3223.3223,0,1,0,8.7093.3223.3221.3221,0,0,0,9.0316.6445Z"/>
  <path class="cls-1" d="M9.3348.6875H9.1085a.0138.0138,0,0,1-.0012.013L9.0336.8329c-.0058.01-.0153.01-.0211,0L8.9388.7005a.0138.0138,0,0,1-.0013-.013H8.7283a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1516.1516,0,1,0,.3032,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,9.3348.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M10.7469.6445a.3223.3223,0,1,0-.3223-.3222A.3222.3222,0,0,0,10.7469.6445Z"/>
  <path class="cls-1" d="M11.05.6875h-.2264a.0135.0135,0,0,1-.0012.013l-.0737.1324c-.0058.01-.0153.01-.0211,0L10.6541.7005a.0135.0135,0,0,1-.0012-.013h-.2093a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,11.05.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M12.4622.6445A.3223.3223,0,1,0,12.14.3223.3222.3222,0,0,0,12.4622.6445Z"/>
  <path class="cls-1" d="M12.7655.6875h-.2263a.0138.0138,0,0,1-.0013.013l-.0737.1324c-.0058.01-.0153.01-.0211,0L12.3694.7005a.0135.0135,0,0,1-.0012-.013h-.2093a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,12.7655.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M14.1775.6445a.3223.3223,0,1,0-.3222-.3222A.3222.3222,0,0,0,14.1775.6445Z"/>
  <path class="cls-1" d="M14.4808.6875h-.2263a.0136.0136,0,0,1-.0013.013L14.18.8329c-.0059.01-.0154.01-.0212,0L14.0848.7005a.0136.0136,0,0,1-.0013-.013h-.2093a.1518.1518,0,0,0-.1516.1516v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,14.4808.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M15.8928.6445a.3223.3223,0,1,0-.3222-.3222A.3222.3222,0,0,0,15.8928.6445Z"/>
  <path class="cls-1" d="M16.1961.6875H15.97a.0136.0136,0,0,1-.0013.013l-.0736.1324c-.0059.01-.0153.01-.0211,0L15.8.7005a.0138.0138,0,0,1-.0013-.013H15.59a.1518.1518,0,0,0-.1516.1516v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,16.1961.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M17.6082.6445a.3223.3223,0,1,0-.3223-.3222A.3221.3221,0,0,0,17.6082.6445Z"/>
  <path class="cls-1" d="M17.9114.6875h-.2263a.0138.0138,0,0,1-.0012.013L17.61.8329c-.0058.01-.0153.01-.0211,0L17.5154.7005a.0138.0138,0,0,1-.0013-.013h-.2092a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1516.1516,0,1,0,.3032,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,17.9114.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M19.3235.6445a.3223.3223,0,1,0-.3223-.3222A.3222.3222,0,0,0,19.3235.6445Z"/>
  <path class="cls-1" d="M19.6268.6875H19.4a.0135.0135,0,0,1-.0012.013l-.0737.1324c-.0058.01-.0153.01-.0211,0L19.2307.7005A.0135.0135,0,0,1,19.23.6875H19.02a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,19.6268.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M21.0388.6445a.3223.3223,0,1,0-.3223-.3222A.3222.3222,0,0,0,21.0388.6445Z"/>
  <path class="cls-1" d="M21.3421.6875h-.2263a.0138.0138,0,0,1-.0013.013l-.0737.1324c-.0058.01-.0153.01-.0211,0L20.946.7005a.0135.0135,0,0,1-.0012-.013h-.2093a.1519.1519,0,0,0-.1517.1516v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,21.3421.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M22.7541.6445a.3223.3223,0,1,0-.3222-.3222A.3222.3222,0,0,0,22.7541.6445Z"/>
  <path class="cls-1" d="M23.0574.6875h-.2263a.0136.0136,0,0,1-.0013.013l-.0736.1324c-.0059.01-.0154.01-.0212,0L22.6614.7005A.0136.0136,0,0,1,22.66.6875h-.2093a.1518.1518,0,0,0-.1516.1516v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1516-.1517V.8391A.1518.1518,0,0,0,23.0574.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M24.4694.6445a.3223.3223,0,1,0-.3222-.3222A.3222.3222,0,0,0,24.4694.6445Z"/>
  <path class="cls-1" d="M24.7727.6875h-.2263a.0136.0136,0,0,1-.0013.013l-.0736.1324c-.0059.01-.0153.01-.0211,0L24.3767.7005a.0138.0138,0,0,1-.0013-.013h-.2093a.1518.1518,0,0,0-.1516.1516v.7583a.1518.1518,0,0,0,.1516.1517v.7762a.1517.1517,0,1,0,.3033,0,.1517.1517,0,1,0,.3033,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,24.7727.6875Z"/>
  </g>
  <g>
  <path class="cls-1" d="M26.1848.6445a.3223.3223,0,1,0-.3223-.3222A.3221.3221,0,0,0,26.1848.6445Z"/>
  <path class="cls-1" d="M26.488.6875h-.2263a.0138.0138,0,0,1-.0012.013l-.0737.1324c-.0058.01-.0153.01-.0211,0L26.092.7005a.0138.0138,0,0,1-.0013-.013h-.2092A.1519.1519,0,0,0,25.73.8391v.7583a.1519.1519,0,0,0,.1517.1517v.7762a.1517.1517,0,1,0,.3033,0,.1516.1516,0,1,0,.3032,0V1.7491a.1518.1518,0,0,0,.1517-.1517V.8391A.1518.1518,0,0,0,26.488.6875Z"/>
  </g>
</g>
</svg>`;
}
