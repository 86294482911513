import { ourValues } from '../svg/our-values';
import { ourValuesEn } from '../svg/our-values-en';

$('.strategy__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.strategy__content').removeClass('active');
  $('.strategy__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.strategy__content[data-tab-id="'+id+'"]').addClass('active');
  }
});

($('html[lang=en]').length) ? $('.our-values__svg').html(ourValuesEn) : $('.our-values__svg').html(ourValues);

$('.our-values__btn').hover(function() {
  const id = $(this).attr('id').split('-')[0];
  $(this).css('opacity','0');
  $('#'+id).css('opacity','1');
}, function() {
  $(this).css('opacity','1');
  $('.our-values__text').css('opacity','0');
});
