import { employeesNumberSvg } from '../svg/liczba-pracownikow-2018';
import { employeesEducationSvg } from '../svg/wyksztalcenie';
import { employeesEducationEnSvg } from '../svg/wyksztalcenie-en';
import { averageAgeSvg } from '../svg/wiek';
import { averageAgeEnSvg } from '../svg/wiek-en';

$('.employees__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.employees__content').removeClass('active');
  $('.employees__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.employees__content[data-tab-id="'+id+'"]').addClass('active');
  }

  switch(parseInt(id)) {
    case 1:
      $('.employeesNumber__svg1').html(employeesNumberSvg);
      $('.employeesNumber__svg2').html(employeesNumberSvg);
      $('.employeesNumber__svg3').html(employeesNumberSvg);
      startFirstTabAnim(0,1,100);
      startFirstTabAnim(1,2,120);
      startFirstTabAnim(2,3,140);
     break;
    case 2:
      countdown('women', '%');
      countdown('men', '%');
    case 3:      
      ($('html[lang=en]').length) ? $('.employeesEducation__svg').html(employeesEducationEnSvg) : $('.employeesEducation__svg').html(employeesEducationSvg);
      startThirdTabAnim();
    case 4:
      ($('html[lang=en]').length) ? $('.average-age__svg').html(averageAgeEnSvg) : $('.average-age__svg').html(averageAgeSvg);
      startFourthTabAnim();
     break;
    default:
   } 
  
});

//first tab anim
function startFirstTabAnim(cond, number, delay){
  let length = $(".employeesNumber__svg"+number+" g > g").length;
  $(".employeesNumber__svg"+number+" g > g").each(function(i, elem) {
    if(i<length-cond){
      let that = elem;
      let t = setTimeout(function() { 
          $(that).addClass('active');
      }, delay * i); 
    }
  });
}

//third tab anim
function startThirdTabAnim(){
  for(let i=4; i>=1; i--) {
      let t = setTimeout(function() { 
        $('.employeesEducation__svg svg [data-anim='+i+']').fadeIn();
      }, 600 / i); 
  };
  
  $('.employeesEducation__svg svg [data-anim=5]').delay(800).fadeIn();  
  $('.employeesEducation__svg svg [data-anim=6]').delay(850).fadeIn();  
  $('.employeesEducation__svg svg [data-anim=7]').delay(900).fadeIn();
  $('.employeesEducation__svg svg [data-anim=8]').delay(950).fadeIn();
};

//fourth tab anim
function startFourthTabAnim(){
  let i = 0;  
  let barWidth = setInterval(function() {   
    i++; 
    $('.average-age .redBar').css('width',i+'%');
    if (i>=42) {        
        clearInterval(barWidth);
        $('.average-age .arrow').fadeIn();
        $('.average-age .people1').delay(600).fadeIn();
        $('.average-age .people2').delay(600).fadeIn();
        $('.average-age .cls-3').delay(600).fadeIn();
    }
  }, 20);
};

//check if position
if($('.employees').length > 0) {
  let isChecked = false;
  $(document).on('scroll', function() {
    if($(this).scrollTop()>=$('.employees').position().top-300){
      if(!isChecked){
        $('.employeesNumber__svg1').html(employeesNumberSvg);
        $('.employeesNumber__svg2').html(employeesNumberSvg);
        $('.employeesNumber__svg3').html(employeesNumberSvg);
        startFirstTabAnim(0,1,100);
        startFirstTabAnim(1,2,120);
        startFirstTabAnim(2,3,140);
      }
      isChecked = true;
    }
  });
}

function countdown(id, value){
	let start = $('#countdown-'+ id).data('start');
	let stop = $('#countdown-'+ id).data('stop');
	let duration = $('#countdown-'+ id).data('duration');
	let interval = 50;
	let step = (stop - start) / (duration / interval);
	let i = parseInt(start);

	let counInterval = setInterval(function(){
		if (i < stop){
			i += Math.ceil(step);
		} else{ 
			i = stop;
			clearInterval(counInterval);
		}
		$('#countdown-'+ id).html(i + value); 
	}, interval);
}