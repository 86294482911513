export function employeesEducationSvg(){
    return`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 577.5947 356.375">
    <text class="cls-1" data-anim="8" transform="translate(398.999 82.2769)">52%</text>
    <text class="cls-2" data-anim="6" transform="translate(371.999 260.2788)">13%</text>
    <text class="cls-3" data-anim="7" transform="translate(0 174.2769)">31%</text>
    <text class="cls-4" data-anim="5" transform="translate(110.999 344.2729)">4%</text>
    
    <g>
    <path class="cls-5" data-anim="1" d="M381.7659,62.3552c-6.2205-17.0694-17.5793-32.3948-36.7118-42.0681C288.7193-8.1917,232.6648-2.8,202.9326,12.7665c-23.757,12.4371-38.1521,30.9584-46.7627,49.5887a122.294,122.294,0,0,0-6.0063,16.8942,146.3232,146.3232,0,0,0-5.2863,24.119c-1.0744,9.3385,3.2,16.575,5.0177,22.6476a14.4881,14.4881,0,0,1,.6072,4.8074H385.1174c.3348-1.6816.7864-3.0363,1.0744-4.8074,2.2733-14.0448,1.7206-30.5341-1.195-46.7666C383.9769,73.5739,383.7511,67.805,381.7659,62.3552Z"/>
    <text class="cls-6" data-anim="1" transform="translate(237.3617 69.95)">WYŻSZE</text>
    <path data-anim="4" d="M212.6332,306.2389c5.5548,9.319,91.2909,21.8262,133.76-7.77,3.702-2.5808,3.6086-8.26,3.013-17.49H212.26C213.7153,289.15,209.7137,301.3458,212.6332,306.2389Z"/>
    <path data-anim="3" class="cls-7" d="M141.5685,221.402a41.5832,41.5832,0,0,0,8.0851,4.4844s-7.3338,6.7576-7.6335,9.9146c-.0506.545.7085,1.3235.9187,1.9463,1.2456,3.6591,5.656,8.2291,5.7572,11.4484a13.86,13.86,0,0,1-.9576,4.1379c-1.5609,4.7452-4.29,11.8843-2.102,19.331,4.8386,16.5088,60.0522-1.23,64.2563,2.6976,1.4481,1.3547,1.9775,3.4372,2.3668,5.6171H349.4061a275.8433,275.8433,0,0,1-.8447-27.6457c.1167-4.4338.9186-10.3506,1.3819-15.5863.4087-4.5972.4321-8.4159,1.2145-13.6788.144-.9771.6072-1.705.7746-2.6665Z"/>
    <path data-anim="2" class="cls-8" d="M150.5022,130.8234c-.0078.2608.1324.5606.1012.8175-.8914,7.4311-9.9146,17.1083-18.3539,26.8594-7.3416,8.4821-14.2706,17.0188-14.6209,24.1424-.1323,2.7132,1.234,5.0021,3.3633,7.0379,5.9324,5.6366,17.8712,9.2724,20.5494,14.38,1.5921,3.0363-2.9857,9.9186-2.5069,14.1343.1246,1.0783,1.199,2.1683,2.5341,3.2076H351.9324a144.2477,144.2477,0,0,1,9.2373-31.7214c4.3832-10.3856,9.7006-20.5221,15.0024-31.18.6034-1.2145,1.1055-2.32,1.7089-3.55,2.6548-5.3991,5.2512-14.1148,7.2364-24.1268Z"/>
    <text data-anim="2" class="cls-6" transform="translate(222.4564 180.7303)">ŚREDNIE</text>
    <text class="cls-6" data-anim="3" transform="translate(206.141 255.2205)">Z<tspan class="cls-9" x="8.6865" y="0">A</tspan><tspan class="cls-10" x="17.0923" y="0">W</tspan><tspan x="30.3071" y="0">OD</tspan><tspan class="cls-10" x="49.7593" y="0">O</tspan><tspan x="59.0195" y="0">WE</tspan></text>
    <text class="cls-6" data-anim="4" transform="translate(227.0307 302.9861)">PODS<tspan class="cls-11" x="37.2075" y="0">T</tspan><tspan class="cls-9" x="44.4272" y="0">A</tspan><tspan class="cls-10" x="52.833" y="0">WO</tspan><tspan x="75.3081" y="0">WE</tspan></text>
  </g>
  
</svg>`;
}
