import { developmentSvg } from '../svg/development';
import { developmentEnSvg } from '../svg/development-en';
import { developmentSvgMob } from '../svg/development-mob';
import { developmentEnSvgMob } from '../svg/development-mob-en';

$(window).resize(function(){
  displaySvg();
});
displaySvg();

function displaySvg() {
  if($(window).width() > 767) {
    ($('html[lang=en]').length) ? $('.development__svg').html(developmentEnSvg).removeClass('mobile').addClass('desktop') : $('.development__svg').html(developmentSvg).removeClass('mobile').addClass('desktop');
  } else {
    ($('html[lang=en]').length) ? $('.development__svg').html(developmentEnSvgMob).removeClass('desktop').addClass('mobile') : $('.development__svg').html(developmentSvgMob).removeClass('desktop').addClass('mobile');
  }
}