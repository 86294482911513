import { realizationPlay } from '../svg/realizacje';

let realizations = $('.realizations__carousel');
realizations.owlCarousel({
  items:3,
  loop: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayHoverPause: true,
  smartSpeed: 2000,
  dots: true,
  mouseDrag: false,
  touchDrag: false,
  slideBy: 3,
  responsive:{
    0:{
        items:1,
        slideBy: 1,
        dots: false,
    },
    768:{
        items:2,
        slideBy: 2,
    },
    992:{
        items:3,
    }
  }
});

$('.realizations__play').html(realizationPlay);
