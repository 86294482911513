export function ourValues(){
  return `<svg id="drzewo-wartosci" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1330.4425 1046.3044">
  <path class="cls-1" d="M61.88,838.3975c291.0258-1.0616,397.1849-2.0881,536.83.004,265.6568,3.98,62.32,21.91,15.1617,91.0251,25.45-23.6325,57.6675-34.4315,91.5992-38.3239,5.8426-.67,41.6711-.1259,43.8846-3.748-4.7737,7.8119-21.4212-11.5669-22.5707,105.3717,3.5792-25.8183,58.4663-114.5763,78.1325-108.8941,5.1907,1.5,11.4612,11.485,17.7928,14.4373,29.2859,13.6556,89.5995,13.8884,121.2444,46.5541-14.2138-36.867-51.7744-57.643-85.5665-73.6672-128.4065-60.8908-103.2649-11.4477-98.4822-214.7-52.9643,6.459-110.7806,5.8236-158.0325-22.1281-38.7283-22.91-71.2387-60.4061-86.9776-102.6719C471.794,415.91,592.6592,330.6646,661.7738,386.1491c-30.0721-72.6743-22.412-180.2216,53.6574-220.8833,91.0877-48.69,209.5938-14.2652,187.94,104.9664-7.2818,40.096,25.7358,16.2184,48.1972,10.7871,61.0146-14.7538,119.0065,80.9678,40.3906,153.0323,16.359,17.7668,70.8138,16.19,70.2256,81.3247-1.5118,167.38-334.311,159.8385-247.7435,24.6568,25.4934-39.81,44.3525-66.493,65.1011-109.3408-110.4456,105.745-80.06,157.92-96.3013-112.3995-11.0565,88.13-8.0373,99.035-12.59,187.6451-6.6724,129.8694.2669,96.4873-144.4912,8.0086,9.287,20.6221,89.6506,91.3712,135.0417,111.7924,8.3683,3.7647,32.0551,7.8689,36.8621,14.6676.992,1.4028,8.3275,145.6843,5.7066,182.95,184.1959,0,346.5256,1.2948,530.72.3117" transform="translate(-58.8798 50.5781)"/>
  <text class="cls-4" transform="translate(153.3301 600.1877)">Partnerstwo</text>
  <text class="cls-4" transform="translate(185.5994 287.7373)">Odpowiedzialność</text>
  <text class="cls-4" transform="translate(722.8764 104.3331)">Satysfa<tspan class="cls-5" x="132.1182" y="0">k</tspan><tspan class="cls-6" x="152.0264" y="0">cja</tspan></text>
  <text class="cls-4" transform="translate(1075.6019 419.8826)">Innowacyjność</text>
  <text class="cls-4" transform="translate(1050.1619 671.7559)">Rozwój</text>
  <g id="partnerstwo" class="our-values__text">
    <g>
      <path class="cls-2" d="M307.08,538.8775c0,116.1624,94.5077,210.67,210.67,210.67s210.6641-94.5078,210.6641-210.67-94.5023-210.67-210.6641-210.67S307.08,422.7157,307.08,538.8775Z" transform="translate(-58.8798 50.5781)"/>
      <g>
        <path class="cls-3" d="M439.7964,489.8892c0,.1308-.0752.1953-.2241.1953h-2.2959a.5245.5245,0,0,1-.5039-.252l-6.188-10.332c-.0381-.0928-.0938-.14-.1685-.14-.0556,0-.14.0557-.2519.168l-3.22,3.668a.683.683,0,0,0-.1963.5049v5.9629a.4074.4074,0,0,1-.1118.3222.5413.5413,0,0,1-.3637.0977h-1.792q-.42,0-.42-.3633V469.645c0-.2246.1025-.3359.3081-.3359H426.44c.2051,0,.3076.1113.3076.3359V479.92c0,.1309.0284.1973.084.1973q.0279,0,.168-.1406l9.1841-10.4161a.7093.7093,0,0,1,.2241-.1963.7822.7822,0,0,1,.3359-.0556h1.9322a.3073.3073,0,0,1,.2939.126c.0464.0839.0235.1728-.07.2666l-6.5239,7.2793a.3215.3215,0,0,0,0,.3632l7.3637,12.3487A1.2185,1.2185,0,0,1,439.7964,489.8892Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M446.9922,472.0532a.6013.6013,0,0,1-.1123.4063.5048.5048,0,0,1-.3916.125h-1.624q-.42,0-.42-.4756v-2.3526c0-.2978.13-.4472.3916-.4472h1.7647a.38.38,0,0,1,.2793.1113.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5557a.4943.4943,0,0,1-.1113.3779.5983.5983,0,0,1-.3926.0977h-1.4278a.4826.4826,0,0,1-.3359-.084.4341.4341,0,0,1-.084-.3076V475.6372q0-.3926.336-.3926h1.708c.205,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M454.8457,486.0806a4.9546,4.9546,0,0,0,1.7783,1.82,4.6971,4.6971,0,0,0,2.4639.6719,4.9808,4.9808,0,0,0,4.3682-2.3243.4008.4008,0,0,1,.1962-.125.3205.3205,0,0,1,.1954.0411l1.1767.5878c.1485.0938.168.2247.0557.3926a6.82,6.82,0,0,1-2.6035,2.4492,7.8742,7.8742,0,0,1-3.6123.7705,7.1121,7.1121,0,0,1-3.668-.9521,6.5947,6.5947,0,0,1-2.5342-2.6738,8.2954,8.2954,0,0,1-.91-3.9336,8.9083,8.9083,0,0,1,.9238-4.1582,6.6488,6.6488,0,0,1,2.5342-2.7305,6.9962,6.9962,0,0,1,3.6259-.9512,6.8733,6.8733,0,0,1,3.542.8955,5.9846,5.9846,0,0,1,2.3662,2.6182,9.2075,9.2075,0,0,1,.84,4.0742v.1114a.6936.6936,0,0,1-.084.3789.3776.3776,0,0,1-.3359.125H454.44c-.1679,0-.2519.1406-.2519.42A4.9246,4.9246,0,0,0,454.8457,486.0806Zm7.91-4.4512a.699.699,0,0,0,.3925-.07.4319.4319,0,0,0,.084-.3223,5.08,5.08,0,0,0-.5185-2.2812,4.1023,4.1023,0,0,0-1.5118-1.6807,4.223,4.223,0,0,0-2.3095-.63,4.3684,4.3684,0,0,0-2.4922.7139,4.6888,4.6888,0,0,0-1.624,1.8486,5.1285,5.1285,0,0,0-.5323,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M470.2881,489.7212V475.6089c0-.2422.1025-.3643.3086-.3643h1.624a.2726.2726,0,0,1,.3076.3086v1.82c0,.1114.0322.1778.0987.1953.0644.02.1347-.0175.21-.1113q2.127-2.4932,4.34-2.4922.98,0,.9795.3633v1.68c0,.1875-.084.2617-.252.2246a7.7932,7.7932,0,0,0-1.26-.084,3.959,3.959,0,0,0-1.7636.4766,5.0009,5.0009,0,0,0-1.5967,1.2314,2.3669,2.3669,0,0,0-.6436,1.54v9.2676a.3709.3709,0,0,1-.42.42h-1.54Q470.288,490.0845,470.2881,489.7212Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M489.7061,487.8872a10.4461,10.4461,0,0,0,2.2539-1.8066V475.6372q0-.3926.4482-.3926h1.54a.3467.3467,0,0,1,.3916.3926l-.0556,14.084q0,.3633-.3076.3633h-1.6241a.2718.2718,0,0,1-.3086-.3076v-1.6514c0-.2246-.0839-.252-.2519-.084a11.5259,11.5259,0,0,1-2.6875,1.8057,5.9294,5.9294,0,0,1-2.4082.5175,4.53,4.53,0,0,1-3.22-1.1757,4.0483,4.0483,0,0,1-1.2608-3.1084v-10.416a.3717.3717,0,0,1,.42-.42h1.5684c.2422,0,.3643.1309.3643.3926v9.8281a3.13,3.13,0,0,0,.7695,2.2256,2.7488,2.7488,0,0,0,2.1142.8262A4.505,4.505,0,0,0,489.7061,487.8872Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M501.5215,493.9067a4.0525,4.0525,0,0,1-3.0371.9375,9.8623,9.8623,0,0,1-1.5264-.0693c-.2148-.0469-.3223-.1445-.3223-.2939v-1.2325c0-.205.0938-.3076.28-.3076l1.4278-.084a1.8081,1.8081,0,0,0,1.3017-.5185,2.456,2.456,0,0,0,.4063-1.6094V475.6372q0-.3926.3359-.3926h1.708c.2051,0,.3086.1221.3086.3643V490.897A4.2914,4.2914,0,0,1,501.5215,493.9067Zm.9668-21.8535q0,.5317-.4766.5313H500.36q-.42,0-.4209-.4756v-2.3526a.4678.4678,0,0,1,.1123-.3212.3584.3584,0,0,1,.28-.126h1.792a.3352.3352,0,0,1,.2656.1113.4534.4534,0,0,1,.0987.3086Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M510.3418,486.0806a4.9538,4.9538,0,0,0,1.7783,1.82,4.6945,4.6945,0,0,0,2.4639.6719,4.9805,4.9805,0,0,0,4.3681-2.3243.4035.4035,0,0,1,.1954-.125.3231.3231,0,0,1,.1963.0411l1.1757.5878q.2241.1407.0567.3926a6.8281,6.8281,0,0,1-2.6045,2.4492,7.8684,7.8684,0,0,1-3.6123.7705,7.1173,7.1173,0,0,1-3.668-.9521,6.5924,6.5924,0,0,1-2.5332-2.6738,8.2836,8.2836,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9239-4.1582,6.6422,6.6422,0,0,1,2.5342-2.7305,6.9936,6.9936,0,0,1,3.6259-.9512,6.8736,6.8736,0,0,1,3.542.8955,5.9893,5.9893,0,0,1,2.3653,2.6182,9.195,9.195,0,0,1,.8408,4.0742v.1114a.6936.6936,0,0,1-.084.3789.3786.3786,0,0,1-.3359.125H509.9355c-.1679,0-.2519.1406-.2519.42A4.9255,4.9255,0,0,0,510.3418,486.0806Zm7.91-4.4512a.6932.6932,0,0,0,.3916-.07c.0566-.0469.0839-.1543.0839-.3223a5.0924,5.0924,0,0,0-.5175-2.2812,4.0982,4.0982,0,0,0-1.5127-1.6807,4.2212,4.2212,0,0,0-2.31-.63,4.3684,4.3684,0,0,0-2.4922.7139,4.6966,4.6966,0,0,0-1.624,1.8486,5.1392,5.1392,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M525.7832,489.7212V475.6089c0-.2422.1025-.3643.3086-.3643h1.68c.1865,0,.28.1035.28.3086v1.6231c0,.2246.0927.2529.28.084a14.9387,14.9387,0,0,1,2.5752-1.7637,5.2664,5.2664,0,0,1,2.3809-.5313,4.49,4.49,0,0,1,2.59.6993,3.485,3.485,0,0,1,1.3858,1.7646c.0371.0557.0742.084.1123.084a.6137.6137,0,0,0,.2793-.14,13.9528,13.9528,0,0,1,2.6181-1.82,5.3485,5.3485,0,0,1,2.45-.5879,3.9446,3.9446,0,0,1,4.2842,4.2559v10.416c0,.2988-.14.4473-.42.4473H545.02a.3218.3218,0,0,1-.3642-.3633v-9.9961a3.14,3.14,0,0,0-.6719-2.1563,2.557,2.557,0,0,0-2.0156-.7558,4.2381,4.2381,0,0,0-2.1.5459,11.0087,11.0087,0,0,0-1.9043,1.3584,1.6413,1.6413,0,0,0-.3086.3916.94.94,0,0,0-.084.42v10.1084c0,.2988-.14.4473-.42.4473H535.584a.3214.3214,0,0,1-.3643-.3633v-9.9961q0-2.9121-2.6884-2.9121a4.1913,4.1913,0,0,0-2.0577.5176,14.3046,14.3046,0,0,0-2.3379,1.75v10.584a.357.357,0,0,1-.42.42h-1.54Q525.7832,490.0845,525.7832,489.7212Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M552.02,494.6489c-.2422-.0371-.3642-.1406-.3642-.3086v-1.0918a.2924.2924,0,0,1,.1543-.2793,1.5174,1.5174,0,0,1,.63-.0839h1.7646a1.8321,1.8321,0,0,0,1.2314-.49,3.699,3.699,0,0,0,1.2041-2.6181,1.6669,1.6669,0,0,0-.1123-.6719L551.18,475.7212a.391.391,0,0,1-.0283-.168c0-.2051.1123-.3086.3359-.3086h1.7363a.4686.4686,0,0,1,.4483.3086l4.1719,10.8633c.0556.168.1308.252.2236.252.1123,0,.2051-.1114.28-.336l4.1162-10.7793a.4345.4345,0,0,1,.3916-.3086h1.3164a.2825.2825,0,0,1,.2519.127.2721.2721,0,0,1,.0283.2656l-6.16,15.7637a5.2109,5.2109,0,0,1-1.876,2.6035,5.7289,5.7289,0,0,1-3.0527.7012A10.5194,10.5194,0,0,1,552.02,494.6489Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M583.5059,477.1353a5.164,5.164,0,0,0-2.3663-.5176,4.436,4.436,0,0,0-2.52.6572,1.9939,1.9939,0,0,0-.98,1.7217,1.6468,1.6468,0,0,0,.49,1.1621,4.1388,4.1388,0,0,0,1.7773.91l3.5567,1.0645a5.58,5.58,0,0,1,2.7431,1.498,3.4118,3.4118,0,0,1,.8965,2.3653,3.5817,3.5817,0,0,1-.7842,2.2822,5.0363,5.0363,0,0,1-2.1562,1.54,9.0731,9.0731,0,0,1-6.9434-.3085,5.1753,5.1753,0,0,1-2.38-2.2247.4722.4722,0,0,1-.0839-.2246.2533.2533,0,0,1,.14-.2246l1.0918-.6152c.1865-.1123.3262-.1025.42.0273a5.1306,5.1306,0,0,0,4.6758,2.4082,5.3355,5.3355,0,0,0,2.7305-.6855,1.964,1.964,0,0,0,1.0781-1.7227,1.6427,1.6427,0,0,0-.6445-1.3154,5.9893,5.9893,0,0,0-2.1-.9521l-2.8838-.8125a5.9819,5.9819,0,0,1-2.9258-1.4834,3.2893,3.2893,0,0,1-.8828-2.3243,3.8775,3.8775,0,0,1,.7138-2.2959,4.57,4.57,0,0,1,2.0166-1.5546,8.3182,8.3182,0,0,1,6.3.1543,5.3534,5.3534,0,0,1,2.2539,1.9042q.1962.2813-.0284.3926l-1.1757.6436a.4142.4142,0,0,1-.168.0556.3859.3859,0,0,1-.252-.14A4.18,4.18,0,0,0,583.5059,477.1353Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M594.4111,472.0532a.6013.6013,0,0,1-.1123.4063.5046.5046,0,0,1-.3916.125h-1.624q-.42,0-.42-.4756v-2.3526c0-.2978.13-.4472.3916-.4472H594.02a.38.38,0,0,1,.2793.1113.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5557a.4943.4943,0,0,1-.1113.3779.5983.5983,0,0,1-.3926.0977h-1.4277a.4823.4823,0,0,1-.3359-.084.4341.4341,0,0,1-.084-.3076V475.6372q0-.3926.3359-.3926h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M613.0029,482.6646a.6936.6936,0,0,1-.084.3789.3773.3773,0,0,1-.3359.125H601.8594c-.168,0-.252.1406-.252.42a5.02,5.02,0,0,0,2.4356,4.3125,4.6967,4.6967,0,0,0,2.4638.6719,4.9808,4.9808,0,0,0,4.3682-2.3243.4006.4006,0,0,1,.1963-.125.32.32,0,0,1,.1953.0411l1.1768.5878c.1484.0938.1679.2247.0556.3926a6.5591,6.5591,0,0,1-3.6963,2.8838,5.9063,5.9063,0,0,0-1.6093,1.0225,1.5239,1.5239,0,0,0-.49,1.1054.9508.9508,0,0,0,.336.8125,1.7849,1.7849,0,0,0,1.0644.252,4.233,4.233,0,0,0,.9932-.1123,3.6344,3.6344,0,0,0,.7422-.252,1.08,1.08,0,0,1,.1963-.0556c.0742,0,.1123.0644.1123.1953v.8125a.3554.3554,0,0,1-.168.3086,4.01,4.01,0,0,1-1.0508.3359,6.9453,6.9453,0,0,1-1.3857.1387,3.1575,3.1575,0,0,1-2.086-.6016,2.0631,2.0631,0,0,1-.7138-1.666,2.2186,2.2186,0,0,1,.2656-1.0781,2.4894,2.4894,0,0,1,.8818-.8819,6.8639,6.8639,0,0,1-3.4853-1.0634,6.6821,6.6821,0,0,1-2.38-2.6739,8.3636,8.3636,0,0,1-.8545-3.8222,8.91,8.91,0,0,1,.9238-4.1582,6.6494,6.6494,0,0,1,2.5342-2.7305,6.9963,6.9963,0,0,1,3.626-.9512,6.8739,6.8739,0,0,1,3.542.8955,5.9852,5.9852,0,0,1,2.3662,2.6182,9.2075,9.2075,0,0,1,.84,4.0742Zm-2.4355-1.1055a.4323.4323,0,0,0,.084-.3223,5.08,5.08,0,0,0-.5186-2.2812,4.1027,4.1027,0,0,0-1.5117-1.6807,4.2234,4.2234,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.69,4.69,0,0,0-1.624,1.8486,5.1284,5.1284,0,0,0-.5322,2.4219h8.5117A.6994.6994,0,0,0,610.5674,481.5591Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M365.2324,523.3442a.3766.3766,0,0,1-.07-.2656v-.8125a.9065.9065,0,0,1,.07-.3779,3.0446,3.0446,0,0,1,.2661-.4346l8.148-10.9473.084-.1679c0-.0742-.0747-.1123-.2242-.1123h-7.42a.3885.3885,0,0,1-.2378-.0557q-.07-.0556-.07-.2519v-1.0372c0-.205.0933-.3066.28-.3066h10.36c.1679,0,.2519.0928.2519.2793v.8965a1.1939,1.1939,0,0,1-.28.7275l-8.1762,10.9756c-.0748.0938-.1026.168-.084.2246s.0742.084.1679.084h8.232q.3082,0,.3081.252v1.0644c0,.2236-.1216.336-.3638.336H365.4985A.3772.3772,0,0,1,365.2324,523.3442Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M381.3462,523.05V508.938q0-.3633.3081-.3633h1.624a.2715.2715,0,0,1,.3081.3066v1.82c0,.1123.0323.1777.0982.1962s.1352-.0185.21-.1123q2.1277-2.49,4.34-2.4921.98,0,.98.3652v1.68c0,.1865-.084.2617-.252.2236a7.8977,7.8977,0,0,0-1.26-.084,3.9871,3.9871,0,0,0-1.7641.4756,5.0244,5.0244,0,0,0-1.5962,1.2324,2.3676,2.3676,0,0,0-.6441,1.54v9.2685a.3713.3713,0,0,1-.42.42h-1.54Q381.3458,523.4146,381.3462,523.05Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M395.6123,522.728a6.511,6.511,0,0,1-2.4922-2.7021,9.3341,9.3341,0,0,1,.0283-7.9805,7.0989,7.0989,0,0,1,9.8838-2.7578,6.6775,6.6775,0,0,1,2.4922,2.7578,8.84,8.84,0,0,1,.8819,4.0049,8.49,8.49,0,0,1-.896,3.9482,6.7091,6.7091,0,0,1-2.5059,2.7149,6.9321,6.9321,0,0,1-3.6821.98A7.0736,7.0736,0,0,1,395.6123,522.728Zm7.168-2.38a8.3107,8.3107,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4019-1.61,4.1363,4.1363,0,0,0-3.4438,1.61,8.1276,8.1276,0,0,0,0,8.6944,4.1807,4.1807,0,0,0,3.4721,1.5957A4.0492,4.0492,0,0,0,402.78,520.3481Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M410.0605,523.3442a.3771.3771,0,0,1-.07-.2656v-.8125a.9081.9081,0,0,1,.07-.3779,3.0379,3.0379,0,0,1,.2662-.4346l8.1479-10.9473.084-.1679c0-.0742-.0747-.1123-.2241-.1123h-7.42a.3881.3881,0,0,1-.2378-.0557q-.07-.0556-.07-.2519v-1.0372c0-.205.0933-.3066.28-.3066h10.36c.168,0,.2519.0928.2519.2793v.8965a1.1944,1.1944,0,0,1-.28.7275l-8.1763,10.9756c-.0747.0938-.1026.168-.084.2246s.0742.084.168.084h8.2319q.3084,0,.3081.252v1.0644c0,.2236-.1216.336-.3638.336h-10.976A.3774.3774,0,0,1,410.0605,523.3442Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M433.4683,521.2153a10.4721,10.4721,0,0,0,2.2539-1.8047V508.9653c0-.26.1489-.3906.4477-.3906h1.54a.3463.3463,0,0,1,.3921.3906l-.0562,14.085q0,.3648-.3076.3643h-1.624a.2726.2726,0,0,1-.3081-.3086v-1.6524q0-.3354-.252-.084a11.5327,11.5327,0,0,1-2.688,1.8067,5.913,5.913,0,0,1-2.4082.5175,4.53,4.53,0,0,1-3.22-1.1757,4.05,4.05,0,0,1-1.26-3.1075v-10.416a.3707.3707,0,0,1,.42-.42h1.5684c.2422,0,.3638.1309.3638.3906v9.8282a3.1337,3.1337,0,0,0,.77,2.2265,2.7478,2.7478,0,0,0,2.1138.8262A4.4993,4.4993,0,0,0,433.4683,521.2153Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M443.7861,523.05V508.938q0-.3633.3077-.3633h1.6806c.1865,0,.2793.1016.2793.3066v1.625q0,.3356.28.084a14.7364,14.7364,0,0,1,2.5762-1.7636,5.2334,5.2334,0,0,1,2.38-.5332,4.4841,4.4841,0,0,1,2.59.7011,3.4845,3.4845,0,0,1,1.3867,1.7637c.0371.0557.0742.084.1113.084a.6165.6165,0,0,0,.28-.1406,14.0556,14.0556,0,0,1,2.6182-1.82,5.37,5.37,0,0,1,2.45-.5878A3.9457,3.9457,0,0,1,465.01,512.55v10.415c0,.2989-.14.4493-.42.4493h-1.5673a.3217.3217,0,0,1-.3643-.3643v-9.9961a3.1411,3.1411,0,0,0-.6719-2.1563,2.5591,2.5591,0,0,0-2.0166-.7558,4.2541,4.2541,0,0,0-2.1.5459,11.04,11.04,0,0,0-1.9043,1.3574,1.65,1.65,0,0,0-.3076.3926.94.94,0,0,0-.084.42v10.1074c0,.2989-.14.4493-.42.4493h-1.5684a.3221.3221,0,0,1-.3642-.3643v-9.9961q0-2.9121-2.6875-2.9121a4.1792,4.1792,0,0,0-2.0576.5185,14.19,14.19,0,0,0-2.3389,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C443.916,523.4146,443.7861,523.2935,443.7861,523.05Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M473.0176,505.3813a.6.6,0,0,1-.1123.4063.5049.5049,0,0,1-.3916.127H470.89c-.28,0-.42-.1583-.42-.4766v-2.3516c0-.2988.13-.4482.3916-.4482h1.7647a.3807.3807,0,0,1,.2793.1123.4138.4138,0,0,1,.1123.3076Zm-.1123,17.5567a.4938.4938,0,0,1-.1114.3779.6031.6031,0,0,1-.3925.0987h-1.4278a.4879.4879,0,0,1-.3359-.084.437.437,0,0,1-.084-.3086V508.9653c0-.26.1123-.3906.3359-.3906h1.7081q.3075,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M480.8711,519.4106a4.9581,4.9581,0,0,0,1.7783,1.8194,4.6971,4.6971,0,0,0,2.4639.6719,4.9841,4.9841,0,0,0,4.3681-2.3233.4071.4071,0,0,1,.1963-.1269.33.33,0,0,1,.1953.0429l1.1768.5879c.1484.0938.168.2237.0557.3916a6.83,6.83,0,0,1-2.6035,2.45,7.8872,7.8872,0,0,1-3.6124.7695,7.1123,7.1123,0,0,1-3.6679-.9511,6.6092,6.6092,0,0,1-2.5342-2.6748,8.2926,8.2926,0,0,1-.91-3.9336,8.9057,8.9057,0,0,1,.9239-4.1582,6.6516,6.6516,0,0,1,2.5342-2.7295,6.988,6.988,0,0,1,3.6259-.9531,6.8643,6.8643,0,0,1,3.542.8964,5.99,5.99,0,0,1,2.3662,2.6182,9.2033,9.2033,0,0,1,.84,4.0732v.1133a.6861.6861,0,0,1-.084.377.3777.3777,0,0,1-.3359.1269H480.4658q-.2518,0-.2519.42A4.9293,4.9293,0,0,0,480.8711,519.4106Zm7.91-4.4531a.7014.7014,0,0,0,.3925-.0693c.0557-.0459.084-.1543.084-.3223a5.084,5.084,0,0,0-.5185-2.2822,4.1128,4.1128,0,0,0-1.5118-1.68,4.223,4.223,0,0,0-2.31-.63,4.3608,4.3608,0,0,0-2.4922.7139,4.6928,4.6928,0,0,0-1.624,1.8476,5.1338,5.1338,0,0,0-.5323,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M496.3135,523.05V508.938q0-.3633.3076-.3633h1.6807a.2712.2712,0,0,1,.3076.3066v1.625q0,.3077.2519.084a15.2869,15.2869,0,0,1,2.6739-1.7636,5.6331,5.6331,0,0,1,2.4785-.5332,4.58,4.58,0,0,1,3.3174,1.1484,4.08,4.08,0,0,1,1.19,3.08v10.4433c0,.2989-.14.4493-.42.4493h-1.5684a.3214.3214,0,0,1-.3633-.3643v-9.9961a3.02,3.02,0,0,0-.7148-2.1563,2.89,2.89,0,0,0-2.1973-.7558,4.618,4.618,0,0,0-2.17.5185,13.5359,13.5359,0,0,0-2.4229,1.75v10.584a.3579.3579,0,0,1-.42.42h-1.54C496.4434,523.4146,496.3135,523.2935,496.3135,523.05Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M516.5293,505.3813a.6.6,0,0,1-.1123.4063.5049.5049,0,0,1-.3916.127h-1.624c-.28,0-.42-.1583-.42-.4766v-2.3516c0-.2988.13-.4482.3916-.4482h1.7647a.3807.3807,0,0,1,.2793.1123.4138.4138,0,0,1,.1123.3076Zm-.1123,17.5567a.4943.4943,0,0,1-.1113.3779.6036.6036,0,0,1-.3926.0987h-1.4277a.488.488,0,0,1-.336-.084.4375.4375,0,0,1-.084-.3086V508.9653c0-.26.1123-.3906.336-.3906h1.708q.3076,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M524.3828,519.4106a4.9589,4.9589,0,0,0,1.7783,1.8194,4.6971,4.6971,0,0,0,2.4639.6719,4.9843,4.9843,0,0,0,4.3682-2.3233.4062.4062,0,0,1,.1963-.1269.3307.3307,0,0,1,.1953.0429l1.1767.5879c.1485.0938.168.2237.0557.3916a6.83,6.83,0,0,1-2.6035,2.45,7.8871,7.8871,0,0,1-3.6123.7695,7.1125,7.1125,0,0,1-3.668-.9511,6.61,6.61,0,0,1-2.5342-2.6748,8.2925,8.2925,0,0,1-.91-3.9336,8.9069,8.9069,0,0,1,.9238-4.1582,6.6516,6.6516,0,0,1,2.5342-2.7295,6.988,6.988,0,0,1,3.6259-.9531,6.864,6.864,0,0,1,3.542.8964,5.99,5.99,0,0,1,2.3663,2.6182,9.2047,9.2047,0,0,1,.84,4.0732v.1133a.6861.6861,0,0,1-.084.377.3777.3777,0,0,1-.3359.1269H523.9775q-.2518,0-.2519.42A4.9293,4.9293,0,0,0,524.3828,519.4106Zm7.91-4.4531a.7014.7014,0,0,0,.3925-.0693c.0557-.0459.084-.1543.084-.3223a5.084,5.084,0,0,0-.5185-2.2822,4.1126,4.1126,0,0,0-1.5117-1.68,4.2236,4.2236,0,0,0-2.31-.63,4.3608,4.3608,0,0,0-2.4922.7139,4.6928,4.6928,0,0,0-1.624,1.8476,5.1337,5.1337,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M539.8242,523.05V508.938q0-.3633.3086-.3633h1.68c.1865,0,.28.1016.28.3066v1.625c0,.2237.0927.252.28.084a14.78,14.78,0,0,1,2.5752-1.7636,5.2413,5.2413,0,0,1,2.3809-.5332,4.4838,4.4838,0,0,1,2.59.7011,3.4875,3.4875,0,0,1,1.3858,1.7637c.0371.0557.0742.084.1123.084a.6192.6192,0,0,0,.2793-.1406,14.0852,14.0852,0,0,1,2.6182-1.82,5.3733,5.3733,0,0,1,2.45-.5878,3.9459,3.9459,0,0,1,4.2842,4.2568v10.415c0,.2989-.14.4493-.42.4493h-1.5684a.3221.3221,0,0,1-.3642-.3643v-9.9961a3.1411,3.1411,0,0,0-.6719-2.1563,2.557,2.557,0,0,0-2.0156-.7558,4.2486,4.2486,0,0,0-2.1.5459,11.0016,11.0016,0,0,0-1.9043,1.3574,1.6831,1.6831,0,0,0-.3086.3926.94.94,0,0,0-.084.42v10.1074c0,.2989-.14.4493-.42.4493H549.625a.3217.3217,0,0,1-.3643-.3643v-9.9961q0-2.9121-2.6884-2.9121a4.1823,4.1823,0,0,0-2.0577.5185,14.1937,14.1937,0,0,0-2.3378,1.75v10.584a.358.358,0,0,1-.42.42h-1.54Q539.8243,523.4146,539.8242,523.05Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M565.6963,527.3062a.4216.4216,0,0,1,0-.3077l1.26-3.1367a.6276.6276,0,0,0,.0839-.2519c0-.1309-.1025-.1953-.3076-.1953h-.3642c-.2432,0-.3643-.1124-.3643-.336v-2.4922c0-.2051.0938-.3086.28-.3086h2.3242a.248.248,0,0,1,.28.28v2.2959a.6441.6441,0,0,1-.084.3359l-2.044,3.92a.5888.5888,0,0,1-.5605.3086h-.3076A.2013.2013,0,0,1,565.6963,527.3062Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M590.9941,509.2466a7.0649,7.0649,0,0,1,2.31,2.5195l.0567.2246c0,.1114-.0655.1768-.1963.1953l-1.54.3643-.084.0283c-.0937,0-.1865-.0742-.28-.2246a7.5671,7.5671,0,0,0-1.7217-1.7637,3.2914,3.2914,0,0,0-1.89-.56,4.2155,4.2155,0,0,0-3.4726,1.61,7.988,7.988,0,0,0,.0146,8.7217,4.296,4.296,0,0,0,3.5137,1.5957,4.1481,4.1481,0,0,0,2.31-.6435,6.0014,6.0014,0,0,0,1.8066-1.9873c.0557-.0938.1123-.1446.168-.1543a.3242.3242,0,0,1,.1963.041l1.2031.4492c.1309.0557.168.1777.1123.3633a7.3024,7.3024,0,0,1-2.38,2.6465,6.2931,6.2931,0,0,1-3.6123,1.0214,6.8939,6.8939,0,0,1-3.64-.9658,6.6361,6.6361,0,0,1-2.4922-2.7021,8.5393,8.5393,0,0,1-.8955-3.9756,8.8027,8.8027,0,0,1,.8955-4.0186,6.6573,6.6573,0,0,1,2.5058-2.7578,6.8452,6.8452,0,0,1,3.626-.98A6.1877,6.1877,0,0,1,590.9941,509.2466Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M597.9521,523.05V503.0024c0-.2421.1016-.3642.3077-.3642h1.6523q.3076,0,.3076.3359V510.45q0,.3091.252.084a14.51,14.51,0,0,1,2.7158-1.7363,5.938,5.938,0,0,1,2.4639-.5039,4.5209,4.5209,0,0,1,3.3183,1.1484,4.1738,4.1738,0,0,1,1.1621,3.1084v10.415c0,.2989-.14.4493-.42.4493h-1.5683a.3214.3214,0,0,1-.3633-.3643v-9.9961a3.02,3.02,0,0,0-.7149-2.1563,2.8894,2.8894,0,0,0-2.1972-.7558,4.4592,4.4592,0,0,0-2.1426.5185,15.0418,15.0418,0,0,0-2.4219,1.75v10.584c0,.2793-.1494.42-.4482.42h-1.54A.3214.3214,0,0,1,597.9521,523.05Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M628.64,515.9946a.6871.6871,0,0,1-.0839.377.3778.3778,0,0,1-.336.1269H617.4961q-.252,0-.252.42A5.0172,5.0172,0,0,0,619.68,521.23a4.6968,4.6968,0,0,0,2.4639.6719,4.9841,4.9841,0,0,0,4.3681-2.3233.4066.4066,0,0,1,.1963-.1269.33.33,0,0,1,.1953.0429l1.1768.5879c.1484.0938.1679.2237.0556.3916a6.5554,6.5554,0,0,1-3.6962,2.8838,5.9342,5.9342,0,0,0-1.6094,1.0215,1.5261,1.5261,0,0,0-.49,1.1064.9479.9479,0,0,0,.336.8116,1.785,1.785,0,0,0,1.0644.2529,4.2324,4.2324,0,0,0,.9932-.1123,3.5144,3.5144,0,0,0,.7422-.252,1.2436,1.2436,0,0,1,.1963-.0566c.0742,0,.1123.0664.1123.1973v.8115a.3525.3525,0,0,1-.168.3076,3.9731,3.9731,0,0,1-1.0508.3359,6.8744,6.8744,0,0,1-1.3857.1407,3.1571,3.1571,0,0,1-2.0859-.6026,2.0612,2.0612,0,0,1-.7139-1.665,2.2156,2.2156,0,0,1,.2656-1.0781,2.4835,2.4835,0,0,1,.8818-.8829,6.8551,6.8551,0,0,1-3.4853-1.0644,6.6687,6.6687,0,0,1-2.38-2.6738,8.3542,8.3542,0,0,1-.8545-3.8213,8.9069,8.9069,0,0,1,.9238-4.1582,6.6529,6.6529,0,0,1,2.5342-2.7295,6.9886,6.9886,0,0,1,3.626-.9531,6.8643,6.8643,0,0,1,3.542.8964,5.99,5.99,0,0,1,2.3662,2.6182,9.2033,9.2033,0,0,1,.84,4.0732Zm-2.4355-1.1064c.0557-.0459.084-.1543.084-.3223a5.0841,5.0841,0,0,0-.5186-2.2822,4.1126,4.1126,0,0,0-1.5117-1.68,4.2234,4.2234,0,0,0-2.31-.63,4.36,4.36,0,0,0-2.4921.7139,4.6938,4.6938,0,0,0-1.6241,1.8476,5.1349,5.1349,0,0,0-.5322,2.4219h8.5117A.7016.7016,0,0,0,626.2041,514.8882Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M642.9893,509.2466a7.0682,7.0682,0,0,1,2.3105,2.5195l.0557.2246c0,.1114-.0655.1768-.1953.1953l-1.54.3643-.084.0283c-.0937,0-.1875-.0742-.28-.2246a7.5869,7.5869,0,0,0-1.7217-1.7637,3.2944,3.2944,0,0,0-1.8906-.56,4.2136,4.2136,0,0,0-3.4717,1.61,7.9905,7.9905,0,0,0,.0136,8.7217,4.2967,4.2967,0,0,0,3.5147,1.5957,4.1507,4.1507,0,0,0,2.31-.6435,5.9994,5.9994,0,0,0,1.8056-1.9873c.0567-.0938.1123-.1446.168-.1543a.323.323,0,0,1,.1963.041l1.2041.4492c.13.0557.168.1777.1123.3633a7.3024,7.3024,0,0,1-2.38,2.6465,6.2975,6.2975,0,0,1-3.6123,1.0214,6.895,6.895,0,0,1-3.6406-.9658,6.6345,6.6345,0,0,1-2.4912-2.7021,8.54,8.54,0,0,1-.8965-3.9756,8.8031,8.8031,0,0,1,.8965-4.0186,6.6447,6.6447,0,0,1,2.5058-2.7578,6.8452,6.8452,0,0,1,3.626-.98A6.1826,6.1826,0,0,1,642.9893,509.2466Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M652.3838,505.3813a.6.6,0,0,1-.1123.4063.5049.5049,0,0,1-.3916.127h-1.624c-.28,0-.42-.1583-.42-.4766v-2.3516c0-.2988.13-.4482.3916-.4482h1.7647a.3807.3807,0,0,1,.2793.1123.4138.4138,0,0,1,.1123.3076Zm-.1123,17.5567a.4943.4943,0,0,1-.1113.3779.6036.6036,0,0,1-.3926.0987H650.34a.4879.4879,0,0,1-.3359-.084.437.437,0,0,1-.084-.3086V508.9653c0-.26.1123-.3906.336-.3906h1.708q.3075,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M669.7715,526.3267c.0742,0,.1123.0644.1123.1953v.8125a.3515.3515,0,0,1-.168.3076,4.0129,4.0129,0,0,1-1.05.3359,6.9636,6.9636,0,0,1-1.3867.14,3.1607,3.1607,0,0,1-2.0859-.6016,2.0646,2.0646,0,0,1-.7139-1.666,2.1853,2.1853,0,0,1,.6025-1.5254A4.7943,4.7943,0,0,1,667.14,523.19a.8975.8975,0,0,1-.0556-.2246l-.0567-1.2871c-.0185-.1308-.0654-.1963-.14-.1963a.3859.3859,0,0,0-.252.14,6.9733,6.9733,0,0,1-4.9843,2.0722,4.9835,4.9835,0,0,1-3.43-1.1054,3.754,3.754,0,0,1-1.2461-2.9541,4.052,4.052,0,0,1,1.89-3.5283,10.9649,10.9649,0,0,1,5.4746-1.5118l2.1836-.1679c.3174-.0371.4766-.1778.4766-.42v-.7558a3.1307,3.1307,0,0,0-.9385-2.3945,3.549,3.549,0,0,0-2.5342-.8819,4.74,4.74,0,0,0-4.0312,2.2393.4688.4688,0,0,1-.168.1826.2681.2681,0,0,1-.2246-.0147l-1.5117-.5039a.1963.1963,0,0,1-.14-.1953.6281.6281,0,0,1,.084-.2519A5.8282,5.8282,0,0,1,659.93,509.12a7.7511,7.7511,0,0,1,3.6816-.8261,6.1949,6.1949,0,0,1,4.2,1.2744,4.4259,4.4259,0,0,1,1.4561,3.5146v9.9121a.4893.4893,0,0,1-.084.336.4363.4363,0,0,1-.3076.084h-.0283A7.5679,7.5679,0,0,0,667,524.5063a1.5364,1.5364,0,0,0-.5605,1.1758.9511.9511,0,0,0,.3359.8125,1.789,1.789,0,0,0,1.0644.252,4.2416,4.2416,0,0,0,.9942-.1123,3.6091,3.6091,0,0,0,.7422-.253A1.1038,1.1038,0,0,1,669.7715,526.3267ZM667,516.3022c0-.2978-.1406-.4482-.42-.4482l-1.82.168a9.1106,9.1106,0,0,0-4.0176,1.0078,2.5886,2.5886,0,0,0-1.4141,2.3242,2.3316,2.3316,0,0,0,.8535,1.9043,3.489,3.489,0,0,0,2.2823.6992,4.9133,4.9133,0,0,0,1.708-.3076,5.016,5.016,0,0,0,1.4834-.84,4.0952,4.0952,0,0,0,.9941-1.0782A2.1253,2.1253,0,0,0,667,518.6255Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M457.5625,542.03a.27.27,0,0,1,.0283.2656l-4.2842,14.084a.4969.4969,0,0,1-.5322.3643h-1.3437a.5363.5363,0,0,1-.5879-.3643l-3.3321-10.4717c-.0556-.168-.122-.2519-.1962-.2519-.0372,0-.084.0839-.14.2519l-3.1641,10.4717a.4969.4969,0,0,1-.5322.3643H442.05a.5363.5363,0,0,1-.5879-.3643l-4.396-14.0557a.306.306,0,0,1-.0278-.14c0-.1875.1118-.28.3359-.28h1.6519a.4675.4675,0,0,1,.4482.3076l3.332,10.8926c.0557.1865.1211.2793.1963.2793.0557,0,.13-.1016.2237-.3076l3.3037-10.8916a.3687.3687,0,0,1,.3926-.28h1.3154a.39.39,0,0,1,.42.3076l3.4443,10.8926c.0557.1865.1211.2793.1963.2793s.14-.1016.1963-.3076l3.22-10.8643a.4852.4852,0,0,1,.4756-.3076h1.12A.2819.2819,0,0,1,457.5625,542.03Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M469.3086,543.7935a5.1636,5.1636,0,0,0-2.3662-.5176,4.4278,4.4278,0,0,0-2.5205.6582,1.9927,1.9927,0,0,0-.9795,1.7217,1.6474,1.6474,0,0,0,.49,1.1621,4.1408,4.1408,0,0,0,1.7774.9092l3.5566,1.0644A5.58,5.58,0,0,1,472.01,550.29a3.4141,3.4141,0,0,1,.8965,2.3662,3.5843,3.5843,0,0,1-.7842,2.2822,5.0442,5.0442,0,0,1-2.1563,1.5391,9.0661,9.0661,0,0,1-6.9433-.3077,5.18,5.18,0,0,1-2.38-2.2256.4715.4715,0,0,1-.084-.2246.2518.2518,0,0,1,.14-.2236l1.0918-.6162q.28-.167.42.0283a5.13,5.13,0,0,0,4.6757,2.4072,5.3263,5.3263,0,0,0,2.7305-.6855,1.9612,1.9612,0,0,0,1.0781-1.7217,1.64,1.64,0,0,0-.6445-1.3154,5.986,5.986,0,0,0-2.1-.9531l-2.8838-.8116a5.9886,5.9886,0,0,1-2.9258-1.4834,3.2927,3.2927,0,0,1-.8828-2.3242,3.8777,3.8777,0,0,1,.7139-2.2969,4.5606,4.5606,0,0,1,2.0166-1.5537,8.3068,8.3068,0,0,1,6.3.1543,5.3583,5.3583,0,0,1,2.2539,1.9034q.1964.2813-.0283.3925l-1.1758.6446a.43.43,0,0,1-.168.0556.3855.3855,0,0,1-.2519-.14A4.1819,4.1819,0,0,0,469.3086,543.7935Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M480.0742,542.3237V543.5c0,.168.0459.252.14.252a.3833.3833,0,0,0,.2246-.14,13.861,13.861,0,0,1,1.0351-.9521,4.9926,4.9926,0,0,1,1.3164-.7,5.6551,5.6551,0,0,1,2.044-.336,5.964,5.964,0,0,1,3.15.84,5.7137,5.7137,0,0,1,2.1836,2.52,9.5435,9.5435,0,0,1,.7978,4.1162,10.4,10.4,0,0,1-.7978,4.2559,5.8709,5.8709,0,0,1-5.6416,3.6679,5.9461,5.9461,0,0,1-3.9766-1.4c-.1309-.1308-.2334-.1953-.3076-.1953q-.1407,0-.1406.3633V561q0,.3648-.3077.3643h-1.7363q-.3076,0-.3076-.3643V542.3521a.496.496,0,0,1,.0977-.336.4526.4526,0,0,1,.3505-.1123h1.4834Q480.0743,541.9038,480.0742,542.3237Zm1.834,12.53a4.36,4.36,0,0,0,2.002.4609,4.1023,4.1023,0,0,0,3.374-1.5957,7.2148,7.2148,0,0,0,1.2734-4.5918,6.4647,6.4647,0,0,0-1.26-4.3535,4.3818,4.3818,0,0,0-5.2637-.9521,3.9916,3.9916,0,0,0-1.4141,1.2461,2.6231,2.6231,0,0,0-.5185,1.4834v6.916A6.9394,6.9394,0,0,0,481.9082,554.854Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M498.3721,556.0571a6.5112,6.5112,0,0,1-2.4922-2.7011,9.3332,9.3332,0,0,1,.0283-7.9805,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6755,6.6755,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8818,4.0039,8.4794,8.4794,0,0,1-.8965,3.9482,6.7075,6.7075,0,0,1-2.5058,2.7159,6.9307,6.9307,0,0,1-3.6817.98A7.0632,7.0632,0,0,1,498.3721,556.0571Zm7.1679-2.3789a8.3119,8.3119,0,0,0-.0283-8.709,4.0726,4.0726,0,0,0-3.4023-1.6093,4.1376,4.1376,0,0,0-3.4434,1.6093,8.1254,8.1254,0,0,0,0,8.6944,4.1812,4.1812,0,0,0,3.4717,1.5967A4.05,4.05,0,0,0,505.54,553.6782Zm.2657-16.5351a.3952.3952,0,0,1,.0283.1679c0,.15-.1035.252-.3086.3086l-6.4121,2.0157a.3749.3749,0,0,1-.168.0283c-.1308,0-.2236-.084-.2793-.252l-.2246-.6162a.313.313,0,0,1-.0273-.14c0-.1495.0556-.2334.1679-.252l6.4678-2.6885a.1689.1689,0,0,1,.1123-.0273.22.22,0,0,1,.2236.168Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M519.2734,543.9751c.0938,0,.1407.0664.1407.1973v1.4834a.6214.6214,0,0,1-.28.4755l-2.2119,1.877v8.26a.4914.4914,0,0,1-.1123.3769.5964.5964,0,0,1-.3916.0987h-1.4561a.4876.4876,0,0,1-.3359-.084.4293.4293,0,0,1-.084-.3076v-6.3282l-2.1006,1.791a.3386.3386,0,0,1-.1963.084c-.0928,0-.14-.084-.14-.2519v-1.5117a.5147.5147,0,0,1,.2519-.4473l2.1846-1.8477.0273-11.48q0-.3927.336-.3926h1.6806c.2237,0,.336.1221.336.3642v9.5206l2.1552-1.793A.4964.4964,0,0,1,519.2734,543.9751Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M525.4893,542.3237V543.5c0,.168.0459.252.14.252a.3828.3828,0,0,0,.2246-.14,13.84,13.84,0,0,1,1.0352-.9521,4.9909,4.9909,0,0,1,1.3164-.7,5.6541,5.6541,0,0,1,2.0439-.336,5.964,5.964,0,0,1,3.15.84,5.7131,5.7131,0,0,1,2.1836,2.52,9.5435,9.5435,0,0,1,.7979,4.1162,10.4,10.4,0,0,1-.7979,4.2559,5.8709,5.8709,0,0,1-5.6416,3.6679,5.9461,5.9461,0,0,1-3.9766-1.4c-.1308-.1308-.2334-.1953-.3076-.1953q-.1405,0-.1406.3633V561q0,.3648-.3076.3643h-1.7363q-.3076,0-.3077-.3643V542.3521a.4965.4965,0,0,1,.0977-.336.4527.4527,0,0,1,.3506-.1123h1.4834Q525.4892,541.9038,525.4893,542.3237Zm1.8339,12.53a4.36,4.36,0,0,0,2.002.4609,4.1023,4.1023,0,0,0,3.374-1.5957,7.2142,7.2142,0,0,0,1.2735-4.5918,6.4647,6.4647,0,0,0-1.26-4.3535,4.3818,4.3818,0,0,0-5.2637-.9521,3.9922,3.9922,0,0,0-1.414,1.2461,2.6233,2.6233,0,0,0-.5186,1.4834v6.916A6.9411,6.9411,0,0,0,527.3232,554.854Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M541.28,556.3794V542.2681c0-.2422.1025-.3643.3076-.3643h1.624a.2726.2726,0,0,1,.3086.3076v1.82c0,.1114.0322.1778.0977.1954.0654.0195.1357-.0176.21-.1114q2.1285-2.4916,4.34-2.4922.98,0,.98.3643v1.68c0,.1865-.0839.2617-.2519.2236a7.8985,7.8985,0,0,0-1.26-.084,3.9794,3.9794,0,0,0-1.7646.4766,5.0026,5.0026,0,0,0-1.5957,1.2324,2.368,2.368,0,0,0-.6446,1.5391v9.2685a.3709.3709,0,0,1-.42.42h-1.54C541.41,556.7437,541.28,556.6226,541.28,556.3794Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M556.96,557.0239a4.9838,4.9838,0,0,1-3.43-1.1064,3.7525,3.7525,0,0,1-1.2461-2.9541,4.0506,4.0506,0,0,1,1.89-3.5274,10.9382,10.9382,0,0,1,5.4737-1.5117l2.1845-.168c.3174-.038.4756-.1777.4756-.4209V546.58a3.1282,3.1282,0,0,0-.9375-2.3936,3.5528,3.5528,0,0,0-2.5342-.8828,4.74,4.74,0,0,0-4.0322,2.24.4514.4514,0,0,1-.168.1816.2688.2688,0,0,1-.2236-.0137l-1.5127-.5039a.1974.1974,0,0,1-.14-.1953.6431.6431,0,0,1,.084-.2519,5.829,5.829,0,0,1,2.3945-2.3106,7.7337,7.7337,0,0,1,3.6816-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.4305,4.4305,0,0,1,1.4561,3.5146v9.9121a.4875.4875,0,0,1-.084.336.4392.4392,0,0,1-.3086.084h-1.3994q-.3369,0-.3926-.4483l-.0557-1.2871c-.0185-.1309-.0654-.1973-.14-.1973a.3872.3872,0,0,0-.2529.1407A6.9771,6.9771,0,0,1,556.96,557.0239Zm4.9277-7.84-1.82.168A9.0872,9.0872,0,0,0,556.05,550.36a2.5883,2.5883,0,0,0-1.4141,2.3242,2.33,2.33,0,0,0,.8545,1.9043,3.4843,3.4843,0,0,0,2.2813.6992,4.9063,4.9063,0,0,0,1.708-.3086,5.0016,5.0016,0,0,0,1.4844-.84,4.1122,4.1122,0,0,0,.9941-1.0782,2.1206,2.1206,0,0,0,.35-1.1054v-2.3243Q562.3076,549.1846,561.8877,549.1841Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M579.71,542.5757a7.06,7.06,0,0,1,2.3095,2.52l.0567.2236c0,.1123-.0655.1768-.1963.1963l-1.54.3633-.0839.0283c-.0938,0-.1866-.0742-.28-.2236a7.5922,7.5922,0,0,0-1.7217-1.7647,3.2905,3.2905,0,0,0-1.89-.5595,4.2175,4.2175,0,0,0-3.4727,1.6093,6.782,6.782,0,0,0-1.2881,4.3545,6.7138,6.7138,0,0,0,1.3028,4.3682,4.296,4.296,0,0,0,3.5136,1.5957,4.1487,4.1487,0,0,0,2.31-.6445,6.0014,6.0014,0,0,0,1.8066-1.9873c.0557-.0928.1123-.1446.168-.1543a.3325.3325,0,0,1,.1963.042l1.2031.4482c.1309.0557.168.1777.1123.3643a7.2985,7.2985,0,0,1-2.38,2.6455,6.2936,6.2936,0,0,1-3.6123,1.0224,6.8854,6.8854,0,0,1-3.64-.9668,6.64,6.64,0,0,1-2.4922-2.7011,8.5437,8.5437,0,0,1-.8955-3.9766,8.7985,8.7985,0,0,1,.8955-4.0176,6.6515,6.6515,0,0,1,2.5059-2.7578,6.8357,6.8357,0,0,1,3.6259-.9805A6.1958,6.1958,0,0,1,579.71,542.5757Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M586.1074,561.3071c-.2422-.0371-.3642-.14-.3642-.3076v-1.0918a.2949.2949,0,0,1,.1543-.28,1.5346,1.5346,0,0,1,.63-.0839h1.7647a1.8321,1.8321,0,0,0,1.2314-.49,3.6949,3.6949,0,0,0,1.2041-2.6172,1.6667,1.6667,0,0,0-.1123-.6718l-5.3476-13.3848a.388.388,0,0,1-.0283-.168q0-.3077.3359-.3076h1.7363a.4677.4677,0,0,1,.4483.3076l4.1718,10.8643c.0557.168.1309.2519.2237.2519.1123,0,.2051-.1123.28-.3359l4.1163-10.78a.4334.4334,0,0,1,.3916-.3076H598.26a.2817.2817,0,0,1,.2519.126.2726.2726,0,0,1,.0283.2656l-6.16,15.7637a5.2142,5.2142,0,0,1-1.876,2.6045,5.7385,5.7385,0,0,1-3.0527.7A10.5194,10.5194,0,0,1,586.1074,561.3071Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M379.9468,572.0415a.604.604,0,0,1-.1123.4063.5066.5066,0,0,1-.3916.125h-1.6241q-.42,0-.42-.4756v-2.3526q0-.4467.3921-.4472h1.7642a.3811.3811,0,0,1,.28.1113.4182.4182,0,0,1,.1123.3086Zm-.1123,17.5557a.4943.4943,0,0,1-.1118.3779.5959.5959,0,0,1-.3921.0977h-1.4278a.4826.4826,0,0,1-.3359-.084.4315.4315,0,0,1-.0845-.3076V575.6255q0-.3926.3364-.3926h1.7081c.205,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M400.2886,577.1235a5.1624,5.1624,0,0,0-2.3662-.5175,4.4355,4.4355,0,0,0-2.52.6572,1.9932,1.9932,0,0,0-.98,1.7217,1.6468,1.6468,0,0,0,.49,1.1621,4.1381,4.1381,0,0,0,1.7778.91l3.5562,1.0645a5.58,5.58,0,0,1,2.7436,1.498,3.4135,3.4135,0,0,1,.896,2.3653,3.5843,3.5843,0,0,1-.7837,2.2822,5.0432,5.0432,0,0,1-2.1562,1.54,9.0738,9.0738,0,0,1-6.9439-.3086,5.1763,5.1763,0,0,1-2.38-2.2246.477.477,0,0,1-.084-.2246.2541.2541,0,0,1,.14-.2246l1.0923-.6152c.186-.1123.3262-.1026.42.0273a5.1306,5.1306,0,0,0,4.6758,2.4082,5.3355,5.3355,0,0,0,2.73-.6855,1.964,1.964,0,0,0,1.0781-1.7227,1.6435,1.6435,0,0,0-.644-1.3154,5.9923,5.9923,0,0,0-2.1-.9522l-2.8838-.8125a5.9825,5.9825,0,0,1-2.9258-1.4834,3.2907,3.2907,0,0,1-.8823-2.3242,3.8775,3.8775,0,0,1,.7138-2.2959,4.5682,4.5682,0,0,1,2.0162-1.5547,8.32,8.32,0,0,1,6.3.1543,5.36,5.36,0,0,1,2.2539,1.9043q.1955.2813-.0284.3926l-1.1757.6436a.4176.4176,0,0,1-.168.0556.3838.3838,0,0,1-.252-.14A4.1819,4.1819,0,0,0,400.2886,577.1235Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M407.5127,590.0034a.378.378,0,0,1-.07-.2666v-.8115a.9065.9065,0,0,1,.07-.3779,2.9828,2.9828,0,0,1,.2661-.4336l8.148-10.9492.0839-.168q0-.1113-.2241-.1113h-7.42a.381.381,0,0,1-.2378-.0567q-.07-.0557-.07-.2519v-1.0352c0-.2051.0933-.3086.28-.3086h10.36c.168,0,.252.0938.252.28v.8955a1.1949,1.1949,0,0,1-.28.7285l-8.1763,10.9766c-.0747.0937-.1025.1679-.084.2236s.0743.084.168.084h8.2319q.3084,0,.3082.2519v1.0635c0,.2246-.1216.336-.3638.336H407.7788A.3776.3776,0,0,1,407.5127,590.0034Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M427.2666,590.353a4.9789,4.9789,0,0,1-3.43-1.1064,3.7493,3.7493,0,0,1-1.2461-2.9531,4.051,4.051,0,0,1,1.89-3.5284,10.96,10.96,0,0,1,5.4741-1.5117l2.1841-.168c.3174-.038.476-.1767.476-.4208v-.7559a3.1285,3.1285,0,0,0-.9379-2.3936,3.5508,3.5508,0,0,0-2.5342-.8818,4.7374,4.7374,0,0,0-4.0318,2.24.4551.4551,0,0,1-.1679.1817.2779.2779,0,0,1-.2241-.0137l-1.5123-.5039a.1981.1981,0,0,1-.14-.1963.6439.6439,0,0,1,.084-.2519,5.8293,5.8293,0,0,1,2.394-2.31,7.7369,7.7369,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2735,4.428,4.428,0,0,1,1.456,3.5136v9.9121a.4875.4875,0,0,1-.084.336.4327.4327,0,0,1-.3081.084h-1.4c-.2241,0-.355-.1485-.3921-.4473l-.0561-1.2881c-.0186-.13-.0655-.1963-.14-.1963a.3856.3856,0,0,0-.2524.1406A6.98,6.98,0,0,1,427.2666,590.353Zm4.9282-7.84-1.82.168a9.1,9.1,0,0,0-4.0176,1.0087,2.5862,2.5862,0,0,0-1.414,2.3233,2.3306,2.3306,0,0,0,.854,1.9043,3.4867,3.4867,0,0,0,2.2817.7,4.9332,4.9332,0,0,0,1.708-.3086,4.9957,4.9957,0,0,0,1.4839-.84,4.1,4.1,0,0,0,.9941-1.0781,2.127,2.127,0,0,0,.35-1.1055v-2.3242Q432.6147,582.5132,432.1948,582.5132Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M450.0166,575.9048a7.0623,7.0623,0,0,1,2.31,2.52l.0566.2236q0,.1685-.1963.1963l-1.54.3643-.084.0273c-.0937,0-.1865-.0742-.28-.2236a7.6284,7.6284,0,0,0-1.7217-1.7647,3.2894,3.2894,0,0,0-1.89-.5586,4.2148,4.2148,0,0,0-3.4727,1.6094,7.99,7.99,0,0,0,.0147,8.7227,4.2992,4.2992,0,0,0,3.5137,1.5957,4.1487,4.1487,0,0,0,2.3095-.6446,6.0054,6.0054,0,0,0,1.8067-1.9882c.0556-.0928.1123-.1446.1679-.1543a.3427.3427,0,0,1,.1963.0429l1.2032.4473q.1962.0849.1123.3643a7.2913,7.2913,0,0,1-2.38,2.6455,6.2936,6.2936,0,0,1-3.6123,1.0224,6.8863,6.8863,0,0,1-3.64-.9658,6.6466,6.6466,0,0,1-2.4922-2.7021,8.5412,8.5412,0,0,1-.896-3.9756,8.7967,8.7967,0,0,1,.896-4.0176,6.6476,6.6476,0,0,1,2.5059-2.7588,6.835,6.835,0,0,1,3.626-.9795A6.1949,6.1949,0,0,1,450.0166,575.9048Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M464.24,587.8755a10.4413,10.4413,0,0,0,2.2539-1.8067V575.6255q0-.3926.4483-.3926h1.54a.3467.3467,0,0,1,.3916.3926l-.0556,14.084q0,.3633-.3077.3633h-1.624a.2719.2719,0,0,1-.3086-.3077v-1.6513c0-.2246-.084-.252-.2519-.084a11.5218,11.5218,0,0,1-2.6875,1.8056,5.9279,5.9279,0,0,1-2.4082.5176,4.53,4.53,0,0,1-3.22-1.1758,4.0483,4.0483,0,0,1-1.2607-3.1084v-10.416a.3716.3716,0,0,1,.42-.42h1.5684c.2422,0,.3642.1309.3642.3926v9.8281a3.13,3.13,0,0,0,.77,2.2256,2.7486,2.7486,0,0,0,2.1142.8262A4.5046,4.5046,0,0,0,464.24,587.8755Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M474.5576,589.71V575.5972c0-.2422.1026-.3643.3086-.3643h1.68a.2733.2733,0,0,1,.3086.3086v1.6231c0,.207.084.2343.2519.0839a15.4187,15.4187,0,0,1,2.6739-1.7636,5.6579,5.6579,0,0,1,2.4775-.5313,4.5854,4.5854,0,0,1,3.3184,1.1475,4.0828,4.0828,0,0,1,1.1894,3.08v10.4443c0,.2988-.14.4473-.42.4473h-1.5674a.3214.3214,0,0,1-.3642-.3633v-9.9961a3.0222,3.0222,0,0,0-.7139-2.1563,2.8934,2.8934,0,0,0-2.1982-.7558,4.63,4.63,0,0,0-2.17.5175,13.6332,13.6332,0,0,0-2.4218,1.75v10.584a.3571.3571,0,0,1-.42.42h-1.54Q474.5577,590.0728,474.5576,589.71Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M505.19,589.8208q0,.252-.3076.252h-1.96a.5161.5161,0,0,1-.28-.0694.7264.7264,0,0,1-.2237-.2666l-4.2285-7.2519c-.0371-.0928-.084-.1446-.14-.1543a.251.251,0,0,0-.1963.0986l-2.9678,2.9961a.7574.7574,0,0,0-.1963.56v3.6123a.49.49,0,0,1-.1123.3779.5933.5933,0,0,1-.3916.0977h-1.4277a.4823.4823,0,0,1-.3359-.084.4293.4293,0,0,1-.084-.3076V569.69c0-.2617.1015-.3925.3076-.3925h1.708q.3354,0,.3359.3632v12.9922c0,.1875.0284.28.084.28s.1211-.0557.1963-.168l6.916-7.1963a.8461.8461,0,0,1,.5879-.3359h1.708c.2237,0,.336.0664.336.1963a.82.82,0,0,1-.1963.3359l-4.4239,4.5088a.53.53,0,0,0-.1123.252.4783.4783,0,0,0,.084.2226l5.2647,8.877A.2729.2729,0,0,1,505.19,589.8208Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M511.9668,572.0415a.6067.6067,0,0,1-.1123.4063.5081.5081,0,0,1-.3916.125h-1.624q-.42,0-.4209-.4756v-2.3526q0-.4467.3925-.4472h1.7637a.3822.3822,0,0,1,.28.1113.42.42,0,0,1,.1123.3086Zm-.1123,17.5557a.4942.4942,0,0,1-.1123.3779.5933.5933,0,0,1-.3916.0977h-1.4277a.4828.4828,0,0,1-.336-.084.43.43,0,0,1-.0849-.3076V575.6255q0-.3926.3369-.3926h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M519.82,586.0688a4.9541,4.9541,0,0,0,1.7783,1.82,4.6944,4.6944,0,0,0,2.4639.6718,4.9807,4.9807,0,0,0,4.3682-2.3242.4029.4029,0,0,1,.1953-.125.3237.3237,0,0,1,.1963.041l1.1757.5879q.2242.1407.0567.3926a6.8281,6.8281,0,0,1-2.6045,2.4492,7.8684,7.8684,0,0,1-3.6123.77,7.1183,7.1183,0,0,1-3.668-.9521,6.5924,6.5924,0,0,1-2.5332-2.6738,8.2836,8.2836,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6429,6.6429,0,0,1,2.5342-2.7305,6.9936,6.9936,0,0,1,3.6259-.9512,6.8724,6.8724,0,0,1,3.542.8955,5.9887,5.9887,0,0,1,2.3653,2.6182,9.1947,9.1947,0,0,1,.8408,4.0742v.1113a.6932.6932,0,0,1-.084.3789.3784.3784,0,0,1-.3359.125H519.4141c-.168,0-.252.1407-.252.42A4.9251,4.9251,0,0,0,519.82,586.0688Zm7.91-4.4511a.6941.6941,0,0,0,.3916-.07c.0566-.0469.084-.1543.084-.3223a5.0932,5.0932,0,0,0-.5176-2.2813,4.0986,4.0986,0,0,0-1.5127-1.6806,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.6959,4.6959,0,0,0-1.624,1.8486,5.14,5.14,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M535.2617,589.71V575.5972c0-.2422.1026-.3643.3076-.3643H537.25c.1865,0,.2793.1035.2793.3086v1.6231q0,.3369.28.0839a14.9042,14.9042,0,0,1,2.5761-1.7636,5.2579,5.2579,0,0,1,2.38-.5313,4.4914,4.4914,0,0,1,2.59.6992,3.4827,3.4827,0,0,1,1.3867,1.7647c.0371.0556.0742.084.1113.084a.6106.6106,0,0,0,.28-.14,13.9209,13.9209,0,0,1,2.6182-1.82,5.345,5.345,0,0,1,2.45-.5879,3.9445,3.9445,0,0,1,4.2833,4.2559v10.416c0,.2988-.14.4473-.42.4473H554.498a.3214.3214,0,0,1-.3642-.3633v-9.9961a3.1392,3.1392,0,0,0-.6719-2.1563,2.5591,2.5591,0,0,0-2.0166-.7558,4.2435,4.2435,0,0,0-2.1.5459,11.0469,11.0469,0,0,0-1.9043,1.3584,1.61,1.61,0,0,0-.3076.3916.94.94,0,0,0-.084.42v10.1084c0,.2988-.14.4473-.42.4473h-1.5684a.3218.3218,0,0,1-.3642-.3633v-9.9961q0-2.9121-2.6875-2.9121a4.19,4.19,0,0,0-2.0577.5175,14.3106,14.3106,0,0,0-2.3388,1.75v10.584a.3571.3571,0,0,1-.42.42h-1.54C535.3916,590.0728,535.2617,589.9517,535.2617,589.71Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M588.6855,575.36a.27.27,0,0,1,.0284.2656L584.43,589.71a.4972.4972,0,0,1-.5322.3633h-1.3438a.5367.5367,0,0,1-.5879-.3633l-3.332-10.4727c-.0557-.168-.1221-.2519-.1963-.2519-.0371,0-.084.0839-.14.2519L575.1338,589.71a.4972.4972,0,0,1-.5322.3633h-1.4278a.5368.5368,0,0,1-.5879-.3633L568.19,575.6528a.3116.3116,0,0,1-.0274-.14c0-.1865.1113-.28.3359-.28h1.6514a.4686.4686,0,0,1,.4483.3086l3.332,10.8916c.0557.1865.1211.28.1963.28q.0834,0,.2236-.3086l3.3037-10.8916a.37.37,0,0,1,.3926-.28h1.3154a.3909.3909,0,0,1,.42.3086l3.4443,10.8916c.0557.1865.1211.28.1963.28s.14-.1026.1963-.3086l3.22-10.8633a.486.486,0,0,1,.4756-.3086h1.12A.2825.2825,0,0,1,588.6855,575.36Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M595.6436,589.3872a6.5192,6.5192,0,0,1-2.4922-2.7021,9.3309,9.3309,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6755,6.6755,0,0,1,2.4922,2.7578,8.8294,8.8294,0,0,1,.8818,4.0039,8.4724,8.4724,0,0,1-.8965,3.9472,6.71,6.71,0,0,1-2.5058,2.7168,6.9382,6.9382,0,0,1-3.6817.9795A7.0636,7.0636,0,0,1,595.6436,589.3872Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4023-1.6094,4.1348,4.1348,0,0,0-3.4434,1.6094,8.1237,8.1237,0,0,0,0,8.6934,4.1786,4.1786,0,0,0,3.4717,1.5967A4.05,4.05,0,0,0,602.8115,587.0073Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M611.7568,590.0728a.3708.3708,0,0,1-.4482-.4473V569.69q0-.3926.3369-.3925h1.7354q.28,0,.28.3925v6.8594c0,.2051.0459.3086.14.3086a.6169.6169,0,0,0,.28-.1406,6.3131,6.3131,0,0,1,4.48-1.7637,5.1628,5.1628,0,0,1,4.4658,2.1133,9.1913,9.1913,0,0,1,1.5254,5.5019,8.7859,8.7859,0,0,1-1.6933,5.6983,5.7083,5.7083,0,0,1-4.6905,2.0859,5.3382,5.3382,0,0,1-2.8281-.6855,5.99,5.99,0,0,1-1.7353-1.5264.2275.2275,0,0,0-.168-.1113c-.0752,0-.1221.0644-.1406.1953l-.14,1.4a.7217.7217,0,0,1-.1543.35.4844.4844,0,0,1-.35.0977Zm1.9043-5.0118a3.0974,3.0974,0,0,0,.5743,1.8067,4.1562,4.1562,0,0,0,1.498,1.3154,4.06,4.06,0,0,0,1.9316.49,3.9447,3.9447,0,0,0,3.2344-1.5127,7.13,7.13,0,0,0,1.2178-4.5351,7.6057,7.6057,0,0,0-1.0918-4.41,3.6285,3.6285,0,0,0-3.1641-1.5547,4.9972,4.9972,0,0,0-2.3379.5606,5.9276,5.9276,0,0,0-1.8623,1.5117Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M631.707,586.0688a4.9536,4.9536,0,0,0,1.7784,1.82,4.6938,4.6938,0,0,0,2.4638.6718,4.9807,4.9807,0,0,0,4.3682-2.3242.4029.4029,0,0,1,.1953-.125.3237.3237,0,0,1,.1963.041l1.1758.5879q.2241.1407.0566.3926a6.8281,6.8281,0,0,1-2.6045,2.4492,7.8684,7.8684,0,0,1-3.6123.77,7.1183,7.1183,0,0,1-3.668-.9521,6.5924,6.5924,0,0,1-2.5332-2.6738,8.2836,8.2836,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6429,6.6429,0,0,1,2.5342-2.7305,6.9937,6.9937,0,0,1,3.626-.9512,6.8729,6.8729,0,0,1,3.542.8955,5.9885,5.9885,0,0,1,2.3652,2.6182,9.1947,9.1947,0,0,1,.8408,4.0742v.1113a.6932.6932,0,0,1-.084.3789.3783.3783,0,0,1-.3359.125H631.3008c-.168,0-.252.1407-.252.42A4.9261,4.9261,0,0,0,631.707,586.0688Zm7.91-4.4511a.6939.6939,0,0,0,.3916-.07c.0566-.0469.084-.1543.084-.3223a5.0932,5.0932,0,0,0-.5176-2.2813,4.0986,4.0986,0,0,0-1.5127-1.6806,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.6974,4.6974,0,0,0-1.624,1.8486,5.14,5.14,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M656.7949,575.9048a7.0605,7.0605,0,0,1,2.31,2.52l.0566.2236q0,.1685-.1963.1963l-1.54.3643-.084.0273c-.0937,0-.1865-.0742-.28-.2236a7.63,7.63,0,0,0-1.7216-1.7647,3.29,3.29,0,0,0-1.89-.5586,4.2147,4.2147,0,0,0-3.4726,1.6094,7.99,7.99,0,0,0,.0146,8.7227,4.2992,4.2992,0,0,0,3.5137,1.5957,4.1493,4.1493,0,0,0,2.31-.6446,6.0062,6.0062,0,0,0,1.8066-1.9882c.0557-.0928.1123-.1446.168-.1543a.3429.3429,0,0,1,.1963.0429l1.2031.4473q.1962.0849.1123.3643a7.2913,7.2913,0,0,1-2.38,2.6455,6.2936,6.2936,0,0,1-3.6123,1.0224,6.8863,6.8863,0,0,1-3.64-.9658,6.6491,6.6491,0,0,1-2.4921-2.7021,8.54,8.54,0,0,1-.8955-3.9756,8.7952,8.7952,0,0,1,.8955-4.0176,6.6533,6.6533,0,0,1,2.5058-2.7588,6.8353,6.8353,0,0,1,3.626-.9795A6.1949,6.1949,0,0,1,656.7949,575.9048Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M457.1426,608.688a.2729.2729,0,0,1,.0273.2656l-4.2832,14.085a.4976.4976,0,0,1-.5322.3642H451.01a.5371.5371,0,0,1-.5879-.3642L447.09,612.5669c-.0556-.168-.121-.252-.1953-.252-.0381,0-.084.084-.1406.252L443.59,623.0386a.4958.4958,0,0,1-.5312.3642H441.63a.5364.5364,0,0,1-.5879-.3642l-4.396-14.0557a.3076.3076,0,0,1-.0278-.1406q0-.28.3359-.2793h1.6519a.4668.4668,0,0,1,.4482.3066l3.3325,10.8926c.0557.1875.1211.28.1953.28.0567,0,.1309-.1026.2246-.3076l3.3038-10.8926a.3678.3678,0,0,1,.3916-.2793h1.3164a.39.39,0,0,1,.42.3066l3.4443,10.8926c.0557.1875.1211.28.1953.28s.1407-.1026.1963-.3076l3.22-10.8653a.4858.4858,0,0,1,.4766-.3066h1.12A.2834.2834,0,0,1,457.1426,608.688Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M468.8877,610.4517a5.1722,5.1722,0,0,0-2.3662-.5176,4.4345,4.4345,0,0,0-2.5195.6582,1.9942,1.9942,0,0,0-.9805,1.7226,1.6492,1.6492,0,0,0,.49,1.1622,4.1386,4.1386,0,0,0,1.7783.9091l3.5557,1.0635a5.5786,5.5786,0,0,1,2.7441,1.4981,3.4216,3.4216,0,0,1,.8956,2.3671,3.5861,3.5861,0,0,1-.7833,2.2813,5.0562,5.0562,0,0,1-2.1562,1.54,9.08,9.08,0,0,1-6.9443-.3076,5.1824,5.1824,0,0,1-2.38-2.2265.48.48,0,0,1-.084-.2237.2535.2535,0,0,1,.14-.2236l1.0928-.6162c.1856-.1123.3262-.1026.42.0283a5.13,5.13,0,0,0,4.6758,2.4072,5.3413,5.3413,0,0,0,2.7295-.6855,1.9635,1.9635,0,0,0,1.0781-1.7217,1.6437,1.6437,0,0,0-.6435-1.3164,6.0137,6.0137,0,0,0-2.1006-.9522l-2.8838-.8115a6.0019,6.0019,0,0,1-2.9258-1.4844,3.2941,3.2941,0,0,1-.8818-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.55,4.55,0,0,1,2.0156-1.5537,8.2969,8.2969,0,0,1,6.3008.1543,5.371,5.371,0,0,1,2.2539,1.9033c.13.1866.1211.3174-.0283.3916l-1.1758.6446a.4415.4415,0,0,1-.168.0566.3838.3838,0,0,1-.252-.1406A4.1931,4.1931,0,0,0,468.8877,610.4517Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M476.1113,623.3325a.3775.3775,0,0,1-.0693-.2656v-.8125a.9084.9084,0,0,1,.0693-.3779,3.1068,3.1068,0,0,1,.2666-.4346l8.1475-10.9473.084-.1679c0-.0743-.0742-.1123-.2237-.1123h-7.42a.39.39,0,0,1-.2383-.0557q-.07-.0557-.07-.252V608.87c0-.205.0938-.3066.28-.3066h10.36c.1679,0,.2519.0928.2519.2793v.8965a1.1917,1.1917,0,0,1-.28.7275l-8.1757,10.9756c-.0752.0937-.1026.168-.084.2246s.0742.084.1679.084h8.2315q.309,0,.3086.2519v1.0645c0,.2236-.1221.3359-.3643.3359H476.3779A.38.38,0,0,1,476.1113,623.3325Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M491.6943,627.9663c-.2431-.0381-.3642-.14-.3642-.3076v-1.0918a.2948.2948,0,0,1,.1543-.2813,1.5528,1.5528,0,0,1,.63-.0839h1.7636a1.8338,1.8338,0,0,0,1.2325-.4893,3.6957,3.6957,0,0,0,1.2041-2.6182,1.6852,1.6852,0,0,0-.1124-.6718l-5.3476-13.3838a.38.38,0,0,1-.0283-.169c0-.205.1123-.3066.3359-.3066h1.7363a.4647.4647,0,0,1,.4473.3066l4.1729,10.8653c.0556.1679.13.2519.2236.2519.1123,0,.2051-.1123.28-.3359l4.1152-10.7813a.4345.4345,0,0,1,.3926-.3066h1.3154a.2812.2812,0,0,1,.252.125.2706.2706,0,0,1,.0283.2656l-6.16,15.7647a5.2129,5.2129,0,0,1-1.876,2.6045,5.7349,5.7349,0,0,1-3.0517.6992A10.5776,10.5776,0,0,1,491.6943,627.9663Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M515.76,610.4517a5.1728,5.1728,0,0,0-2.3662-.5176,4.4348,4.4348,0,0,0-2.52.6582,1.9941,1.9941,0,0,0-.98,1.7226,1.6488,1.6488,0,0,0,.49,1.1622,4.138,4.138,0,0,0,1.7783.9091l3.5557,1.0635a5.5786,5.5786,0,0,1,2.7441,1.4981,3.4215,3.4215,0,0,1,.8955,2.3671,3.586,3.586,0,0,1-.7832,2.2813,5.0568,5.0568,0,0,1-2.1562,1.54,9.08,9.08,0,0,1-6.9444-.3076,5.1821,5.1821,0,0,1-2.38-2.2265.48.48,0,0,1-.084-.2237.2533.2533,0,0,1,.14-.2236l1.0928-.6162c.1855-.1123.3262-.1026.42.0283a5.13,5.13,0,0,0,4.6758,2.4072,5.3415,5.3415,0,0,0,2.73-.6855,1.9637,1.9637,0,0,0,1.0781-1.7217,1.6437,1.6437,0,0,0-.6435-1.3164,6.0137,6.0137,0,0,0-2.1006-.9522l-2.8838-.8115a6.0019,6.0019,0,0,1-2.9258-1.4844,3.2941,3.2941,0,0,1-.8818-2.3242,3.8768,3.8768,0,0,1,.7138-2.2959,4.5506,4.5506,0,0,1,2.0157-1.5537,8.2966,8.2966,0,0,1,6.3007.1543,5.37,5.37,0,0,1,2.2539,1.9033c.13.1866.1211.3174-.0283.3916l-1.1758.6446a.4412.4412,0,0,1-.1679.0566.3838.3838,0,0,1-.252-.1406A4.1939,4.1939,0,0,0,515.76,610.4517Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M528.1221,610.2983c-.15,0-.2246.084-.2246.252v8.876a2.684,2.684,0,0,0,.42,1.7363,1.7459,1.7459,0,0,0,1.3721.4756h1.9326a.2719.2719,0,0,1,.3076.3076l-.0283.9531c0,.1856-.1114.3076-.336.3633a16.2778,16.2778,0,0,1-2.6318.1406,3.4857,3.4857,0,0,1-2.5762-.8261,3.623,3.623,0,0,1-.8115-2.6182V610.606c0-.2051-.0938-.3077-.28-.3077h-2.24c-.2051,0-.3076-.0927-.3076-.28v-1.1758a.2469.2469,0,0,1,.28-.2793h2.38c.13,0,.1953-.0752.1953-.2246l.3086-4.4522c0-.2422.1026-.3642.3077-.3642h1.4q.3356,0,.336.3926v4.4238a.1981.1981,0,0,0,.2236.2246h3.5283q.3076,0,.3077.2519v1.2032c0,.1875-.1026.28-.3077.28Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M549.3174,623.1509q0,.2518-.3076.2519h-1.96a.5167.5167,0,0,1-.28-.07.7292.7292,0,0,1-.2236-.2656l-4.2285-7.252c-.0371-.0937-.084-.1445-.14-.1543s-.122.0235-.1963.0977l-2.9677,2.9961a.7578.7578,0,0,0-.1963.56v3.6114a.49.49,0,0,1-.1123.3779.5986.5986,0,0,1-.3916.0986h-1.4278a.4874.4874,0,0,1-.3359-.084.4324.4324,0,0,1-.084-.3085V603.0181c0-.2608.1016-.3916.3076-.3916h1.708c.2237,0,.336.122.336.3642v12.9922c0,.1856.0283.2793.084.2793s.1211-.0557.1963-.168l6.916-7.1953a.8421.8421,0,0,1,.5879-.3359h1.708c.2236,0,.3359.0644.3359.1953a.8136.8136,0,0,1-.1963.3359l-4.4238,4.5079a.5372.5372,0,0,0-.1123.2519.483.483,0,0,0,.084.2246l5.2646,8.875A.28.28,0,0,1,549.3174,623.1509Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M556.0938,605.37a.6044.6044,0,0,1-.1124.4063.5075.5075,0,0,1-.3916.1269h-1.624c-.28,0-.4209-.1582-.4209-.4765v-2.3516q0-.4482.3926-.4482h1.7637a.3826.3826,0,0,1,.28.1123.4174.4174,0,0,1,.1124.3076Zm-.1124,17.5567a.4937.4937,0,0,1-.1123.3779.5984.5984,0,0,1-.3916.0986H554.05a.487.487,0,0,1-.3359-.084.4315.4315,0,0,1-.085-.3085V608.9536c0-.26.1123-.3906.3369-.3906h1.708q.3076,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M571.3672,609.2349a7.0682,7.0682,0,0,1,2.31,2.5195l.0557.2246c0,.1113-.0654.1768-.1953.1953l-1.54.3643-.0839.0283c-.0938,0-.1875-.0742-.28-.2246a7.5869,7.5869,0,0,0-1.7217-1.7637,3.2942,3.2942,0,0,0-1.8906-.5605,4.2136,4.2136,0,0,0-3.4717,1.61,7.99,7.99,0,0,0,.0137,8.7217,4.2963,4.2963,0,0,0,3.5146,1.5957,4.1505,4.1505,0,0,0,2.31-.6436,6.0007,6.0007,0,0,0,1.8057-1.9873c.0566-.0937.1123-.1445.1679-.1543a.3237.3237,0,0,1,.1963.0411l1.2041.4492c.13.0556.168.1777.1123.3633a7.3,7.3,0,0,1-2.38,2.6464,6.2964,6.2964,0,0,1-3.6123,1.0215,6.8955,6.8955,0,0,1-3.6406-.9658,6.6345,6.6345,0,0,1-2.4912-2.7021,8.54,8.54,0,0,1-.8965-3.9756,8.8031,8.8031,0,0,1,.8965-4.0186,6.6443,6.6443,0,0,1,2.5059-2.7578,6.8447,6.8447,0,0,1,3.6259-.9805A6.1825,6.1825,0,0,1,571.3672,609.2349Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M578.3252,623.0386V602.9907c0-.2422.1025-.3642.3086-.3642h1.6514c.205,0,.3086.1123.3086.3359v7.4756q0,.309.2519.084a14.4587,14.4587,0,0,1,2.7158-1.7364,5.933,5.933,0,0,1,2.4639-.5039,4.5228,4.5228,0,0,1,3.3184,1.1485,4.173,4.173,0,0,1,1.1611,3.1084v10.415c0,.2988-.14.4492-.42.4492h-1.5674a.3217.3217,0,0,1-.3643-.3642v-9.9961a3.0242,3.0242,0,0,0-.7138-2.1563,2.8934,2.8934,0,0,0-2.1983-.7558,4.4528,4.4528,0,0,0-2.1416.5185,14.9627,14.9627,0,0,0-2.4219,1.75v10.584c0,.2793-.15.42-.4482.42h-1.54A.3217.3217,0,0,1,578.3252,623.0386Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M598.2334,622.9536a.4512.4512,0,0,1-.1123.3506.5986.5986,0,0,1-.3916.0986h-1.96a.4956.4956,0,0,1-.3222-.084.3926.3926,0,0,1-.0977-.3085v-2.4082q0-.3633.3076-.3633h2.2959c.1865,0,.28.1113.28.3359Z" transform="translate(-58.8798 50.5781)"/>
      </g>
    </g>
  </g>
  <g id="odpowiedzialnosc" class="our-values__text">
    <g>
      <path class="cls-2" d="M454.779,226.4276c0,116.1624,94.5077,210.67,210.67,210.67s210.6641-94.5077,210.6641-210.67-94.5023-210.67-210.6641-210.67S454.779,110.2658,454.779,226.4276Z" transform="translate(-58.8798 50.5781)"/>
      <g>
        <path class="cls-3" d="M601.7461,177.5366a.32.32,0,0,1-.0977-.2373V157.2515q0-.3927.3643-.3926h6.4678a10.1424,10.1424,0,0,1,5.7539,1.3857,4.96,4.96,0,0,1,1.1338,6.8467,5.9474,5.9474,0,0,1-2.7715,1.8193c-.0752.02-.1123.0567-.1123.1123s.0371.0938.1123.1124q4.1718,1.0633,4.1719,4.956a4.669,4.669,0,0,1-2.0723,4.1572,10.8286,10.8286,0,0,1-6.0195,1.3868h-6.6924A.3206.3206,0,0,1,601.7461,177.5366Zm7.0986-11.4092a6.2627,6.2627,0,0,0,3.3321-.8408,2.9681,2.9681,0,0,0,1.372-2.7431,3.1748,3.1748,0,0,0-1.3447-2.7862,6.42,6.42,0,0,0-3.7236-.9385H604.7c-.2422,0-.3643.1124-.3643.336v6.6084c0,.2422.084.3642.252.3642Zm-.3086,9.5479a7.4542,7.4542,0,0,0,4.0879-.8965,3.0952,3.0952,0,0,0,1.3448-2.7715,3.28,3.28,0,0,0-1.4-2.9121,6.5153,6.5153,0,0,0-3.7237-.9521h-4.1162a.4988.4988,0,0,0-.3086.07.3375.3375,0,0,0-.084.2656v6.8037a.5889.5889,0,0,0,.0567.3086.2177.2177,0,0,0,.1953.084Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M624.5244,159.603a.6066.6066,0,0,1-.1123.4063.5076.5076,0,0,1-.3916.126h-1.624c-.28,0-.4209-.1582-.4209-.4766v-2.3516q0-.4482.3926-.4482h1.7636a.383.383,0,0,1,.28.1123.4177.4177,0,0,1,.1123.3076Zm-.1123,17.5557a.4925.4925,0,0,1-.1123.3779.5927.5927,0,0,1-.3916.0987h-1.4277a.488.488,0,0,1-.336-.084.4323.4323,0,0,1-.0849-.3086V163.187q0-.3911.3369-.3916h1.708q.3077,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M632.3779,173.6313a4.9508,4.9508,0,0,0,1.7784,1.8194,4.6939,4.6939,0,0,0,2.4638.6719,4.9821,4.9821,0,0,0,4.3682-2.3233.4063.4063,0,0,1,.1953-.126.3253.3253,0,0,1,.1963.042l1.1758.5879c.1494.0938.1679.2237.0566.3916a6.8388,6.8388,0,0,1-2.6045,2.45,7.8813,7.8813,0,0,1-3.6123.77,7.1177,7.1177,0,0,1-3.668-.9512,6.6013,6.6013,0,0,1-2.5332-2.6748,8.2808,8.2808,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6463,6.6463,0,0,1,2.5342-2.73,6.9849,6.9849,0,0,1,3.626-.9522,6.8739,6.8739,0,0,1,3.542.8955,5.994,5.994,0,0,1,2.3652,2.6182,9.195,9.195,0,0,1,.8408,4.0742v.1123a.69.69,0,0,1-.084.378.3787.3787,0,0,1-.3359.1259H631.9717q-.252,0-.252.42A4.9312,4.9312,0,0,0,632.3779,173.6313Zm7.91-4.4521a.693.693,0,0,0,.3916-.07c.0566-.0459.084-.1543.084-.3223a5.0924,5.0924,0,0,0-.5176-2.2812,4.1044,4.1044,0,0,0-1.5127-1.6807,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.705,4.705,0,0,0-1.624,1.8486,5.1431,5.1431,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M647.8193,177.271V163.1587q0-.3633.3086-.3633h1.6241a.2719.2719,0,0,1,.3076.3076v1.82c0,.1114.0322.1778.0986.1954.0645.02.1348-.0176.21-.1114q2.1268-2.4916,4.34-2.4922.98,0,.98.3643v1.68c0,.1865-.084.2617-.252.2236a7.9081,7.9081,0,0,0-1.26-.084,3.9729,3.9729,0,0,0-1.7637.4766,5.0151,5.0151,0,0,0-1.5967,1.2314,2.3668,2.3668,0,0,0-.6435,1.54v9.2685a.3714.3714,0,0,1-.42.42h-1.54Q647.8194,177.6353,647.8193,177.271Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M658.7256,177.5649a.3748.3748,0,0,1-.07-.2656v-.8125a.9061.9061,0,0,1,.07-.3779,2.9338,2.9338,0,0,1,.2656-.4336l8.1484-10.9482.084-.168c0-.0742-.0752-.1123-.2246-.1123h-7.42a.3868.3868,0,0,1-.2373-.0557q-.07-.0556-.07-.2519V163.103c0-.2051.0928-.3076.28-.3076h10.3593c.168,0,.252.0928.252.2793v.8965a1.1968,1.1968,0,0,1-.2793.7275l-8.1768,10.9766c-.0742.0937-.1025.168-.084.2236s.0743.084.168.084h8.2324q.3076,0,.3077.252v1.0644c0,.2236-.1211.336-.3633.336H658.9912A.3749.3749,0,0,1,658.7256,177.5649Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M677.0654,173.6313a4.9508,4.9508,0,0,0,1.7784,1.8194,4.6939,4.6939,0,0,0,2.4638.6719,4.9821,4.9821,0,0,0,4.3682-2.3233.4063.4063,0,0,1,.1953-.126.3253.3253,0,0,1,.1963.042l1.1758.5879c.1494.0938.1679.2237.0566.3916a6.8388,6.8388,0,0,1-2.6045,2.45,7.8813,7.8813,0,0,1-3.6123.77,7.1177,7.1177,0,0,1-3.668-.9512,6.6013,6.6013,0,0,1-2.5332-2.6748,8.2808,8.2808,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6463,6.6463,0,0,1,2.5342-2.73,6.9849,6.9849,0,0,1,3.626-.9522,6.8739,6.8739,0,0,1,3.542.8955,5.994,5.994,0,0,1,2.3652,2.6182,9.195,9.195,0,0,1,.8408,4.0742v.1123a.69.69,0,0,1-.084.378.3787.3787,0,0,1-.3359.1259H676.6592q-.252,0-.252.42A4.9312,4.9312,0,0,0,677.0654,173.6313Zm7.91-4.4521a.693.693,0,0,0,.3916-.07c.0566-.0459.084-.1543.084-.3223a5.0924,5.0924,0,0,0-.5176-2.2812,4.1044,4.1044,0,0,0-1.5127-1.6807,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.705,4.705,0,0,0-1.624,1.8486,5.1431,5.1431,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M692.5068,177.271V163.1587q0-.3633.3077-.3633h1.6806q.28,0,.2793.3076v1.6241q0,.3354.28.0839a14.7926,14.7926,0,0,1,2.5762-1.7636,5.2459,5.2459,0,0,1,2.38-.5323,4.4839,4.4839,0,0,1,2.59.7,3.4845,3.4845,0,0,1,1.3867,1.7637c.0371.0557.0742.084.1113.084a.6106.6106,0,0,0,.28-.14,13.9683,13.9683,0,0,1,2.6182-1.82,5.3578,5.3578,0,0,1,2.45-.5879,3.9445,3.9445,0,0,1,4.2832,4.2559v10.416q0,.4482-.42.4483h-1.5673a.3217.3217,0,0,1-.3643-.3643v-9.9961a3.1406,3.1406,0,0,0-.6719-2.1562,2.5588,2.5588,0,0,0-2.0166-.7559,4.2541,4.2541,0,0,0-2.1.5459,11.0469,11.0469,0,0,0-1.9043,1.3584,1.629,1.629,0,0,0-.3076.3916.94.94,0,0,0-.084.42V177.187q0,.4482-.42.4483h-1.5684a.3221.3221,0,0,1-.3642-.3643v-9.9961q0-2.9121-2.6875-2.9121a4.1792,4.1792,0,0,0-2.0576.5185,14.2536,14.2536,0,0,0-2.3389,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C692.6367,177.6353,692.5068,177.5142,692.5068,177.271Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M718.7422,182.1987c-.2422-.0371-.3643-.14-.3643-.3076v-1.0918a.2941.2941,0,0,1,.1543-.28,1.5352,1.5352,0,0,1,.63-.0839h1.7647a1.8323,1.8323,0,0,0,1.2314-.49,3.6953,3.6953,0,0,0,1.2041-2.6181,1.67,1.67,0,0,0-.1123-.6719l-5.3477-13.3838a.39.39,0,0,1-.0283-.168q0-.3077.336-.3076h1.7363a.4675.4675,0,0,1,.4482.3076l4.1719,10.8643c.0557.168.1309.2519.2236.2519.1123,0,.2051-.1123.28-.3359l4.1162-10.78a.4335.4335,0,0,1,.3916-.3076h1.3164a.282.282,0,0,1,.252.126.2729.2729,0,0,1,.0283.2656l-6.16,15.7637a5.2141,5.2141,0,0,1-1.8759,2.6045,5.7389,5.7389,0,0,1-3.0528.7A10.5175,10.5175,0,0,1,718.7422,182.1987Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M523.123,210.2778a6.5141,6.5141,0,0,1-2.4921-2.7021,9.3309,9.3309,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6687,6.6687,0,0,1,2.4922,2.7578,8.834,8.834,0,0,1,.8818,4.0039,8.4913,8.4913,0,0,1-.8955,3.9482,6.7209,6.7209,0,0,1-2.5059,2.7159,6.9423,6.9423,0,0,1-3.6826.98A7.0658,7.0658,0,0,1,523.123,210.2778Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0671,4.0671,0,0,0-3.4014-1.6093,4.1366,4.1366,0,0,0-3.4443,1.6093,8.13,8.13,0,0,0,0,8.6944A4.1808,4.1808,0,0,0,526.89,209.48,4.0483,4.0483,0,0,0,530.291,207.8979Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M548.9531,210.5718v-1.2041c0-.168-.0468-.252-.14-.252-.0567,0-.1309.0469-.2246.1406a8.8613,8.8613,0,0,1-1.834,1.4141,5.0891,5.0891,0,0,1-2.5615.5733,6.0772,6.0772,0,0,1-3.1924-.84,5.74,5.74,0,0,1-2.2119-2.5342,9.414,9.414,0,0,1-.8116-4.1015,9.65,9.65,0,0,1,.84-4.1436,6.66,6.66,0,0,1,2.3232-2.7861,5.9454,5.9454,0,0,1,3.3887-.9942,5.5775,5.5775,0,0,1,2.1836.3916,6.6463,6.6463,0,0,1,1.7363,1.0918.7174.7174,0,0,0,.3076.169c.0938,0,.1407-.1221.1407-.3643v-6.58c0-.2421.1015-.3642.3076-.3642h1.708c.2051,0,.3076.1221.3076.3642v19.9639a.541.541,0,0,1-.084.35.4791.4791,0,0,1-.3633.0987h-1.4287A.3463.3463,0,0,1,548.9531,210.5718Zm-1.89-12.5293a4.2959,4.2959,0,0,0-1.9746-.4619,4.1679,4.1679,0,0,0-3.416,1.5957,7.0077,7.0077,0,0,0-1.2881,4.5078,6.5221,6.5221,0,0,0,1.2744,4.3965,4.1369,4.1369,0,0,0,3.2617,1.455,3.97,3.97,0,0,0,2.002-.5175,4.0993,4.0993,0,0,0,1.4277-1.3018,2.8238,2.8238,0,0,0,.5185-1.5684V199.4A7.2364,7.2364,0,0,0,547.0635,198.0425Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M559.2852,196.5444V197.72c0,.168.0459.252.14.252a.3882.3882,0,0,0,.2246-.14,13.6485,13.6485,0,0,1,1.0352-.9521,4.9618,4.9618,0,0,1,1.3164-.7,5.6541,5.6541,0,0,1,2.0439-.336,5.964,5.964,0,0,1,3.15.84,5.7081,5.7081,0,0,1,2.1836,2.52,9.5388,9.5388,0,0,1,.7979,4.1152,10.3934,10.3934,0,0,1-.7979,4.2559,5.867,5.867,0,0,1-5.6416,3.668,5.9454,5.9454,0,0,1-3.9766-1.3995q-.1962-.1962-.3076-.1963c-.0937,0-.1406.1221-.1406.3643v5.208c0,.2422-.1025.3643-.3076.3643h-1.7363c-.2051,0-.3077-.1221-.3077-.3643V196.5718a.5.5,0,0,1,.0977-.336.4527.4527,0,0,1,.3506-.1113h1.4834Q559.2852,196.1245,559.2852,196.5444Zm1.8339,12.5293a4.3485,4.3485,0,0,0,2.002.4619,4.1023,4.1023,0,0,0,3.374-1.5957,7.2142,7.2142,0,0,0,1.2735-4.5918,6.4689,6.4689,0,0,0-1.26-4.3544,4.383,4.383,0,0,0-5.2637-.9512,3.9835,3.9835,0,0,0-1.414,1.2461,2.6209,2.6209,0,0,0-.5186,1.4834v6.916A6.9139,6.9139,0,0,0,561.1191,209.0737Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M577.583,210.2778a6.5078,6.5078,0,0,1-2.4922-2.7021,9.3319,9.3319,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6741,6.6741,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8819,4.0039,8.4806,8.4806,0,0,1-.8965,3.9482,6.714,6.714,0,0,1-2.5059,2.7159,6.9388,6.9388,0,0,1-3.6816.98A7.0633,7.0633,0,0,1,577.583,210.2778Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4024-1.6093,4.1347,4.1347,0,0,0-3.4433,1.6093,8.1249,8.1249,0,0,0,0,8.6944,4.18,4.18,0,0,0,3.4716,1.5957A4.05,4.05,0,0,0,584.751,207.8979Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M612.4844,196.2505a.27.27,0,0,1,.0283.2656L608.2285,210.6a.4969.4969,0,0,1-.5322.3643h-1.3438a.5365.5365,0,0,1-.5879-.3643l-3.332-10.4717c-.0556-.168-.1221-.2519-.1963-.2519-.0371,0-.084.0839-.14.2519L598.9326,210.6a.4969.4969,0,0,1-.5322.3643h-1.4277a.5363.5363,0,0,1-.5879-.3643l-4.3965-14.0557a.3132.3132,0,0,1-.0274-.1406c0-.1865.1114-.2793.336-.2793h1.6513a.4678.4678,0,0,1,.4483.3076l3.332,10.8916c.0557.1875.1211.28.1963.28.0557,0,.13-.1025.2236-.3076l3.3037-10.8926a.369.369,0,0,1,.3926-.2793H603.16a.39.39,0,0,1,.42.3076l3.4443,10.8916c.0557.1875.1211.28.1963.28s.14-.1025.1963-.3076l3.22-10.8643a.4854.4854,0,0,1,.4756-.3076h1.12A.282.282,0,0,1,612.4844,196.2505Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M619.4,192.9321a.6066.6066,0,0,1-.1123.4063.5078.5078,0,0,1-.3916.126h-1.624c-.28,0-.4209-.1582-.4209-.4766v-2.3516q0-.4482.3925-.4482h1.7637a.383.383,0,0,1,.28.1123.4177.4177,0,0,1,.1123.3076Zm-.1123,17.5557a.4925.4925,0,0,1-.1123.3779.5927.5927,0,0,1-.3916.0987h-1.4278a.4879.4879,0,0,1-.3359-.084.4323.4323,0,0,1-.085-.3086V196.5161q0-.391.337-.3916h1.708q.3075,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M627.2539,206.96a4.9514,4.9514,0,0,0,1.7783,1.8194,4.6945,4.6945,0,0,0,2.4639.6719,4.9821,4.9821,0,0,0,4.3682-2.3233.4063.4063,0,0,1,.1953-.126.3253.3253,0,0,1,.1963.042l1.1757.5879c.1495.0938.168.2237.0567.3916a6.8388,6.8388,0,0,1-2.6045,2.45,7.8813,7.8813,0,0,1-3.6123.77,7.1177,7.1177,0,0,1-3.668-.9512,6.6013,6.6013,0,0,1-2.5332-2.6748,8.2808,8.2808,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6457,6.6457,0,0,1,2.5342-2.73,6.9848,6.9848,0,0,1,3.6259-.9522,6.8733,6.8733,0,0,1,3.542.8955,5.9942,5.9942,0,0,1,2.3653,2.6182,9.195,9.195,0,0,1,.8408,4.0742v.1123a.69.69,0,0,1-.084.378.3788.3788,0,0,1-.3359.1259H626.8477q-.252,0-.252.42A4.93,4.93,0,0,0,627.2539,206.96Zm7.91-4.4521a.6932.6932,0,0,0,.3916-.07c.0566-.0459.0839-.1543.0839-.3223a5.0924,5.0924,0,0,0-.5175-2.2812,4.1044,4.1044,0,0,0-1.5127-1.6807,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.7034,4.7034,0,0,0-1.624,1.8486,5.1419,5.1419,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M652.8594,210.5718v-1.2041c0-.168-.0469-.252-.14-.252a.3822.3822,0,0,0-.2236.1406,8.8878,8.8878,0,0,1-1.834,1.4141,5.0931,5.0931,0,0,1-2.5625.5733,6.0739,6.0739,0,0,1-3.1914-.84,5.7484,5.7484,0,0,1-2.2129-2.5342,9.4284,9.4284,0,0,1-.8115-4.1015,9.6665,9.6665,0,0,1,.84-4.1436,6.6693,6.6693,0,0,1,2.3243-2.7861,5.9416,5.9416,0,0,1,3.3876-.9942,5.5823,5.5823,0,0,1,2.1846.3916,6.64,6.64,0,0,1,1.7354,1.0918.713.713,0,0,0,.3086.169c.0927,0,.14-.1221.14-.3643v-6.58c0-.2421.1026-.3642.3076-.3642h1.708c.2061,0,.3086.1221.3086.3642v19.9639a.5472.5472,0,0,1-.084.35.48.48,0,0,1-.3642.0987H653.252A.347.347,0,0,1,652.8594,210.5718Zm-1.89-12.5293a4.2888,4.2888,0,0,0-1.9736-.4619,4.1679,4.1679,0,0,0-3.416,1.5957,7.0018,7.0018,0,0,0-1.2881,4.5078,6.5221,6.5221,0,0,0,1.2734,4.3965,4.1391,4.1391,0,0,0,3.2627,1.455,3.9752,3.9752,0,0,0,2.002-.5175,4.1081,4.1081,0,0,0,1.4277-1.3018,2.83,2.83,0,0,0,.5176-1.5684V199.4A7.2188,7.2188,0,0,0,650.97,198.0425Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M659.65,210.894a.3748.3748,0,0,1-.07-.2656v-.8125a.9061.9061,0,0,1,.07-.3779,2.9338,2.9338,0,0,1,.2656-.4336l8.1485-10.9482.0839-.168c0-.0742-.0752-.1123-.2246-.1123h-7.42a.3868.3868,0,0,1-.2373-.0557q-.07-.0557-.07-.2519v-1.0362c0-.205.0928-.3076.28-.3076h10.3593c.168,0,.252.0928.252.2793V197.3a1.1968,1.1968,0,0,1-.2793.7275l-8.1768,10.9766c-.0742.0937-.1025.168-.0839.2236s.0742.084.1679.084h8.2324q.3076,0,.3077.252v1.0644q0,.3356-.3633.336H659.916A.3749.3749,0,0,1,659.65,210.894Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M678.2275,192.9321a.6012.6012,0,0,1-.1123.4063.5047.5047,0,0,1-.3916.126H676.1c-.28,0-.42-.1582-.42-.4766v-2.3516c0-.2988.13-.4482.3916-.4482h1.7646a.3806.3806,0,0,1,.2793.1123.4138.4138,0,0,1,.1123.3076Zm-.1123,17.5557a.4928.4928,0,0,1-.1113.3779.5975.5975,0,0,1-.3926.0987h-1.4277a.4876.4876,0,0,1-.3359-.084.437.437,0,0,1-.084-.3086V196.5161q0-.391.3359-.3916h1.708q.3077,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M687.4951,211.2437a4.9837,4.9837,0,0,1-3.43-1.1055,3.7529,3.7529,0,0,1-1.2461-2.9541,4.0518,4.0518,0,0,1,1.89-3.5283,10.9642,10.9642,0,0,1,5.4746-1.5118l2.1836-.1679q.4761-.0557.4766-.42V200.8a3.1309,3.1309,0,0,0-.9385-2.3945,3.5492,3.5492,0,0,0-2.5342-.8819,4.7388,4.7388,0,0,0-4.0313,2.24.4582.4582,0,0,1-.1679.1816.2711.2711,0,0,1-.2246-.0137l-1.5118-.5039a.1975.1975,0,0,1-.14-.1963.636.636,0,0,1,.084-.2519,5.8256,5.8256,0,0,1,2.3935-2.31,7.74,7.74,0,0,1,3.6817-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.4272,4.4272,0,0,1,1.456,3.5146v9.9121a.4893.4893,0,0,1-.084.336.436.436,0,0,1-.3076.084h-1.4q-.3354,0-.3916-.4483l-.0566-1.2881c-.0186-.13-.0654-.1963-.14-.1963a.3836.3836,0,0,0-.2519.1407A6.9765,6.9765,0,0,1,687.4951,211.2437Zm4.9287-7.84-1.82.168a9.0963,9.0963,0,0,0-4.0176,1.0088,2.5862,2.5862,0,0,0-1.414,2.3232,2.3316,2.3316,0,0,0,.8535,1.9043,3.4891,3.4891,0,0,0,2.2822.7,4.9142,4.9142,0,0,0,1.708-.3086,4.995,4.995,0,0,0,1.4834-.84,4.0942,4.0942,0,0,0,.9942-1.0782,2.1249,2.1249,0,0,0,.3506-1.1054v-2.3242C692.8438,203.5542,692.7031,203.4038,692.4238,203.4038Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M702.98,210.4878a.4925.4925,0,0,1-.1123.3779.5964.5964,0,0,1-.3916.0987H701.02a.4879.4879,0,0,1-.3359-.084.4323.4323,0,0,1-.085-.3086l.0284-19.9912q0-.3927.3359-.3926h1.6807c.2236,0,.3359.1221.3359.3642Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M708.69,210.6V196.4878q0-.3633.3086-.3633h1.68a.2726.2726,0,0,1,.3086.3076v1.6241c0,.206.084.2334.252.0839a15.3037,15.3037,0,0,1,2.6738-1.7636,5.6457,5.6457,0,0,1,2.4775-.5323,4.5858,4.5858,0,0,1,3.3184,1.1475,4.0824,4.0824,0,0,1,1.1894,3.08v10.4443q0,.4482-.42.4483h-1.5674a.3217.3217,0,0,1-.3642-.3643V200.604a3.0235,3.0235,0,0,0-.7139-2.1562,2.893,2.893,0,0,0-2.1982-.7559,4.6187,4.6187,0,0,0-2.17.5185,13.5906,13.5906,0,0,0-2.4218,1.75v10.584a.358.358,0,0,1-.42.42h-1.54Q708.69,210.9644,708.69,210.6Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M728.9492,210.2778a6.5143,6.5143,0,0,1-2.4922-2.7021,9.3314,9.3314,0,0,1,.0284-7.98,7.0985,7.0985,0,0,1,9.8837-2.7578,6.6681,6.6681,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8819,4.0039,8.4925,8.4925,0,0,1-.8955,3.9482,6.7209,6.7209,0,0,1-2.5059,2.7159,6.9423,6.9423,0,0,1-3.6826.98A7.0658,7.0658,0,0,1,728.9492,210.2778Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0671,4.0671,0,0,0-3.4014-1.6093,4.1364,4.1364,0,0,0-3.4443,1.6093,8.13,8.13,0,0,0,0,8.6944,4.1807,4.1807,0,0,0,3.4726,1.5957A4.0483,4.0483,0,0,0,736.1172,207.8979Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M751.9092,198.0142a5.161,5.161,0,0,0-2.3662-.5176,4.4349,4.4349,0,0,0-2.52.6572,1.9943,1.9943,0,0,0-.98,1.7227,1.6488,1.6488,0,0,0,.49,1.1621,4.139,4.139,0,0,0,1.7783.9092l3.5557,1.0644a5.5786,5.5786,0,0,1,2.7441,1.4981,3.4174,3.4174,0,0,1,.8955,2.3662,3.5858,3.5858,0,0,1-.7832,2.2812,5.05,5.05,0,0,1-2.1562,1.54,9.08,9.08,0,0,1-6.9444-.3077,5.1859,5.1859,0,0,1-2.38-2.2255.4832.4832,0,0,1-.084-.2247.2537.2537,0,0,1,.14-.2236l1.0928-.6162c.1855-.1123.3262-.1025.42.0283a5.13,5.13,0,0,0,4.6758,2.4072,5.3316,5.3316,0,0,0,2.73-.6855,1.9637,1.9637,0,0,0,1.0781-1.7217,1.6451,1.6451,0,0,0-.6435-1.3164,6.012,6.012,0,0,0-2.1006-.9521l-2.8838-.8116a5.9943,5.9943,0,0,1-2.9258-1.4843,3.2929,3.2929,0,0,1-.8818-2.3243,3.8775,3.8775,0,0,1,.7138-2.2959,4.5585,4.5585,0,0,1,2.0157-1.5537,8.309,8.309,0,0,1,6.3007.1543,5.3708,5.3708,0,0,1,2.254,1.9034c.13.1875.121.3183-.0284.3925l-1.1757.6436a.4242.4242,0,0,1-.168.0566.3834.3834,0,0,1-.252-.1406A4.1909,4.1909,0,0,0,751.9092,198.0142Zm1.05-6.65a.3952.3952,0,0,1,.0283.1679c0,.15-.1025.252-.3076.3086l-6.4121,2.0157a.3957.3957,0,0,1-.168.0283c-.1308,0-.2246-.084-.28-.252l-.2236-.6162a.2994.2994,0,0,1-.0283-.14c0-.1494.0556-.2334.168-.252l6.4677-2.6885a.1626.1626,0,0,1,.1123-.0283.22.22,0,0,1,.2237.168Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M769.9971,196.7964a7.0664,7.0664,0,0,1,2.3095,2.5195l.0567.2246c0,.1114-.0654.1768-.1963.1953l-1.54.3643-.084.0283c-.0938,0-.1866-.0742-.28-.2246a7.6072,7.6072,0,0,0-1.7217-1.7637,3.29,3.29,0,0,0-1.89-.5595,4.2147,4.2147,0,0,0-3.4727,1.6093,7.9894,7.9894,0,0,0,.0147,8.7227,4.2986,4.2986,0,0,0,3.5136,1.5957,4.1487,4.1487,0,0,0,2.31-.6445,6,6,0,0,0,1.8066-1.9873c.0557-.0938.1123-.1446.168-.1543a.3332.3332,0,0,1,.1963.042l1.2031.4482c.1309.0557.168.1777.1123.3633a7.3015,7.3015,0,0,1-2.38,2.6465,6.2931,6.2931,0,0,1-3.6123,1.0215,6.8848,6.8848,0,0,1-3.64-.9659,6.6368,6.6368,0,0,1-2.4922-2.7021,8.5405,8.5405,0,0,1-.8955-3.9756,8.8,8.8,0,0,1,.8955-4.0176,6.66,6.66,0,0,1,2.5059-2.7588,6.844,6.844,0,0,1,3.6259-.98A6.1877,6.1877,0,0,1,769.9971,196.7964Zm.1543-5.4326a.3974.3974,0,0,1,.0273.1679c0,.15-.1025.252-.3076.3086l-6.4121,2.0157a.3926.3926,0,0,1-.168.0283c-.1308,0-.2236-.084-.28-.252l-.2236-.6162a.3125.3125,0,0,1-.0283-.14c0-.1494.0566-.2334.168-.252l6.4687-2.6885a.16.16,0,0,1,.1113-.0283.2212.2212,0,0,1,.2246.168Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M783.1289,210.894a.3748.3748,0,0,1-.07-.2656v-.8125a.9061.9061,0,0,1,.07-.3779,2.9338,2.9338,0,0,1,.2656-.4336l8.1485-10.9482.084-.168c0-.0742-.0752-.1123-.2247-.1123h-7.42a.3868.3868,0,0,1-.2373-.0557q-.07-.0557-.07-.2519v-1.0362c0-.205.0928-.3076.28-.3076h10.3594c.1679,0,.2519.0928.2519.2793V197.3a1.1962,1.1962,0,0,1-.2793.7275L786.11,209.0044c-.0743.0937-.1026.168-.084.2236s.0742.084.1679.084h8.2325q.3075,0,.3076.252v1.0644q0,.3356-.3633.336H783.3945A.3749.3749,0,0,1,783.1289,210.894Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M802.8828,211.2437a4.9837,4.9837,0,0,1-3.43-1.1055,3.7529,3.7529,0,0,1-1.2461-2.9541,4.0518,4.0518,0,0,1,1.89-3.5283,10.9572,10.9572,0,0,1,5.4736-1.5118l2.1846-.1679q.4761-.0557.4756-.42V200.8a3.13,3.13,0,0,0-.9375-2.3945,3.5523,3.5523,0,0,0-2.5342-.8819,4.74,4.74,0,0,0-4.0322,2.24.4514.4514,0,0,1-.168.1816.2688.2688,0,0,1-.2236-.0137l-1.5127-.5039a.1986.1986,0,0,1-.14-.1963.6531.6531,0,0,1,.084-.2519,5.8335,5.8335,0,0,1,2.3945-2.31,7.7341,7.7341,0,0,1,3.6817-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.43,4.43,0,0,1,1.456,3.5146v9.9121a.4893.4893,0,0,1-.084.336.4389.4389,0,0,1-.3086.084H808.707q-.3369,0-.3925-.4483l-.0557-1.2881c-.0186-.13-.0654-.1963-.14-.1963a.3874.3874,0,0,0-.2529.1407A6.976,6.976,0,0,1,802.8828,211.2437Zm4.9277-7.84-1.82.168a9.0993,9.0993,0,0,0-4.0175,1.0088,2.588,2.588,0,0,0-1.4141,2.3232,2.33,2.33,0,0,0,.8545,1.9043,3.4843,3.4843,0,0,0,2.2812.7,4.9059,4.9059,0,0,0,1.708-.3086,5,5,0,0,0,1.4844-.84,4.1112,4.1112,0,0,0,.9942-1.0782,2.1231,2.1231,0,0,0,.35-1.1054v-2.3242C808.2305,203.5542,808.0908,203.4038,807.8105,203.4038Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M571.69,229.58a.2727.2727,0,0,1,.0273.2656l-4.2832,14.085a.4979.4979,0,0,1-.5322.3633h-1.3448a.5373.5373,0,0,1-.5878-.3633l-3.3321-10.4727c-.0556-.1679-.1211-.2519-.1953-.2519-.0381,0-.084.084-.1406.2519L558.1367,243.93a.496.496,0,0,1-.5312.3633h-1.4287a.5367.5367,0,0,1-.5879-.3633l-4.3955-14.0567a.2979.2979,0,0,1-.0284-.14c0-.1865.1123-.28.336-.28h1.6523a.4678.4678,0,0,1,.4483.3076l3.332,10.8926c.0557.1865.1211.28.1953.28.0566,0,.1309-.1026.2246-.3086l3.3037-10.8916a.3687.3687,0,0,1,.3916-.28h1.3164a.3909.3909,0,0,1,.42.3076l3.4443,10.8926c.0557.1865.1211.28.1953.28s.1406-.1026.1963-.3086l3.22-10.8643a.4867.4867,0,0,1,.4766-.3076h1.12A.2843.2843,0,0,1,571.69,229.58Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M583.4346,231.3433a5.1728,5.1728,0,0,0-2.3662-.5176,4.4348,4.4348,0,0,0-2.52.6582,1.9927,1.9927,0,0,0-.98,1.7217,1.6468,1.6468,0,0,0,.49,1.1621,4.1375,4.1375,0,0,0,1.7783.91l3.5557,1.0635a5.5786,5.5786,0,0,1,2.7441,1.4981,3.4191,3.4191,0,0,1,.8955,2.3662,3.5867,3.5867,0,0,1-.7832,2.2822,5.0568,5.0568,0,0,1-2.1562,1.54,9.0751,9.0751,0,0,1-6.9444-.3086,5.1758,5.1758,0,0,1-2.38-2.2255.48.48,0,0,1-.084-.2237.255.255,0,0,1,.14-.2246l1.0928-.6152c.1855-.1123.3262-.1026.42.0273a5.1306,5.1306,0,0,0,4.6758,2.4082,5.3415,5.3415,0,0,0,2.73-.6855,1.964,1.964,0,0,0,1.0781-1.7227,1.6432,1.6432,0,0,0-.6435-1.3154,5.9956,5.9956,0,0,0-2.1006-.9522l-2.8838-.8125a5.9932,5.9932,0,0,1-2.9258-1.4834,3.2941,3.2941,0,0,1-.8818-2.3242,3.8768,3.8768,0,0,1,.7138-2.2959,4.56,4.56,0,0,1,2.0157-1.5547,8.309,8.309,0,0,1,6.3007.1543,5.3653,5.3653,0,0,1,2.2539,1.9043c.13.1866.1211.3174-.0283.3916l-1.1758.6446a.4349.4349,0,0,1-.1679.0556.3817.3817,0,0,1-.252-.14A4.1855,4.1855,0,0,0,583.4346,231.3433Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M590.6582,244.2241a.38.38,0,0,1-.0693-.2666v-.8115a.9068.9068,0,0,1,.0693-.3779,3.097,3.097,0,0,1,.2666-.4346l8.1475-10.9482.084-.168c0-.0742-.0743-.1113-.2237-.1113h-7.42a.3827.3827,0,0,1-.2383-.0567q-.07-.0556-.07-.2519v-1.0362c0-.205.0938-.3076.28-.3076h10.36c.168,0,.252.0938.252.28v.8955a1.1927,1.1927,0,0,1-.28.7285l-8.1758,10.9756c-.0752.0937-.1025.168-.084.2246s.0743.084.168.084h8.2315c.206,0,.3085.084.3085.2519v1.0635c0,.2246-.122.336-.3642.336H590.9248A.38.38,0,0,1,590.6582,244.2241Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M606.2412,248.8579c-.2432-.0381-.3642-.1406-.3642-.3086v-1.0918a.2932.2932,0,0,1,.1543-.28,1.5346,1.5346,0,0,1,.63-.0839h1.7637a1.8336,1.8336,0,0,0,1.2324-.4893,3.699,3.699,0,0,0,1.2041-2.6182,1.6841,1.6841,0,0,0-.1123-.6718L605.4014,229.93a.381.381,0,0,1-.0284-.169c0-.205.1124-.3076.336-.3076h1.7363a.4657.4657,0,0,1,.4473.3076l4.1728,10.8643c.0557.168.13.2519.2237.2519.1123,0,.205-.1113.28-.3359l4.1153-10.78a.4356.4356,0,0,1,.3925-.3076h1.3155a.2817.2817,0,0,1,.2519.126.27.27,0,0,1,.0284.2656l-6.16,15.7647a5.21,5.21,0,0,1-1.876,2.6035,5.7257,5.7257,0,0,1-3.0517.7A10.5765,10.5765,0,0,1,606.2412,248.8579Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M630.3066,231.3433a5.1722,5.1722,0,0,0-2.3662-.5176,4.4347,4.4347,0,0,0-2.5195.6582,1.9928,1.9928,0,0,0-.9805,1.7217,1.6469,1.6469,0,0,0,.49,1.1621,4.1369,4.1369,0,0,0,1.7783.91l3.5556,1.0635a5.5789,5.5789,0,0,1,2.7442,1.4981,3.4191,3.4191,0,0,1,.8955,2.3662,3.5867,3.5867,0,0,1-.7832,2.2822,5.0565,5.0565,0,0,1-2.1563,1.54,9.0748,9.0748,0,0,1-6.9443-.3086,5.1761,5.1761,0,0,1-2.38-2.2255.4815.4815,0,0,1-.084-.2237.2551.2551,0,0,1,.14-.2246l1.0928-.6152c.1855-.1123.3261-.1026.42.0273a5.1306,5.1306,0,0,0,4.6758,2.4082,5.3418,5.3418,0,0,0,2.7295-.6855,1.964,1.964,0,0,0,1.0781-1.7227,1.6433,1.6433,0,0,0-.6436-1.3154,5.9956,5.9956,0,0,0-2.1006-.9522l-2.8837-.8125a5.9928,5.9928,0,0,1-2.9258-1.4834,3.2942,3.2942,0,0,1-.8819-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.56,4.56,0,0,1,2.0156-1.5547,8.3093,8.3093,0,0,1,6.3008.1543,5.3653,5.3653,0,0,1,2.2539,1.9043c.13.1866.1211.3174-.0283.3916l-1.1758.6446a.4347.4347,0,0,1-.168.0556.3818.3818,0,0,1-.2519-.14A4.1851,4.1851,0,0,0,630.3066,231.3433Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M642.6689,231.19c-.15,0-.2246.084-.2246.252v8.876a2.6787,2.6787,0,0,0,.42,1.7353,1.7424,1.7424,0,0,0,1.372.4766h1.9326a.2719.2719,0,0,1,.3077.3076l-.0284.9522c0,.1865-.1113.3085-.3359.3642a16.3089,16.3089,0,0,1-2.6318.14,3.4853,3.4853,0,0,1-2.5762-.8262,3.6187,3.6187,0,0,1-.8115-2.6172v-9.3525c0-.2051-.0938-.3077-.28-.3077h-2.24q-.3076,0-.3077-.28v-1.1758a.2476.2476,0,0,1,.28-.28h2.38c.13,0,.1953-.0742.1953-.2236l.3086-4.4522c0-.2422.1026-.3642.3076-.3642h1.4q.3356,0,.336.3926V229.23a.1974.1974,0,0,0,.2236.2236h3.5283q.3076,0,.3076.252V230.91q0,.28-.3076.28Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M663.8643,244.0415q0,.252-.3077.252h-1.96a.5164.5164,0,0,1-.28-.0694.7339.7339,0,0,1-.2236-.2666l-4.2285-7.2519c-.0372-.0928-.084-.1446-.14-.1543a.248.248,0,0,0-.1963.0986l-2.9678,2.9961a.7574.7574,0,0,0-.1962.56v3.6123a.492.492,0,0,1-.1123.3779.5993.5993,0,0,1-.3916.0977h-1.4278a.4823.4823,0,0,1-.3359-.084.4293.4293,0,0,1-.084-.3076V223.91c0-.2608.1016-.3916.3076-.3916h1.708q.3356,0,.336.3632v12.9922c0,.1866.0283.28.0839.28s.1211-.0557.1963-.168l6.916-7.1962a.8459.8459,0,0,1,.5879-.336h1.708c.2237,0,.336.0654.336.1963a.8191.8191,0,0,1-.1963.3359l-4.4238,4.5079a.5372.5372,0,0,0-.1123.2519.48.48,0,0,0,.0839.2236l5.2647,8.876A.2774.2774,0,0,1,663.8643,244.0415Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M670.6406,226.2612a.6049.6049,0,0,1-.1123.4063.5076.5076,0,0,1-.3916.126h-1.624q-.42,0-.4209-.4756v-2.3526q0-.4467.3926-.4472h1.7636a.382.382,0,0,1,.28.1113.42.42,0,0,1,.1123.3086Zm-.1123,17.5567a.4956.4956,0,0,1-.1123.3779.5991.5991,0,0,1-.3916.0977h-1.4277a.4828.4828,0,0,1-.336-.084.43.43,0,0,1-.0849-.3076V229.8452q0-.3911.3369-.3916h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M678.4941,240.29a4.9617,4.9617,0,0,0,1.7784,1.82,4.6966,4.6966,0,0,0,2.4638.6718,4.9829,4.9829,0,0,0,4.3682-2.3242.4039.4039,0,0,1,.1963-.126.33.33,0,0,1,.1953.042l1.1768.5879c.1484.0938.1679.2246.0556.3926a6.8188,6.8188,0,0,1-2.6035,2.4492,7.8742,7.8742,0,0,1-3.6123.77,7.1134,7.1134,0,0,1-3.668-.9521,6.601,6.601,0,0,1-2.5342-2.6738,8.2954,8.2954,0,0,1-.91-3.9336,8.9066,8.9066,0,0,1,.9238-4.1582,6.6494,6.6494,0,0,1,2.5342-2.7305,6.997,6.997,0,0,1,3.626-.9522,6.8646,6.8646,0,0,1,3.542.8965,5.9858,5.9858,0,0,1,2.3662,2.6182,9.2041,9.2041,0,0,1,.84,4.0732v.1123a.69.69,0,0,1-.084.378.3775.3775,0,0,1-.3359.1259H678.0889c-.168,0-.252.1407-.252.42A4.9258,4.9258,0,0,0,678.4941,240.29Zm7.91-4.4522a.7027.7027,0,0,0,.3926-.0693.4323.4323,0,0,0,.084-.3223,5.0841,5.0841,0,0,0-.5186-2.2822,4.1063,4.1063,0,0,0-1.5117-1.68,4.2234,4.2234,0,0,0-2.31-.63,4.361,4.361,0,0,0-2.4922.7139,4.6876,4.6876,0,0,0-1.624,1.8476,5.1311,5.1311,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M701.3564,243.93V229.8179c0-.2422.1026-.3643.3077-.3643h1.6806a.2719.2719,0,0,1,.3076.3076v1.6241q0,.309.252.0839a15.4,15.4,0,0,1,2.6738-1.7636,5.647,5.647,0,0,1,2.4785-.5323,4.5793,4.5793,0,0,1,3.3174,1.1485,4.0794,4.0794,0,0,1,1.19,3.08v10.4433q0,.4482-.42.4483h-1.5683a.3211.3211,0,0,1-.3633-.3633v-9.9961a3.018,3.018,0,0,0-.7149-2.1563,2.8893,2.8893,0,0,0-2.1972-.7558,4.6281,4.6281,0,0,0-2.17.5176,13.5817,13.5817,0,0,0-2.4229,1.75v10.5839a.357.357,0,0,1-.42.42h-1.54C701.4863,244.2935,701.3564,244.1724,701.3564,243.93Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M722.748,244.5737a4.9786,4.9786,0,0,1-3.43-1.1064,3.75,3.75,0,0,1-1.2461-2.9531,4.0509,4.0509,0,0,1,1.89-3.5284,10.9639,10.9639,0,0,1,5.4746-1.5117l2.1836-.1679c.3174-.0381.4766-.1778.4766-.4209v-.7559a3.1289,3.1289,0,0,0-.9385-2.3936,3.549,3.549,0,0,0-2.5342-.8818,4.7375,4.7375,0,0,0-4.0312,2.2393.47.47,0,0,1-.168.1826.2759.2759,0,0,1-.2246-.0147l-1.5117-.5039a.1963.1963,0,0,1-.14-.1953.6281.6281,0,0,1,.084-.2519A5.8276,5.8276,0,0,1,721.0264,230a7.7508,7.7508,0,0,1,3.6816-.8262,6.19,6.19,0,0,1,4.2,1.2745,4.4252,4.4252,0,0,1,1.4561,3.5136v9.9121a.488.488,0,0,1-.084.336.4314.4314,0,0,1-.3076.084h-1.4q-.3356,0-.3916-.4483l-.0567-1.2871c-.0185-.1309-.0654-.1963-.14-.1963a.3859.3859,0,0,0-.252.14A6.9775,6.9775,0,0,1,722.748,244.5737Zm4.9288-7.84-1.82.168a9.1105,9.1105,0,0,0-4.0175,1.0078,2.5875,2.5875,0,0,0-1.4141,2.3242,2.3316,2.3316,0,0,0,.8535,1.9043,3.4889,3.4889,0,0,0,2.2822.6992,4.9367,4.9367,0,0,0,1.7081-.3076,5.0175,5.0175,0,0,0,1.4834-.84,4.0951,4.0951,0,0,0,.9941-1.0781,2.1284,2.1284,0,0,0,.3506-1.1064v-2.3233C728.0967,236.8833,727.9561,236.7339,727.6768,236.7339Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M743.1465,231.3433a5.1754,5.1754,0,0,0-2.3662-.5176,4.4357,4.4357,0,0,0-2.52.6582,1.9937,1.9937,0,0,0-.98,1.7217,1.6472,1.6472,0,0,0,.49,1.1621,4.1393,4.1393,0,0,0,1.7774.91l3.5566,1.0635a5.58,5.58,0,0,1,2.7432,1.4981,3.4158,3.4158,0,0,1,.8964,2.3662,3.5824,3.5824,0,0,1-.7841,2.2822,5.0449,5.0449,0,0,1-2.1563,1.54,9.0723,9.0723,0,0,1-6.9433-.3086,5.1707,5.1707,0,0,1-2.38-2.2255.4693.4693,0,0,1-.084-.2237.2533.2533,0,0,1,.14-.2246l1.0918-.6152c.1866-.1123.3262-.1026.42.0273a5.1306,5.1306,0,0,0,4.6757,2.4082,5.3451,5.3451,0,0,0,2.7305-.6855,1.9638,1.9638,0,0,0,1.0781-1.7227,1.6414,1.6414,0,0,0-.6445-1.3154,5.9891,5.9891,0,0,0-2.1-.9522l-2.8838-.8125a5.9886,5.9886,0,0,1-2.9258-1.4834,3.2906,3.2906,0,0,1-.8828-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.5625,4.5625,0,0,1,2.0166-1.5547,8.3068,8.3068,0,0,1,6.3.1543,5.3543,5.3543,0,0,1,2.2539,1.9043q.1962.28-.0283.3916l-1.1758.6446a.43.43,0,0,1-.168.0556.3855.3855,0,0,1-.2519-.14A4.1827,4.1827,0,0,0,743.1465,231.3433Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M750.37,244.2241a.38.38,0,0,1-.0693-.2666v-.8115a.9068.9068,0,0,1,.0693-.3779,3.1068,3.1068,0,0,1,.2666-.4346l8.1475-10.9482.084-.168c0-.0742-.0743-.1113-.2237-.1113h-7.42a.3827.3827,0,0,1-.2383-.0567q-.07-.0556-.07-.2519v-1.0362c0-.205.0938-.3076.28-.3076h10.36c.168,0,.252.0938.252.28v.8955a1.1927,1.1927,0,0,1-.28.7285l-8.1758,10.9756c-.0752.0937-.1025.168-.0839.2246s.0742.084.1679.084h8.2315q.309,0,.3086.2519v1.0635c0,.2246-.1221.336-.3643.336H750.6367A.38.38,0,0,1,750.37,244.2241Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M768.71,240.29a4.9606,4.9606,0,0,0,1.7783,1.82,4.6966,4.6966,0,0,0,2.4638.6718,4.9828,4.9828,0,0,0,4.3682-2.3242.4043.4043,0,0,1,.1963-.126.33.33,0,0,1,.1953.042l1.1768.5879c.1484.0938.1679.2246.0556.3926a6.8188,6.8188,0,0,1-2.6035,2.4492,7.8742,7.8742,0,0,1-3.6123.77,7.1134,7.1134,0,0,1-3.668-.9521,6.6,6.6,0,0,1-2.5341-2.6738,8.2942,8.2942,0,0,1-.91-3.9336,8.9066,8.9066,0,0,1,.9238-4.1582,6.65,6.65,0,0,1,2.5342-2.7305,6.9972,6.9972,0,0,1,3.626-.9522,6.8646,6.8646,0,0,1,3.542.8965,5.9852,5.9852,0,0,1,2.3662,2.6182,9.2027,9.2027,0,0,1,.84,4.0732v.1123a.6909.6909,0,0,1-.0839.378.3779.3779,0,0,1-.336.1259H768.3047c-.168,0-.252.1407-.252.42A4.9249,4.9249,0,0,0,768.71,240.29Zm7.91-4.4522a.7025.7025,0,0,0,.3926-.0693.4319.4319,0,0,0,.084-.3223,5.0841,5.0841,0,0,0-.5186-2.2822,4.1063,4.1063,0,0,0-1.5117-1.68,4.2234,4.2234,0,0,0-2.31-.63,4.361,4.361,0,0,0-2.4922.7139,4.6876,4.6876,0,0,0-1.624,1.8476,5.1311,5.1311,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M565.585,262.91a.27.27,0,0,1,.0283.2656l-4.2842,14.084a.497.497,0,0,1-.5322.3642h-1.3438a.5364.5364,0,0,1-.5879-.3642l-3.332-10.4717c-.0557-.168-.1221-.252-.1963-.252-.0371,0-.084.084-.14.252l-3.1641,10.4717a.4969.4969,0,0,1-.5322.3642h-1.4278a.5363.5363,0,0,1-.5878-.3642l-4.3965-14.0557a.3132.3132,0,0,1-.0274-.1406c0-.1865.1114-.2793.336-.2793h1.6513a.4677.4677,0,0,1,.4483.3076l3.332,10.8916c.0557.1875.1211.28.1963.28.0557,0,.13-.1026.2236-.3076l3.3037-10.8926a.369.369,0,0,1,.3926-.2793h1.3154a.39.39,0,0,1,.42.3076l3.4443,10.8916c.0557.1875.1211.28.1963.28s.14-.1026.1963-.3076l3.22-10.8643a.4852.4852,0,0,1,.4756-.3076h1.12A.282.282,0,0,1,565.585,262.91Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M569.5049,282.187q-.3648-.0557-.3643-.3076v-1.0918a.2923.2923,0,0,1,.1543-.28,1.5347,1.5347,0,0,1,.63-.084h1.7637a1.8346,1.8346,0,0,0,1.2324-.49,3.6957,3.6957,0,0,0,1.2041-2.6182,1.6872,1.6872,0,0,0-.1123-.6718l-5.3477-13.3838a.3749.3749,0,0,1-.0283-.168q0-.3077.336-.3076h1.7363a.4655.4655,0,0,1,.4473.3076l4.1728,10.8643c.0557.1679.13.2519.2236.2519.1123,0,.2051-.1123.28-.3359l4.1152-10.78a.4357.4357,0,0,1,.3926-.3076h1.3155a.2818.2818,0,0,1,.2519.126.27.27,0,0,1,.0283.2656l-6.16,15.7637a5.2083,5.2083,0,0,1-1.876,2.6045,5.7351,5.7351,0,0,1-3.0518.7A10.5175,10.5175,0,0,1,569.5049,282.187Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M586.7246,277.6235a.3709.3709,0,0,1-.4482-.4482V257.24q0-.3926.3359-.3925h1.7363c.1866,0,.2793.1308.2793.3925v6.8594c0,.2061.0469.3086.1407.3086a.6209.6209,0,0,0,.28-.1406,6.311,6.311,0,0,1,4.4795-1.7637,5.1611,5.1611,0,0,1,4.4658,2.1143,9.18,9.18,0,0,1,1.5264,5.5019,8.785,8.785,0,0,1-1.6943,5.6973,5.7063,5.7063,0,0,1-4.6895,2.0859,5.3333,5.3333,0,0,1-2.8281-.6855,5.9677,5.9677,0,0,1-1.7363-1.5264.2259.2259,0,0,0-.168-.1113c-.0752,0-.1211.0654-.14.1953l-.1406,1.4a.7221.7221,0,0,1-.1533.35.4886.4886,0,0,1-.3506.0986Zm1.9033-5.0127a3.098,3.098,0,0,0,.5742,1.8067,4.1656,4.1656,0,0,0,1.4981,1.3154,4.0641,4.0641,0,0,0,1.9326.49,3.9434,3.9434,0,0,0,3.2334-1.5117,7.1272,7.1272,0,0,0,1.2178-4.5361,7.5981,7.5981,0,0,0-1.0918-4.41,3.6255,3.6255,0,0,0-3.1641-1.5537,4.9993,4.9993,0,0,0-2.3379.56,5.9446,5.9446,0,0,0-1.8623,1.5127Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M606.9551,276.937a6.5078,6.5078,0,0,1-2.4922-2.7021,9.3319,9.3319,0,0,1,.0283-7.9795,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6755,6.6755,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8818,4.0039,8.4794,8.4794,0,0,1-.8965,3.9482,6.7135,6.7135,0,0,1-2.5058,2.7158,6.9382,6.9382,0,0,1-3.6817.98A7.0636,7.0636,0,0,1,606.9551,276.937Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4023-1.6094,4.135,4.135,0,0,0-3.4434,1.6094,8.1254,8.1254,0,0,0,0,8.6944,4.1807,4.1807,0,0,0,3.4717,1.5957A4.05,4.05,0,0,0,614.123,274.5571Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M622.62,277.2593V263.147q0-.3633.3086-.3633h1.624a.2719.2719,0,0,1,.3077.3076v1.82c0,.1113.0322.1778.0986.1953.0644.02.1348-.0175.21-.1113q2.127-2.4917,4.34-2.4922.98,0,.98.3643v1.68c0,.1865-.084.2617-.252.2236a7.8963,7.8963,0,0,0-1.26-.084,3.9729,3.9729,0,0,0-1.7637.4766,5.0151,5.0151,0,0,0-1.5967,1.2314,2.3668,2.3668,0,0,0-.6435,1.54v9.2685a.3713.3713,0,0,1-.42.42h-1.54Q622.62,277.6235,622.62,277.2593Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M634.2129,282.187q-.3648-.0557-.3643-.3076v-1.0918a.2923.2923,0,0,1,.1543-.28,1.5347,1.5347,0,0,1,.63-.084h1.7637a1.8346,1.8346,0,0,0,1.2324-.49,3.6957,3.6957,0,0,0,1.2041-2.6182,1.6872,1.6872,0,0,0-.1123-.6718l-5.3477-13.3838a.3749.3749,0,0,1-.0283-.168q0-.3077.336-.3076h1.7363a.4655.4655,0,0,1,.4473.3076l4.1728,10.8643c.0557.1679.13.2519.2236.2519.1123,0,.2051-.1123.28-.3359l4.1153-10.78a.4355.4355,0,0,1,.3925-.3076h1.3155a.2818.2818,0,0,1,.2519.126.27.27,0,0,1,.0283.2656l-6.16,15.7637a5.2083,5.2083,0,0,1-1.876,2.6045,5.7351,5.7351,0,0,1-3.0518.7A10.5175,10.5175,0,0,1,634.2129,282.187Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M648.24,281.5151a.4316.4316,0,0,1,0-.3076l1.2608-3.1357a.6529.6529,0,0,0,.084-.253c0-.13-.1036-.1953-.3086-.1953h-.3633q-.3648,0-.3643-.3359v-2.4922c0-.2051.0928-.3086.28-.3086h2.3232a.248.248,0,0,1,.28.28v2.2959a.6418.6418,0,0,1-.084.3359l-2.0439,3.92a.5865.5865,0,0,1-.56.3086h-.3086A.2022.2022,0,0,1,648.24,281.5151Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M674.0566,277.231v-1.2041c0-.168-.0468-.252-.14-.252-.0566,0-.1309.0469-.2246.1406a8.8613,8.8613,0,0,1-1.834,1.4141,5.0891,5.0891,0,0,1-2.5615.5732,6.0783,6.0783,0,0,1-3.1924-.84,5.74,5.74,0,0,1-2.2119-2.5342,9.4141,9.4141,0,0,1-.8115-4.1016,9.6516,9.6516,0,0,1,.84-4.1435,6.66,6.66,0,0,1,2.3232-2.7861,5.9454,5.9454,0,0,1,3.3887-.9942,5.5771,5.5771,0,0,1,2.1836.3916,6.6437,6.6437,0,0,1,1.7363,1.0918.717.717,0,0,0,.3077.169c.0937,0,.1406-.1221.1406-.3643v-6.58c0-.2422.1015-.3642.3076-.3642h1.708c.2051,0,.3076.122.3076.3642v19.9639a.5411.5411,0,0,1-.084.35.479.479,0,0,1-.3632.0986h-1.4288A.3463.3463,0,0,1,674.0566,277.231Zm-1.89-12.5293a4.296,4.296,0,0,0-1.9746-.462,4.1682,4.1682,0,0,0-3.416,1.5957,7.0082,7.0082,0,0,0-1.2881,4.5079,6.5218,6.5218,0,0,0,1.2744,4.3964,4.137,4.137,0,0,0,3.2617,1.4551,3.97,3.97,0,0,0,2.002-.5176,4.0991,4.0991,0,0,0,1.4277-1.3017,2.824,2.824,0,0,0,.5186-1.5684v-6.748A7.2368,7.2368,0,0,0,672.167,264.7017Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M680.8467,277.5532a.3748.3748,0,0,1-.07-.2656v-.8125a.9061.9061,0,0,1,.07-.3779,2.925,2.925,0,0,1,.2656-.4336l8.1484-10.9483.084-.1679c0-.0743-.0752-.1123-.2246-.1123H681.7a.3868.3868,0,0,1-.2373-.0557q-.07-.0556-.07-.252v-1.0361c0-.2051.0928-.3076.28-.3076h10.3593c.168,0,.252.0928.252.2793v.8965a1.1968,1.1968,0,0,1-.2793.7275l-8.1768,10.9766c-.0742.0937-.1025.1679-.084.2236s.0743.084.168.084h8.2324q.3077,0,.3076.2519v1.0645q0,.3354-.3632.3359H681.1123A.3748.3748,0,0,1,680.8467,277.5532Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M699.4248,259.5913a.6067.6067,0,0,1-.1123.4063.5081.5081,0,0,1-.3916.1259h-1.624c-.28,0-.4209-.1582-.4209-.4765v-2.3516q0-.4482.3926-.4482h1.7636a.383.383,0,0,1,.28.1123.4177.4177,0,0,1,.1123.3076Zm-.1123,17.5557a.4927.4927,0,0,1-.1123.3779.5928.5928,0,0,1-.3916.0986h-1.4277a.4881.4881,0,0,1-.336-.0839.4325.4325,0,0,1-.0849-.3086V263.1753q0-.391.3369-.3916h1.708q.3077,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M708.6924,277.9028a4.9835,4.9835,0,0,1-3.43-1.1054,3.7525,3.7525,0,0,1-1.2461-2.9541,4.0521,4.0521,0,0,1,1.89-3.5284,10.9559,10.9559,0,0,1,5.4736-1.5117l2.1846-.1679q.476-.0558.4755-.42V267.46a3.13,3.13,0,0,0-.9375-2.3946,3.5525,3.5525,0,0,0-2.5341-.8818,4.7405,4.7405,0,0,0-4.0323,2.24.4517.4517,0,0,1-.1679.1817.2691.2691,0,0,1-.2237-.0137l-1.5127-.5039a.1987.1987,0,0,1-.14-.1963.6526.6526,0,0,1,.084-.2519,5.8335,5.8335,0,0,1,2.3945-2.31,7.734,7.734,0,0,1,3.6816-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.43,4.43,0,0,1,1.4561,3.5146v9.9121a.4893.4893,0,0,1-.084.336.4391.4391,0,0,1-.3086.0839h-1.3994q-.3369,0-.3926-.4482l-.0556-1.2881c-.0186-.13-.0655-.1963-.14-.1963a.3874.3874,0,0,0-.2529.1406A6.9757,6.9757,0,0,1,708.6924,277.9028Zm4.9277-7.84-1.82.168a9.1016,9.1016,0,0,0-4.0176,1.0087,2.588,2.588,0,0,0-1.414,2.3233,2.3292,2.3292,0,0,0,.8545,1.9043,3.4843,3.4843,0,0,0,2.2812.7,4.9086,4.9086,0,0,0,1.708-.3086,4.9984,4.9984,0,0,0,1.4844-.84,4.11,4.11,0,0,0,.9941-1.0781,2.1234,2.1234,0,0,0,.35-1.1055v-2.3242C714.04,270.2134,713.9,270.063,713.62,270.063Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M727.0322,264.855c.0928,0,.14.0664.14.1963v1.4843a.6229.6229,0,0,1-.28.4756l-2.2119,1.876v8.26a.4929.4929,0,0,1-.1113.3779.5978.5978,0,0,1-.3926.0986H722.72a.488.488,0,0,1-.3359-.0839.4372.4372,0,0,1-.084-.3086v-6.3282l-2.1,1.792a.3437.3437,0,0,1-.1963.084c-.0937,0-.14-.084-.14-.2519v-1.5118a.516.516,0,0,1,.2519-.4482l2.1836-1.8477.0283-11.4795c0-.2617.1114-.3925.336-.3925h1.68c.2246,0,.3359.122.3359.3642v9.52l2.1562-1.792A.4853.4853,0,0,1,727.0322,264.855Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M734.5635,277.9028a4.9835,4.9835,0,0,1-3.43-1.1054,3.7525,3.7525,0,0,1-1.2461-2.9541,4.0523,4.0523,0,0,1,1.89-3.5284,10.964,10.964,0,0,1,5.4747-1.5117l2.1835-.1679q.4761-.0558.4766-.42V267.46a3.1311,3.1311,0,0,0-.9385-2.3946,3.5493,3.5493,0,0,0-2.5341-.8818,4.7388,4.7388,0,0,0-4.0313,2.24.458.458,0,0,1-.168.1817.2711.2711,0,0,1-.2246-.0137l-1.5117-.5039a.1975.1975,0,0,1-.14-.1963.6366.6366,0,0,1,.0839-.2519,5.8258,5.8258,0,0,1,2.3936-2.31,7.7393,7.7393,0,0,1,3.6816-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.4272,4.4272,0,0,1,1.4561,3.5146v9.9121a.4893.4893,0,0,1-.084.336.4363.4363,0,0,1-.3076.0839h-1.4q-.3354,0-.3916-.4482l-.0566-1.2881c-.0186-.13-.0655-.1963-.14-.1963a.3834.3834,0,0,0-.2519.1406A6.9763,6.9763,0,0,1,734.5635,277.9028Zm4.9287-7.84-1.82.168a9.0974,9.0974,0,0,0-4.0176,1.0087,2.5863,2.5863,0,0,0-1.4141,2.3233,2.3314,2.3314,0,0,0,.8536,1.9043,3.4889,3.4889,0,0,0,2.2822.7,4.9169,4.9169,0,0,0,1.708-.3086,4.993,4.993,0,0,0,1.4834-.84,4.0934,4.0934,0,0,0,.9941-1.0781,2.1252,2.1252,0,0,0,.3506-1.1055v-2.3242C739.9121,270.2134,739.7715,270.063,739.4922,270.063Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M747.667,277.2593V263.147q0-.3633.3086-.3633h1.68a.2726.2726,0,0,1,.3086.3076v1.624c0,.2061.084.2334.2519.084a15.3089,15.3089,0,0,1,2.6738-1.7637,5.6475,5.6475,0,0,1,2.4776-.5322,4.5851,4.5851,0,0,1,3.3183,1.1475,4.0825,4.0825,0,0,1,1.1895,3.08v10.4443q0,.4482-.42.4482h-1.5674a.3217.3217,0,0,1-.3643-.3642v-9.9961a3.0238,3.0238,0,0,0-.7138-2.1563,2.8934,2.8934,0,0,0-2.1983-.7558,4.618,4.618,0,0,0-2.17.5185,13.58,13.58,0,0,0-2.4219,1.75v10.584a.3578.3578,0,0,1-.42.42h-1.54Q747.6671,277.6235,747.667,277.2593Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M767.8838,259.5913a.6067.6067,0,0,1-.1123.4063.5081.5081,0,0,1-.3916.1259h-1.624c-.28,0-.4209-.1582-.4209-.4765v-2.3516q0-.4482.3925-.4482h1.7637a.383.383,0,0,1,.28.1123.4177.4177,0,0,1,.1123.3076Zm-.1123,17.5557a.4927.4927,0,0,1-.1123.3779.5928.5928,0,0,1-.3916.0986H765.84a.488.488,0,0,1-.3359-.0839.4325.4325,0,0,1-.085-.3086V263.1753q0-.391.337-.3916h1.708q.3075,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M777.1514,277.9028a4.9835,4.9835,0,0,1-3.43-1.1054,3.7525,3.7525,0,0,1-1.2461-2.9541,4.0523,4.0523,0,0,1,1.89-3.5284,10.9567,10.9567,0,0,1,5.4737-1.5117l2.1845-.1679q.4761-.0558.4756-.42V267.46a3.13,3.13,0,0,0-.9375-2.3946,3.5527,3.5527,0,0,0-2.5342-.8818,4.74,4.74,0,0,0-4.0322,2.24.4511.4511,0,0,1-.168.1817.2688.2688,0,0,1-.2236-.0137l-1.5127-.5039a.1985.1985,0,0,1-.14-.1963.6526.6526,0,0,1,.084-.2519,5.8335,5.8335,0,0,1,2.3945-2.31,7.7337,7.7337,0,0,1,3.6816-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.43,4.43,0,0,1,1.4561,3.5146v9.9121a.4893.4893,0,0,1-.084.336.4391.4391,0,0,1-.3086.0839h-1.3994q-.3369,0-.3926-.4482l-.0557-1.2881c-.0185-.13-.0654-.1963-.14-.1963a.3874.3874,0,0,0-.2529.1406A6.9757,6.9757,0,0,1,777.1514,277.9028Zm4.9277-7.84-1.82.168a9.1012,9.1012,0,0,0-4.0176,1.0087,2.5881,2.5881,0,0,0-1.4141,2.3233,2.33,2.33,0,0,0,.8545,1.9043,3.4846,3.4846,0,0,0,2.2813.7,4.9086,4.9086,0,0,0,1.708-.3086,5,5,0,0,0,1.4844-.84,4.11,4.11,0,0,0,.9941-1.0781,2.1234,2.1234,0,0,0,.35-1.1055v-2.3242C782.499,270.2134,782.3594,270.063,782.0791,270.063Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M617.65,292.92a.6013.6013,0,0,1-.1123.4063.5052.5052,0,0,1-.3916.1259h-1.624c-.28,0-.42-.1582-.42-.4765v-2.3516c0-.2988.13-.4482.3916-.4482h1.7647a.3807.3807,0,0,1,.2793.1123.4138.4138,0,0,1,.1123.3076Zm-.1123,17.5557a.4928.4928,0,0,1-.1113.3779.5978.5978,0,0,1-.3926.0986h-1.4278a.488.488,0,0,1-.3359-.0839.4372.4372,0,0,1-.084-.3086V296.5044q0-.3911.336-.3916h1.708q.3075,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M637.9922,298.0024a5.1609,5.1609,0,0,0-2.3662-.5175,4.4349,4.4349,0,0,0-2.52.6572,1.9941,1.9941,0,0,0-.98,1.7226,1.649,1.649,0,0,0,.49,1.1622,4.1386,4.1386,0,0,0,1.7783.9091l3.5557,1.0645a5.5793,5.5793,0,0,1,2.7441,1.498,3.4177,3.4177,0,0,1,.8955,2.3662,3.586,3.586,0,0,1-.7832,2.2813,5.0478,5.0478,0,0,1-2.1562,1.54,9.08,9.08,0,0,1-6.9443-.3076,5.1853,5.1853,0,0,1-2.38-2.2256.4826.4826,0,0,1-.084-.2246.2536.2536,0,0,1,.14-.2236l1.0928-.6162c.1856-.1123.3262-.1026.42.0283a5.13,5.13,0,0,0,4.6758,2.4072,5.3323,5.3323,0,0,0,2.73-.6855,1.9635,1.9635,0,0,0,1.0781-1.7217,1.645,1.645,0,0,0-.6435-1.3164,6.0137,6.0137,0,0,0-2.1006-.9522l-2.8838-.8115a5.9934,5.9934,0,0,1-2.9258-1.4844,3.2924,3.2924,0,0,1-.8818-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.5576,4.5576,0,0,1,2.0156-1.5537,8.3093,8.3093,0,0,1,6.3008.1543,5.3716,5.3716,0,0,1,2.2539,1.9033c.13.1875.1211.3184-.0284.3926l-1.1757.6436a.4242.4242,0,0,1-.168.0566.3838.3838,0,0,1-.252-.1406A4.1911,4.1911,0,0,0,637.9922,298.0024Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M651.6709,298.1841c.0928,0,.14.0664.14.1963v1.4843a.6228.6228,0,0,1-.28.4756l-2.2119,1.876v8.26a.4923.4923,0,0,1-.1114.3779.5974.5974,0,0,1-.3925.0986h-1.4561a.4876.4876,0,0,1-.3359-.0839.4372.4372,0,0,1-.084-.3086v-6.3282l-2.1,1.792a.3439.3439,0,0,1-.1963.084c-.0938,0-.14-.084-.14-.2519v-1.5118a.5161.5161,0,0,1,.252-.4482l2.1836-1.8477.0283-11.4795c0-.2617.1113-.3925.3359-.3925h1.68c.2246,0,.336.122.336.3642v9.52l2.1562-1.792A.4853.4853,0,0,1,651.6709,298.1841Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M658.0684,310.2661a6.5072,6.5072,0,0,1-2.4922-2.7021,9.3309,9.3309,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6755,6.6755,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8818,4.0039,8.4794,8.4794,0,0,1-.8965,3.9482,6.7135,6.7135,0,0,1-2.5058,2.7158,6.9382,6.9382,0,0,1-3.6817.9795A7.0636,7.0636,0,0,1,658.0684,310.2661Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4023-1.6094,4.1348,4.1348,0,0,0-3.4434,1.6094,8.1254,8.1254,0,0,0,0,8.6944,4.1806,4.1806,0,0,0,3.4717,1.5957A4.05,4.05,0,0,0,665.2363,307.8862Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M692.97,296.2388a.27.27,0,0,1,.0283.2656l-4.2841,14.084a.4971.4971,0,0,1-.5323.3642h-1.3437a.5364.5364,0,0,1-.5879-.3642l-3.332-10.4717c-.0557-.168-.1221-.252-.1963-.252-.0371,0-.084.084-.14.252l-3.164,10.4717a.4971.4971,0,0,1-.5323.3642H677.458a.5364.5364,0,0,1-.5879-.3642l-4.3965-14.0557a.3146.3146,0,0,1-.0273-.1406c0-.1865.1113-.2793.3359-.2793h1.6514a.4675.4675,0,0,1,.4482.3076l3.3321,10.8916c.0556.1875.1211.28.1963.28.0556,0,.13-.1026.2236-.3076l3.3037-10.8926a.3689.3689,0,0,1,.3926-.2793h1.3154a.39.39,0,0,1,.42.3076L687.51,307.312c.0556.1875.1211.28.1963.28s.14-.1026.1962-.3076l3.22-10.8643a.485.485,0,0,1,.4756-.3076h1.12A.2817.2817,0,0,1,692.97,296.2388Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M701.0615,311.2319a4.9836,4.9836,0,0,1-3.43-1.1054,3.7529,3.7529,0,0,1-1.2461-2.9541,4.0521,4.0521,0,0,1,1.89-3.5284,10.9566,10.9566,0,0,1,5.4736-1.5117l2.1846-.1679q.4761-.0558.4756-.42v-.7558a3.13,3.13,0,0,0-.9375-2.3946,3.5527,3.5527,0,0,0-2.5342-.8818,4.74,4.74,0,0,0-4.0322,2.24.4518.4518,0,0,1-.168.1817.2688.2688,0,0,1-.2236-.0137l-1.5127-.5039a.1986.1986,0,0,1-.14-.1963.6531.6531,0,0,1,.084-.2519,5.8341,5.8341,0,0,1,2.3945-2.31,7.7341,7.7341,0,0,1,3.6817-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.43,4.43,0,0,1,1.456,3.5146v9.9121a.49.49,0,0,1-.0839.336.4393.4393,0,0,1-.3086.0839h-1.3995q-.3369,0-.3925-.4482l-.0557-1.2881c-.0186-.13-.0654-.1963-.14-.1963a.3878.3878,0,0,0-.253.1406A6.9756,6.9756,0,0,1,701.0615,311.2319Zm4.9278-7.84-1.82.168a9.1011,9.1011,0,0,0-4.0175,1.0087,2.5881,2.5881,0,0,0-1.4141,2.3233,2.33,2.33,0,0,0,.8545,1.9043,3.4845,3.4845,0,0,0,2.2812.7,4.9092,4.9092,0,0,0,1.7081-.3086,4.9991,4.9991,0,0,0,1.4843-.84,4.1109,4.1109,0,0,0,.9942-1.0781,2.1233,2.1233,0,0,0,.35-1.1054V303.84C706.4092,303.5425,706.27,303.3921,705.9893,303.3921Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M716.3486,310.5044a.4487.4487,0,0,1-.1113.35.5976.5976,0,0,1-.3926.0986h-1.96a.4915.4915,0,0,1-.3213-.0839.39.39,0,0,1-.0987-.3086v-2.4073q0-.3647.3086-.3642h2.2959c.1866,0,.2793.1123.2793.3359Z" transform="translate(-58.8798 50.5781)"/>
      </g>
    </g>
  </g>
  <g id="satysfakcja" class="our-values__text">
    <g>
      <path class="cls-2" d="M671.618,160.092c0,116.1624,94.5077,210.67,210.67,210.67s210.664-94.5077,210.664-210.67-94.5022-210.67-210.664-210.67S671.618,43.93,671.618,160.092Z" transform="translate(-58.8798 50.5781)"/>
      <g>
        <path class="cls-3" d="M768.2705,91.0273a7.8869,7.8869,0,0,1,2.7578,2.1,5.7092,5.7092,0,0,1,1.3155,2.856l.0292.1958a.2043.2043,0,0,1-.07.168.2393.2393,0,0,1-.1543.0561l-2.1.168h-.0566a.1714.1714,0,0,1-.14-.07.48.48,0,0,1-.0839-.21,5.1689,5.1689,0,0,0-1.7364-2.9121,4.8362,4.8362,0,0,0-3.1924-1.0923,5.1888,5.1888,0,0,0-4.4941,2.17q-1.5528,2.17-1.5527,6.482,0,4.2282,1.5527,6.37a5.3567,5.3567,0,0,0,4.6348,2.1421,5.4987,5.4987,0,0,0,3.3183-.9658,6.6137,6.6137,0,0,0,2.1416-3.0381q.0557-.1956.336-.1119l1.6513.3921q.1963.0564.1407.3916a7.4856,7.4856,0,0,1-1.4561,2.646,7.8738,7.8738,0,0,1-2.6318,2.03,8.088,8.088,0,0,1-3.6123.7837,8.5643,8.5643,0,0,1-4.76-1.2876,8.224,8.224,0,0,1-3.0518-3.6963,15.9153,15.9153,0,0,1-.0136-11.41,8.0923,8.0923,0,0,1,3.0234-3.6684,8.46,8.46,0,0,1,4.6895-1.2739A8.158,8.158,0,0,1,768.2705,91.0273Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M776.3057,111.23a.3777.3777,0,0,1-.0694-.2661v-.812a.9075.9075,0,0,1,.0694-.378,2.9822,2.9822,0,0,1,.2666-.434L784.72,98.3911l.084-.168c0-.0742-.0752-.1118-.2236-.1118h-7.42a.3865.3865,0,0,1-.2383-.0561q-.07-.0556-.07-.252V96.7671q0-.3076.28-.3076h10.36q.252,0,.2519.28v.896a1.1925,1.1925,0,0,1-.28.728l-8.1768,10.9761c-.0742.0937-.1016.1679-.084.2241.02.0556.0742.084.168.084h8.2324c.2051,0,.3086.0839.3086.2519v1.064c0,.2241-.1221.3359-.3642.3359H776.5723A.378.378,0,0,1,776.3057,111.23Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M794.6455,107.2954a4.9562,4.9562,0,0,0,1.7783,1.82,4.6961,4.6961,0,0,0,2.4639.6719,4.9825,4.9825,0,0,0,4.3682-2.3237.4063.4063,0,0,1,.1953-.126.3282.3282,0,0,1,.1963.042l1.1767.5879c.1485.0937.168.2241.0557.3921a6.8271,6.8271,0,0,1-2.6045,2.45,7.8734,7.8734,0,0,1-3.6113.77,7.1119,7.1119,0,0,1-3.668-.9517,6.601,6.601,0,0,1-2.5342-2.6743,8.2937,8.2937,0,0,1-.91-3.9336,8.8964,8.8964,0,0,1,.9238-4.1582,6.6446,6.6446,0,0,1,2.5342-2.73,6.99,6.99,0,0,1,3.6259-.9521,6.8677,6.8677,0,0,1,3.542.896,5.98,5.98,0,0,1,2.3653,2.6182,9.1925,9.1925,0,0,1,.8408,4.0737v.1123a.69.69,0,0,1-.084.3779.3775.3775,0,0,1-.3359.126H794.24q-.2518,0-.2519.42A4.927,4.927,0,0,0,794.6455,107.2954Zm7.91-4.4521a.6942.6942,0,0,0,.3916-.07c.0566-.0463.084-.1543.084-.3222a5.0817,5.0817,0,0,0-.5176-2.2818,4.1074,4.1074,0,0,0-1.5117-1.68,4.226,4.226,0,0,0-2.3106-.63,4.3676,4.3676,0,0,0-2.4922.7138,4.6957,4.6957,0,0,0-1.623,1.8482,5.1424,5.1424,0,0,0-.5332,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M810.0879,110.9355V96.8232c0-.2421.1016-.3637.3076-.3637h1.624a.2726.2726,0,0,1,.3086.3076v1.82c0,.1118.0313.1777.0977.1958.0644.019.1347-.0181.21-.1118q2.1285-2.4917,4.34-2.4922.98,0,.98.3643v1.68q0,.2806-.252.2242a7.838,7.838,0,0,0-1.26-.084,3.9781,3.9781,0,0,0-1.7637.4761,5,5,0,0,0-1.5957,1.2319,2.3682,2.3682,0,0,0-.6445,1.54v9.2681a.3711.3711,0,0,1-.42.42h-1.54C810.2178,111.2993,810.0879,111.1782,810.0879,110.9355Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M824.5352,96.8794v1.1758q0,.2519.1406.2519a.387.387,0,0,0,.2236-.14,13.8773,13.8773,0,0,1,1.0361-.9522,4.9571,4.9571,0,0,1,1.3155-.7,5.664,5.664,0,0,1,2.0439-.3359,5.9581,5.9581,0,0,1,3.15.84,5.7,5.7,0,0,1,2.1836,2.52,9.5422,9.5422,0,0,1,.7988,4.1157,10.3942,10.3942,0,0,1-.7988,4.2558,5.8689,5.8689,0,0,1-5.6416,3.668,5.9413,5.9413,0,0,1-3.9756-1.4c-.1308-.13-.2344-.1958-.3086-.1958-.0937,0-.1406.1216-.1406.3638v5.208c0,.2427-.1016.3642-.3066.3642H822.52q-.309,0-.3086-.3642V96.9072a.503.503,0,0,1,.0977-.3359.4542.4542,0,0,1,.3506-.1118h1.4834Q824.5352,96.46,824.5352,96.8794Zm1.8339,12.53a4.3548,4.3548,0,0,0,2.002.4619,4.1023,4.1023,0,0,0,3.374-1.5957,7.2169,7.2169,0,0,0,1.2744-4.5923,6.4687,6.4687,0,0,0-1.26-4.354,4.3842,4.3842,0,0,0-5.2647-.9517,3.9931,3.9931,0,0,0-1.414,1.2461,2.6225,2.6225,0,0,0-.5186,1.4839v6.916A6.934,6.934,0,0,0,826.3691,109.4092Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M842.791,93.2671a.6.6,0,0,1-.1123.4062.5076.5076,0,0,1-.3916.126h-1.624c-.28,0-.4209-.1582-.4209-.4761v-2.352q0-.4474.3926-.4478h1.7636a.3823.3823,0,0,1,.28.1119.415.415,0,0,1,.1123.3081Zm-.1123,17.5561a.4943.4943,0,0,1-.1123.378.5959.5959,0,0,1-.3916.0981h-1.4277a.4849.4849,0,0,1-.336-.084.4356.4356,0,0,1-.0849-.3081V96.8511c0-.2608.1133-.3916.3369-.3916h1.708q.3077,0,.3076.3637Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M850.6445,107.2954a4.9557,4.9557,0,0,0,1.7784,1.82,4.693,4.693,0,0,0,2.4638.6719,4.9825,4.9825,0,0,0,4.3682-2.3237.4039.4039,0,0,1,.1963-.126.3253.3253,0,0,1,.1953.042l1.1758.5879q.2241.1406.0566.3921a6.8311,6.8311,0,0,1-2.6035,2.45,7.8821,7.8821,0,0,1-3.6133.77,7.117,7.117,0,0,1-3.668-.9517,6.5987,6.5987,0,0,1-2.5332-2.6743,8.2819,8.2819,0,0,1-.91-3.9336,8.9086,8.9086,0,0,1,.9238-4.1582,6.6511,6.6511,0,0,1,2.5342-2.73,6.9931,6.9931,0,0,1,3.626-.9521,6.8682,6.8682,0,0,1,3.542.896,5.9993,5.9993,0,0,1,2.3662,2.6182,9.2064,9.2064,0,0,1,.84,4.0737v.1123a.69.69,0,0,1-.084.3779.3785.3785,0,0,1-.3359.126H850.2383q-.252,0-.252.42A4.9289,4.9289,0,0,0,850.6445,107.2954Zm7.91-4.4521a.7.7,0,0,0,.3926-.07.4312.4312,0,0,0,.084-.3222,5.0957,5.0957,0,0,0-.5186-2.2818A4.1029,4.1029,0,0,0,857,98.4893a4.2167,4.2167,0,0,0-2.3086-.63,4.3633,4.3633,0,0,0-2.4922.7138,4.6945,4.6945,0,0,0-1.625,1.8482,5.13,5.13,0,0,0-.5312,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M866.0859,110.9355V96.8232c0-.2421.1036-.3637.3086-.3637h1.68c.1875,0,.28.1025.28.3076v1.624c0,.2241.0928.2525.28.084a14.8259,14.8259,0,0,1,2.5761-1.7637,5.2466,5.2466,0,0,1,2.38-.5322,4.4879,4.4879,0,0,1,2.59.7,3.4816,3.4816,0,0,1,1.3857,1.7642c.0371.0556.0742.0839.1123.0839a.6173.6173,0,0,0,.28-.14,14.0168,14.0168,0,0,1,2.6172-1.82,5.3648,5.3648,0,0,1,2.4511-.5879,3.9449,3.9449,0,0,1,4.2832,4.2563v10.4156q0,.4482-.42.4482h-1.5683a.3213.3213,0,0,1-.3633-.3638V100.94a3.1449,3.1449,0,0,0-.6719-2.1563,2.5606,2.5606,0,0,0-2.0166-.7559,4.2471,4.2471,0,0,0-2.1.5459,11.0441,11.0441,0,0,0-1.9043,1.358,1.6272,1.6272,0,0,0-.3076.392.9266.9266,0,0,0-.084.42v10.1079c0,.2988-.1406.4482-.42.4482h-1.5684a.3219.3219,0,0,1-.3642-.3638V100.94q0-2.9122-2.6885-2.9122a4.1836,4.1836,0,0,0-2.0567.5181,14.2038,14.2038,0,0,0-2.3388,1.75v10.584a.3574.3574,0,0,1-.42.42h-1.54Q866.086,111.2993,866.0859,110.9355Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M892.3223,115.8633q-.3633-.0564-.3633-.3081v-1.0918a.2928.2928,0,0,1,.1543-.28,1.5269,1.5269,0,0,1,.6289-.084h1.7646a1.83,1.83,0,0,0,1.2315-.49,3.694,3.694,0,0,0,1.2051-2.6182,1.6672,1.6672,0,0,0-.1133-.6719l-5.3477-13.3838a.3953.3953,0,0,1-.0273-.1684c0-.2051.1113-.3076.3359-.3076h1.7363a.4663.4663,0,0,1,.4473.3076l4.1719,10.8642c.0566.168.1308.252.2246.252.1113,0,.2051-.1118.2793-.3359l4.1162-10.78a.4335.4335,0,0,1,.3916-.3076h1.3164a.2821.2821,0,0,1,.252.1259.2705.2705,0,0,1,.0283.2657l-6.16,15.7641a5.2149,5.2149,0,0,1-1.8759,2.604,5.7319,5.7319,0,0,1-3.0528.7A10.5633,10.5633,0,0,1,892.3223,115.8633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M916.5137,110.9355V96.8232c0-.2421.1035-.3637.3086-.3637h1.624a.2719.2719,0,0,1,.3076.3076v1.82c0,.1118.0332.1777.0986.1958s.1358-.0181.21-.1118q2.127-2.4917,4.34-2.4922.98,0,.9805.3643v1.68q0,.2806-.2519.2242a7.84,7.84,0,0,0-1.26-.084,3.9722,3.9722,0,0,0-1.7647.4761,5.0157,5.0157,0,0,0-1.5966,1.2319,2.3669,2.3669,0,0,0-.6436,1.54v9.2681a.371.371,0,0,1-.42.42h-1.54Q916.5136,111.2993,916.5137,110.9355Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M932.1934,111.5791a4.9829,4.9829,0,0,1-3.43-1.106,3.7546,3.7546,0,0,1-1.2461-2.9536,4.0523,4.0523,0,0,1,1.8906-3.5283,10.9566,10.9566,0,0,1,5.4736-1.5117l2.1846-.168c.3164-.0376.4756-.1772.4756-.42v-.7558a3.13,3.13,0,0,0-.9385-2.3941,3.5481,3.5481,0,0,0-2.5332-.8818,4.74,4.74,0,0,0-4.0322,2.24.4572.4572,0,0,1-.168.1822.2712.2712,0,0,1-.2236-.0142l-1.5127-.5039a.1981.1981,0,0,1-.14-.1958.6327.6327,0,0,1,.084-.252,5.8293,5.8293,0,0,1,2.3946-2.31,7.74,7.74,0,0,1,3.6816-.8262,6.1917,6.1917,0,0,1,4.1992,1.2739,4.4269,4.4269,0,0,1,1.457,3.5142v9.9121a.4934.4934,0,0,1-.0839.3359.4379.4379,0,0,1-.3086.084h-1.4q-.3356,0-.3916-.4482l-.0557-1.2876c-.0195-.13-.0664-.1963-.1406-.1963a.3893.3893,0,0,0-.252.14A6.9766,6.9766,0,0,1,932.1934,111.5791Zm4.9287-7.84-1.82.1679a9.1084,9.1084,0,0,0-4.0176,1.0083,2.5889,2.5889,0,0,0-1.4141,2.3238,2.33,2.33,0,0,0,.8545,1.9043,3.4845,3.4845,0,0,0,2.2813.7,4.9237,4.9237,0,0,0,1.708-.3081,5.0052,5.0052,0,0,0,1.4834-.84,4.0765,4.0765,0,0,0,.9941-1.0781,2.1177,2.1177,0,0,0,.3506-1.106v-2.3237C937.542,103.8892,937.4023,103.7393,937.1221,103.7393Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M955.4609,110.9072v-1.2041c0-.1679-.0468-.2519-.1386-.2519-.0567,0-.1309.0468-.2246.14a8.8568,8.8568,0,0,1-1.834,1.4141,5.0877,5.0877,0,0,1-2.5625.5737,6.0816,6.0816,0,0,1-3.1914-.84,5.7512,5.7512,0,0,1-2.2129-2.5342,9.4289,9.4289,0,0,1-.8106-4.1016,9.654,9.654,0,0,1,.84-4.144,6.6625,6.6625,0,0,1,2.3232-2.7862,5.9459,5.9459,0,0,1,3.3877-.9941,5.573,5.573,0,0,1,2.1846.3921,6.6093,6.6093,0,0,1,1.7353,1.0918.7151.7151,0,0,0,.3086.1684q.1407,0,.1407-.3642v-6.58c0-.2422.1015-.3638.3066-.3638h1.709q.3076,0,.3076.3638v19.9639a.5492.5492,0,0,1-.084.35.4828.4828,0,0,1-.3642.0981h-1.4278A.3467.3467,0,0,1,955.4609,110.9072Zm-1.8886-12.53a4.3028,4.3028,0,0,0-1.9746-.4619,4.17,4.17,0,0,0-3.4161,1.5957,7.005,7.005,0,0,0-1.288,4.5083,6.52,6.52,0,0,0,1.2744,4.396,4.136,4.136,0,0,0,3.2617,1.4556,3.9776,3.9776,0,0,0,2.0019-.5176,4.1086,4.1086,0,0,0,1.4278-1.3022,2.8224,2.8224,0,0,0,.5176-1.5679v-6.748A7.2382,7.2382,0,0,0,953.5723,98.3774Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M965.9766,110.6133a6.514,6.514,0,0,1-2.4922-2.7022,9.3318,9.3318,0,0,1,.0283-7.98,7.0988,7.0988,0,0,1,9.8838-2.7579,6.6721,6.6721,0,0,1,2.4922,2.7579,8.8344,8.8344,0,0,1,.8818,4.0043,8.4893,8.4893,0,0,1-.8955,3.9478,6.7143,6.7143,0,0,1-2.5059,2.7158,6.9379,6.9379,0,0,1-3.6826.98A7.0707,7.0707,0,0,1,965.9766,110.6133Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0685,4.0685,0,0,0-3.4014-1.61,4.1356,4.1356,0,0,0-3.4443,1.61,8.1244,8.1244,0,0,0,0,8.6938,4.1794,4.1794,0,0,0,3.4717,1.5962A4.05,4.05,0,0,0,973.1445,108.2334Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M988.9355,98.3491a5.1657,5.1657,0,0,0-2.3652-.5176,4.4359,4.4359,0,0,0-2.52.6578,1.9946,1.9946,0,0,0-.98,1.7221,1.6437,1.6437,0,0,0,.49,1.1621,4.1321,4.1321,0,0,0,1.7774.91l3.5566,1.064a5.5806,5.5806,0,0,1,2.7432,1.498,3.4186,3.4186,0,0,1,.8955,2.3662,3.5864,3.5864,0,0,1-.7832,2.2818,5.0535,5.0535,0,0,1-2.1562,1.54,9.0752,9.0752,0,0,1-6.9434-.3081A5.1785,5.1785,0,0,1,980.27,108.5a.4826.4826,0,0,1-.084-.2241.2533.2533,0,0,1,.1407-.2241l1.0918-.6158c.1855-.1123.3261-.1025.42.0279a5.13,5.13,0,0,0,4.6758,2.4077,5.341,5.341,0,0,0,2.73-.6856,1.9635,1.9635,0,0,0,1.0782-1.7221,1.6422,1.6422,0,0,0-.6446-1.3159,5.9982,5.9982,0,0,0-2.1-.9522l-2.8847-.812a5.9933,5.9933,0,0,1-2.9258-1.4839,3.2937,3.2937,0,0,1-.8819-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.564,4.564,0,0,1,2.0156-1.5542,8.3093,8.3093,0,0,1,6.3008.1543,5.3617,5.3617,0,0,1,2.2539,1.9038q.1964.2805-.0283.3921l-1.1758.644a.43.43,0,0,1-.1679.0562.3828.3828,0,0,1-.252-.14A4.18,4.18,0,0,0,988.9355,98.3491Zm1.0508-6.65a.4108.4108,0,0,1,.0274.168c0,.15-.1026.2519-.3076.3081l-6.4122,2.0161a.3944.3944,0,0,1-.1679.0278c-.1309,0-.2237-.0839-.28-.2519l-.2236-.6162a.3113.3113,0,0,1-.0283-.14c0-.1494.0566-.2334.1679-.2519l6.4688-2.688a.1571.1571,0,0,1,.1113-.0283.2215.2215,0,0,1,.2246.1679Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1007.0234,97.1313a7.0751,7.0751,0,0,1,2.3106,2.52l.0556.2241q0,.1677-.1962.1958l-1.5391.3642-.084.0279c-.0937,0-.1875-.0742-.2812-.2241a7.615,7.615,0,0,0-1.7207-1.7642,3.2939,3.2939,0,0,0-1.8907-.56,4.2162,4.2162,0,0,0-3.4726,1.61,7.994,7.994,0,0,0,.0146,8.7222,4.2984,4.2984,0,0,0,3.5147,1.5957,4.1454,4.1454,0,0,0,2.3086-.6441,5.9929,5.9929,0,0,0,1.8066-1.9878c.0567-.0932.1123-.1445.168-.1543a.332.332,0,0,1,.1963.042l1.2041.4483c.13.0561.1679.1777.1113.3637a7.2844,7.2844,0,0,1-2.3789,2.646,6.2981,6.2981,0,0,1-3.6133,1.022,6.89,6.89,0,0,1-3.64-.9658,6.641,6.641,0,0,1-2.4912-2.7022,8.54,8.54,0,0,1-.8965-3.9756,8.7991,8.7991,0,0,1,.8965-4.018,6.6418,6.6418,0,0,1,2.5058-2.7583,6.8335,6.8335,0,0,1,3.625-.98A6.1875,6.1875,0,0,1,1007.0234,97.1313Zm.1543-5.4321a.3946.3946,0,0,1,.0274.168c0,.15-.1016.2519-.3076.3081l-6.4112,2.0161a.3947.3947,0,0,1-.1679.0278q-.1964,0-.2813-.2519l-.2236-.6162a.3113.3113,0,0,1-.0283-.14c0-.1494.0566-.2334.1679-.2519l6.4688-2.688a.1588.1588,0,0,1,.1113-.0283.22.22,0,0,1,.2246.1679Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M754.1582,144.5586a.3773.3773,0,0,1-.07-.2661v-.812a.9069.9069,0,0,1,.07-.378,3.0571,3.0571,0,0,1,.2656-.434l8.1485-10.9483.084-.168c0-.0742-.0743-.1118-.2247-.1118h-7.42a.3831.3831,0,0,1-.2373-.0561q-.07-.0557-.07-.252v-1.0361c0-.2051.0928-.3076.28-.3076h10.3594q.2518,0,.2519.28v.896a1.1966,1.1966,0,0,1-.2793.728l-8.1758,10.9761c-.0752.0937-.1035.1679-.084.2241.0176.0556.0743.084.168.084h8.2315c.206,0,.3076.0839.3076.2519v1.064q0,.3362-.3633.3359H754.4238A.3769.3769,0,0,1,754.1582,144.5586Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M787.8555,144.2363v-1.2041c0-.1679-.0469-.2519-.14-.2519a.3829.3829,0,0,0-.2236.14,8.8568,8.8568,0,0,1-1.834,1.4141,5.09,5.09,0,0,1-2.5625.5737,6.0769,6.0769,0,0,1-3.1914-.84,5.7451,5.7451,0,0,1-2.2129-2.5342,9.4286,9.4286,0,0,1-.8115-4.1016,9.67,9.67,0,0,1,.84-4.144,6.67,6.67,0,0,1,2.3233-2.7862,5.9509,5.9509,0,0,1,3.3886-.9941,5.5644,5.5644,0,0,1,2.1836.3921,6.6165,6.6165,0,0,1,1.7364,1.0918.7092.7092,0,0,0,.3086.1684c.0927,0,.14-.1215.14-.3642v-6.58q0-.3633.3076-.3638h1.708c.2061,0,.3076.1216.3076.3638V144.18a.5437.5437,0,0,1-.0839.35.48.48,0,0,1-.3633.0981H788.248A.3466.3466,0,0,1,787.8555,144.2363Zm-1.89-12.53a4.2951,4.2951,0,0,0-1.9736-.4619,4.1671,4.1671,0,0,0-3.416,1.5957,7.0045,7.0045,0,0,0-1.2891,4.5083,6.52,6.52,0,0,0,1.2744,4.396,4.1382,4.1382,0,0,0,3.2627,1.4556,3.98,3.98,0,0,0,2.002-.5176,4.118,4.118,0,0,0,1.4277-1.3022,2.83,2.83,0,0,0,.5176-1.5679v-6.748A7.2222,7.2222,0,0,0,785.9658,131.7065Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M794.6465,144.5586a.3773.3773,0,0,1-.07-.2661v-.812a.9069.9069,0,0,1,.07-.378,2.9646,2.9646,0,0,1,.2656-.434l8.1484-10.9483.084-.168c0-.0742-.0752-.1118-.2246-.1118H795.5a.3885.3885,0,0,1-.2383-.0561q-.07-.0557-.07-.252v-1.0361q0-.3077.2813-.3076H805.832q.252,0,.252.28v.896a1.1972,1.1972,0,0,1-.2793.728l-8.1768,10.9761c-.0752.0937-.1025.1679-.084.2241s.0743.084.168.084h8.2315q.309,0,.3086.2519v1.064q0,.3362-.3633.3359H794.9121A.3752.3752,0,0,1,794.6465,144.5586Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M813.2246,126.5962a.6.6,0,0,1-.1133.4062.5036.5036,0,0,1-.3906.126h-1.625c-.2793,0-.42-.1582-.42-.4761V124.3q0-.4475.3926-.4478h1.7636a.38.38,0,0,1,.2793.1119.4155.4155,0,0,1,.1133.3081Zm-.1133,17.5561a.4945.4945,0,0,1-.1113.378.5973.5973,0,0,1-.3926.0981H811.18a.4861.4861,0,0,1-.3359-.084.4354.4354,0,0,1-.084-.3081V130.18q0-.3912.3359-.3916h1.709c.2051,0,.3066.1216.3066.3637Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M822.4922,144.9082a4.98,4.98,0,0,1-3.43-1.106,3.7484,3.7484,0,0,1-1.2461-2.9536,4.0506,4.0506,0,0,1,1.8887-3.5283,10.9633,10.9633,0,0,1,5.4746-1.5117l2.1836-.168c.3183-.0376.4765-.1772.4765-.42v-.7558a3.1292,3.1292,0,0,0-.9375-2.3941,3.5529,3.5529,0,0,0-2.5351-.8818,4.7384,4.7384,0,0,0-4.0313,2.24.4642.4642,0,0,1-.1679.1822.2735.2735,0,0,1-.2246-.0142l-1.5118-.5039a.197.197,0,0,1-.14-.1958.6479.6479,0,0,1,.084-.252,5.8326,5.8326,0,0,1,2.3935-2.31,7.7456,7.7456,0,0,1,3.6817-.8262,6.1936,6.1936,0,0,1,4.2011,1.2739,4.4285,4.4285,0,0,1,1.4551,3.5142v9.9121a.4826.4826,0,0,1-.084.3359.4315.4315,0,0,1-.3076.084h-1.3994q-.3369,0-.3926-.4482l-.0566-1.2876c-.0176-.13-.0645-.1963-.1387-.1963a.3845.3845,0,0,0-.2529.14A6.9761,6.9761,0,0,1,822.4922,144.9082Zm4.9277-7.84-1.82.1679a9.105,9.105,0,0,0-4.0176,1.0083,2.5871,2.5871,0,0,0-1.414,2.3238,2.3313,2.3313,0,0,0,.8535,1.9043,3.4889,3.4889,0,0,0,2.2822.7,4.9233,4.9233,0,0,0,1.708-.3081,5.0093,5.0093,0,0,0,1.4844-.84,4.1258,4.1258,0,0,0,.9941-1.0781,2.1332,2.1332,0,0,0,.35-1.106v-2.3237C827.84,137.2183,827.6992,137.0684,827.42,137.0684Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M840.832,131.86c.0928,0,.14.0659.14.1962v1.4839a.6238.6238,0,0,1-.28.4761l-2.2119,1.876v8.26a.4943.4943,0,0,1-.1123.378.5959.5959,0,0,1-.3916.0981H836.52a.4847.4847,0,0,1-.3359-.084.4311.4311,0,0,1-.084-.3081v-6.3281L834,139.7a.3477.3477,0,0,1-.1973.084c-.0927,0-.1386-.084-.1386-.252v-1.5117a.516.516,0,0,1,.2519-.4482l2.1836-1.8477.0274-11.48q0-.3918.3359-.3921h1.6807q.3354,0,.3359.3638v9.52l2.1553-1.792A.4856.4856,0,0,1,840.832,131.86Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M848.3633,144.9082a4.98,4.98,0,0,1-3.43-1.106,3.7484,3.7484,0,0,1-1.2461-2.9536,4.0506,4.0506,0,0,1,1.8887-3.5283,10.9633,10.9633,0,0,1,5.4746-1.5117l2.1836-.168c.3183-.0376.4765-.1772.4765-.42v-.7558a3.1292,3.1292,0,0,0-.9375-2.3941,3.5529,3.5529,0,0,0-2.5351-.8818,4.7384,4.7384,0,0,0-4.0313,2.24.4642.4642,0,0,1-.1679.1822.2735.2735,0,0,1-.2246-.0142l-1.5118-.5039a.197.197,0,0,1-.14-.1958.6479.6479,0,0,1,.084-.252,5.8326,5.8326,0,0,1,2.3935-2.31,7.7456,7.7456,0,0,1,3.6817-.8262,6.1938,6.1938,0,0,1,4.2011,1.2739,4.4285,4.4285,0,0,1,1.4551,3.5142v9.9121a.4826.4826,0,0,1-.084.3359.4315.4315,0,0,1-.3076.084h-1.3994q-.3369,0-.3926-.4482l-.0566-1.2876c-.0176-.13-.0645-.1963-.1387-.1963a.3845.3845,0,0,0-.2529.14A6.9762,6.9762,0,0,1,848.3633,144.9082Zm4.9277-7.84-1.82.1679a9.105,9.105,0,0,0-4.0176,1.0083,2.5871,2.5871,0,0,0-1.414,2.3238,2.3313,2.3313,0,0,0,.8535,1.9043,3.4889,3.4889,0,0,0,2.2822.7,4.9233,4.9233,0,0,0,1.708-.3081,5.0093,5.0093,0,0,0,1.4844-.84,4.1258,4.1258,0,0,0,.9941-1.0781,2.1332,2.1332,0,0,0,.35-1.106v-2.3237C853.7109,137.2183,853.57,137.0684,853.291,137.0684Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M861.4668,144.2646V130.1523q0-.3631.3086-.3637h1.68a.2726.2726,0,0,1,.3086.3076v1.624q0,.3091.2519.084a15.3344,15.3344,0,0,1,2.6739-1.7637,5.6394,5.6394,0,0,1,2.4775-.5322,4.58,4.58,0,0,1,3.3174,1.148,4.08,4.08,0,0,1,1.19,3.08V144.18q0,.4482-.42.4482h-1.5674a.3216.3216,0,0,1-.3643-.3638v-9.996a3.0228,3.0228,0,0,0-.7138-2.1563,2.8935,2.8935,0,0,0-2.1983-.7559,4.6239,4.6239,0,0,0-2.17.5181,13.6139,13.6139,0,0,0-2.4228,1.75v10.584a.3574.3574,0,0,1-.42.42h-1.539Q861.4669,144.6284,861.4668,144.2646Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M881.6836,126.5962a.6.6,0,0,1-.1133.4062.5038.5038,0,0,1-.3906.126h-1.625c-.2793,0-.42-.1582-.42-.4761V124.3q0-.4475.3925-.4478h1.7637a.38.38,0,0,1,.2793.1119.4155.4155,0,0,1,.1133.3081Zm-.1133,17.5561a.4945.4945,0,0,1-.1113.378.5973.5973,0,0,1-.3926.0981h-1.4277a.4866.4866,0,0,1-.336-.084.4359.4359,0,0,1-.0839-.3081V130.18q0-.3912.3359-.3916h1.709c.2051,0,.3066.1216.3066.3637Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M890.9512,144.9082a4.98,4.98,0,0,1-3.43-1.106,3.7484,3.7484,0,0,1-1.2461-2.9536,4.0506,4.0506,0,0,1,1.8887-3.5283,10.9633,10.9633,0,0,1,5.4746-1.5117l2.1836-.168c.3183-.0376.4765-.1772.4765-.42v-.7558a3.1292,3.1292,0,0,0-.9375-2.3941,3.5529,3.5529,0,0,0-2.5351-.8818,4.7383,4.7383,0,0,0-4.0313,2.24.4642.4642,0,0,1-.1679.1822.2737.2737,0,0,1-.2247-.0142l-1.5117-.5039a.197.197,0,0,1-.14-.1958.6479.6479,0,0,1,.084-.252,5.8326,5.8326,0,0,1,2.3935-2.31,7.7453,7.7453,0,0,1,3.6817-.8262,6.1938,6.1938,0,0,1,4.2011,1.2739,4.4285,4.4285,0,0,1,1.4551,3.5142v9.9121a.4826.4826,0,0,1-.084.3359.4315.4315,0,0,1-.3076.084h-1.3994q-.3369,0-.3926-.4482l-.0566-1.2876c-.0176-.13-.0645-.1963-.1387-.1963a.3843.3843,0,0,0-.2529.14A6.9762,6.9762,0,0,1,890.9512,144.9082Zm4.9277-7.84-1.82.1679a9.105,9.105,0,0,0-4.0176,1.0083,2.5871,2.5871,0,0,0-1.414,2.3238,2.3313,2.3313,0,0,0,.8535,1.9043,3.4887,3.4887,0,0,0,2.2822.7,4.9233,4.9233,0,0,0,1.708-.3081,5.0093,5.0093,0,0,0,1.4844-.84,4.1276,4.1276,0,0,0,.9941-1.0781,2.1342,2.1342,0,0,0,.35-1.106v-2.3237C896.2988,137.2183,896.1582,137.0684,895.8789,137.0684Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M903.1582,148.5205a.433.433,0,0,1,0-.3081l1.26-3.1357a.6367.6367,0,0,0,.084-.2525q0-.1956-.3077-.1958H903.83q-.3633,0-.3633-.3359V141.8q0-.3076.2793-.3081H906.07a.248.248,0,0,1,.28.28v2.2959a.6433.6433,0,0,1-.084.3359l-2.0439,3.92a.5876.5876,0,0,1-.5606.3081h-.3076A.2009.2009,0,0,1,903.1582,148.5205Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M928.9746,144.2363v-1.2041q0-.2518-.1406-.2519a.3823.3823,0,0,0-.2236.14,8.83,8.83,0,0,1-1.834,1.4141,5.0839,5.0839,0,0,1-2.5616.5737,6.08,6.08,0,0,1-3.1923-.84,5.744,5.744,0,0,1-2.212-2.5342,9.4152,9.4152,0,0,1-.8125-4.1016,9.6688,9.6688,0,0,1,.84-4.144,6.6719,6.6719,0,0,1,2.3242-2.7862,5.9491,5.9491,0,0,1,3.3887-.9941,5.5682,5.5682,0,0,1,2.1836.3921,6.647,6.647,0,0,1,1.7363,1.0918.7082.7082,0,0,0,.3076.1684c.0928,0,.14-.1215.14-.3642v-6.58q0-.3633.3086-.3638h1.707q.309,0,.3086.3638V144.18a.5436.5436,0,0,1-.084.35.4817.4817,0,0,1-.3633.0981h-1.4287A.346.346,0,0,1,928.9746,144.2363Zm-1.8906-12.53a4.2957,4.2957,0,0,0-1.9736-.4619,4.17,4.17,0,0,0-3.4161,1.5957,7.01,7.01,0,0,0-1.288,4.5083,6.52,6.52,0,0,0,1.2734,4.396,4.1409,4.1409,0,0,0,3.2627,1.4556,3.9776,3.9776,0,0,0,2.0019-.5176,4.1006,4.1006,0,0,0,1.4278-1.3022,2.8316,2.8316,0,0,0,.5185-1.5679v-6.748A7.2256,7.2256,0,0,0,927.084,131.7065Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M937.43,144.6284a.37.37,0,0,1-.4473-.4482V124.2446c0-.2612.1114-.3921.336-.3921h1.7363c.1855,0,.2793.1309.2793.3921v6.86q0,.3084.1406.3081a.6165.6165,0,0,0,.2793-.14,6.3115,6.3115,0,0,1,4.4805-1.7642,5.1613,5.1613,0,0,1,4.4658,2.1143,9.1893,9.1893,0,0,1,1.5264,5.5019,8.7867,8.7867,0,0,1-1.6944,5.6978,5.7083,5.7083,0,0,1-4.69,2.0859,5.3377,5.3377,0,0,1-2.8281-.6855,5.9952,5.9952,0,0,1-1.7354-1.5264.2266.2266,0,0,0-.1679-.1118c-.0752,0-.1221.0654-.14.1958l-.1406,1.4a.716.716,0,0,1-.1543.35.4868.4868,0,0,1-.35.0981Zm1.9043-5.0122a3.0965,3.0965,0,0,0,.5742,1.8062,4.1662,4.1662,0,0,0,1.4981,1.3159,4.0611,4.0611,0,0,0,1.9316.49,3.9458,3.9458,0,0,0,3.2344-1.5122A7.1262,7.1262,0,0,0,947.79,137.18a7.6041,7.6041,0,0,0-1.0918-4.41,3.6257,3.6257,0,0,0-3.164-1.5542,4.9965,4.9965,0,0,0-2.3379.56,5.9338,5.9338,0,0,0-1.8623,1.5122Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M958.7939,144.9082a4.9809,4.9809,0,0,1-3.43-1.106,3.7513,3.7513,0,0,1-1.2461-2.9536,4.0514,4.0514,0,0,1,1.89-3.5283,10.9575,10.9575,0,0,1,5.4746-1.5117l2.1836-.168c.3174-.0376.4766-.1772.4766-.42v-.7558a3.13,3.13,0,0,0-.9385-2.3941,3.5506,3.5506,0,0,0-2.5342-.8818,4.7381,4.7381,0,0,0-4.0312,2.24.4716.4716,0,0,1-.168.1822.2735.2735,0,0,1-.2246-.0142l-1.5117-.5039a.1977.1977,0,0,1-.1406-.1958.649.649,0,0,1,.0839-.252,5.8353,5.8353,0,0,1,2.3946-2.31,7.7421,7.7421,0,0,1,3.6816-.8262,6.1928,6.1928,0,0,1,4.2,1.2739,4.43,4.43,0,0,1,1.4561,3.5142v9.9121a.4884.4884,0,0,1-.084.3359.4363.4363,0,0,1-.3086.084h-1.3994q-.3356,0-.3916-.4482l-.0567-1.2876c-.0185-.13-.0654-.1963-.14-.1963a.3889.3889,0,0,0-.253.14A6.9744,6.9744,0,0,1,958.7939,144.9082Zm4.9288-7.84-1.82.1679a9.1007,9.1007,0,0,0-4.0175,1.0083,2.587,2.587,0,0,0-1.4141,2.3238,2.3287,2.3287,0,0,0,.8535,1.9043,3.4863,3.4863,0,0,0,2.2813.7,4.9292,4.9292,0,0,0,1.709-.3081,5.0065,5.0065,0,0,0,1.4834-.84,4.11,4.11,0,0,0,.9941-1.0781,2.1268,2.1268,0,0,0,.3506-1.106v-2.3237C964.1426,137.2183,964.002,137.0684,963.7227,137.0684Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M971.8975,144.2646V130.1523q0-.3631.3076-.3637h1.6806q.28,0,.28.3076v1.624q0,.3362.2793.084a14.86,14.86,0,0,1,2.5762-1.7637,5.2474,5.2474,0,0,1,2.3808-.5322,4.4877,4.4877,0,0,1,2.59.7,3.4858,3.4858,0,0,1,1.3857,1.7642c.0371.0556.0742.0839.1123.0839a.6149.6149,0,0,0,.2793-.14,14.02,14.02,0,0,1,2.6182-1.82,5.3578,5.3578,0,0,1,2.45-.5879,3.9439,3.9439,0,0,1,4.2832,4.2563V144.18c0,.2988-.1387.4482-.42.4482h-1.5674a.3216.3216,0,0,1-.3643-.3638v-9.996a3.14,3.14,0,0,0-.6718-2.1563,2.5573,2.5573,0,0,0-2.0157-.7559,4.2449,4.2449,0,0,0-2.1.5459,10.9716,10.9716,0,0,0-1.9043,1.358,1.6382,1.6382,0,0,0-.3086.392.9414.9414,0,0,0-.084.42V144.18q0,.4482-.42.4482h-1.5683a.3213.3213,0,0,1-.3633-.3638v-9.996q0-2.9122-2.6885-2.9122a4.1842,4.1842,0,0,0-2.0576.5181,14.2418,14.2418,0,0,0-2.3379,1.75v10.584a.3574.3574,0,0,1-.42.42h-1.541C972.0273,144.6284,971.8975,144.5073,971.8975,144.2646Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M998.1328,149.1924q-.3633-.0564-.3633-.3081v-1.0918a.2928.2928,0,0,1,.1543-.28,1.5272,1.5272,0,0,1,.6289-.084h1.7647a1.83,1.83,0,0,0,1.2314-.49,3.694,3.694,0,0,0,1.2051-2.6182,1.6672,1.6672,0,0,0-.1133-.6719l-5.3476-13.3838a.3932.3932,0,0,1-.0274-.1684c0-.2051.1114-.3076.336-.3076h1.7363a.4664.4664,0,0,1,.4473.3076l4.1718,10.8642c.0567.168.1309.252.2246.252.1114,0,.2051-.1118.2793-.3359l4.1162-10.78a.4336.4336,0,0,1,.3917-.3076h1.3164a.2818.2818,0,0,1,.2519.126.27.27,0,0,1,.0283.2656l-6.16,15.7641a5.2144,5.2144,0,0,1-1.876,2.604,5.7317,5.7317,0,0,1-3.0527.7A10.5641,10.5641,0,0,1,998.1328,149.1924Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M817.2129,177.272a6.5205,6.5205,0,0,1-2.4922-2.7022,9.3368,9.3368,0,0,1,.0293-7.98,7.0977,7.0977,0,0,1,9.8828-2.7578,6.6657,6.6657,0,0,1,2.4922,2.7578,8.8354,8.8354,0,0,1,.8828,4.0044,8.4777,8.4777,0,0,1-.8965,3.9478,6.714,6.714,0,0,1-2.5058,2.7158,6.9372,6.9372,0,0,1-3.6817.98A7.0743,7.0743,0,0,1,817.2129,177.272Zm7.168-2.38a8.3075,8.3075,0,0,0-.0274-8.708,4.07,4.07,0,0,0-3.4023-1.61,4.1351,4.1351,0,0,0-3.4434,1.61,8.1244,8.1244,0,0,0,0,8.6938,4.1781,4.1781,0,0,0,3.4717,1.5962A4.0472,4.0472,0,0,0,824.3809,174.8921Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M853.18,177.7061c0,.1679-.1035.2519-.3086.2519h-1.96a.5161.5161,0,0,1-.28-.07.7369.7369,0,0,1-.2237-.2661L846.18,170.37c-.0381-.0932-.084-.1445-.1406-.1543s-.1211.0239-.1953.0982L842.875,173.31a.7566.7566,0,0,0-.1953.56v3.6118a.49.49,0,0,1-.1133.378.5946.5946,0,0,1-.3906.0981h-1.4287a.4849.4849,0,0,1-.336-.084.4359.4359,0,0,1-.084-.3081V157.5742q0-.3918.3077-.3921h1.709c.2226,0,.3359.1216.3359.3638v12.9922c0,.1865.0273.28.084.28.0547,0,.1211-.0557.1953-.168l6.916-7.1958a.8442.8442,0,0,1,.5879-.3359h1.708c.2236,0,.3359.0654.3359.1958a.8173.8173,0,0,1-.1963.3359l-4.4238,4.5083a.54.54,0,0,0-.1113.252.4694.4694,0,0,0,.084.2236l5.2636,8.876A.2759.2759,0,0,1,853.18,177.7061Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M859.1855,177.272a6.5145,6.5145,0,0,1-2.4921-2.7022,9.332,9.332,0,0,1,.0283-7.98,7.0989,7.0989,0,0,1,9.8838-2.7578,6.6724,6.6724,0,0,1,2.4922,2.7578,8.8364,8.8364,0,0,1,.8818,4.0044,8.49,8.49,0,0,1-.8955,3.9478,6.7143,6.7143,0,0,1-2.5059,2.7158,6.9381,6.9381,0,0,1-3.6826.98A7.0714,7.0714,0,0,1,859.1855,177.272Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0686,4.0686,0,0,0-3.4014-1.61,4.1355,4.1355,0,0,0-3.4443,1.61,8.1244,8.1244,0,0,0,0,8.6938,4.1793,4.1793,0,0,0,3.4717,1.5962A4.05,4.05,0,0,0,866.3535,174.8921Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M874.8516,177.5942V163.4819q0-.3633.3086-.3637h1.68c.1866,0,.2793.1025.2793.3076v1.624q0,.3361.2813.084a14.8206,14.8206,0,0,1,2.5752-1.7637,5.252,5.252,0,0,1,2.38-.5322,4.4873,4.4873,0,0,1,2.59.7,3.4828,3.4828,0,0,1,1.3867,1.7641c.0371.0557.0743.084.1114.084a.6136.6136,0,0,0,.28-.14,13.9874,13.9874,0,0,1,2.6182-1.82,5.3608,5.3608,0,0,1,2.45-.5879,3.9465,3.9465,0,0,1,4.2842,4.2563V177.51c0,.2988-.1407.4482-.42.4482h-1.5684a.322.322,0,0,1-.3643-.3638v-9.9961a3.14,3.14,0,0,0-.6718-2.1562,2.56,2.56,0,0,0-2.0166-.7559,4.2505,4.2505,0,0,0-2.1.5459,11.0828,11.0828,0,0,0-1.9042,1.3579,1.6542,1.6542,0,0,0-.3077.3921.9414.9414,0,0,0-.084.42V177.51q0,.4482-.42.4482h-1.5674a.3223.3223,0,0,1-.3652-.3638v-9.9961q0-2.9121-2.6875-2.9121a4.187,4.187,0,0,0-2.0576.5181,14.2419,14.2419,0,0,0-2.3389,1.75v10.584a.3573.3573,0,0,1-.42.42h-1.5391Q874.8516,177.958,874.8516,177.5942Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M909.4023,163.3979v1.2042c0,.1679-.0937.2519-.2793.2519h-3.1923c-.1494,0-.2237.084-.2237.252v12.46q0,.3925-.3359.3921h-1.708c-.2061,0-.3076-.1211-.3076-.3638V165.0781c0-.1494-.0664-.2241-.1973-.2241H900.415c-.1865,0-.28-.084-.28-.2519v-1.2042c0-.1865.1025-.28.3086-.28h2.66q.252,0,.252-.28v-2.044q0-3.6116,3.5-3.6118h2.3232q.3648,0,.3643.336V158.61c0,.187-.1035.28-.3086.28h-1.959q-1.626,0-1.625,2.0162v1.96a.2227.2227,0,0,0,.2519.252h3.2207A.2473.2473,0,0,1,909.4023,163.3979Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M915.5488,177.272a6.5212,6.5212,0,0,1-2.4922-2.7022,9.3378,9.3378,0,0,1,.0293-7.98,7.0978,7.0978,0,0,1,9.8829-2.7578,6.6654,6.6654,0,0,1,2.4921,2.7578,8.8355,8.8355,0,0,1,.8829,4.0044,8.479,8.479,0,0,1-.8965,3.9478,6.7143,6.7143,0,0,1-2.5059,2.7158,6.9371,6.9371,0,0,1-3.6816.98A7.0747,7.0747,0,0,1,915.5488,177.272Zm7.168-2.38a8.3078,8.3078,0,0,0-.0273-8.708,4.0705,4.0705,0,0,0-3.4024-1.61,4.1349,4.1349,0,0,0-3.4433,1.61,8.1239,8.1239,0,0,0,0,8.6938,4.1778,4.1778,0,0,0,3.4716,1.5962A4.047,4.047,0,0,0,922.7168,174.8921Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M931.2148,177.5942V163.4819c0-.2422.1016-.3637.3077-.3637h1.624a.2726.2726,0,0,1,.3086.3076v1.82c0,.1118.0312.1777.0976.1958.0645.019.1348-.0181.21-.1118q2.1283-2.4918,4.34-2.4922.98,0,.98.3642v1.68q0,.2805-.2519.2242a7.84,7.84,0,0,0-1.26-.084,3.98,3.98,0,0,0-1.7637.476,5.0026,5.0026,0,0,0-1.5957,1.232,2.3687,2.3687,0,0,0-.6445,1.54v9.2681a.371.371,0,0,1-.42.42h-1.54C931.3447,177.958,931.2148,177.8369,931.2148,177.5942Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M947.26,164.854c-.15,0-.2246.084-.2246.252v8.8759a2.6815,2.6815,0,0,0,.42,1.7359,1.7469,1.7469,0,0,0,1.373.476H950.76a.2723.2723,0,0,1,.3076.3082l-.0283.9521c0,.1865-.1114.3081-.336.3638a16.3007,16.3007,0,0,1-2.6308.14,3.4837,3.4837,0,0,1-2.5762-.8262,3.6154,3.6154,0,0,1-.8125-2.6176v-9.3521c0-.2051-.0938-.3081-.2793-.3081h-2.24c-.2061,0-.3086-.0933-.3086-.28v-1.1763a.2475.2475,0,0,1,.28-.28h2.38c.1309,0,.1953-.0747.1953-.2242l.3086-4.4521q0-.3633.3086-.3638h1.3994q.3356,0,.336.3921v4.4238a.1982.1982,0,0,0,.2236.2242h3.5283c.2061,0,.3076.0839.3076.2519v1.2041c0,.1865-.1015.28-.3076.28Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M786.666,193.2554a.6.6,0,0,1-.1133.4062.5036.5036,0,0,1-.3906.126h-1.625c-.2793,0-.42-.1582-.42-.4761V190.96q0-.4476.3926-.4478h1.7636a.38.38,0,0,1,.2793.1118.4157.4157,0,0,1,.1133.3081Zm-.1133,17.5561a.4947.4947,0,0,1-.1113.378.5973.5973,0,0,1-.3926.0981h-1.4277a.4861.4861,0,0,1-.3359-.084.4354.4354,0,0,1-.084-.3081V196.8394q0-.3912.3359-.3916h1.709c.2051,0,.3066.1215.3066.3637Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M798.4951,211.2178a.3778.3778,0,0,1-.0693-.2661V210.14a.9071.9071,0,0,1,.0693-.3779,2.991,2.991,0,0,1,.2666-.4341l8.1475-10.9482.084-.168c0-.0742-.0752-.1118-.2237-.1118h-7.42a.3865.3865,0,0,1-.2383-.0561q-.07-.0558-.07-.252v-1.0361c0-.2051.0938-.3076.28-.3076h10.36q.252,0,.252.28v.896a1.1927,1.1927,0,0,1-.28.7281l-8.1767,10.976c-.0743.0938-.1016.168-.084.2242.0195.0556.0742.0839.1679.0839h8.2325c.205,0,.3086.084.3086.252v1.064c0,.2241-.1221.3359-.3643.3359H798.7617A.3787.3787,0,0,1,798.4951,211.2178Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M818.249,211.5674a4.9814,4.9814,0,0,1-3.43-1.106,3.7518,3.7518,0,0,1-1.2461-2.9536,4.0514,4.0514,0,0,1,1.89-3.5283,10.9575,10.9575,0,0,1,5.4746-1.5117l2.1836-.168c.3174-.0376.4766-.1772.4766-.42v-.7559a3.13,3.13,0,0,0-.9385-2.394,3.5506,3.5506,0,0,0-2.5342-.8818,4.7379,4.7379,0,0,0-4.0312,2.24.47.47,0,0,1-.168.1821.273.273,0,0,1-.2246-.0141l-1.5117-.5039a.1978.1978,0,0,1-.1407-.1958.6479.6479,0,0,1,.084-.252,5.8351,5.8351,0,0,1,2.3945-2.31,7.7428,7.7428,0,0,1,3.6817-.8262,6.1928,6.1928,0,0,1,4.2,1.2739,4.43,4.43,0,0,1,1.456,3.5142v9.9121a.4887.4887,0,0,1-.0839.3359.4363.4363,0,0,1-.3086.084h-1.3995q-.3354,0-.3916-.4482l-.0566-1.2876c-.0186-.13-.0654-.1963-.14-.1963a.3885.3885,0,0,0-.253.14A6.9748,6.9748,0,0,1,818.249,211.5674Zm4.9287-7.84-1.82.168a9.1016,9.1016,0,0,0-4.0176,1.0083,2.5871,2.5871,0,0,0-1.414,2.3237,2.3286,2.3286,0,0,0,.8535,1.9043,3.4862,3.4862,0,0,0,2.2812.7,4.9265,4.9265,0,0,0,1.709-.3081,5.0016,5.0016,0,0,0,1.4834-.84,4.1109,4.1109,0,0,0,.9942-1.0781,2.1271,2.1271,0,0,0,.3506-1.106v-2.3237C823.5977,203.8774,823.457,203.7275,823.1777,203.7275Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M841.5166,210.8955v-1.2041c0-.168-.0469-.2519-.14-.2519-.0567,0-.1309.0468-.2247.14a8.8307,8.8307,0,0,1-1.8339,1.4141,5.0813,5.0813,0,0,1-2.5616.5737,6.084,6.084,0,0,1-3.1923-.84,5.7492,5.7492,0,0,1-2.212-2.5341,9.4141,9.4141,0,0,1-.8115-4.1016,9.6537,9.6537,0,0,1,.84-4.144,6.6638,6.6638,0,0,1,2.3242-2.7862,5.9434,5.9434,0,0,1,3.3877-.9941,5.5741,5.5741,0,0,1,2.1845.3921,6.6408,6.6408,0,0,1,1.7354,1.0918.713.713,0,0,0,.3076.1684q.1407,0,.1406-.3642v-6.58c0-.2422.1016-.3638.3077-.3638h1.708c.205,0,.3086.1216.3086.3638v19.9639a.55.55,0,0,1-.084.35.4846.4846,0,0,1-.3643.0981h-1.4287A.346.346,0,0,1,841.5166,210.8955Zm-1.89-12.53a4.3029,4.3029,0,0,0-1.9747-.4619,4.1721,4.1721,0,0,0-3.416,1.5957,7.01,7.01,0,0,0-1.2871,4.5083,6.52,6.52,0,0,0,1.2735,4.396,4.1389,4.1389,0,0,0,3.2617,1.4556,3.9749,3.9749,0,0,0,2.0019-.5176,4.0919,4.0919,0,0,0,1.4278-1.3022,2.8238,2.8238,0,0,0,.5185-1.5679v-6.7481A7.2417,7.2417,0,0,0,839.627,198.3657Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M852.0313,210.6016a6.514,6.514,0,0,1-2.4922-2.7022,9.3282,9.3282,0,0,1,.0273-7.98,7.1,7.1,0,0,1,9.8848-2.7578,6.6859,6.6859,0,0,1,2.4922,2.7578,8.8347,8.8347,0,0,1,.8808,4.0044,8.49,8.49,0,0,1-.8955,3.9478,6.7072,6.7072,0,0,1-2.5058,2.7158,6.936,6.936,0,0,1-3.6827.98A7.0646,7.0646,0,0,1,852.0313,210.6016Zm7.1679-2.38a8.313,8.313,0,0,0-.0293-8.708,4.0672,4.0672,0,0,0-3.4013-1.61,4.137,4.137,0,0,0-3.4444,1.61,8.13,8.13,0,0,0,0,8.6938,4.1814,4.1814,0,0,0,3.4727,1.5962A4.0512,4.0512,0,0,0,859.1992,208.2217Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M886.9326,196.5737a.2728.2728,0,0,1,.0283.2657l-4.2841,14.0844a.4979.4979,0,0,1-.5323.3638h-1.3437a.5358.5358,0,0,1-.5879-.3638l-3.332-10.4721q-.0851-.252-.1963-.252c-.0381,0-.084.084-.14.252l-3.164,10.4721a.4971.4971,0,0,1-.5323.3638H871.42a.5373.5373,0,0,1-.5879-.3638l-4.3955-14.0561a.3134.3134,0,0,1-.0283-.14q0-.28.3359-.28h1.6524a.4675.4675,0,0,1,.4482.3076l3.3321,10.8921q.0834.28.1962.28c.0557,0,.13-.1025.2237-.3081L875.9,196.7275a.3692.3692,0,0,1,.3926-.28h1.3154a.3907.3907,0,0,1,.42.3076l3.4444,10.8921c.0556.187.1211.28.1953.28s.1406-.1025.1972-.3081l3.2188-10.8642a.4863.4863,0,0,1,.4765-.3076h1.12A.2816.2816,0,0,1,886.9326,196.5737Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M893.8906,210.6016a6.5147,6.5147,0,0,1-2.4922-2.7022,9.3278,9.3278,0,0,1,.0274-7.98,7.1,7.1,0,0,1,9.8847-2.7578,6.6845,6.6845,0,0,1,2.4922,2.7578,8.8348,8.8348,0,0,1,.8809,4.0044,8.49,8.49,0,0,1-.8955,3.9478,6.7074,6.7074,0,0,1-2.5059,2.7158,6.9356,6.9356,0,0,1-3.6826.98A7.0652,7.0652,0,0,1,893.8906,210.6016Zm7.168-2.38a8.313,8.313,0,0,0-.0293-8.708,4.0674,4.0674,0,0,0-3.4014-1.61,4.1368,4.1368,0,0,0-3.4443,1.61,8.1293,8.1293,0,0,0,0,8.6938,4.1811,4.1811,0,0,0,3.4727,1.5962A4.0513,4.0513,0,0,0,901.0586,208.2217Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M911.9365,210.8115a.4945.4945,0,0,1-.1123.378.5959.5959,0,0,1-.3916.0981h-1.456a.4849.4849,0,0,1-.336-.084.4311.4311,0,0,1-.084-.3081l.0274-19.9917q0-.3918.3359-.3921h1.6807q.3354,0,.3359.3638Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M919.874,207.2837a4.9562,4.9562,0,0,0,1.7783,1.82,4.6961,4.6961,0,0,0,2.4639.6719,4.9825,4.9825,0,0,0,4.3682-2.3237.4048.4048,0,0,1,.1953-.126.3282.3282,0,0,1,.1963.042l1.1767.5879c.1485.0937.168.2241.0557.3921a6.8264,6.8264,0,0,1-2.6045,2.45,7.8747,7.8747,0,0,1-3.6113.77,7.1119,7.1119,0,0,1-3.668-.9517,6.6017,6.6017,0,0,1-2.5342-2.6743,8.2937,8.2937,0,0,1-.91-3.9336,8.8964,8.8964,0,0,1,.9238-4.1582,6.6452,6.6452,0,0,1,2.5342-2.73,6.9905,6.9905,0,0,1,3.626-.9521,6.8682,6.8682,0,0,1,3.542.896,5.98,5.98,0,0,1,2.3652,2.6181,9.1932,9.1932,0,0,1,.8408,4.0738v.1123a.69.69,0,0,1-.084.3779.3773.3773,0,0,1-.3359.126H919.4688q-.252,0-.252.42A4.927,4.927,0,0,0,919.874,207.2837Zm7.91-4.4522a.6942.6942,0,0,0,.3916-.07c.0566-.0464.084-.1543.084-.3222a5.0817,5.0817,0,0,0-.5176-2.2818,4.1083,4.1083,0,0,0-1.5117-1.68,4.2267,4.2267,0,0,0-2.3106-.63,4.367,4.367,0,0,0-2.4922.7138,4.6964,4.6964,0,0,0-1.623,1.8482,5.1432,5.1432,0,0,0-.5332,2.4218Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M935.3164,210.9238V196.8115c0-.2422.1016-.3637.3076-.3637h1.6807a.2719.2719,0,0,1,.3076.3076v1.624q0,.309.252.084a15.3717,15.3717,0,0,1,2.6738-1.7637,5.6456,5.6456,0,0,1,2.4775-.5322,4.5822,4.5822,0,0,1,3.3184,1.1479,4.0825,4.0825,0,0,1,1.1894,3.08v10.4439c0,.2988-.1386.4482-.42.4482h-1.5674a.3215.3215,0,0,1-.3642-.3638v-9.9961a3.0227,3.0227,0,0,0-.7139-2.1562,2.89,2.89,0,0,0-2.1982-.7559,4.6272,4.6272,0,0,0-2.17.5181,13.6127,13.6127,0,0,0-2.4218,1.75v10.584a.3574.3574,0,0,1-.42.42h-1.54C935.4463,211.2876,935.3164,211.1665,935.3164,210.9238Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M955.5322,193.2554a.6055.6055,0,0,1-.1123.4062.5048.5048,0,0,1-.3916.126h-1.624c-.28,0-.42-.1582-.42-.4761V190.96c0-.2984.13-.4478.3916-.4478h1.7646a.38.38,0,0,1,.2793.1118.4191.4191,0,0,1,.1123.3081Zm-.1123,17.5561a.4947.4947,0,0,1-.1113.378.601.601,0,0,1-.3926.0981h-1.4277a.4849.4849,0,0,1-.336-.084.4311.4311,0,0,1-.0839-.3081V196.8394c0-.2608.1113-.3916.3359-.3916h1.708q.3076,0,.3076.3637Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M963.3857,207.2837a4.9557,4.9557,0,0,0,1.7784,1.82,4.6958,4.6958,0,0,0,2.4638.6719,4.9825,4.9825,0,0,0,4.3682-2.3237.4048.4048,0,0,1,.1953-.126.3282.3282,0,0,1,.1963.042l1.1768.5879c.1484.0937.1679.2241.0556.3921a6.8264,6.8264,0,0,1-2.6045,2.45,7.8747,7.8747,0,0,1-3.6113.77,7.1121,7.1121,0,0,1-3.668-.9517,6.6017,6.6017,0,0,1-2.5342-2.6743,8.2937,8.2937,0,0,1-.91-3.9336,8.8964,8.8964,0,0,1,.9238-4.1582,6.6452,6.6452,0,0,1,2.5342-2.73,6.9908,6.9908,0,0,1,3.626-.9521,6.8682,6.8682,0,0,1,3.542.896,5.98,5.98,0,0,1,2.3652,2.6181,9.1918,9.1918,0,0,1,.8408,4.0738v.1123a.69.69,0,0,1-.084.3779.3773.3773,0,0,1-.3359.126H962.9805q-.252,0-.252.42A4.928,4.928,0,0,0,963.3857,207.2837Zm7.91-4.4522a.6942.6942,0,0,0,.3916-.07c.0566-.0464.084-.1543.084-.3222a5.0817,5.0817,0,0,0-.5176-2.2818,4.1083,4.1083,0,0,0-1.5117-1.68,4.2267,4.2267,0,0,0-2.3106-.63,4.3665,4.3665,0,0,0-2.4921.7138,4.6967,4.6967,0,0,0-1.6231,1.8482,5.1432,5.1432,0,0,0-.5332,2.4218Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M981.0117,210.8394a.45.45,0,0,1-.1113.35.601.601,0,0,1-.3926.0981h-1.96a.4878.4878,0,0,1-.3213-.084.3882.3882,0,0,1-.0987-.3081v-2.4077q0-.364.3076-.3643h2.2969c.1856,0,.2793.1123.2793.336Z" transform="translate(-58.8798 50.5781)"/>
      </g>
    </g>
  </g>
  <g id="innowacyjnosc" class="our-values__text">
    <g>
      <path class="cls-2" d="M829.0364,357.9279c0,116.1624,94.5077,210.67,210.67,210.67s210.664-94.5077,210.664-210.67-94.5022-210.67-210.664-210.67S829.0364,241.7661,829.0364,357.9279Z" transform="translate(-58.8798 50.5781)"/>
      <g>
        <path class="cls-3" d="M938.877,288.5a.4349.4349,0,0,1,.0419.3643L933.15,308.7153a.5292.5292,0,0,1-.1963.3223.733.733,0,0,1-.42.0977h-1.708c-.3545,0-.5694-.13-.6436-.3916l-4.4805-15.876q-.0834-.3077-.1953.0273l-4.5361,15.82a.5337.5337,0,0,1-.1963.3223.735.735,0,0,1-.42.0977h-1.7363c-.3545,0-.5694-.13-.6436-.3916l-5.6836-19.88a.3012.3012,0,0,1-.0283-.1407q0-.3631.42-.3632h1.792a.43.43,0,0,1,.4756.3359l4.5644,16.2959c.0557.2051.1124.3076.168.3076.0742,0,.13-.0928.168-.28l4.6474-16.3515a.4776.4776,0,0,1,.5049-.3076h1.5957a.6536.6536,0,0,1,.336.0693.4468.4468,0,0,1,.1679.2666l4.5918,16.38c.0567.168.1123.2519.168.2519s.1123-.0839.168-.2519l4.62-16.4082q.0557-.3077.4766-.3076h1.3994A.4021.4021,0,0,1,938.877,288.5Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M943.2305,313.7q-.3648-.0571-.3643-.3086v-1.0918a.2934.2934,0,0,1,.1543-.28,1.5349,1.5349,0,0,1,.63-.0839h1.7637a1.8336,1.8336,0,0,0,1.2324-.4893,3.7,3.7,0,0,0,1.2041-2.6182,1.6841,1.6841,0,0,0-.1123-.6718l-5.3477-13.3838a.3827.3827,0,0,1-.0283-.169q0-.3077.3359-.3076h1.7364a.4654.4654,0,0,1,.4472.3076l4.1729,10.8643c.0557.168.13.2519.2236.2519.1123,0,.2051-.1113.28-.3359l4.1152-10.78a.4356.4356,0,0,1,.3926-.3076h1.3154a.282.282,0,0,1,.252.126.27.27,0,0,1,.0283.2656l-6.16,15.7647a5.2108,5.2108,0,0,1-1.876,2.6035,5.7258,5.7258,0,0,1-3.0518.7A10.5757,10.5757,0,0,1,943.2305,313.7Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M969.6475,294.9673a7.0638,7.0638,0,0,1,2.31,2.52l.0557.2236c0,.1123-.0655.1778-.1953.1963l-1.54.3643-.084.0273c-.0937,0-.1875-.0742-.28-.2236a7.6121,7.6121,0,0,0-1.7217-1.7647,3.2937,3.2937,0,0,0-1.8906-.5595,4.2136,4.2136,0,0,0-3.4717,1.61,7.99,7.99,0,0,0,.0137,8.7217,4.2966,4.2966,0,0,0,3.5146,1.5957,4.1505,4.1505,0,0,0,2.31-.6436,6.0032,6.0032,0,0,0,1.8056-1.9882c.0567-.0928.1123-.1446.168-.1543a.3312.3312,0,0,1,.1963.042l1.2041.4482c.13.0566.168.1777.1123.3643a7.2913,7.2913,0,0,1-2.38,2.6455,6.2981,6.2981,0,0,1-3.6123,1.0224,6.895,6.895,0,0,1-3.6406-.9658,6.647,6.647,0,0,1-2.4912-2.7021,8.54,8.54,0,0,1-.8965-3.9756,8.8,8.8,0,0,1,.8965-4.0186,6.6387,6.6387,0,0,1,2.5058-2.7578,6.8363,6.8363,0,0,1,3.626-.9805A6.19,6.19,0,0,1,969.6475,294.9673Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M976.6064,308.772V288.7231c0-.2421.1016-.3632.3077-.3632h1.6523c.2051,0,.3076.1113.3076.3359v7.4756c0,.206.084.2334.252.084a14.4647,14.4647,0,0,1,2.7158-1.7364,5.94,5.94,0,0,1,2.4639-.5039,4.5205,4.5205,0,0,1,3.3183,1.1485,4.1736,4.1736,0,0,1,1.1621,3.1084v10.415q0,.4482-.42.4483h-1.5683a.3211.3211,0,0,1-.3633-.3633v-9.9961a3.018,3.018,0,0,0-.7149-2.1563,2.8894,2.8894,0,0,0-2.1972-.7558,4.47,4.47,0,0,0-2.1426.5175,15.1114,15.1114,0,0,0-2.4219,1.75v10.584q0,.42-.4482.42h-1.54A.3211.3211,0,0,1,976.6064,308.772Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M996.8359,308.45a6.5255,6.5255,0,0,1-2.4921-2.7021,9.3332,9.3332,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6739,6.6739,0,0,1,2.4921,2.7578,8.8367,8.8367,0,0,1,.8819,4.0049,8.4871,8.4871,0,0,1-.8955,3.9472,6.7088,6.7088,0,0,1-2.5059,2.7159,6.9338,6.9338,0,0,1-3.6826.98A7.0751,7.0751,0,0,1,996.8359,308.45Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.068,4.068,0,0,0-3.4014-1.61,4.1374,4.1374,0,0,0-3.4443,1.61,8.1286,8.1286,0,0,0,0,8.6934,4.1792,4.1792,0,0,0,3.4726,1.5967A4.0483,4.0483,0,0,0,1004.0039,306.07Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1022.666,308.7437V307.54c0-.168-.0469-.252-.14-.252-.0567,0-.1309.0469-.2246.14a8.7931,8.7931,0,0,1-1.834,1.4141,5.0784,5.0784,0,0,1-2.5615.5742,6.0864,6.0864,0,0,1-3.1924-.84,5.7459,5.7459,0,0,1-2.2119-2.5342,9.4141,9.4141,0,0,1-.8116-4.1016,9.6579,9.6579,0,0,1,.84-4.1445,6.6659,6.6659,0,0,1,2.3232-2.7861,5.953,5.953,0,0,1,3.3887-.9942,5.5589,5.5589,0,0,1,2.1836.3926,6.6176,6.6176,0,0,1,1.7363,1.0918.711.711,0,0,0,.3076.168c.0938,0,.1407-.1211.1407-.3643v-6.58c0-.2421.1015-.3632.3076-.3632h1.708q.3077,0,.3076.3632V308.687a.5442.5442,0,0,1-.084.3506.484.484,0,0,1-.3633.0977h-1.4287A.3456.3456,0,0,1,1022.666,308.7437Zm-1.89-12.53a4.3091,4.3091,0,0,0-1.9746-.4619,4.1709,4.1709,0,0,0-3.4161,1.5957,7.0126,7.0126,0,0,0-1.288,4.5088,6.5192,6.5192,0,0,0,1.2744,4.3955,4.1378,4.1378,0,0,0,3.2617,1.456,3.979,3.979,0,0,0,2.0019-.5176,4.101,4.101,0,0,0,1.4278-1.3027,2.8232,2.8232,0,0,0,.5185-1.5674v-6.748A7.242,7.242,0,0,0,1020.7764,296.2134Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1029.4561,309.0659a.3794.3794,0,0,1-.07-.2666v-.8115a.9061.9061,0,0,1,.07-.3779,2.9755,2.9755,0,0,1,.2656-.4346l8.1484-10.9482.084-.168c0-.0742-.0752-.1113-.2246-.1113h-7.42a.3791.3791,0,0,1-.2373-.0567q-.07-.0557-.07-.2519V294.603c0-.2051.0927-.3076.28-.3076h10.3594c.168,0,.252.0938.252.28v.8955a1.1972,1.1972,0,0,1-.2793.7285l-8.1768,10.9756c-.0742.0937-.1025.168-.084.2246s.0742.084.168.084h8.2324q.3077,0,.3076.2519v1.0635c0,.2246-.1211.336-.3633.336h-10.9765A.3753.3753,0,0,1,1029.4561,309.0659Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1048.0332,291.103a.6.6,0,0,1-.1123.4063.5048.5048,0,0,1-.3916.126h-1.624q-.42,0-.42-.4756v-2.3526c0-.2978.13-.4472.3916-.4472h1.7646a.38.38,0,0,1,.2793.1113.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5567a.4958.4958,0,0,1-.1113.3779.6042.6042,0,0,1-.3926.0977h-1.4277a.4828.4828,0,0,1-.336-.084.4346.4346,0,0,1-.084-.3076V294.687q0-.391.336-.3916h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1053.6611,308.772V294.66c0-.2422.1026-.3643.3086-.3643h1.68c.1865,0,.28.1025.28.3076v1.6241c0,.2246.0928.2529.28.0839a14.8855,14.8855,0,0,1,2.5752-1.7636,5.2531,5.2531,0,0,1,2.3808-.5323,4.4917,4.4917,0,0,1,2.59.7,3.4859,3.4859,0,0,1,1.3857,1.7647c.0371.0556.0742.084.1123.084a.6187.6187,0,0,0,.2793-.1407,14.0487,14.0487,0,0,1,2.6182-1.82,5.3608,5.3608,0,0,1,2.45-.5879,3.9459,3.9459,0,0,1,4.2841,4.2569v10.415q0,.4482-.42.4483h-1.5683a.3218.3218,0,0,1-.3643-.3633v-9.9961a3.1392,3.1392,0,0,0-.6719-2.1563,2.5569,2.5569,0,0,0-2.0156-.7558,4.2381,4.2381,0,0,0-2.1.5459,11.0016,11.0016,0,0,0-1.9043,1.3574,1.6629,1.6629,0,0,0-.3086.3926.94.94,0,0,0-.084.42V308.687q0,.4482-.42.4483h-1.5674a.3214.3214,0,0,1-.3642-.3633v-9.9961q0-2.9121-2.6885-2.9121a4.1919,4.1919,0,0,0-2.0576.5175,14.2534,14.2534,0,0,0-2.3379,1.75v10.584a.3571.3571,0,0,1-.42.42h-1.54Q1053.6612,309.1353,1053.6611,308.772Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1079.8975,313.7q-.3648-.0571-.3643-.3086v-1.0918a.2934.2934,0,0,1,.1543-.28,1.5349,1.5349,0,0,1,.63-.0839h1.7637a1.8336,1.8336,0,0,0,1.2324-.4893,3.7,3.7,0,0,0,1.2041-2.6182,1.6841,1.6841,0,0,0-.1123-.6718l-5.3477-13.3838a.3827.3827,0,0,1-.0283-.169q0-.3077.3359-.3076h1.7364a.4654.4654,0,0,1,.4472.3076l4.1729,10.8643q.0834.252.2236.2519c.1123,0,.2051-.1113.28-.3359l4.1152-10.78a.4356.4356,0,0,1,.3926-.3076h1.3154a.282.282,0,0,1,.252.126.27.27,0,0,1,.0283.2656l-6.16,15.7647a5.21,5.21,0,0,1-1.8759,2.6035,5.7258,5.7258,0,0,1-3.0518.7A10.5746,10.5746,0,0,1,1079.8975,313.7Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1106.4131,294.7153v1.1758c0,.168.0459.252.14.252a.3828.3828,0,0,0,.2246-.14q.5874-.5874,1.0352-.9521a4.9909,4.9909,0,0,1,1.3164-.7,5.6547,5.6547,0,0,1,2.044-.336,5.9554,5.9554,0,0,1,3.15.8409,5.7042,5.7042,0,0,1,2.1836,2.5195,9.5451,9.5451,0,0,1,.7979,4.1162,10.3951,10.3951,0,0,1-.7979,4.2559,5.8705,5.8705,0,0,1-5.6416,3.6679,5.9456,5.9456,0,0,1-3.9765-1.4c-.1309-.13-.2334-.1953-.3076-.1953q-.1407,0-.1407.3633v5.208q0,.3648-.3076.3643h-1.7363q-.3077,0-.3076-.3643V294.7437a.4965.4965,0,0,1,.0976-.336.4529.4529,0,0,1,.3506-.1123h1.4834Q1106.4131,294.2954,1106.4131,294.7153Zm1.834,12.53a4.36,4.36,0,0,0,2.0019.4619,4.105,4.105,0,0,0,3.374-1.5957,7.2192,7.2192,0,0,0,1.2735-4.5928,6.4676,6.4676,0,0,0-1.26-4.3535,4.3818,4.3818,0,0,0-5.2637-.9521,4.0018,4.0018,0,0,0-1.414,1.2461,2.6237,2.6237,0,0,0-.5186,1.4843V305.86A6.9563,6.9563,0,0,0,1108.2471,307.2456Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1124.71,308.45a6.5257,6.5257,0,0,1-2.4922-2.7021,9.3332,9.3332,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6748,6.6748,0,0,1,2.4922,2.7578,8.8366,8.8366,0,0,1,.8818,4.0049,8.4871,8.4871,0,0,1-.8955,3.9472,6.7088,6.7088,0,0,1-2.5059,2.7159,6.9336,6.9336,0,0,1-3.6826.98A7.0745,7.0745,0,0,1,1124.71,308.45Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.068,4.068,0,0,0-3.4014-1.61,4.1375,4.1375,0,0,0-3.4443,1.61,8.1286,8.1286,0,0,0,0,8.6934,4.1794,4.1794,0,0,0,3.4727,1.5967A4.0482,4.0482,0,0,0,1131.8779,306.07Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1139.1582,309.0659a.38.38,0,0,1-.0693-.2666v-.8115a.9068.9068,0,0,1,.0693-.3779,3.097,3.097,0,0,1,.2666-.4346l8.1475-10.9482.084-.168c0-.0742-.0743-.1113-.2237-.1113h-7.42a.3827.3827,0,0,1-.2383-.0567q-.07-.0557-.07-.2519V294.603c0-.2051.0938-.3076.28-.3076h10.36c.168,0,.252.0938.252.28v.8955a1.1927,1.1927,0,0,1-.28.7285l-8.1758,10.9756c-.0752.0937-.1025.168-.084.2246s.0743.084.168.084h8.2315c.206,0,.3085.084.3085.2519v1.0635c0,.2246-.122.336-.3642.336h-10.9756A.38.38,0,0,1,1139.1582,309.0659Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1158.9121,309.4155a4.9791,4.9791,0,0,1-3.43-1.1064,3.7508,3.7508,0,0,1-1.2461-2.9531,4.051,4.051,0,0,1,1.89-3.5284,10.9563,10.9563,0,0,1,5.4736-1.5117l2.1846-.168c.3174-.038.4756-.1777.4756-.4208v-.7559a3.1282,3.1282,0,0,0-.9375-2.3936,3.5523,3.5523,0,0,0-2.5342-.8818,4.7391,4.7391,0,0,0-4.0322,2.2393.4623.4623,0,0,1-.168.1826.2735.2735,0,0,1-.2236-.0147l-1.5127-.5039a.1974.1974,0,0,1-.14-.1953.6431.6431,0,0,1,.084-.2519,5.8359,5.8359,0,0,1,2.3945-2.3106,7.7459,7.7459,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2745,4.4283,4.4283,0,0,1,1.456,3.5136v9.9121a.4875.4875,0,0,1-.084.336.434.434,0,0,1-.3086.084h-1.3994q-.3369,0-.3925-.4483l-.0557-1.2871c-.0186-.1309-.0654-.1963-.14-.1963a.39.39,0,0,0-.2529.14A6.9769,6.9769,0,0,1,1158.9121,309.4155Zm4.9277-7.84-1.82.168a9.1139,9.1139,0,0,0-4.0175,1.0078,2.5894,2.5894,0,0,0-1.4141,2.3242,2.33,2.33,0,0,0,.8545,1.9043,3.484,3.484,0,0,0,2.2812.6992,4.9278,4.9278,0,0,0,1.708-.3076,5.02,5.02,0,0,0,1.4844-.84,4.1109,4.1109,0,0,0,.9942-1.0781,2.1265,2.1265,0,0,0,.35-1.1064v-2.3233Q1164.26,301.5757,1163.84,301.5757Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M908.9863,329.5142a5.1722,5.1722,0,0,0-2.3662-.5176,4.4347,4.4347,0,0,0-2.5195.6582,1.9928,1.9928,0,0,0-.9805,1.7217,1.6469,1.6469,0,0,0,.49,1.1621,4.1369,4.1369,0,0,0,1.7783.91l3.5556,1.0635a5.5789,5.5789,0,0,1,2.7442,1.4981,3.4191,3.4191,0,0,1,.8955,2.3662,3.5867,3.5867,0,0,1-.7832,2.2822,5.0572,5.0572,0,0,1-2.1563,1.54A9.0748,9.0748,0,0,1,902.7,341.89a5.1761,5.1761,0,0,1-2.38-2.2255.48.48,0,0,1-.084-.2237.255.255,0,0,1,.14-.2246l1.0928-.6152c.1855-.1123.3261-.1026.42.0273a5.1306,5.1306,0,0,0,4.6758,2.4082,5.3411,5.3411,0,0,0,2.7294-.6855,1.9639,1.9639,0,0,0,1.0782-1.7227,1.6433,1.6433,0,0,0-.6436-1.3154,5.9956,5.9956,0,0,0-2.1006-.9522l-2.8838-.8125a5.9928,5.9928,0,0,1-2.9257-1.4834,3.2942,3.2942,0,0,1-.8819-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.5593,4.5593,0,0,1,2.0156-1.5547,8.3093,8.3093,0,0,1,6.3008.1543,5.3653,5.3653,0,0,1,2.2539,1.9043c.13.1866.1211.3174-.0283.3916l-1.1758.6446a.4352.4352,0,0,1-.168.0556.3815.3815,0,0,1-.2519-.14A4.1851,4.1851,0,0,0,908.9863,329.5142Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M916.21,342.395a.38.38,0,0,1-.0694-.2666v-.8115a.9067.9067,0,0,1,.0694-.3779,3.0873,3.0873,0,0,1,.2666-.4346l8.1474-10.9482.084-.168c0-.0742-.0742-.1113-.2236-.1113h-7.42a.3823.3823,0,0,1-.2383-.0567q-.07-.0556-.07-.2519v-1.0362q0-.3075.28-.3076h10.36c.168,0,.2519.0938.2519.28V328.8a1.1925,1.1925,0,0,1-.28.7285l-8.1758,10.9756c-.0752.0937-.1026.168-.084.2246s.0742.084.168.084h8.2314c.2061,0,.3086.084.3086.2519v1.0635c0,.2246-.1221.336-.3643.336H916.4766A.38.38,0,0,1,916.21,342.395Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M935.9639,342.7446a4.9789,4.9789,0,0,1-3.43-1.1064,3.75,3.75,0,0,1-1.2461-2.9531,4.0511,4.0511,0,0,1,1.89-3.5284,10.9639,10.9639,0,0,1,5.4746-1.5117l2.1836-.1679c.3174-.0381.4766-.1778.4766-.4209V332.3a3.1289,3.1289,0,0,0-.9385-2.3936,3.549,3.549,0,0,0-2.5342-.8818,4.7376,4.7376,0,0,0-4.0312,2.2393.4688.4688,0,0,1-.168.1826.2759.2759,0,0,1-.2246-.0147l-1.5117-.5039a.1963.1963,0,0,1-.14-.1953.6281.6281,0,0,1,.084-.2519,5.8282,5.8282,0,0,1,2.3936-2.3106,7.7511,7.7511,0,0,1,3.6816-.8262,6.19,6.19,0,0,1,4.2,1.2745,4.4252,4.4252,0,0,1,1.4561,3.5136v9.9121a.4875.4875,0,0,1-.084.336.4314.4314,0,0,1-.3076.084h-1.4q-.3354,0-.3916-.4483l-.0567-1.2871c-.0185-.1309-.0654-.1963-.14-.1963a.3859.3859,0,0,0-.252.14A6.9774,6.9774,0,0,1,935.9639,342.7446Zm4.9287-7.84-1.82.168a9.1106,9.1106,0,0,0-4.0176,1.0078,2.5875,2.5875,0,0,0-1.4141,2.3242,2.3316,2.3316,0,0,0,.8535,1.9043,3.489,3.489,0,0,0,2.2823.6992,4.9366,4.9366,0,0,0,1.708-.3076,5.0163,5.0163,0,0,0,1.4834-.84,4.0934,4.0934,0,0,0,.9941-1.0781,2.1284,2.1284,0,0,0,.3506-1.1064V335.353C941.3125,335.0542,941.1719,334.9048,940.8926,334.9048Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M949.5166,342.4644a.37.37,0,0,1-.4482-.4483V322.0806q0-.3912.3359-.3916h1.7363q.28,0,.2793.3916v6.86q0,.3077.1406.3076a.6152.6152,0,0,0,.28-.14,6.3076,6.3076,0,0,1,4.48-1.7647,5.1611,5.1611,0,0,1,4.4658,2.1143,9.1846,9.1846,0,0,1,1.5264,5.5019,8.7878,8.7878,0,0,1-1.6943,5.6983,5.7092,5.7092,0,0,1-4.6895,2.0859,5.3333,5.3333,0,0,1-2.8281-.6855,5.9968,5.9968,0,0,1-1.7363-1.5264.2276.2276,0,0,0-.168-.1123c-.0752,0-.1211.0654-.14.1963l-.1406,1.3994a.727.727,0,0,1-.1533.3506.4936.4936,0,0,1-.3506.0977Zm1.9033-5.0118a3.0948,3.0948,0,0,0,.5742,1.8057,4.1682,4.1682,0,0,0,1.4981,1.3164,4.0641,4.0641,0,0,0,1.9326.49,3.9415,3.9415,0,0,0,3.2334-1.5127,7.1253,7.1253,0,0,0,1.2178-4.5361,7.5968,7.5968,0,0,0-1.0918-4.4092,3.6263,3.6263,0,0,0-3.1641-1.5547,5,5,0,0,0-2.3379.5606,5.9276,5.9276,0,0,0-1.8623,1.5117Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M969.62,341.9888a.4916.4916,0,0,1-.1123.3779.5991.5991,0,0,1-.3916.0977H967.66a.4828.4828,0,0,1-.336-.084.4346.4346,0,0,1-.084-.3076l.0284-19.9922c0-.2608.1113-.3916.3359-.3916h1.68q.3354,0,.3359.3632Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M977.8379,341.7788a6.5257,6.5257,0,0,1-2.4922-2.7021,9.3341,9.3341,0,0,1,.0283-7.9805,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6742,6.6742,0,0,1,2.4922,2.7578,8.8366,8.8366,0,0,1,.8818,4.0049,8.4871,8.4871,0,0,1-.8955,3.9472,6.7086,6.7086,0,0,1-2.5058,2.7159,6.9338,6.9338,0,0,1-3.6826.98A7.0746,7.0746,0,0,1,977.8379,341.7788Zm7.168-2.38a8.31,8.31,0,0,0-.0284-8.708,4.0676,4.0676,0,0,0-3.4013-1.61,4.1376,4.1376,0,0,0-3.4444,1.61,8.1292,8.1292,0,0,0,0,8.6934,4.1794,4.1794,0,0,0,3.4727,1.5967A4.0484,4.0484,0,0,0,985.0059,339.3989Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M993.5039,342.1011V327.9888c0-.2422.1025-.3643.3076-.3643h1.6807a.2719.2719,0,0,1,.3076.3076v1.6241q0,.309.252.0839a15.4,15.4,0,0,1,2.6738-1.7636,5.647,5.647,0,0,1,2.4785-.5323,4.58,4.58,0,0,1,3.3174,1.1485,4.08,4.08,0,0,1,1.19,3.08v10.4433q0,.4482-.42.4483h-1.5684a.321.321,0,0,1-.3632-.3633V332.105a3.018,3.018,0,0,0-.7149-2.1563,2.89,2.89,0,0,0-2.1973-.7558,4.63,4.63,0,0,0-2.17.5175,13.5882,13.5882,0,0,0-2.4228,1.75v10.584a.3571.3571,0,0,1-.42.42h-1.54C993.6338,342.4644,993.5039,342.3433,993.5039,342.1011Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1010.7236,347.0288c-.2422-.0381-.3642-.1406-.3642-.3086v-1.0918a.2949.2949,0,0,1,.1543-.28,1.5349,1.5349,0,0,1,.63-.0839h1.7646a1.831,1.831,0,0,0,1.2314-.4893,3.7,3.7,0,0,0,1.2042-2.6182,1.6665,1.6665,0,0,0-.1124-.6718l-5.3476-13.3838a.3961.3961,0,0,1-.0283-.169q0-.3075.3359-.3076h1.7363a.4678.4678,0,0,1,.4483.3076l4.1719,10.8643c.0556.168.1308.2519.2236.2519.1123,0,.2051-.1113.28-.3359l4.1162-10.78a.4335.4335,0,0,1,.3916-.3076h1.3164a.2817.2817,0,0,1,.2519.126.2726.2726,0,0,1,.0284.2656l-6.16,15.7647a5.2155,5.2155,0,0,1-1.876,2.6035,5.7293,5.7293,0,0,1-3.0527.7A10.5776,10.5776,0,0,1,1010.7236,347.0288Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1037.38,324.4321a.6.6,0,0,1-.1123.4063.5048.5048,0,0,1-.3916.126h-1.624q-.42,0-.42-.4756v-2.3526c0-.2978.13-.4472.3916-.4472h1.7647a.38.38,0,0,1,.2793.1113.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5567a.4958.4958,0,0,1-.1113.3779.6042.6042,0,0,1-.3926.0977h-1.4278a.4826.4826,0,0,1-.3359-.084.4341.4341,0,0,1-.084-.3076V328.0161q0-.3911.336-.3916h1.708c.205,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1063.3076,342.2124q0,.252-.3076.252h-1.96a.516.516,0,0,1-.28-.0694.735.735,0,0,1-.2237-.2666l-4.2285-7.2519c-.0371-.0928-.084-.1446-.14-.1543a.2479.2479,0,0,0-.1963.0986l-2.9678,2.9961a.757.757,0,0,0-.1963.56v3.6123a.4916.4916,0,0,1-.1123.3779.5991.5991,0,0,1-.3916.0977h-1.4277a.4828.4828,0,0,1-.336-.084.43.43,0,0,1-.0839-.3076V322.0806c0-.2608.1015-.3916.3076-.3916h1.708q.3354,0,.3359.3632v12.9922c0,.1866.0283.28.084.28s.1211-.0557.1963-.168l6.916-7.1963a.8461.8461,0,0,1,.5879-.3359h1.708c.2236,0,.3359.0654.3359.1963a.82.82,0,0,1-.1962.3359l-4.4239,4.5079a.5372.5372,0,0,0-.1123.2519.48.48,0,0,0,.084.2236l5.2647,8.876A.2774.2774,0,0,1,1063.3076,342.2124Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1067.6191,342.1011V327.9888c0-.2422.1026-.3643.3086-.3643h1.6241a.2719.2719,0,0,1,.3076.3076v1.82c0,.1123.0322.1778.0986.1963.0645.0186.1348-.0185.21-.1123q2.1269-2.4917,4.34-2.4922.98,0,.9795.3643v1.68c0,.1875-.084.2617-.2519.2246a7.7941,7.7941,0,0,0-1.26-.084,3.9706,3.9706,0,0,0-1.7637.4756,5.0178,5.0178,0,0,0-1.5967,1.2324,2.3668,2.3668,0,0,0-.6435,1.54v9.2675a.371.371,0,0,1-.42.42h-1.54Q1067.6192,342.4644,1067.6191,342.1011Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1081.6064,338.46a4.9612,4.9612,0,0,0,1.7784,1.82,4.6941,4.6941,0,0,0,2.4638.6718,4.9829,4.9829,0,0,0,4.3682-2.3242.4063.4063,0,0,1,.1953-.126.3326.3326,0,0,1,.1963.042l1.1758.5879q.2241.1407.0566.3926a6.8281,6.8281,0,0,1-2.6045,2.4492,7.8684,7.8684,0,0,1-3.6123.77,7.1179,7.1179,0,0,1-3.6679-.9521,6.5973,6.5973,0,0,1-2.5332-2.6738,8.2825,8.2825,0,0,1-.91-3.9336,8.8944,8.8944,0,0,1,.9238-4.1582,6.6429,6.6429,0,0,1,2.5342-2.7305,6.9947,6.9947,0,0,1,3.626-.9522,6.8646,6.8646,0,0,1,3.542.8965,5.9885,5.9885,0,0,1,2.3652,2.6182,9.1888,9.1888,0,0,1,.8408,4.0732v.1123a.69.69,0,0,1-.084.378.3787.3787,0,0,1-.3359.1259H1081.2c-.168,0-.252.1407-.252.42A4.9261,4.9261,0,0,0,1081.6064,338.46Zm7.91-4.4521a.6963.6963,0,0,0,.3916-.0693c.0566-.0469.084-.1543.084-.3223a5.0967,5.0967,0,0,0-.5176-2.2822,4.1018,4.1018,0,0,0-1.5127-1.68,4.221,4.221,0,0,0-2.31-.63,4.36,4.36,0,0,0-2.4921.7139,4.6948,4.6948,0,0,0-1.6241,1.8476,5.1443,5.1443,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1104.3418,340.2661a10.4342,10.4342,0,0,0,2.2539-1.8057V328.0161q0-.3911.4482-.3916h1.54a.3464.3464,0,0,1,.3916.3916l-.0557,14.085q0,.3633-.3076.3633h-1.624a.2722.2722,0,0,1-.3086-.3077v-1.6523q0-.3354-.252-.084a11.5344,11.5344,0,0,1-2.6875,1.8067,5.929,5.929,0,0,1-2.4082.5175,4.53,4.53,0,0,1-3.22-1.1758,4.05,4.05,0,0,1-1.2607-3.1084v-10.416a.3713.3713,0,0,1,.42-.42h1.5683c.2422,0,.3643.1309.3643.3916v9.8281a3.1341,3.1341,0,0,0,.77,2.2266,2.7489,2.7489,0,0,0,2.1143.8262A4.4961,4.4961,0,0,0,1104.3418,340.2661Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1116.1572,346.2866a4.0525,4.0525,0,0,1-3.0371.9375,9.8607,9.8607,0,0,1-1.5263-.0693c-.2149-.0469-.3223-.1445-.3223-.294v-1.2324q0-.3077.28-.3076l1.4277-.084a1.8085,1.8085,0,0,0,1.3018-.5185,2.4581,2.4581,0,0,0,.4062-1.6094V328.0161q0-.3911.3359-.3916h1.708c.2051,0,.3086.1221.3086.3643v15.2881A4.2931,4.2931,0,0,1,1116.1572,346.2866Zm.9668-21.8545c0,.3555-.1592.5323-.4765.5323h-1.6514q-.42,0-.4209-.4756v-2.3526a.4685.4685,0,0,1,.1123-.3213.3588.3588,0,0,1,.28-.1259h1.792a.3352.3352,0,0,1,.2656.1113.4539.4539,0,0,1,.0986.3086Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1124.9775,338.46a4.9612,4.9612,0,0,0,1.7784,1.82,4.6941,4.6941,0,0,0,2.4638.6718,4.9829,4.9829,0,0,0,4.3682-2.3242.4063.4063,0,0,1,.1953-.126.3328.3328,0,0,1,.1963.042l1.1758.5879q.2241.1407.0566.3926a6.8281,6.8281,0,0,1-2.6045,2.4492,7.8684,7.8684,0,0,1-3.6123.77,7.1185,7.1185,0,0,1-3.668-.9521,6.5986,6.5986,0,0,1-2.5332-2.6738,8.2836,8.2836,0,0,1-.91-3.9336,8.8944,8.8944,0,0,1,.9238-4.1582,6.6429,6.6429,0,0,1,2.5342-2.7305,6.9944,6.9944,0,0,1,3.626-.9522,6.8646,6.8646,0,0,1,3.542.8965,5.9885,5.9885,0,0,1,2.3652,2.6182,9.19,9.19,0,0,1,.8408,4.0732v.1123a.69.69,0,0,1-.084.378.3787.3787,0,0,1-.3359.1259h-10.7246c-.168,0-.252.1407-.252.42A4.9261,4.9261,0,0,0,1124.9775,338.46Zm7.91-4.4521a.6963.6963,0,0,0,.3916-.0693c.0566-.0469.084-.1543.084-.3223a5.0967,5.0967,0,0,0-.5176-2.2822,4.1018,4.1018,0,0,0-1.5127-1.68,4.221,4.221,0,0,0-2.31-.63,4.36,4.36,0,0,0-2.4921.7139,4.6948,4.6948,0,0,0-1.6241,1.8476,5.1431,5.1431,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1140.4189,342.1011V327.9888c0-.2422.1026-.3643.3086-.3643h1.68q.28,0,.28.3076v1.6241c0,.2246.0928.2529.28.0839a14.8855,14.8855,0,0,1,2.5752-1.7636,5.2531,5.2531,0,0,1,2.3808-.5323,4.4915,4.4915,0,0,1,2.59.7,3.4854,3.4854,0,0,1,1.3857,1.7647c.0371.0556.0742.084.1123.084a.6182.6182,0,0,0,.2793-.1407,14.0487,14.0487,0,0,1,2.6182-1.82,5.3608,5.3608,0,0,1,2.45-.5879,3.946,3.946,0,0,1,4.2842,4.2569v10.415q0,.4482-.42.4483h-1.5683a.3218.3218,0,0,1-.3643-.3633V332.105a3.1392,3.1392,0,0,0-.6719-2.1563,2.5569,2.5569,0,0,0-2.0156-.7558,4.2383,4.2383,0,0,0-2.1.5459,11.0016,11.0016,0,0,0-1.9043,1.3574,1.6655,1.6655,0,0,0-.3086.3926.9409.9409,0,0,0-.084.42v10.1074q0,.4482-.42.4483H1150.22a.3214.3214,0,0,1-.3642-.3633V332.105q0-2.9121-2.6885-2.9121a4.1919,4.1919,0,0,0-2.0576.5175,14.2534,14.2534,0,0,0-2.3379,1.75v10.584a.3571.3571,0,0,1-.42.42h-1.54Q1140.419,342.4644,1140.4189,342.1011Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1166.6553,347.0288c-.2422-.0381-.3643-.1406-.3643-.3086v-1.0918a.2951.2951,0,0,1,.1543-.28,1.5352,1.5352,0,0,1,.63-.0839h1.7646a1.8314,1.8314,0,0,0,1.2315-.4893,3.7,3.7,0,0,0,1.2041-2.6182,1.6667,1.6667,0,0,0-.1123-.6718l-5.3477-13.3838a.3977.3977,0,0,1-.0283-.169q0-.3075.3359-.3076h1.7364a.4676.4676,0,0,1,.4482.3076l4.1719,10.8643c.0557.168.1309.2519.2236.2519.1123,0,.2051-.1113.28-.3359l4.1162-10.78a.4336.4336,0,0,1,.3916-.3076h1.3164a.282.282,0,0,1,.252.126.2729.2729,0,0,1,.0283.2656l-6.16,15.7647a5.2154,5.2154,0,0,1-1.8759,2.6035,5.73,5.73,0,0,1-3.0528.7A10.5757,10.5757,0,0,1,1166.6553,347.0288Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M885.9424,375.43V361.3179q0-.3633.3086-.3633h1.68a.2726.2726,0,0,1,.3086.3076v1.624c0,.2061.0839.2334.2519.084a15.3089,15.3089,0,0,1,2.6738-1.7637,5.6472,5.6472,0,0,1,2.4776-.5322,4.5851,4.5851,0,0,1,3.3183,1.1475,4.0825,4.0825,0,0,1,1.1895,3.08v10.4443q0,.4482-.42.4482h-1.5674a.3217.3217,0,0,1-.3643-.3642v-9.9961a3.0242,3.0242,0,0,0-.7138-2.1563,2.8934,2.8934,0,0,0-2.1983-.7558,4.618,4.618,0,0,0-2.17.5185,13.58,13.58,0,0,0-2.4219,1.75v10.584a.3578.3578,0,0,1-.42.42h-1.54Q885.9423,375.7944,885.9424,375.43Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M906.2012,375.1079a6.5078,6.5078,0,0,1-2.4922-2.7021,9.3309,9.3309,0,0,1,.0283-7.9795,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6755,6.6755,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8818,4.0039,8.4794,8.4794,0,0,1-.8965,3.9482,6.7135,6.7135,0,0,1-2.5058,2.7158,6.9382,6.9382,0,0,1-3.6817.9795A7.0636,7.0636,0,0,1,906.2012,375.1079Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.07,4.07,0,0,0-3.4023-1.6094,4.135,4.135,0,0,0-3.4434,1.6094,8.1254,8.1254,0,0,0,0,8.6944,4.1807,4.1807,0,0,0,3.4717,1.5957A4.05,4.05,0,0,0,913.3691,372.728Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M941.1025,361.0806a.27.27,0,0,1,.0284.2656l-4.2842,14.084a.497.497,0,0,1-.5322.3642h-1.3438a.5364.5364,0,0,1-.5879-.3642l-3.332-10.4717c-.0557-.168-.1221-.252-.1963-.252-.0371,0-.084.084-.14.252l-3.164,10.4717a.497.497,0,0,1-.5322.3642h-1.4278a.5364.5364,0,0,1-.5879-.3642l-4.3965-14.0557a.3146.3146,0,0,1-.0273-.1406c0-.1865.1113-.2793.3359-.2793h1.6514a.4675.4675,0,0,1,.4482.3076l3.3321,10.8916c.0556.1875.1211.28.1963.28q.0834,0,.2236-.3076l3.3037-10.8926a.369.369,0,0,1,.3926-.2793h1.3154a.39.39,0,0,1,.42.3076l3.4444,10.8916c.0556.1875.1211.28.1963.28s.14-.1026.1963-.3076l3.22-10.8643a.4851.4851,0,0,1,.4756-.3076h1.12A.2817.2817,0,0,1,941.1025,361.0806Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M947.78,371.7905a4.9506,4.9506,0,0,0,1.7783,1.8194,4.697,4.697,0,0,0,2.4639.6718,4.9819,4.9819,0,0,0,4.3681-2.3232.4043.4043,0,0,1,.1963-.126.3223.3223,0,0,1,.1953.042l1.1768.5879c.1484.0938.168.2236.0556.3916a6.8294,6.8294,0,0,1-2.6035,2.45,7.8871,7.8871,0,0,1-3.6123.7695,7.1121,7.1121,0,0,1-3.6679-.9511,6.6029,6.6029,0,0,1-2.5342-2.6748,8.2914,8.2914,0,0,1-.91-3.9336,8.9083,8.9083,0,0,1,.9239-4.1582,6.652,6.652,0,0,1,2.5341-2.7295,6.988,6.988,0,0,1,3.626-.9522,6.8726,6.8726,0,0,1,3.542.8955,5.99,5.99,0,0,1,2.3662,2.6182,9.2072,9.2072,0,0,1,.84,4.0742v.1123a.69.69,0,0,1-.084.3779.3776.3776,0,0,1-.336.126H947.375q-.252,0-.252.42A4.93,4.93,0,0,0,947.78,371.7905Zm7.91-4.4521a.7.7,0,0,0,.3926-.07c.0557-.0459.084-.1543.084-.3223a5.08,5.08,0,0,0-.5186-2.2812,4.109,4.109,0,0,0-1.5117-1.6807,4.2234,4.2234,0,0,0-2.31-.63,4.3681,4.3681,0,0,0-2.4921.7139,4.6953,4.6953,0,0,0-1.6241,1.8486,5.1323,5.1323,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M973.1064,357.7622a.6062.6062,0,0,1-.1123.4063.508.508,0,0,1-.3916.1259h-1.624c-.28,0-.4209-.1582-.4209-.4765v-2.3516q0-.4482.3926-.4482h1.7637a.3828.3828,0,0,1,.28.1123.4173.4173,0,0,1,.1123.3076Zm-.1123,17.5557a.4923.4923,0,0,1-.1123.3779.5926.5926,0,0,1-.3916.0986h-1.4277a.487.487,0,0,1-.3359-.084.4315.4315,0,0,1-.085-.3085V361.3462q0-.3912.3369-.3916h1.708q.3076,0,.3076.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M988.8975,375.4019v-1.2041c0-.168-.0469-.252-.14-.252a.382.382,0,0,0-.2236.1406,8.8878,8.8878,0,0,1-1.834,1.4141,5.0931,5.0931,0,0,1-2.5625.5732,6.0747,6.0747,0,0,1-3.1914-.84,5.7484,5.7484,0,0,1-2.2129-2.5342,9.4286,9.4286,0,0,1-.8115-4.1016,9.6649,9.6649,0,0,1,.84-4.1435,6.67,6.67,0,0,1,2.3242-2.7861,5.9422,5.9422,0,0,1,3.3877-.9942,5.5823,5.5823,0,0,1,2.1846.3916,6.6408,6.6408,0,0,1,1.7354,1.0918.7133.7133,0,0,0,.3085.169c.0928,0,.14-.1221.14-.3643v-6.58c0-.2422.1025-.3642.3076-.3642h1.708c.2061,0,.3086.122.3086.3642v19.9639a.5474.5474,0,0,1-.084.35.48.48,0,0,1-.3642.0986H989.29A.3469.3469,0,0,1,988.8975,375.4019Zm-1.89-12.5293a4.2889,4.2889,0,0,0-1.9736-.462,4.1682,4.1682,0,0,0-3.416,1.5957,7.0023,7.0023,0,0,0-1.2881,4.5079,6.5218,6.5218,0,0,0,1.2734,4.3964,4.139,4.139,0,0,0,3.2627,1.4551,3.9755,3.9755,0,0,0,2.002-.5176,4.1079,4.1079,0,0,0,1.4277-1.3017,2.83,2.83,0,0,0,.5176-1.5684V364.23A7.2188,7.2188,0,0,0,987.0078,362.8726Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M999.1318,371.7905a4.9508,4.9508,0,0,0,1.7784,1.8194,4.6941,4.6941,0,0,0,2.4638.6718,4.982,4.982,0,0,0,4.3682-2.3232.4063.4063,0,0,1,.1953-.126.3253.3253,0,0,1,.1963.042l1.1758.5879c.1494.0938.1679.2236.0566.3916a6.8388,6.8388,0,0,1-2.6045,2.45,7.8813,7.8813,0,0,1-3.6123.7695,7.1179,7.1179,0,0,1-3.668-.9511,6.6013,6.6013,0,0,1-2.5332-2.6748,8.2808,8.2808,0,0,1-.91-3.9336,8.8973,8.8973,0,0,1,.9238-4.1582,6.6457,6.6457,0,0,1,2.5342-2.7295,6.9849,6.9849,0,0,1,3.626-.9522,6.8729,6.8729,0,0,1,3.542.8955,5.994,5.994,0,0,1,2.3652,2.6182,9.1947,9.1947,0,0,1,.8408,4.0742v.1123a.6893.6893,0,0,1-.084.3779.3785.3785,0,0,1-.3359.126H998.7256q-.252,0-.252.42A4.9315,4.9315,0,0,0,999.1318,371.7905Zm7.91-4.4521a.6939.6939,0,0,0,.3916-.07c.0566-.0459.084-.1543.084-.3223a5.0924,5.0924,0,0,0-.5176-2.2812,4.1044,4.1044,0,0,0-1.5127-1.6807,4.221,4.221,0,0,0-2.31-.63,4.3687,4.3687,0,0,0-2.4922.7139,4.7036,4.7036,0,0,0-1.624,1.8486,5.1431,5.1431,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1016.8,371.7905a4.9514,4.9514,0,0,0,1.7783,1.8194,4.697,4.697,0,0,0,2.4639.6718,4.982,4.982,0,0,0,4.3682-2.3232.4041.4041,0,0,1,.1962-.126.3227.3227,0,0,1,.1954.042l1.1767.5879c.1485.0938.168.2236.0557.3916a6.83,6.83,0,0,1-2.6035,2.45,7.8871,7.8871,0,0,1-3.6123.7695,7.1125,7.1125,0,0,1-3.668-.9511,6.6036,6.6036,0,0,1-2.5342-2.6748,8.2925,8.2925,0,0,1-.91-3.9336,8.9083,8.9083,0,0,1,.9238-4.1582,6.6516,6.6516,0,0,1,2.5342-2.7295,6.9873,6.9873,0,0,1,3.6259-.9522,6.8724,6.8724,0,0,1,3.542.8955,5.99,5.99,0,0,1,2.3662,2.6182,9.2072,9.2072,0,0,1,.84,4.0742v.1123a.6893.6893,0,0,1-.084.3779.3775.3775,0,0,1-.3359.126h-10.7237q-.2518,0-.2519.42A4.93,4.93,0,0,0,1016.8,371.7905Zm7.91-4.4521a.7.7,0,0,0,.3925-.07c.0557-.0459.084-.1543.084-.3223a5.08,5.08,0,0,0-.5185-2.2812,4.109,4.109,0,0,0-1.5117-1.6807,4.2236,4.2236,0,0,0-2.31-.63,4.3684,4.3684,0,0,0-2.4922.7139,4.6942,4.6942,0,0,0-1.624,1.8486,5.1311,5.1311,0,0,0-.5323,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1058.8975,361.0806a.27.27,0,0,1,.0283.2656l-4.2842,14.084a.497.497,0,0,1-.5322.3642h-1.3438a.5364.5364,0,0,1-.5879-.3642l-3.332-10.4717c-.0557-.168-.1221-.252-.1963-.252-.0371,0-.084.084-.14.252l-3.1641,10.4717a.4969.4969,0,0,1-.5322.3642h-1.4278a.5363.5363,0,0,1-.5878-.3642l-4.3965-14.0557a.3132.3132,0,0,1-.0274-.1406c0-.1865.1114-.2793.336-.2793h1.6513a.4677.4677,0,0,1,.4483.3076l3.332,10.8916c.0557.1875.1211.28.1963.28.0557,0,.13-.1026.2236-.3076l3.3037-10.8926a.369.369,0,0,1,.3926-.2793h1.3154a.39.39,0,0,1,.42.3076l3.4443,10.8916c.0557.1875.1211.28.1963.28s.14-.1026.1963-.3076l3.22-10.8643a.4852.4852,0,0,1,.4756-.3076h1.12A.282.282,0,0,1,1058.8975,361.0806Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1073.2754,375.1079a6.5078,6.5078,0,0,1-2.4922-2.7021,9.3319,9.3319,0,0,1,.0283-7.9795,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6748,6.6748,0,0,1,2.4922,2.7578,8.8327,8.8327,0,0,1,.8818,4.0039,8.4806,8.4806,0,0,1-.8964,3.9482,6.7145,6.7145,0,0,1-2.5059,2.7158,6.9379,6.9379,0,0,1-3.6816.9795A7.0639,7.0639,0,0,1,1073.2754,375.1079Zm7.168-2.38a8.31,8.31,0,0,0-.0284-8.708,4.07,4.07,0,0,0-3.4023-1.6094,4.135,4.135,0,0,0-3.4434,1.6094,8.1254,8.1254,0,0,0,0,8.6944,4.1807,4.1807,0,0,0,3.4717,1.5957A4.05,4.05,0,0,0,1080.4434,372.728Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1089.39,375.7944a.371.371,0,0,1-.4482-.4482V355.4106q0-.3925.3359-.3925h1.7364q.28,0,.2793.3925V362.27c0,.2061.0468.3086.1406.3086a.6211.6211,0,0,0,.28-.1406,6.3108,6.3108,0,0,1,4.48-1.7637,5.1613,5.1613,0,0,1,4.4658,2.1143,9.18,9.18,0,0,1,1.5263,5.5019,8.785,8.785,0,0,1-1.6943,5.6973,5.7062,5.7062,0,0,1-4.6894,2.0859,5.3334,5.3334,0,0,1-2.8282-.6855,5.97,5.97,0,0,1-1.7363-1.5264.2259.2259,0,0,0-.168-.1113c-.0752,0-.1211.0654-.14.1953l-.1406,1.4a.7221.7221,0,0,1-.1534.35.4885.4885,0,0,1-.3505.0986Zm1.9034-5.0127a3.098,3.098,0,0,0,.5742,1.8067,4.1644,4.1644,0,0,0,1.498,1.3154,4.0642,4.0642,0,0,0,1.9327.49,3.9437,3.9437,0,0,0,3.2334-1.5117,7.1272,7.1272,0,0,0,1.2177-4.5361,7.5981,7.5981,0,0,0-1.0918-4.41,3.6253,3.6253,0,0,0-3.164-1.5537,4.9987,4.9987,0,0,0-2.3379.56,5.9423,5.9423,0,0,0-1.8623,1.5127Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1114.4072,362.8442a5.1606,5.1606,0,0,0-2.3662-.5175,4.4344,4.4344,0,0,0-2.5195.6572,1.9942,1.9942,0,0,0-.9805,1.7226,1.6489,1.6489,0,0,0,.49,1.1622,4.1374,4.1374,0,0,0,1.7783.9091l3.5556,1.0645a5.58,5.58,0,0,1,2.7442,1.498,3.4177,3.4177,0,0,1,.8955,2.3662,3.586,3.586,0,0,1-.7832,2.2813,5.0488,5.0488,0,0,1-2.1563,1.54,9.08,9.08,0,0,1-6.9443-.3076,5.1858,5.1858,0,0,1-2.38-2.2256.4826.4826,0,0,1-.084-.2246.2535.2535,0,0,1,.14-.2236l1.0927-.6162c.1856-.1123.3262-.1026.42.0283a5.13,5.13,0,0,0,4.6758,2.4072,5.3322,5.3322,0,0,0,2.7294-.6855,1.9636,1.9636,0,0,0,1.0782-1.7217,1.6451,1.6451,0,0,0-.6436-1.3164,6.0137,6.0137,0,0,0-2.1006-.9522l-2.8838-.8115a5.9926,5.9926,0,0,1-2.9257-1.4844,3.2925,3.2925,0,0,1-.8819-2.3242,3.8769,3.8769,0,0,1,.7139-2.2959,4.5582,4.5582,0,0,1,2.0156-1.5537,8.3093,8.3093,0,0,1,6.3008.1543,5.37,5.37,0,0,1,2.2539,1.9033c.13.1875.1211.3184-.0283.3926l-1.1758.6436a.4242.4242,0,0,1-.168.0566.3836.3836,0,0,1-.2519-.1406A4.1914,4.1914,0,0,0,1114.4072,362.8442Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1121.6309,375.7241a.3753.3753,0,0,1-.0694-.2656v-.8125a.9067.9067,0,0,1,.0694-.3779,3.0332,3.0332,0,0,1,.2666-.4336l8.1474-10.9483.084-.1679c0-.0743-.0742-.1123-.2236-.1123h-7.42a.39.39,0,0,1-.2383-.0557q-.07-.0557-.07-.252v-1.0361q0-.3077.28-.3076h10.36c.168,0,.2519.0928.2519.2793v.8965a1.1921,1.1921,0,0,1-.28.7275l-8.1758,10.9766c-.0752.0937-.1026.1679-.084.2236s.0742.084.168.084h8.2314c.2061,0,.3086.084.3086.2519v1.0645c0,.2236-.1221.3359-.3643.3359h-10.9755A.3793.3793,0,0,1,1121.6309,375.7241Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1141.3848,376.0737a4.9835,4.9835,0,0,1-3.43-1.1054,3.7525,3.7525,0,0,1-1.2461-2.9541,4.0523,4.0523,0,0,1,1.89-3.5284,10.9567,10.9567,0,0,1,5.4737-1.5117l2.1845-.1679q.4761-.0558.4756-.42V365.63a3.13,3.13,0,0,0-.9375-2.3946,3.5523,3.5523,0,0,0-2.5342-.8818,4.74,4.74,0,0,0-4.0322,2.24.4511.4511,0,0,1-.168.1817.2688.2688,0,0,1-.2236-.0137l-1.5127-.5039a.1985.1985,0,0,1-.14-.1963.6526.6526,0,0,1,.084-.2519,5.8335,5.8335,0,0,1,2.3945-2.31,7.7337,7.7337,0,0,1,3.6816-.8262,6.1948,6.1948,0,0,1,4.2,1.2735,4.43,4.43,0,0,1,1.4561,3.5146v9.9121a.4888.4888,0,0,1-.084.3359.4386.4386,0,0,1-.3086.084h-1.3994q-.3369,0-.3926-.4482l-.0557-1.2881c-.0185-.13-.0654-.1963-.14-.1963a.3874.3874,0,0,0-.2529.1406A6.9757,6.9757,0,0,1,1141.3848,376.0737Zm4.9277-7.84-1.82.168a9.1012,9.1012,0,0,0-4.0176,1.0087,2.5881,2.5881,0,0,0-1.4141,2.3233,2.33,2.33,0,0,0,.8545,1.9043,3.4846,3.4846,0,0,0,2.2813.7,4.9086,4.9086,0,0,0,1.708-.3086,5,5,0,0,0,1.4844-.84,4.11,4.11,0,0,0,.9941-1.0781,2.1234,2.1234,0,0,0,.35-1.1055v-2.3242C1146.7324,368.3843,1146.5928,368.2339,1146.3125,368.2339Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1154.4883,375.43V361.3179q0-.3633.3076-.3633h1.624a.2726.2726,0,0,1,.3086.3076v1.82c0,.1113.0322.1778.0977.1953.0654.02.1357-.0175.21-.1113q2.1285-2.4916,4.34-2.4922.98,0,.98.3643v1.68c0,.1865-.0839.2617-.2519.2236a7.8977,7.8977,0,0,0-1.26-.084,3.9794,3.9794,0,0,0-1.7646.4766,5,5,0,0,0-1.5957,1.2314,2.3684,2.3684,0,0,0-.6446,1.54v9.2685a.3712.3712,0,0,1-.42.42h-1.54C1154.6182,375.7944,1154.4883,375.6733,1154.4883,375.43Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1165.3945,375.7241a.3753.3753,0,0,1-.07-.2656v-.8125a.9077.9077,0,0,1,.07-.3779,2.9276,2.9276,0,0,1,.2657-.4336l8.1484-10.9483.084-.1679c0-.0743-.0752-.1123-.2246-.1123h-7.42a.3868.3868,0,0,1-.2373-.0557q-.07-.0557-.07-.252v-1.0361c0-.2051.0928-.3076.28-.3076H1176.58c.1679,0,.2519.0928.2519.2793v.8965a1.1962,1.1962,0,0,1-.2793.7275l-8.1767,10.9766c-.0742.0937-.1026.1679-.084.2236s.0742.084.168.084h8.2324q.3077,0,.3076.2519v1.0645q0,.3354-.3633.3359H1165.66A.3751.3751,0,0,1,1165.3945,375.7241Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1183.7344,371.7905a4.9506,4.9506,0,0,0,1.7783,1.8194,4.6944,4.6944,0,0,0,2.4639.6718,4.9819,4.9819,0,0,0,4.3681-2.3232.4068.4068,0,0,1,.1953-.126.3252.3252,0,0,1,.1963.042l1.1758.5879c.1494.0938.168.2236.0567.3916a6.84,6.84,0,0,1-2.6045,2.45,7.8819,7.8819,0,0,1-3.6123.7695,7.1173,7.1173,0,0,1-3.668-.9511,6.6013,6.6013,0,0,1-2.5332-2.6748,8.2808,8.2808,0,0,1-.91-3.9336,8.8961,8.8961,0,0,1,.9239-4.1582,6.6455,6.6455,0,0,1,2.5341-2.7295,6.9854,6.9854,0,0,1,3.626-.9522,6.8726,6.8726,0,0,1,3.542.8955,5.9934,5.9934,0,0,1,2.3652,2.6182,9.1933,9.1933,0,0,1,.8409,4.0742v.1123a.69.69,0,0,1-.084.3779.3788.3788,0,0,1-.336.126h-10.7246q-.2518,0-.2519.42A4.9305,4.9305,0,0,0,1183.7344,371.7905Zm7.91-4.4521a.6936.6936,0,0,0,.3916-.07c.0567-.0459.084-.1543.084-.3223a5.0911,5.0911,0,0,0-.5176-2.2812,4.1044,4.1044,0,0,0-1.5127-1.6807,4.2209,4.2209,0,0,0-2.31-.63,4.3684,4.3684,0,0,0-2.4922.7139,4.702,4.702,0,0,0-1.624,1.8486,5.1432,5.1432,0,0,0-.5323,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M880.86,394.7036v1.1758c0,.168.0459.2519.14.2519a.383.383,0,0,0,.2246-.14,14.06,14.06,0,0,1,1.0352-.9521,4.9909,4.9909,0,0,1,1.3164-.7,5.6541,5.6541,0,0,1,2.0439-.336,5.9564,5.9564,0,0,1,3.15.8408,5.7056,5.7056,0,0,1,2.1836,2.52,9.5466,9.5466,0,0,1,.7979,4.1162,10.3959,10.3959,0,0,1-.7979,4.2558,5.8708,5.8708,0,0,1-5.6416,3.668,5.9461,5.9461,0,0,1-3.9766-1.4c-.1308-.13-.2334-.1953-.3076-.1953q-.1405,0-.1406.3633v5.208q0,.3648-.3076.3643h-1.7363q-.3077,0-.3077-.3643V394.7319a.4964.4964,0,0,1,.0977-.3359.4527.4527,0,0,1,.3506-.1123h1.4834Q880.86,394.2837,880.86,394.7036Zm1.8339,12.53a4.3611,4.3611,0,0,0,2.002.4619A4.105,4.105,0,0,0,888.07,406.1a7.22,7.22,0,0,0,1.2735-4.5928,6.4681,6.4681,0,0,0-1.26-4.3535,4.3818,4.3818,0,0,0-5.2637-.9521,4.0008,4.0008,0,0,0-1.414,1.2461,2.623,2.623,0,0,0-.5186,1.4843v6.916A6.956,6.956,0,0,0,882.6943,407.2339Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M896.6514,408.76V394.6479c0-.2421.1025-.3642.3086-.3642h1.624a.2719.2719,0,0,1,.3076.3076v1.82c0,.1123.0322.1778.0986.1963.0645.0186.1348-.0185.21-.1123q2.127-2.4917,4.34-2.4922.98,0,.98.3643v1.68c0,.1875-.084.2617-.2519.2246a7.7826,7.7826,0,0,0-1.26-.084,3.9706,3.9706,0,0,0-1.7637.4756,5.0173,5.0173,0,0,0-1.5966,1.2324,2.3669,2.3669,0,0,0-.6436,1.54v9.2676a.3709.3709,0,0,1-.42.42h-1.54Q896.6514,409.1235,896.6514,408.76Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M910.918,408.438a6.52,6.52,0,0,1-2.4922-2.7022,9.3329,9.3329,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.681,6.681,0,0,1,2.4922,2.7578,8.8366,8.8366,0,0,1,.8818,4.0049,8.4752,8.4752,0,0,1-.8965,3.9472,6.7009,6.7009,0,0,1-2.5058,2.7158,6.9307,6.9307,0,0,1-3.6817.9805A7.0729,7.0729,0,0,1,910.918,408.438Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0709,4.0709,0,0,0-3.4023-1.61,4.1358,4.1358,0,0,0-3.4434,1.61,8.1237,8.1237,0,0,0,0,8.6934,4.1787,4.1787,0,0,0,3.4717,1.5966A4.05,4.05,0,0,0,918.0859,406.0581Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M936.748,408.7319v-1.2041c0-.1679-.0468-.2519-.14-.2519-.0566,0-.1309.0469-.2246.14a8.7931,8.7931,0,0,1-1.834,1.4141,5.0784,5.0784,0,0,1-2.5615.5742,6.0876,6.0876,0,0,1-3.1924-.84,5.7459,5.7459,0,0,1-2.2119-2.5342,9.4141,9.4141,0,0,1-.8115-4.1016,9.6594,9.6594,0,0,1,.84-4.1445,6.666,6.666,0,0,1,2.3232-2.7861,5.9523,5.9523,0,0,1,3.3887-.9942,5.5585,5.5585,0,0,1,2.1836.3926,6.6176,6.6176,0,0,1,1.7363,1.0918.7112.7112,0,0,0,.3077.168c.0937,0,.1406-.1211.1406-.3643v-6.58c0-.2422.1015-.3633.3076-.3633h1.708q.3077,0,.3076.3633v19.9639a.5446.5446,0,0,1-.084.3506.4838.4838,0,0,1-.3632.0976H937.14A.3456.3456,0,0,1,936.748,408.7319Zm-1.89-12.53a4.3073,4.3073,0,0,0-1.9746-.462,4.171,4.171,0,0,0-3.416,1.5957,7.0128,7.0128,0,0,0-1.2881,4.5088,6.519,6.519,0,0,0,1.2744,4.3955,4.1379,4.1379,0,0,0,3.2617,1.4561,3.9806,3.9806,0,0,0,2.002-.5176,4.1015,4.1015,0,0,0,1.4277-1.3027,2.8234,2.8234,0,0,0,.5186-1.5674V397.56A7.2424,7.2424,0,0,0,934.8584,396.2017Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M952.05,406.9253a10.4342,10.4342,0,0,0,2.2539-1.8057V394.6753q0-.391.4483-.3916h1.54a.3464.3464,0,0,1,.3916.3916l-.0557,14.085q0,.3632-.3076.3632h-1.624a.2722.2722,0,0,1-.3086-.3076v-1.6523q0-.3355-.252-.084a11.53,11.53,0,0,1-2.6875,1.8066,5.9275,5.9275,0,0,1-2.4082.5176,4.53,4.53,0,0,1-3.22-1.1758A4.05,4.05,0,0,1,944.56,405.12v-10.416a.3712.3712,0,0,1,.42-.42h1.5684c.2421,0,.3642.1309.3642.3916v9.8281a3.1341,3.1341,0,0,0,.7695,2.2266,2.7489,2.7489,0,0,0,2.1143.8262A4.4961,4.4961,0,0,0,952.05,406.9253Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M975.248,408.8716c0,.168-.1035.2519-.3085.2519h-1.96a.5187.5187,0,0,1-.28-.0693.7438.7438,0,0,1-.2236-.2666l-4.2276-7.252c-.038-.0927-.0839-.1445-.1406-.1543s-.1211.0245-.1953.0987l-2.9687,2.9961a.7564.7564,0,0,0-.1954.56v3.6123a.4954.4954,0,0,1-.1123.378.6027.6027,0,0,1-.3916.0976h-1.4287a.4843.4843,0,0,1-.3359-.0839.4344.4344,0,0,1-.084-.3077V388.74q0-.3911.3086-.3916h1.708q.3354,0,.3359.3633v12.9922c0,.1865.0274.28.084.28s.1211-.0557.1953-.168l6.9161-7.1963a.8457.8457,0,0,1,.5879-.3359h1.708c.2246,0,.3359.0654.3359.1963a.8259.8259,0,0,1-.1953.3359l-4.4238,4.5078a.5374.5374,0,0,0-.1123.252.4812.4812,0,0,0,.0839.2236l5.2637,8.876A.2772.2772,0,0,1,975.248,408.8716Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M983.48,396.02c-.1494,0-.2236.084-.2236.252v8.8759a2.6728,2.6728,0,0,0,.42,1.7354,1.7412,1.7412,0,0,0,1.3721.4766H986.98a.2719.2719,0,0,1,.3076.3076l-.0273.9521c0,.1866-.1123.3086-.336.3643a16.3414,16.3414,0,0,1-2.6318.14,3.4841,3.4841,0,0,1-2.5762-.8261,3.6143,3.6143,0,0,1-.8125-2.6172v-9.3526c0-.205-.0937-.3076-.2793-.3076h-2.24c-.2061,0-.3086-.0937-.3086-.28V394.564a.248.248,0,0,1,.28-.28h2.38c.1308,0,.1962-.0742.1962-.2236l.3077-4.4522c0-.2422.1025-.3642.3086-.3642h1.3994c.2246,0,.3359.1308.3359.3925V394.06a.1981.1981,0,0,0,.2246.2236h3.5274q.309,0,.3086.2519V395.74q0,.28-.3086.28Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M993.9385,408.438a6.52,6.52,0,0,1-2.4922-2.7022,9.3339,9.3339,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.681,6.681,0,0,1,2.4922,2.7578,8.8366,8.8366,0,0,1,.8818,4.0049,8.4752,8.4752,0,0,1-.8965,3.9472,6.7009,6.7009,0,0,1-2.5058,2.7158,6.9307,6.9307,0,0,1-3.6817.9805A7.0729,7.0729,0,0,1,993.9385,408.438Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0709,4.0709,0,0,0-3.4023-1.61,4.1358,4.1358,0,0,0-3.4434,1.61,8.1237,8.1237,0,0,0,0,8.6934,4.1787,4.1787,0,0,0,3.4717,1.5966A4.05,4.05,0,0,0,1001.1064,406.0581Zm.2657-16.5342a.3926.3926,0,0,1,.0283.168c0,.1494-.1035.2519-.3086.3076l-6.4121,2.0166a.39.39,0,0,1-.168.0274c-.1308,0-.2236-.084-.2793-.252l-.2246-.6162a.3131.3131,0,0,1-.0273-.14c0-.1494.0556-.2334.1679-.2519l6.4678-2.6875a.162.162,0,0,1,.1123-.0283.22.22,0,0,1,.2236.1679Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1028.8408,394.41a.2724.2724,0,0,1,.0274.2656l-4.2832,14.085a.498.498,0,0,1-.5323.3632h-1.3447a.5374.5374,0,0,1-.5879-.3632l-3.332-10.4727c-.0557-.168-.1211-.252-.1953-.252-.0381,0-.084.084-.1407.252l-3.164,10.4727a.4961.4961,0,0,1-.5313.3632h-1.4287a.5368.5368,0,0,1-.5879-.3632l-4.3955-14.0567a.2994.2994,0,0,1-.0283-.14q0-.28.3359-.28h1.6524a.4675.4675,0,0,1,.4482.3076l3.3321,10.8926c.0556.1865.1211.28.1953.28.0566,0,.1308-.1026.2246-.3086l3.3037-10.8916a.3685.3685,0,0,1,.3916-.28h1.3164a.3907.3907,0,0,1,.42.3076l3.4444,10.8926c.0556.1865.1211.28.1953.28s.1406-.1026.1963-.3086l3.22-10.8643a.4863.4863,0,0,1,.4766-.3076h1.12A.284.284,0,0,1,1028.8408,394.41Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1030.4355,413.0161a.4344.4344,0,0,1,0-.3086l1.2608-3.1357a.6529.6529,0,0,0,.084-.252c0-.1308-.1035-.1963-.3086-.1963h-.3633c-.2432,0-.3643-.1113-.3643-.3359v-2.4922c0-.2051.0928-.3076.28-.3076h2.3233a.2479.2479,0,0,1,.28.28v2.2959a.6443.6443,0,0,1-.084.3359l-2.0439,3.92a.5874.5874,0,0,1-.56.3076h-.3086A.2016.2016,0,0,1,1030.4355,413.0161Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1050.0078,396.02c-.15,0-.2246.084-.2246.252v8.8759a2.6789,2.6789,0,0,0,.42,1.7354,1.7426,1.7426,0,0,0,1.3721.4766h1.9326a.2719.2719,0,0,1,.3076.3076l-.0283.9521c0,.1866-.1113.3086-.3359.3643a16.3258,16.3258,0,0,1-2.6319.14,3.4856,3.4856,0,0,1-2.5761-.8261,3.6183,3.6183,0,0,1-.8116-2.6172v-9.3526q0-.3075-.28-.3076h-2.24q-.3075,0-.3076-.28V394.564a.2476.2476,0,0,1,.28-.28h2.38c.13,0,.1953-.0742.1953-.2236l.3086-4.4522c0-.2422.1025-.3642.3076-.3642h1.4q.3354,0,.3359.3925V394.06a.1974.1974,0,0,0,.2237.2236h3.5283q.3077,0,.3076.2519V395.74q0,.28-.3076.28Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1060.1855,405.12a4.96,4.96,0,0,0,1.7784,1.82,4.6958,4.6958,0,0,0,2.4638.6719,4.9829,4.9829,0,0,0,4.3682-2.3242.4039.4039,0,0,1,.1963-.126.3289.3289,0,0,1,.1953.042l1.1768.5879c.1484.0938.1679.2246.0556.3926a6.8188,6.8188,0,0,1-2.6035,2.4492,7.8742,7.8742,0,0,1-3.6123.77,7.1134,7.1134,0,0,1-3.668-.9521,6.6,6.6,0,0,1-2.5341-2.6739,8.2939,8.2939,0,0,1-.91-3.9336,8.9072,8.9072,0,0,1,.9238-4.1582,6.6492,6.6492,0,0,1,2.5342-2.73,6.997,6.997,0,0,1,3.626-.9522,6.8636,6.8636,0,0,1,3.542.8965,5.9846,5.9846,0,0,1,2.3662,2.6182,9.2027,9.2027,0,0,1,.84,4.0732v.1123a.6893.6893,0,0,1-.084.3779.3773.3773,0,0,1-.3359.126H1059.78c-.168,0-.252.1406-.252.42A4.9253,4.9253,0,0,0,1060.1855,405.12Zm7.91-4.4521a.7016.7016,0,0,0,.3926-.0694.432.432,0,0,0,.084-.3222,5.0838,5.0838,0,0,0-.5186-2.2822,4.1063,4.1063,0,0,0-1.5117-1.68,4.2225,4.2225,0,0,0-2.31-.63,4.3617,4.3617,0,0,0-2.4922.7138,4.6872,4.6872,0,0,0-1.624,1.8477,5.1311,5.1311,0,0,0-.5322,2.4219Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1085.2734,394.9556a7.0623,7.0623,0,0,1,2.3106,2.52l.0556.2236c0,.1123-.0654.1777-.1953.1963l-1.54.3643-.084.0273c-.0937,0-.1875-.0742-.28-.2236a7.6079,7.6079,0,0,0-1.7216-1.7647,3.2939,3.2939,0,0,0-1.8907-.56,4.2136,4.2136,0,0,0-3.4716,1.61,7.9905,7.9905,0,0,0,.0136,8.7217,4.297,4.297,0,0,0,3.5147,1.5957,4.15,4.15,0,0,0,2.3095-.6436,6.0028,6.0028,0,0,0,1.8057-1.9883c.0567-.0927.1123-.1445.168-.1543a.3328.3328,0,0,1,.1963.042l1.2041.4483c.13.0566.1679.1777.1123.3642a7.29,7.29,0,0,1-2.38,2.6455,6.2974,6.2974,0,0,1-3.6123,1.0225,6.8959,6.8959,0,0,1-3.6406-.9658,6.646,6.646,0,0,1-2.4912-2.7022,8.54,8.54,0,0,1-.8965-3.9755,8.8,8.8,0,0,1,.8965-4.0186,6.638,6.638,0,0,1,2.5058-2.7578,6.836,6.836,0,0,1,3.626-.9805A6.19,6.19,0,0,1,1085.2734,394.9556Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1092.2314,408.76V388.7114q0-.3633.3086-.3633h1.6514c.2051,0,.3086.1114.3086.336V396.16c0,.206.084.2334.252.084a14.3958,14.3958,0,0,1,2.7158-1.7364,5.9328,5.9328,0,0,1,2.4638-.5039,4.5224,4.5224,0,0,1,3.3184,1.1485,4.173,4.173,0,0,1,1.1611,3.1084v10.415q0,.4482-.42.4482h-1.5674a.3214.3214,0,0,1-.3642-.3632v-9.9961a3.0222,3.0222,0,0,0-.7139-2.1563,2.8934,2.8934,0,0,0-2.1982-.7558,4.4632,4.4632,0,0,0-2.1416.5175,15.01,15.01,0,0,0-2.4219,1.75v10.584c0,.28-.15.42-.4483.42h-1.54A.3214.3214,0,0,1,1092.2314,408.76Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1109.9561,408.76V394.6479c0-.2421.1025-.3642.3076-.3642h1.6806a.2719.2719,0,0,1,.3077.3076v1.624q0,.3091.2519.084a15.401,15.401,0,0,1,2.6738-1.7637,5.6485,5.6485,0,0,1,2.4786-.5322,4.5788,4.5788,0,0,1,3.3173,1.1485,4.0791,4.0791,0,0,1,1.19,3.08v10.4434q0,.4482-.42.4482h-1.5683a.3211.3211,0,0,1-.3633-.3632v-9.9961a3.0184,3.0184,0,0,0-.7148-2.1563,2.89,2.89,0,0,0-2.1973-.7558,4.6292,4.6292,0,0,0-2.17.5175,13.5817,13.5817,0,0,0-2.4229,1.75v10.584a.3569.3569,0,0,1-.42.42h-1.54C1110.0859,409.1235,1109.9561,409.0024,1109.9561,408.76Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1130.2139,408.438a6.5189,6.5189,0,0,1-2.4922-2.7022,9.3329,9.3329,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.681,6.681,0,0,1,2.4922,2.7578,8.8366,8.8366,0,0,1,.8818,4.0049,8.4752,8.4752,0,0,1-.8965,3.9472,6.7009,6.7009,0,0,1-2.5058,2.7158,6.9307,6.9307,0,0,1-3.6817.9805A7.0729,7.0729,0,0,1,1130.2139,408.438Zm7.1679-2.38a8.31,8.31,0,0,0-.0283-8.708,4.0709,4.0709,0,0,0-3.4023-1.61,4.1358,4.1358,0,0,0-3.4434,1.61,8.1237,8.1237,0,0,0,0,8.6934,4.1787,4.1787,0,0,0,3.4717,1.5966A4.05,4.05,0,0,0,1137.3818,406.0581Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1148.26,408.6479a.4918.4918,0,0,1-.1123.378.5994.5994,0,0,1-.3916.0976H1146.3a.4824.4824,0,0,1-.3359-.0839.4344.4344,0,0,1-.084-.3077l.0283-19.9922c0-.2607.1113-.3916.3359-.3916h1.68q.3356,0,.336.3633Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1156.4775,408.438a6.5251,6.5251,0,0,1-2.4921-2.7022,9.3329,9.3329,0,0,1,.0283-7.98,7.0986,7.0986,0,0,1,9.8838-2.7578,6.6733,6.6733,0,0,1,2.4921,2.7578,8.8367,8.8367,0,0,1,.8819,4.0049,8.4871,8.4871,0,0,1-.8955,3.9472,6.7079,6.7079,0,0,1-2.5059,2.7158,6.9339,6.9339,0,0,1-3.6826.9805A7.076,7.076,0,0,1,1156.4775,408.438Zm7.168-2.38a8.31,8.31,0,0,0-.0283-8.708,4.068,4.068,0,0,0-3.4014-1.61,4.1375,4.1375,0,0,0-3.4443,1.61,8.1286,8.1286,0,0,0,0,8.6934,4.1791,4.1791,0,0,0,3.4726,1.5966A4.0482,4.0482,0,0,0,1163.6455,406.0581Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1186.0879,394.48v.9795a.5559.5559,0,0,1-.126.4062.5279.5279,0,0,1-.4336.0977,3.4583,3.4583,0,0,0-1.456.2519q-.6725.2813-.5606.6446a2.9742,2.9742,0,0,0,.168.42,3.5062,3.5062,0,0,1,.2246.6855,3.7022,3.7022,0,0,1,.084.7989,3.9936,3.9936,0,0,1-1.5547,3.248,7.0347,7.0347,0,0,1-4.5215,1.26,8.6382,8.6382,0,0,0-2.8564.3359q-.8673.3354-.8672.8682,0,.419.5879.63a7.8867,7.8867,0,0,0,1.96.2939l3.6114.1963a5.9528,5.9528,0,0,1,3.5839,1.1475,3.4258,3.4258,0,0,1,1.1768,2.7441,3.769,3.769,0,0,1-1.8623,3.332,9.7706,9.7706,0,0,1-5.3057,1.2041,11.4883,11.4883,0,0,1-5.4179-1.0224,3.1928,3.1928,0,0,1-1.8623-2.9258q0-1.875,2.6318-3.1641c.2051-.0928.2051-.1963,0-.3076a1.7912,1.7912,0,0,1-1.2881-1.6523,1.643,1.643,0,0,1,.5742-1.1758,4.3076,4.3076,0,0,1,1.6377-.9238q.1407-.0279.1406-.1407a.198.198,0,0,0-.1406-.1953,4.6664,4.6664,0,0,1-1.8193-1.5547,3.956,3.956,0,0,1-.5889-2.1972,4.0971,4.0971,0,0,1,1.6524-3.4444,7.5754,7.5754,0,0,1,4.6757-1.26,6.4418,6.4418,0,0,1,4.2,1.26.6111.6111,0,0,0,.28.14.6015.6015,0,0,0,.3076-.14,5.1717,5.1717,0,0,1,1.2461-.91,2.9589,2.9589,0,0,1,1.3858-.378Q1186.0875,394.0317,1186.0879,394.48Zm-10.64,12.8242a2.3273,2.3273,0,0,0-1.1484.2939,3.1059,3.1059,0,0,0-1.0918.9092,2.0794,2.0794,0,0,0-.4482,1.2881,2.2343,2.2343,0,0,0,1.372,2.0586,9.0715,9.0715,0,0,0,4.0606.7139q4.7315,0,4.7314-2.8a1.9152,1.9152,0,0,0-.6582-1.54,3.6241,3.6241,0,0,0-2.1416-.6445Zm5.3614-6.3008a2.7491,2.7491,0,0,0,.9668-2.2676,2.9331,2.9331,0,0,0-.98-2.3525,4.3241,4.3241,0,0,0-2.8838-.84,4.408,4.408,0,0,0-2.8838.8544,2.8654,2.8654,0,0,0-1.0361,2.3379,2.6928,2.6928,0,0,0,1.0215,2.2539,4.6147,4.6147,0,0,0,2.8984.7979A4.5227,4.5227,0,0,0,1180.81,401.0034Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1192.8633,391.0913a.6.6,0,0,1-.1123.4063.5052.5052,0,0,1-.3916.1259h-1.624q-.42,0-.42-.4756v-2.3525c0-.2978.13-.4473.3916-.4473h1.7647a.38.38,0,0,1,.2793.1114.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5566a.4956.4956,0,0,1-.1114.378.6038.6038,0,0,1-.3925.0976h-1.4278a.4827.4827,0,0,1-.3359-.0839.4344.4344,0,0,1-.084-.3077V394.6753q0-.391.336-.3916h1.708c.205,0,.3076.1221.3076.3642Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1200.9561,391.0913a.6049.6049,0,0,1-.1123.4063.5082.5082,0,0,1-.3917.1259h-1.624q-.42,0-.4209-.4756v-2.3525q0-.4467.3926-.4473h1.7637a.3823.3823,0,0,1,.28.1114.42.42,0,0,1,.1123.3086Zm-.1123,17.5566a.4954.4954,0,0,1-.1124.378.599.599,0,0,1-.3916.0976h-1.4277a.4824.4824,0,0,1-.3359-.0839.4292.4292,0,0,1-.085-.3077V394.6753q0-.391.3369-.3916h1.708c.2051,0,.3077.1221.3077.3642Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M949.74,424.42a.6.6,0,0,1-.1123.4063.505.505,0,0,1-.3916.1259h-1.624q-.42,0-.42-.4755v-2.3526c0-.2978.13-.4473.3916-.4473h1.7646a.38.38,0,0,1,.2793.1114.4162.4162,0,0,1,.1123.3086Zm-.1123,17.5567a.4958.4958,0,0,1-.1113.3779.6043.6043,0,0,1-.3926.0976h-1.4277a.4824.4824,0,0,1-.3359-.0839.4344.4344,0,0,1-.084-.3077V428.0044q0-.3911.3359-.3916h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M961.57,442.3833a.3794.3794,0,0,1-.07-.2666v-.8115a.9069.9069,0,0,1,.07-.378,2.99,2.99,0,0,1,.2656-.4345l8.1485-10.9483.084-.1679c0-.0743-.0752-.1114-.2246-.1114h-7.42a.3794.3794,0,0,1-.2373-.0566q-.07-.0557-.07-.252V427.92c0-.2051.0928-.3076.28-.3076h10.3594q.2518,0,.2519.28v.8955a1.1968,1.1968,0,0,1-.2793.7285l-8.1767,10.9756c-.0743.0937-.1026.1679-.084.2246s.0742.084.1679.084h8.2325q.3075,0,.3076.2519v1.0635c0,.2246-.1211.3359-.3633.3359H961.8359A.3755.3755,0,0,1,961.57,442.3833Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M981.3242,442.7329a4.98,4.98,0,0,1-3.43-1.1064,3.751,3.751,0,0,1-1.2461-2.9532,4.0509,4.0509,0,0,1,1.89-3.5283,10.9633,10.9633,0,0,1,5.4746-1.5117l2.1836-.168c.3174-.0381.4766-.1777.4766-.4209v-.7558a3.1289,3.1289,0,0,0-.9385-2.3936,3.5491,3.5491,0,0,0-2.5342-.8818,4.7379,4.7379,0,0,0-4.0313,2.2392.47.47,0,0,1-.1679.1827.2759.2759,0,0,1-.2246-.0147l-1.5118-.5039a.1964.1964,0,0,1-.14-.1953.6273.6273,0,0,1,.084-.252,5.8283,5.8283,0,0,1,2.3935-2.31,7.7515,7.7515,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2744,4.4252,4.4252,0,0,1,1.456,3.5137v9.9121a.4875.4875,0,0,1-.084.336.4311.4311,0,0,1-.3076.0839h-1.4q-.3354,0-.3916-.4482l-.0566-1.2871c-.0186-.1309-.0654-.1963-.14-.1963a.3862.3862,0,0,0-.2519.14A6.9772,6.9772,0,0,1,981.3242,442.7329Zm4.9287-7.84-1.82.1679a9.1124,9.1124,0,0,0-4.0176,1.0078,2.5878,2.5878,0,0,0-1.414,2.3243,2.3316,2.3316,0,0,0,.8535,1.9043,3.4888,3.4888,0,0,0,2.2822.6992,4.9385,4.9385,0,0,0,1.708-.3076,5.015,5.015,0,0,0,1.4834-.84,4.0939,4.0939,0,0,0,.9942-1.0781,2.1284,2.1284,0,0,0,.3506-1.1064v-2.3233C986.6729,435.0425,986.5322,434.8931,986.2529,434.8931Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M994.4277,442.0894V427.9771c0-.2422.1026-.3643.3086-.3643H996.36a.2719.2719,0,0,1,.3076.3076v1.82c0,.1123.0322.1778.0986.1963.0645.0186.1348-.0185.21-.1123q2.1269-2.4917,4.34-2.4922.98,0,.9795.3643v1.68c0,.1875-.084.2617-.252.2246a7.78,7.78,0,0,0-1.26-.084,3.9706,3.9706,0,0,0-1.7637.4756,5.0178,5.0178,0,0,0-1.5967,1.2324,2.3667,2.3667,0,0,0-.6435,1.54v9.2676a.3709.3709,0,0,1-.42.42h-1.54Q994.4278,442.4526,994.4277,442.0894Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1005.334,442.3833a.3794.3794,0,0,1-.07-.2666v-.8115a.9069.9069,0,0,1,.07-.378,2.99,2.99,0,0,1,.2656-.4345l8.1484-10.9483.084-.1679c0-.0743-.0752-.1114-.2246-.1114h-7.42a.3794.3794,0,0,1-.2373-.0566q-.07-.0557-.07-.252V427.92c0-.2051.0928-.3076.28-.3076H1016.52q.252,0,.252.28v.8955a1.1974,1.1974,0,0,1-.2793.7285l-8.1768,10.9756c-.0742.0937-.1025.1679-.084.2246s.0743.084.168.084h8.2324q.3077,0,.3077.2519v1.0635c0,.2246-.1211.3359-.3633.3359H1005.6A.3755.3755,0,0,1,1005.334,442.3833Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1033.208,445.3647q.1113,0,.1113.1963v.8116a.352.352,0,0,1-.1679.3086,4.0089,4.0089,0,0,1-1.05.3359,6.9554,6.9554,0,0,1-1.3858.14,3.158,3.158,0,0,1-2.0859-.6015,2.0618,2.0618,0,0,1-.7139-1.666,2.1908,2.1908,0,0,1,.6016-1.5264,4.8077,4.8077,0,0,1,2.0586-1.1338.8906.8906,0,0,1-.0567-.2246l-.0556-1.2871c-.0186-.1309-.0655-.1963-.14-.1963a.39.39,0,0,0-.2529.14,6.9766,6.9766,0,0,1-4.9834,2.0723,4.9794,4.9794,0,0,1-3.43-1.1064,3.7506,3.7506,0,0,1-1.2461-2.9532,4.0509,4.0509,0,0,1,1.89-3.5283,10.9559,10.9559,0,0,1,5.4736-1.5117l2.1846-.168c.3173-.0381.4755-.1777.4755-.4209v-.7558a3.1282,3.1282,0,0,0-.9375-2.3936,3.552,3.552,0,0,0-2.5341-.8818,4.74,4.74,0,0,0-4.0323,2.2392.4626.4626,0,0,1-.1679.1827.2738.2738,0,0,1-.2237-.0147l-1.5127-.5039a.1976.1976,0,0,1-.14-.1953.6439.6439,0,0,1,.084-.252,5.8363,5.8363,0,0,1,2.3945-2.31,7.7459,7.7459,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2744,4.4282,4.4282,0,0,1,1.4561,3.5137v9.9121a.4875.4875,0,0,1-.084.336.4342.4342,0,0,1-.3086.0839h-.0273a7.5919,7.5919,0,0,0-1.8487,1.0918,1.5418,1.5418,0,0,0-.56,1.1768.9466.9466,0,0,0,.3359.8115,1.7836,1.7836,0,0,0,1.0635.252,4.23,4.23,0,0,0,.9941-.1114,3.5676,3.5676,0,0,0,.7422-.2529A1.1635,1.1635,0,0,1,1033.208,445.3647Zm-2.7725-10.0234q0-.4482-.42-.4482l-1.82.1679a9.1162,9.1162,0,0,0-4.0176,1.0078,2.59,2.59,0,0,0-1.414,2.3243,2.33,2.33,0,0,0,.8545,1.9043,3.484,3.484,0,0,0,2.2812.6992,4.93,4.93,0,0,0,1.708-.3076,5.02,5.02,0,0,0,1.4844-.84,4.11,4.11,0,0,0,.9941-1.0781,2.1265,2.1265,0,0,0,.35-1.1064Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1048.3555,442.061v-1.2041c0-.1679-.0469-.2519-.14-.2519-.0566,0-.1308.0469-.2246.14a8.7931,8.7931,0,0,1-1.834,1.4141,5.0784,5.0784,0,0,1-2.5615.5742,6.0876,6.0876,0,0,1-3.1924-.84,5.7459,5.7459,0,0,1-2.2119-2.5342,9.4141,9.4141,0,0,1-.8115-4.1016,9.6594,9.6594,0,0,1,.84-4.1445,6.6655,6.6655,0,0,1,2.3233-2.7861,5.9525,5.9525,0,0,1,3.3886-.9942,5.5585,5.5585,0,0,1,2.1836.3926,6.6181,6.6181,0,0,1,1.7364,1.0918.71.71,0,0,0,.3076.168c.0937,0,.1406-.1211.1406-.3643v-6.58c0-.2422.1016-.3633.3076-.3633h1.708q.3077,0,.3076.3633v19.9639a.5451.5451,0,0,1-.0839.3506.4842.4842,0,0,1-.3633.0976h-1.4287A.3456.3456,0,0,1,1048.3555,442.061Zm-1.89-12.53a4.3073,4.3073,0,0,0-1.9746-.462,4.1709,4.1709,0,0,0-3.416,1.5958,7.0123,7.0123,0,0,0-1.2881,4.5087,6.519,6.519,0,0,0,1.2744,4.3955,4.1377,4.1377,0,0,0,3.2617,1.4561,3.9791,3.9791,0,0,0,2.002-.5176,4.1015,4.1015,0,0,0,1.4277-1.3027,2.8234,2.8234,0,0,0,.5186-1.5674v-6.748A7.2424,7.2424,0,0,0,1046.4658,429.5308Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1055.1455,442.3833a.38.38,0,0,1-.0693-.2666v-.8115a.9076.9076,0,0,1,.0693-.378,3.1129,3.1129,0,0,1,.2666-.4345l8.1475-10.9483.084-.1679c0-.0743-.0743-.1114-.2237-.1114H1056a.3828.3828,0,0,1-.2383-.0566q-.07-.0557-.07-.252V427.92c0-.2051.0938-.3076.28-.3076h10.36q.252,0,.252.28v.8955a1.1929,1.1929,0,0,1-.28.7285l-8.1758,10.9756c-.0752.0937-.1025.1679-.084.2246s.0743.084.168.084h8.2315q.309,0,.3086.2519v1.0635c0,.2246-.1221.3359-.3643.3359h-10.9756A.38.38,0,0,1,1055.1455,442.3833Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1074.8994,442.7329a4.98,4.98,0,0,1-3.43-1.1064,3.751,3.751,0,0,1-1.2461-2.9532,4.0509,4.0509,0,0,1,1.89-3.5283,10.9563,10.9563,0,0,1,5.4736-1.5117l2.1846-.168c.3174-.0381.4756-.1777.4756-.4209v-.7558a3.1282,3.1282,0,0,0-.9375-2.3936,3.5523,3.5523,0,0,0-2.5342-.8818,4.7392,4.7392,0,0,0-4.0322,2.2392.4627.4627,0,0,1-.168.1827.2735.2735,0,0,1-.2236-.0147l-1.5127-.5039a.1975.1975,0,0,1-.14-.1953.6439.6439,0,0,1,.084-.252,5.8369,5.8369,0,0,1,2.3945-2.31,7.7459,7.7459,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2744,4.4284,4.4284,0,0,1,1.456,3.5137v9.9121a.4875.4875,0,0,1-.084.336.434.434,0,0,1-.3086.0839h-1.3994q-.3369,0-.3925-.4482l-.0557-1.2871c-.0186-.1309-.0654-.1963-.14-.1963a.39.39,0,0,0-.2529.14A6.9766,6.9766,0,0,1,1074.8994,442.7329Zm4.9277-7.84-1.82.1679a9.1153,9.1153,0,0,0-4.0175,1.0078,2.59,2.59,0,0,0-1.4141,2.3243,2.33,2.33,0,0,0,.8545,1.9043,3.484,3.484,0,0,0,2.2812.6992,4.93,4.93,0,0,0,1.708-.3076,5.02,5.02,0,0,0,1.4844-.84,4.1109,4.1109,0,0,0,.9942-1.0781,2.1265,2.1265,0,0,0,.35-1.1064v-2.3233Q1080.2471,434.8931,1079.8271,434.8931Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1088.0029,442.0894V427.9771c0-.2422.1026-.3643.3076-.3643h1.6807a.2719.2719,0,0,1,.3076.3076v1.624q0,.3091.252.084a15.401,15.401,0,0,1,2.6738-1.7637,5.6483,5.6483,0,0,1,2.4785-.5322,4.579,4.579,0,0,1,3.3174,1.1485,4.0791,4.0791,0,0,1,1.19,3.08v10.4434q0,.4482-.42.4482h-1.5683a.3211.3211,0,0,1-.3633-.3632v-9.9961a3.018,3.018,0,0,0-.7149-2.1563,2.8894,2.8894,0,0,0-2.1972-.7558,4.63,4.63,0,0,0-2.17.5175,13.5816,13.5816,0,0,0-2.4228,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C1088.1328,442.4526,1088.0029,442.3315,1088.0029,442.0894Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1108.2188,424.42a.6.6,0,0,1-.1124.4063.505.505,0,0,1-.3916.1259h-1.624q-.42,0-.42-.4755v-2.3526c0-.2978.13-.4473.3916-.4473h1.7646a.38.38,0,0,1,.2793.1114.4162.4162,0,0,1,.1124.3086Zm-.1124,17.5567a.4958.4958,0,0,1-.1113.3779.6041.6041,0,0,1-.3926.0976h-1.4277a.4824.4824,0,0,1-.3359-.0839.4344.4344,0,0,1-.084-.3077V428.0044q0-.3911.3359-.3916h1.708c.2051,0,.3076.1221.3076.3643Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1117.4863,442.7329a4.98,4.98,0,0,1-3.43-1.1064,3.751,3.751,0,0,1-1.2461-2.9532,4.0509,4.0509,0,0,1,1.89-3.5283,10.9636,10.9636,0,0,1,5.4746-1.5117l2.1836-.168c.3174-.0381.4766-.1777.4766-.4209v-.7558a3.1289,3.1289,0,0,0-.9385-2.3936,3.549,3.549,0,0,0-2.5342-.8818,4.7376,4.7376,0,0,0-4.0312,2.2392.47.47,0,0,1-.168.1827.2759.2759,0,0,1-.2246-.0147l-1.5117-.5039a.1963.1963,0,0,1-.14-.1953.6289.6289,0,0,1,.084-.252,5.8283,5.8283,0,0,1,2.3935-2.31,7.7515,7.7515,0,0,1,3.6817-.8262,6.19,6.19,0,0,1,4.2,1.2744,4.4252,4.4252,0,0,1,1.456,3.5137v9.9121a.4881.4881,0,0,1-.0839.336.4316.4316,0,0,1-.3077.0839h-1.4q-.3354,0-.3916-.4482l-.0566-1.2871c-.0185-.1309-.0654-.1963-.14-.1963a.3861.3861,0,0,0-.252.14A6.9772,6.9772,0,0,1,1117.4863,442.7329Zm4.9287-7.84-1.82.1679a9.1124,9.1124,0,0,0-4.0176,1.0078,2.5878,2.5878,0,0,0-1.414,2.3243,2.3316,2.3316,0,0,0,.8535,1.9043,3.4888,3.4888,0,0,0,2.2822.6992,4.9381,4.9381,0,0,0,1.708-.3076,5.015,5.015,0,0,0,1.4834-.84,4.0939,4.0939,0,0,0,.9942-1.0781,2.1284,2.1284,0,0,0,.3506-1.1064v-2.3233C1122.835,435.0425,1122.6943,434.8931,1122.415,434.8931Z" transform="translate(-58.8798 50.5781)"/>
        <path class="cls-3" d="M1132.7744,442.0044a.4514.4514,0,0,1-.1113.3506.6043.6043,0,0,1-.3926.0976h-1.96a.4854.4854,0,0,1-.3212-.0839.3874.3874,0,0,1-.0987-.3077v-2.4082c0-.2422.1026-.3642.3086-.3642h2.2959q.28,0,.2793.3359Z" transform="translate(-58.8798 50.5781)"/>
      </g>
    </g>
  </g>
  <g id="rozwoj" class="our-values__text">
    <g>
      <path class="cls-2" d="M797.3627,589.3816c0,116.1624,94.5077,210.67,210.67,210.67s210.6641-94.5077,210.6641-210.67-94.5023-210.67-210.6641-210.67S797.3627,473.22,797.3627,589.3816Z" transform="translate(-58.8798 50.5781)"/>
      <text class="cls-7" transform="translate(794.2975 640.3602)">K<tspan class="cls-8" x="20.2437" y="0">ażde</tspan><tspan class="cls-9" x="87.7236" y="0">g</tspan><tspan class="cls-8" x="105.7554" y="0">o dnia chcemy</tspan><tspan class="cls-8"><tspan x="68.459" y="33.3291">się </tspan><tspan class="cls-10" x="117.375" y="33.3291">r</tspan><tspan x="129.1353" y="33.3291">ozwijać.</tspan></tspan></text>
    </g>
  </g>
  <g id="partnerstwo-plus" class="our-values__btn">
    <circle class="cls-2" cx="517.7475" cy="538.8776" r="47.9496" transform="translate(-86.4324 1100.4344) rotate(-89.2846)"/>
    <polygon class="cls-3" points="461.141 568.994 456.594 568.994 456.594 587.182 438.406 587.182 438.406 591.729 456.594 591.729 456.594 609.917 461.141 609.917 461.141 591.729 479.329 591.729 479.329 587.182 461.141 587.182 461.141 568.994"/>
  </g>
  <g id="odpowiedzialnosc-plus" class="our-values__btn">
    <circle class="cls-2" cx="665.4461" cy="226.4276" r="47.9496" transform="translate(371.8477 939.5728) rotate(-89.2846)"/>
    <polygon class="cls-3" points="608.84 256.544 604.293 256.544 604.293 274.732 586.105 274.732 586.105 279.279 604.293 279.279 604.293 297.467 608.84 297.467 608.84 279.279 627.028 279.279 627.028 274.732 608.84 274.732 608.84 256.544"/>
  </g>
  <g id="satysfakcja-plus" class="our-values__btn">
    <path class="cls-2" d="M928.1576,160.0921a45.8725,45.8725,0,1,1-45.8724-45.8739A45.8723,45.8723,0,0,1,928.1576,160.0921Z" transform="translate(-58.8798 50.5781)"/>
    <polygon class="cls-3" points="825.679 190.208 821.132 190.208 821.132 208.397 802.944 208.397 802.944 212.944 821.132 212.944 821.132 231.132 825.679 231.132 825.679 212.944 843.867 212.944 843.867 208.397 825.679 208.397 825.679 190.208"/>
  </g>
  <g id="innowacyjnosc-plus" class="our-values__btn">
    <circle class="cls-2" cx="1039.7036" cy="357.9279" r="47.9496" transform="translate(609.942 1443.6595) rotate(-89.2846)"/>
    <polygon class="cls-3" points="983.097 388.044 978.55 388.044 978.55 406.232 960.362 406.232 960.362 410.78 978.55 410.78 978.55 428.968 983.097 428.968 983.097 410.78 1001.285 410.78 1001.285 406.232 983.097 406.232 983.097 388.044"/>
  </g>
  <g id="rozwoj-plus" class="our-values__btn">
    <path class="cls-2" d="M1053.9023,610.4458a45.8725,45.8725,0,1,1-45.8724-45.8739A45.8723,45.8723,0,0,1,1053.9023,610.4458Z" transform="translate(-58.8798 50.5781)"/>
    <polygon class="cls-3" points="951.424 640.562 946.876 640.562 946.876 658.75 928.688 658.75 928.688 663.297 946.876 663.297 946.876 681.485 951.424 681.485 951.424 663.297 969.612 663.297 969.612 658.75 951.424 658.75 951.424 640.562"/>
  </g>
</svg>`; 
}