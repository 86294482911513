$('.hamburger').on('click', function() {
  if(!$(this).hasClass('hamburger--active')) {
    $(this).addClass('hamburger--active');
    $('.navs__left').slideDown();
    $('.navs__right').slideDown();
  } else {
    $(this).removeClass('hamburger--active');
    $('.navs__left').slideUp();
    $('.navs__right').slideUp();
  }
});

$('#font-size').on('click', function() {
  if(!$('html').hasClass('bigger-font')) {
    $('html').addClass('bigger-font');
  } else {
    $('html').removeClass('bigger-font');
  }
});

$('#contrast').on('click', function() {
  if(!$('body').hasClass('hc')) {
    $('body').addClass('hc');
    localStorage.setItem('contrast', 'yes');
  } else {
    $('body').removeClass('hc');
    localStorage.removeItem('contrast');
  }
});

if(localStorage.getItem('contrast') == 'yes') {
  $('body').addClass('hc');
}

$('#search').on('click', function() {
  $('.search-box').fadeToggle().css('flex');
});

if ($('html[lang=en]').length) {
  $('#lang').html('PL').attr('href', '/pl');
} else {
  $('#lang').html('EN').attr('href', '/en');
}