export function averageAgeEnSvg() {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.2834 64.3498">
  <g class="people1">
    <g>
      <path class="cls-1" d="M277.0147,49.6868a1.4629,1.4629,0,0,0,1.4586,1.4586h.073a1.4628,1.4628,0,0,0,1.4586-1.4586v-5.74h-2.99Z"/>
      <path class="cls-1" d="M281.0187,49.6868a1.4629,1.4629,0,0,0,1.4586,1.4586h.073a1.4629,1.4629,0,0,0,1.4586-1.4586v-5.74h-2.99Z"/>
      <path class="cls-1" d="M290.2984,36.9888a1.7325,1.7325,0,0,0-1.7305,1.73.38.38,0,0,0,.76,0,.97.97,0,0,1,1.94,0V51.1456a.38.38,0,0,0,.76,0V38.7192A1.7324,1.7324,0,0,0,290.2984,36.9888Z"/>
      <path class="cls-1" d="M290.37,34.6561c-2.2809-.5069-4.2239-2.9146-4.9-3.8015s-1.0561-1.943-2.6557-2.3605h-.0312a3.0365,3.0365,0,0,1-4.5324.0125h-.0678a9.4326,9.4326,0,0,0-3.6,3.5079c-.345.6056-.6121,1.077-.5936,1.0475s-.2366.4486-.5671,1.0621L271.2977,38.07a1.2368,1.2368,0,0,0,.4726,1.6839l.0037.002a1.3151,1.3151,0,0,0,1.7184-.5228l2.044-3.4846c.3527-.6012.649-1.1052.6584-1.12s-.0353.5408-.0995,1.2348l-.2714,2.941c-.0641.6939-.1691,1.83-.233,2.5236l-.2117,2.2919a.4549.4549,0,0,0,.4022.5021.4651.4651,0,0,0,.0578.0027h9.3445a.4549.4549,0,0,0,.4627-.4472.4426.4426,0,0,0-.0027-.0576l-.2086-2.2679c-.0639-.694-.1615-1.83-.2171-2.5251l-.2937-3.0034c-.0645-.694.3374-.9176.8931-.4969a14.3288,14.3288,0,0,0,2.6611,1.5633,3.06,3.06,0,0,0,2.6514.0467C291.8909,36.1768,291.13,34.91,290.37,34.6561Z"/>
      <path class="cls-1" d="M280.5117,23.0176a2.8688,2.8688,0,1,0,2.7695,2.9648c.0011-.0325.0016-.0651.0016-.0976A2.8235,2.8235,0,0,0,280.5117,23.0176Z"/>
    </g>
    <g >
      <path class="cls-1" d="M306.4924,40.0792a1.7928,1.7928,0,0,0-1.7909,1.7909.38.38,0,0,0,.76,0,1.0307,1.0307,0,0,1,2.0613,0v9.2755a.38.38,0,0,0,.76,0V41.87A1.793,1.793,0,0,0,306.4924,40.0792Z"/>
      <g>
        <path class="cls-1" d="M306.17,38.7123c-1.1582-.4428-1.9284-3.0456-2.0659-4.4876a.3822.3822,0,0,1-.0064-.07v-.0016l-.0043-.0557c-.0008-.01-.0012-.0207-.001-.0311l.0053-1.1226a4.07,4.07,0,0,0-3.2769-3.6888l-.433.001a3.0547,3.0547,0,0,1-3.3548.0079l-.4829.0011a4.0672,4.0672,0,0,0-3.24,3.6787V41.447a.6343.6343,0,0,0,.6337.6336h.1826a.64.64,0,0,0,.6341-.47V35.0941a.38.38,0,0,1,.38-.3749h.0027a.38.38,0,0,1,.3773.38l-.0169,14.272a1.3956,1.3956,0,0,0,1.3939,1.3939h.0262a1.3956,1.3956,0,0,0,1.3939-1.3939V41.1838a.38.38,0,0,1,.38-.38h0a.3911.3911,0,0,1,.3928.38v8.1874a1.3956,1.3956,0,0,0,1.3939,1.3939h.0262a1.3956,1.3956,0,0,0,1.3939-1.3939V35.1761a.36.36,0,0,1,0-.0509.3842.3842,0,0,1,.3917-.3645.3739.3739,0,0,1,.3688.3628c.3306,2.0285,1.0969,4.571,2.87,5.2491a.8839.8839,0,0,0,.3171.0589.8889.8889,0,0,0,.3176-1.7192Z"/>
        <path class="cls-1" d="M298.7046,23.2672a2.8688,2.8688,0,1,0,2.7695,2.9648q.0016-.0487.0016-.0975A2.8235,2.8235,0,0,0,298.7046,23.2672Z"/>
      </g>
    </g>
  </g>
  <g class="people2">
    <g>
      <path class="cls-2" d="M6.91,23.0181A2.9174,2.9174,0,0,0,4.0309,25.973q.0007.0681.0049.1362a2.8907,2.8907,0,1,0,5.7491,0A2.9174,2.9174,0,0,0,7.0465,23.023Q6.9786,23.0189,6.91,23.0181Z"/>
      <path class="cls-2" d="M11.2186,32.2042l.5369,1.5964-.6342-1.8487a1.7277,1.7277,0,0,0-1.5245-1.0886H4.2241A1.7277,1.7277,0,0,0,2.7,31.9519l-.6343,1.8487.5392-1.5964-2.5562,7.55a.9234.9234,0,0,0,.577,1.1674l.0223.0077A.9433.9433,0,0,0,1.83,40.3575l2.0534-5.7346.0139-.005-1.202,7.9346a.5988.5988,0,0,0,.6077.6856h.9309v7.1362a1.1543,1.1543,0,0,0,1.151,1.151h.0575a1.1544,1.1544,0,0,0,1.151-1.151V43.238h.6335v7.1363a1.1544,1.1544,0,0,0,1.151,1.151h.0576a1.1544,1.1544,0,0,0,1.151-1.151V43.238h.9307a.5985.5985,0,0,0,.6077-.6856L9.9234,34.6179l.016.005,2.0534,5.7346a.9431.9431,0,0,0,1.1826.5717l.0223-.0076a.9235.9235,0,0,0,.5769-1.1674Z"/>
    </g>
    <g>
      <path class="cls-2" d="M19.6394,23.4932a3.0216,3.0216,0,0,0-2.9824,3.06q.0009.0707.0051.1412a2.9952,2.9952,0,1,0,5.9546,0A3.0217,3.0217,0,0,0,19.78,23.4983Q19.71,23.4941,19.6394,23.4932Z"/>
      <path class="cls-2" d="M23.6653,31.3536H15.6135a1.11,1.11,0,0,0-1.1087,1.1086v9.2544a.4757.4757,0,0,0,.4752.4751h.7213a.4758.4758,0,0,0,.4752-.4751v-6.131a.4751.4751,0,0,1,.95,0V50.7342a.7928.7928,0,0,0,.7919.7919h.4584a.797.797,0,0,0,.7854-.6914c-.0019-.0332-.0028-.0668-.0028-.1006v-8.75a.4751.4751,0,0,1,.4751-.4751.4832.4832,0,0,1,.4844.4751v8.75c0,.0337-.0009.0674-.0027.1006a.797.797,0,0,0,.7853.6914H21.36a.7927.7927,0,0,0,.7918-.7919V35.5856a.4752.4752,0,0,1,.95,0v6.1316a.4757.4757,0,0,0,.4751.4751h.7214a.4757.4757,0,0,0,.4751-.4751V32.4628A1.11,1.11,0,0,0,23.6653,31.3536Z"/>
    </g>
  </g>
  <rect class="cls-1" y="49.7453" width="308.2834" height="14.6045"/>
  <rect class="cls-2 redBar" y="49.7453" width="128.8651" height="14.6045"/>
  <g class="arrow">
    <path class="cls-2" d="M119.3446,37.1417h19.041l-9.2168,12.8222-.3061.4219-9.5181-13.2441Zm9.52,12.6025.3037-.2187Z"/>
    </g>
  <g id="38-lat" data-name=" 38 lat" class="cls-3">
    <text class="cls-4" transform="translate(95.6273 22.8329)">38</text>
    <text class="cls-5" transform="translate(130.6493 22.8329)"><tspan xml:space="preserve"> years</tspan></text>
  </g>
  </svg>`;
}