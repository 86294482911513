import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$('.product__slider.owl-carousel').owlCarousel({
  loop: true,
  margin: 0,
  nav: false,
  dots: true,
  items: 1,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  autoplay: true,
  autoplayTimeout: 3500,
  autoplayHoverPause: false,
});

$('.info__slider.owl-carousel').owlCarousel({
  loop: true,
  margin: 0,
  nav: false,
  dots: true,
  items: 1,
  autoplay: true,
  autoplayTimeout: 3500,
  autoplayHoverPause: false,
  smartSpeed: 1200,
});