$('.revenues__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.revenues__content').removeClass('active');
  $('.revenues__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.revenues__content[data-tab-id="'+id+'"]').addClass('active');
  }
});

let countries = $('.revenues__carousel');
countries.owlCarousel({
  items:5,
  loop: true,
  autoWidth:true,
  margin: 20,
  autoplay: true,
  slideTransition: 'linear',
  autoplayTimeout: 1000,
  autoplaySpeed: 1000,
  autoplayHoverPause: false
});

