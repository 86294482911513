$('.awards__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.awards__content').removeClass('active');
  $('.awards__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.awards__content[data-tab-id="'+id+'"]').addClass('active');
  }
});

let award = $('.awards__carousel');
award.owlCarousel({
  items:1,
  loop:true,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  autoplay:true,
  autoplayTimeout:6000,
  autoplayHoverPause:true,
  nav: false,
  dots: true,
  mouseDrag: false,
  touchDrag: false
});