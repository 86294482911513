let lug2018 = $('.lug-2018__carousel');
lug2018.owlCarousel({
  items:1,
  loop:true,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  autoplay:true,
  autoplayTimeout:6000,
  autoplayHoverPause:true,
  nav: false,
  dots: true,
  mouseDrag: false,
  touchDrag: false
});