$(window).on('scroll', function() {
  var scrollBtn = $('#scroll-up');
  var windowFromTop = $(window).scrollTop();
  var windowHeight = $(window).height();

  if (windowFromTop > (windowHeight / 2)) {
    scrollBtn.addClass('active');
  } else {
    scrollBtn.removeClass('active');
  }
});
$('#scroll-up').on('click', function(e) {
  e.preventDefault();
  $('body, html').animate({scrollTop: 0}, 1200, 'swing');
});