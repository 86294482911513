export function developmentSvgMob(){
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.183 302.21">
    <g>
      <path class="cls-1" d="M0,68.427c42.555,0,77.052,37.012,77.052,82.668S42.555,233.764,0,233.764"/>
      <g class="development__btn" id="doskonalenie" [data-popup] data-popup="13">
        <path class="cls-2" d="M22.547,230.527a4.118,4.118,0,1,1-5.189-2.644A4.118,4.118,0,0,1,22.547,230.527Z"/>
        <text class="cls-3" transform="translate(69.82 277.737)">Dos<tspan class="cls-7" x="15.081" y="0">k</tspan><tspan x="19.482" y="0">onalenie</tspan></text>
        <path class="cls-8" d="M58.486,266.7a27.119,27.119,0,1,1-38.694-15.469l2.038-9.588,7.284,6.559A27.122,27.122,0,0,1,58.486,266.7Z"/>
        <path class="cls-8" d="M32.717,295.5a20.361,20.361,0,1,1,20.361-20.36A20.361,20.361,0,0,1,32.717,295.5Z"/>
        <path class="cls-2" d="M38.59,275.046a6,6,0,1,0-11.508,2.36h0l0,0a20.861,20.861,0,0,1,1.349,1.947,9.276,9.276,0,0,1,1.434,3.522h5.472a9.339,9.339,0,0,1,1.453-3.554,21.52,21.52,0,0,1,1.289-1.864l.042-.054h-.016A5.966,5.966,0,0,0,38.59,275.046Zm-9.513.116a9.316,9.316,0,0,0-.171,2.545c-.3-.436-.551-.773-.691-.956a4.655,4.655,0,0,1-.321-1.7,4.705,4.705,0,0,1,4.7-4.7,4.791,4.791,0,0,1,.59.041A6.4,6.4,0,0,0,29.077,275.162Z"/>
        <path class="cls-2" d="M34.68,283.972H30.508a.651.651,0,0,0-.649.648v.406a.651.651,0,0,0,.649.648h.263v.466a.325.325,0,0,0,.324.324h3a.325.325,0,0,0,.324-.324v-.466h.263a.651.651,0,0,0,.649-.648v-.406A.651.651,0,0,0,34.68,283.972Z"/>
        <path class="cls-2" d="M33.08,266.943c0,.356-.219.648-.486.648s-.486-.292-.486-.648v-2.485c0-.356.218-.648.486-.648s.486.292.486.648Z"/>
        <path class="cls-2" d="M29.417,267.674a.506.506,0,1,1-.841.487l-1.245-2.151a.506.506,0,1,1,.842-.487Z"/>
        <path class="cls-2" d="M26.614,270.141a.506.506,0,1,1-.484.843l-2.155-1.237a.506.506,0,1,1,.484-.843Z"/>
        <path class="cls-2" d="M25.424,273.681c.356,0,.649.217.649.484s-.29.487-.646.489l-2.485.008c-.356,0-.649-.217-.65-.484s.29-.487.647-.488Z"/>
        <path class="cls-2" d="M36.741,268.206a.506.506,0,1,1-.842-.486l1.244-2.151a.506.506,0,1,1,.842.486Z"/>
        <path class="cls-2" d="M39.278,271.133a.506.506,0,1,1-.485-.843l2.153-1.239a.506.506,0,1,1,.485.842Z"/>
        <path class="cls-2" d="M40.009,274.936c-.356,0-.648-.22-.647-.487s.293-.486.649-.485l2.485,0c.356,0,.648.22.647.487s-.293.486-.649.485Z"/>
      </g>
      <g class="development__btn" id="iso" [data-popup] data-popup="12">
        <path class="cls-2" d="M63.474,197.645a4.118,4.118,0,1,1-5.752.911A4.119,4.119,0,0,1,63.474,197.645Z"/>
        <text class="cls-3" transform="translate(134.995 230.373)">ISO</text>
        <path class="cls-8" d="M113.814,205.789a27.119,27.119,0,1,1-40.4,10.229l-3.987-8.955,9.749,1.025A27.12,27.12,0,0,1,113.814,205.789Z"/>
        <path class="cls-8" d="M97.935,248.134A20.361,20.361,0,1,1,118.3,227.773,20.362,20.362,0,0,1,97.935,248.134Z"/>
        <path class="cls-9" d="M108.739,226.131a7.769,7.769,0,0,0,0-2.852l-1.662-.593a6.669,6.669,0,0,0-.359-.867l.756-1.594a7.957,7.957,0,0,0-2.016-2.018l-1.595.758a6.49,6.49,0,0,0-.868-.359l-.591-1.663a7.892,7.892,0,0,0-2.853,0l-.591,1.663a6.546,6.546,0,0,0-.868.359l-1.594-.758a7.944,7.944,0,0,0-2.017,2.018l.758,1.594a6.406,6.406,0,0,0-.36.867l-1.662.593a7.876,7.876,0,0,0-.132,1.425,7.553,7.553,0,0,0,.051.842h5.042a2.893,2.893,0,0,1-.137-.842,2.937,2.937,0,1,1,3.427,2.889v4.983a7.7,7.7,0,0,0,.936-.11L103,230.8a6.432,6.432,0,0,0,.869-.36l1.594.759a7.913,7.913,0,0,0,2.016-2.019l-.756-1.594a6.656,6.656,0,0,0,.359-.866Z"/>
        <rect class="cls-9" x="87.415" y="233.595" width="8.328" height="5.952"/>
        <rect class="cls-9" x="96.57" y="233.595" width="8.329" height="5.952"/>
        <rect class="cls-9" x="91.993" y="226.714" width="8.328" height="5.954"/>
      </g>
      <g class="development__btn" id="laboratorium" [data-popup] data-popup="11">
        <path class="cls-2" d="M77.258,146.987A4.118,4.118,0,1,1,73.14,151.1,4.118,4.118,0,0,1,77.258,146.987Z"/>
        <text class="cls-3" transform="translate(159.889 153.705)">Labo<tspan class="cls-5" x="19.318" y="0">r</tspan><tspan x="22.444" y="0">a</tspan><tspan class="cls-6" x="26.953" y="0">t</tspan><tspan x="30.03" y="0">orium</tspan></text>
        <path class="cls-8" d="M122.77,178.224A27.119,27.119,0,1,0,96.1,146.2l-8.489,4.9,8.489,4.9A27.121,27.121,0,0,0,122.77,178.224Z"/>
        <path class="cls-8" d="M122.846,171.466A20.361,20.361,0,1,1,143.207,151.1,20.362,20.362,0,0,1,122.846,171.466Z"/>
        <path class="cls-2" d="M129.119,143.44q-.405.64-.815,1.277a2.353,2.353,0,0,0-1.269-.375,2.3,2.3,0,0,0-.707.111,2.383,2.383,0,0,0-1.651,2.67,2.279,2.279,0,0,0,1.073,1.593q-.55.856-1.1,1.7a.364.364,0,0,1-.558.1c-.911-.583-1.824-1.166-2.731-1.755-.255-.164-.275-.343-.094-.624q1.751-2.735,3.5-5.466c.309-.482.613-.966.925-1.444.2-.3.361-.332.664-.138q1.3.831,2.605,1.667C129.286,142.972,129.325,143.117,129.119,143.44Z"/>
        <path class="cls-2" d="M126.788,140.466c.388-.595.8-1.224,1.211-1.854.173-.265.338-.534.514-.8.189-.283.334-.326.611-.153.613.384,1.219.779,1.828,1.171a.375.375,0,0,1,.127.577c-.578.911-1.161,1.82-1.733,2.716Z"/>
        <path class="cls-2" d="M121.053,149.452l2.565,1.64c-.287.435-.579.887-.882,1.332a.346.346,0,0,1-.51.1q-.958-.6-1.905-1.219a.351.351,0,0,1-.112-.53C120.491,150.331,120.777,149.886,121.053,149.452Z"/>
        <path class="cls-2" d="M131.094,161.3a9.084,9.084,0,0,0-1.221-.9c-.245-.151-.5-.289-.762-.418-.08-.04-.16-.081-.245-.121a14,14,0,0,0,1.47-1.332,8.053,8.053,0,0,0,1.582-8.668,7.813,7.813,0,0,0-2.766-3.6,1.063,1.063,0,0,1-.312-.392,2.009,2.009,0,1,0-1.751,2.86.879.879,0,0,1,.548.107,5.143,5.143,0,0,1,1.813,5.149,5.252,5.252,0,0,1-6.183,4.174,4.191,4.191,0,0,1-2.276-1.131c.272,0,.539.009.811-.005.552-.017.877-.32.877-.8a.792.792,0,0,0-.9-.807q-1.209-.006-2.419,0c-1.1,0-2.209,0-3.314,0a.855.855,0,0,0-.891.815.808.808,0,0,0,.86.793c.271.014.548-.013.815.018a.527.527,0,0,1,.343.2,7.887,7.887,0,0,0,2.3,2.445c.058.045.116.085.182.138-.1.045-.2.094-.289.138a10.432,10.432,0,0,0-1.154.673c-.178.125-.356.254-.525.388-.094.075-.192.164-.29.249-.071.062-.169.111-.183.209-.013.121.005.259,0,.383,0,.263,0,.526,0,.793h14.107v-1.122c0-.009,0-.018-.008-.022A.949.949,0,0,0,131.094,161.3Zm-4.032-13.573a.994.994,0,0,1-1.046-.975.977.977,0,0,1,.975-1.034.95.95,0,0,1,1.016.98A.989.989,0,0,1,127.062,147.723Z"/>
      </g>
      <g class="development__btn" id="inwestycje" [data-popup] data-popup="10">
        <path class="cls-2" d="M58.633,97.9a4.118,4.118,0,1,1-.911,5.753A4.119,4.119,0,0,1,58.633,97.9Z"/>
        <text class="cls-3" transform="translate(134.994 77.037)">Inwestycje</text>
        <path class="cls-8" d="M113.814,96.422a27.119,27.119,0,1,0-40.4-10.23L69.43,95.147l9.749-1.025A27.121,27.121,0,0,0,113.814,96.422Z"/>
        <path class="cls-8" d="M97.935,94.8A20.361,20.361,0,1,1,118.3,74.437,20.361,20.361,0,0,1,97.935,94.8Z"/>
        <path class="cls-9" d="M103.723,71.955,99.3,63.117a.43.43,0,0,0-.737,0l-4.419,8.838Z"/>
        <path class="cls-9" d="M97.577,79.372H90.439l-3,6a.413.413,0,0,0,.368.6h9.768Z"/>
        <path class="cls-9" d="M110.43,85.369l-3-6h-7.138v6.594h9.768a.413.413,0,0,0,.368-.6Z"/>
        <path class="cls-9" d="M97.577,77.11h-.951a.137.137,0,0,1-.111-.215l2.31-3.26a.141.141,0,0,1,.221,0l2.31,3.26a.137.137,0,0,1-.111.215h-.951v1.438h6.726l-2.885-5.769h-10.4l-2.885,5.769h6.726Z"/>
      </g>
      <g class="development__btn" id="biot" [data-popup] data-popup="9">
        <path class="cls-2" d="M14.714,69.138a4.118,4.118,0,1,1,2.644,5.189A4.118,4.118,0,0,1,14.714,69.138Z"/>
        <text class="cls-3" transform="translate(69.82 29.653)">BI<tspan class="cls-4" x="8.137" y="0">O</tspan><tspan x="13.97" y="0">T</tspan></text>
        <path class="cls-8" d="M58.486,35.506A27.119,27.119,0,1,0,19.792,50.975l2.038,9.588L29.114,54A27.122,27.122,0,0,0,58.486,35.506Z"/>
        <path class="cls-8" d="M32.717,47.414A20.361,20.361,0,1,1,53.078,27.053,20.361,20.361,0,0,1,32.717,47.414Z"/>
        <rect class="cls-2" x="28.642" y="22.979" width="8.152" height="8.15" rx="0.519"/>
        <path class="cls-2" d="M41.493,22.509a.582.582,0,1,0,0-1.164H40.074A1.7,1.7,0,0,0,38.427,19.7V18.278a.582.582,0,1,0-1.163,0V19.7h-.888V18.278a.582.582,0,1,0-1.164,0V19.7h-.886V18.278a.583.583,0,0,0-1.166,0V19.7h-.885V18.278a.583.583,0,0,0-1.166,0V19.7h-.885V18.278a.583.583,0,0,0-1.165,0V19.7h-.886V18.278a.583.583,0,0,0-1.165,0V19.7a1.7,1.7,0,0,0-1.647,1.648H23.944a.582.582,0,1,0,0,1.164h1.415V23.4H23.944a.583.583,0,1,0,0,1.165h1.415v.885H23.944a.583.583,0,1,0,0,1.166h1.415V27.5H23.944a.582.582,0,1,0,0,1.164h1.415v.886H23.944a.582.582,0,1,0,0,1.164h1.415V31.6H23.944a.583.583,0,1,0,0,1.165h1.417a1.7,1.7,0,0,0,1.647,1.648v1.415a.583.583,0,1,0,1.165,0V34.415h.886v1.413a.583.583,0,1,0,1.165,0V34.415h.885v1.413a.583.583,0,1,0,1.166,0V34.415h.885v1.413a.583.583,0,1,0,1.166,0V34.415h.886v1.413a.582.582,0,1,0,1.164,0V34.415h.888v1.413a.582.582,0,1,0,1.163,0V34.413a1.7,1.7,0,0,0,1.647-1.648h1.419a.583.583,0,1,0,0-1.165H40.077v-.888h1.416a.582.582,0,1,0,0-1.164H40.077v-.886h1.416a.582.582,0,1,0,0-1.164H40.077v-.886h1.416a.583.583,0,1,0,0-1.166H40.077v-.885h1.416a.582.582,0,1,0,0-1.164H40.077v-.888ZM38.332,32.667H27.1V21.441H38.332Z"/>
      </g>
    </g>
  </svg>`;
}