footer();

$(window).resize(function() {
  footer();
});

function footer() {
  if($(window).outerWidth(true) < 769){
    $('footer').css('margin-top',$('.home__top__box-2').innerHeight()+'px');
  } else {
    $('footer').css('margin-top', 0);
  }
}