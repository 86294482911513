import { factorsDevelopment } from '../svg/factors-development';
import { factorsDevelopmentEn } from '../svg/factors-development-en';

$('.market__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.market__content').removeClass('active');
  $('.market__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.market__content[data-tab-id="'+id+'"]').addClass('active');
  }
});

($('html[lang=en]').length) ? $('.factors-development__svg').html(factorsDevelopmentEn) : $('.factors-development__svg').html(factorsDevelopment);

$('.factors-development__btn').hover(function() {
  const id = $(this).attr('id').split('-icon')[0];
  $(this).css('opacity','0');
  $('#'+id).css('opacity','1');
}, function() {
  $(this).css('opacity','1');
  $('.factors-development__text').css('opacity','0');
});