export function realizationPlay() {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253.9915 78.9581">
  <g>
    <path class="cls-1" d="M688.2041,258.3438,685.03,247.9727h1.8164l1.65,5.9863.6152,2.2266q.04-.1664.5371-2.1387l1.65-6.0742h1.8066l1.5528,6.0156.5176,1.9824.5957-2.0019,1.7773-5.9961h1.709l-3.2422,10.3711H694.19l-1.65-6.2109-.4-1.7676-2.1,7.9785Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M707.833,255.0039l1.8164.2247a4.4325,4.4325,0,0,1-1.5918,2.4707,5.3925,5.3925,0,0,1-6.5771-.5225,5.4756,5.4756,0,0,1-1.333-3.9307,5.7309,5.7309,0,0,1,1.3476-4.0625,4.5622,4.5622,0,0,1,3.4961-1.4453,4.4345,4.4345,0,0,1,3.3984,1.416,5.6254,5.6254,0,0,1,1.3184,3.9844c0,.104-.0029.26-.01.4688h-7.7343a3.9892,3.9892,0,0,0,.9668,2.6172,2.8766,2.8766,0,0,0,2.1679.9082,2.6912,2.6912,0,0,0,1.65-.5079A3.3649,3.3649,0,0,0,707.833,255.0039Zm-5.7715-2.8417h5.791a3.515,3.515,0,0,0-.664-1.9629,2.6937,2.6937,0,0,0-2.1778-1.0157,2.7909,2.7909,0,0,0-2.0361.8106A3.21,3.21,0,0,0,702.0615,252.1622Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M724.1807,257.0645a6.5559,6.5559,0,0,1-1.88,1.1719,5.43,5.43,0,0,1-1.9385.3418,3.7707,3.7707,0,0,1-2.6269-.835,2.7548,2.7548,0,0,1-.918-2.1338,2.8337,2.8337,0,0,1,.3467-1.3916,2.875,2.875,0,0,1,.9082-1.0107,4.2719,4.2719,0,0,1,1.2646-.5762,12.5034,12.5034,0,0,1,1.5625-.2637,16.0934,16.0934,0,0,0,3.1348-.6054c.0068-.2408.01-.3941.01-.459a1.9407,1.9407,0,0,0-.498-1.5137,2.9419,2.9419,0,0,0-2.002-.5957,3.1036,3.1036,0,0,0-1.831.4346,2.6532,2.6532,0,0,0-.874,1.5381l-1.7188-.2344a4.2545,4.2545,0,0,1,.7715-1.7822,3.384,3.384,0,0,1,1.5527-1.045,6.9274,6.9274,0,0,1,2.3536-.3662,6.2258,6.2258,0,0,1,2.1582.3125,2.8435,2.8435,0,0,1,1.2207.7862,2.7651,2.7651,0,0,1,.5468,1.1962,9.8962,9.8962,0,0,1,.0879,1.6211v2.3438a24.8081,24.8081,0,0,0,.1123,3.1006,3.9089,3.9089,0,0,0,.4444,1.2451h-1.836A3.7129,3.7129,0,0,1,724.1807,257.0645Zm-.1465-3.9258a12.8881,12.8881,0,0,1-2.8711.6641,6.6317,6.6317,0,0,0-1.5332.3515,1.5308,1.5308,0,0,0-.6934.5713,1.5539,1.5539,0,0,0,.2881,2.0069,2.2893,2.2893,0,0,0,1.5576.4687,3.6243,3.6243,0,0,0,1.8067-.4443,2.7368,2.7368,0,0,0,1.1621-1.2158,4.2508,4.2508,0,0,0,.2832-1.7579Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M728.5166,258.3438V247.9727h1.582v1.5723a4.2854,4.2854,0,0,1,1.1182-1.4551,1.9517,1.9517,0,0,1,1.1279-.3516,3.4306,3.4306,0,0,1,1.8067.5664l-.6055,1.6309a2.5153,2.5153,0,0,0-1.2891-.3809,1.6751,1.6751,0,0,0-1.0351.3467,1.8775,1.8775,0,0,0-.6543.9619,6.8474,6.8474,0,0,0-.293,2.0508v5.43Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M742.2959,255.0039l1.8164.2247a4.4325,4.4325,0,0,1-1.5918,2.4707,5.3925,5.3925,0,0,1-6.5771-.5225,5.4756,5.4756,0,0,1-1.333-3.9307,5.7309,5.7309,0,0,1,1.3476-4.0625,4.5621,4.5621,0,0,1,3.4961-1.4453,4.4345,4.4345,0,0,1,3.3984,1.416,5.6259,5.6259,0,0,1,1.3184,3.9844c0,.104-.0029.26-.01.4688h-7.7343a3.9892,3.9892,0,0,0,.9668,2.6172,2.8766,2.8766,0,0,0,2.1679.9082,2.6912,2.6912,0,0,0,1.65-.5079A3.3649,3.3649,0,0,0,742.2959,255.0039Zm-5.7715-2.8417h5.791a3.515,3.515,0,0,0-.664-1.9629,2.6937,2.6937,0,0,0-2.1778-1.0157,2.7909,2.7909,0,0,0-2.0361.8106A3.21,3.21,0,0,0,736.5244,252.1622Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M752.0908,258.3438V244.1446h2.8906v11.7871h7.1875v2.4121Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M764.21,244.0274h2.8906v7.7539a16.1332,16.1332,0,0,0,.1074,2.3926,2.3189,2.3189,0,0,0,.8838,1.4111,3.0778,3.0778,0,0,0,1.9092.5322,2.9126,2.9126,0,0,0,1.8554-.5029,1.9878,1.9878,0,0,0,.752-1.2354,16.054,16.054,0,0,0,.127-2.4316v-7.92h2.8906v7.5195a19.3153,19.3153,0,0,1-.2344,3.6426,4.0312,4.0312,0,0,1-.8643,1.7969,4.3651,4.3651,0,0,1-1.6845,1.167,7.3326,7.3326,0,0,1-2.7539.4345,7.9257,7.9257,0,0,1-3.11-.4736,4.4545,4.4545,0,0,1-1.6748-1.2305,3.94,3.94,0,0,1-.8105-1.5869,17.4077,17.4077,0,0,1-.2832-3.6328Z" transform="translate(-611.3011 -243.7832)"/>
    <path class="cls-1" d="M785.333,253.08V250.668h6.2305v5.7031a7.836,7.836,0,0,1-2.6319,1.5479,9.5822,9.5822,0,0,1-3.4912.6689,7.8344,7.8344,0,0,1-3.916-.9424,5.9917,5.9917,0,0,1-2.51-2.6953,8.7155,8.7155,0,0,1-.84-3.8134,8.24,8.24,0,0,1,.9375-3.9746,6.3178,6.3178,0,0,1,2.7441-2.6661,7.4067,7.4067,0,0,1,3.4278-.7129,6.837,6.837,0,0,1,4.165,1.1182,5.05,5.05,0,0,1,1.9287,3.0908l-2.8711.5371a3.0323,3.0323,0,0,0-1.1377-1.665,3.4406,3.4406,0,0,0-2.0849-.61,3.9172,3.9172,0,0,0-3.0127,1.2012,5.0613,5.0613,0,0,0-1.1182,3.5645,5.6074,5.6074,0,0,0,1.1328,3.8232,3.7827,3.7827,0,0,0,2.9688,1.2744,4.9729,4.9729,0,0,0,1.8213-.3564,6.264,6.264,0,0,0,1.5674-.8643V253.08Z" transform="translate(-611.3011 -243.7832)"/>
  </g>
  <g>
    <circle class="cls-2" cx="126.9958" cy="57.0324" r="21.9257"/>
    <path id="part" class="cls-1" d="M743.9144,301.1238l-8.6169,4.9744v-9.951Z" transform="translate(-611.3011 -243.7832)"/>
  </g>
  <g>
    <line class="cls-3" y1="7.4023" x2="59.6259" y2="7.4023"/>
    <line class="cls-4" x1="194.3656" y1="7.4023" x2="253.9915" y2="7.4023"/>
  </g>
</svg>`;
}