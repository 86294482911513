import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$.fn.openPopup = function(id){
    if($(this).length < 1){
        return;
    }
    $('body').css('overflow','hidden');
    $('.mask').fadeIn(300);
    $(this).css('display','flex').hide().fadeIn(400);
    $(this).trigger('openPopup');
    ($('.popup__slider').length > 0) && initSlider();
    popupHeight(id);
    $('.popup').animate({ scrollTop: 0 });
}
$.fn.closePopup = function(){
    $('body').css('overflow','visible');
    $('.popup').fadeOut(400);
    $('.mask').fadeOut(500);
    $(this).trigger('closePopup');
    $('.popup__mask').css('height', '');
}

$('body').on('click', 'button[data-popup], a[data-popup], g[data-popup]', function() {
    const id = $(this).attr('data-popup');
    $('.popup[data-popup="'+ id +'"]').openPopup(id);
    return false;
});

$('.popup__close').on('click', function() {
  $(this).closePopup();
});

$('.popup__mask').on('click', function() {
  $(this).closePopup();
});

$('.popup-result__close').on('click', function() {
  $(this).closePopup();
});

$('.popup__video button').on('click', function() {
  let vid = document.querySelector('.video'); 
  $(this).fadeOut();
  vid.play(); 
});

function popupHeight(id) {
  let height = 0;
  if (!$('.popup__content-text[data-popup="'+ id +'"]').hasClass('slider')) {
    height = $('.popup__content-text[data-popup="'+ id +'"]').height() + 100;
  } else {
    height = $('.popup__content-text[data-popup="'+ id +'"]').height() + 150;
  }
  let mask = height + 300;

  ($(window).height() < mask) && $('.popup__mask').css('height', mask);

  $('.popup__content').css('height', height);
}

function initSlider() {
  let owl = $('.popup__slider');
  owl.on('initialized.owl.carousel', function(event) {
    const height = $('.popup__content--text').height() + 100;
    $('.popup__content').css('height', height);
  }).owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    items: 1,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    autoplay: true,
    autoplayTimeout: 3500,
    autoplayHoverPause: false,
  });
  const height = $('.popup__content--text').height() + 100;
  $('.popup__content').css('height', height);
}
