var history = $('.history__carousel');
history.on('initialized.owl.carousel', function(event) {
  const height = $('.history').height();
  if($(window).width() > 767) {
    $('.history__content').css('height', height + 'px');
  }
  $('.history__bg').css('height', height + 'px');
}).owlCarousel({
  items:1,
  loop:false,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  nav: true,
  dots: false,
  mouseDrag: false,
  touchDrag: false,
  navText: ['','']
});

history.on('changed.owl.carousel', function(e) {
  let index = e.item.index;
  timeline(index)
});

function timeline(index) {
  let date = [];
  for (let i = 0; i < 4; i++) {
    date.push(''+ 2019 - index - i +'');
    if(date[3] < '2007') {
      $('.timeline__item[data-timline-id="3"]').css('display','none');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
      
    } else {
      $('.timeline__item[data-timline-id="3"]').css('display','block');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
    }

    if(date[2] < '2007') {
      $('.timeline__item[data-timline-id="2"]').css('display','none');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
    } else {
      $('.timeline__item[data-timline-id="2"]').css('display','block');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
    }

    if(date[1] < '2007') {
      $('.timeline__item[data-timline-id="1"]').css('display','none');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
    } else {
      $('.timeline__item[data-timline-id="1"]').css('display','block');
      $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
    }

    $('.timeline__date[data-date-id="'+ (i+1) +'"]').html(date[i].substr(0, 2) + '<br>' + date[i].substr(2,2));
  }
}
