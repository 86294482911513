$('.risk__tab').on('click', function() {
  const id = $(this).attr('data-tab-id');
  $('.risk__content').removeClass('active');
  $('.risk__tab').removeClass('active');
  if(!$(this).hasClass('active')) {
    $(this).addClass('active');
    $('.risk__content[data-tab-id="'+id+'"]').addClass('active');
  }
});

risickSun4();
risickSun();

$(window).resize(function() {
  risickSun4();
  risickSun();
});

function risickSun4() {
  $.sun('.risk__content[data-tab-id="1"] .sun', 'init', {
    firstAngle: 0,
    angleStep: 1.57,
    inactiveSize: 70,
    activeSize: 300,
    radius: 230,
    play: true,
    duration: 5000,
    onClick: function (sun, pos, elem) {
      $.sun(sun, 'seek', pos);
      $.sun(sun, 'resetTimer');
    },
  });
  $('.sun').on('mouseenter', function () {
      $.sun($(this), 'suspend');
  });
  $('.sun').on('mouseleave', function () {
      $.sun($(this), 'play');
  });
  $('.ring').css({
    'height' : '350px',
    'width' : '350px',
    'display' : 'block',
    'border' : '1px solid #d3d3d3'
  })
  if($(window).width() < 576) {
    $.sun('.risk__content[data-tab-id="1"] .sun', 'init', {
      firstAngle: 0,
      angleStep: 1.57,
      inactiveSize: 1,
      activeSize: 240, 
      radius: 160, 
      play: true,
      onClick: function (sun, pos, elem) {
          $.sun(sun, 'seek', pos);
          $.sun(sun, 'resetTimer');
      }
    });
    $('.ring').fadeOut();
  }
}

function risickSun() {
  $.sun('.risk__content[data-tab-id="2"] .sun', 'init', {
    firstAngle: -1.05,
    angleStep: 0.37,
    inactiveSize: 70,
    activeSize: 300,
    radius: 230,
    play: true,
    duration: 5000,
    onClick: function (sun, pos, elem) {
      $.sun(sun, 'seek', pos);
      $.sun(sun, 'resetTimer');
    },
  });
  $('.sun').on('mouseenter', function () {
      $.sun($(this), 'suspend');
  });
  $('.sun').on('mouseleave', function () {
      $.sun($(this), 'play');
  });
  $('.ring').css({
    'height' : '350px',
    'width' : '350px',
    'display' : 'block',
    'border' : '1px solid #d3d3d3'
  });
  if($(window).width() < 576) {
    $.sun('.risk__content[data-tab-id="2"] .sun', 'init', {
      firstAngle: -1.05,
      angleStep: 0.37,
      inactiveSize: 1,
      activeSize: 240, 
      radius: 160, 
      play: true,
      onClick: function (sun, pos, elem) {
          $.sun(sun, 'seek', pos);
          $.sun(sun, 'resetTimer');
      }
    });
    $('.ring').fadeOut();
  }
}