export function ourValuesEn(){
  return `<svg id="drzewo-wartosci" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1330.4425 1046.3044">
  <path class="cls-1" d="M61.88,838.3975c291.0258-1.0616,397.1849-2.0881,536.83.004,265.6568,3.98,62.32,21.91,15.1617,91.0251,25.45-23.6325,57.6675-34.4315,91.5992-38.3239,5.8426-.67,41.6711-.1259,43.8846-3.748-4.7737,7.8119-21.4212-11.5669-22.5707,105.3717,3.5792-25.8183,58.4663-114.5763,78.1325-108.8941,5.1907,1.5,11.4612,11.485,17.7928,14.4373,29.2859,13.6556,89.5995,13.8884,121.2444,46.5541-14.2138-36.867-51.7744-57.643-85.5665-73.6672-128.4065-60.8908-103.2649-11.4477-98.4822-214.7-52.9643,6.459-110.7806,5.8236-158.0325-22.1281-38.7283-22.91-71.2387-60.4061-86.9776-102.6719C471.794,415.91,592.6592,330.6646,661.7738,386.1491c-30.0721-72.6743-22.412-180.2216,53.6574-220.8833,91.0877-48.69,209.5938-14.2652,187.94,104.9664-7.2818,40.096,25.7358,16.2184,48.1972,10.7871,61.0146-14.7538,119.0065,80.9678,40.3906,153.0323,16.359,17.7668,70.8138,16.19,70.2256,81.3247-1.5118,167.38-334.311,159.8385-247.7435,24.6568,25.4934-39.81,44.3525-66.493,65.1011-109.3408-110.4456,105.745-80.06,157.92-96.3013-112.3995-11.0565,88.13-8.0373,99.035-12.59,187.6451-6.6724,129.8694.2669,96.4873-144.4912,8.0086,9.287,20.6221,89.6506,91.3712,135.0417,111.7924,8.3683,3.7647,32.0551,7.8689,36.8621,14.6676.992,1.4028,8.3275,145.6843,5.7066,182.95,184.1959,0,346.5256,1.2948,530.72.3117" transform="translate(-58.8798 50.5781)"/>
  <text class="cls-4" transform="translate(153.3301 600.1877)">Partnership</text>
  <text class="cls-4" transform="translate(185.5994 287.7373)">Responsibility</text>
  <text class="cls-4" transform="translate(722.8764 104.3331)">Satisfaction</text>
  <text class="cls-4" transform="translate(1075.6019 419.8826)">Innovation</text>
  <text class="cls-4" transform="translate(1050.1619 671.7559)">Development</text>
  <g id="partnerstwo" class="our-values__text">
  <g>
    <path class="cls-2" d="M248.2,589.456c0,116.162,94.507,210.67,210.67,210.67s210.664-94.508,210.664-210.67-94.5-210.67-210.664-210.67S248.2,473.294,248.2,589.456Z"/>
    <g class="cls-4">
      <g class="cls-4">
        <path class="cls-3" d="M368.036,520.027a.433.433,0,0,1,.042.364l-5.768,19.852a.533.533,0,0,1-.2.322.73.73,0,0,1-.42.1h-1.708c-.355,0-.569-.131-.644-.392l-4.48-15.875c-.056-.207-.122-.2-.2.027l-4.536,15.82a.533.533,0,0,1-.2.322.732.732,0,0,1-.42.1h-1.736q-.533,0-.644-.392l-5.684-19.88a.306.306,0,0,1-.028-.14c0-.242.14-.364.42-.364h1.792a.431.431,0,0,1,.476.336l4.564,16.3c.056.205.112.307.168.307.075,0,.13-.093.168-.28l4.648-16.352a.476.476,0,0,1,.5-.308h1.6a.652.652,0,0,1,.336.07.449.449,0,0,1,.168.266l4.592,16.381c.056.167.112.251.168.251s.112-.084.168-.251l4.62-16.409q.055-.308.476-.308h1.4A.4.4,0,0,1,368.036,520.027Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M373.216,536.659a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.87,6.87,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H372.81q-.252,0-.252.42A4.922,4.922,0,0,0,373.216,536.659Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.687,4.687,0,0,0-1.624,1.847,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M399.718,540.943a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.968,10.968,0,0,1,5.474-1.512l2.184-.168q.476-.055.476-.42V530.5a3.129,3.129,0,0,0-.938-2.394,3.551,3.551,0,0,0-2.534-.882,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.735,7.735,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,399.718,540.943Zm4.928-7.839-1.82.167a9.1,9.1,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.325,2.327,2.327,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.937,4.937,0,0,0,1.708-.308,5.036,5.036,0,0,0,1.484-.839,4.132,4.132,0,0,0,.994-1.079,2.131,2.131,0,0,0,.35-1.106v-2.324Q405.066,533.1,404.646,533.1Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M412.822,540.3V526.188c0-.244.1-.365.308-.365h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.128-2.491,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.363,2.363,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C412.952,540.663,412.822,540.542,412.822,540.3Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M426.808,536.659a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.3,8.3,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.87,6.87,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H426.4q-.252,0-.252.42A4.922,4.922,0,0,0,426.808,536.659Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.679,4.679,0,0,0-1.624,1.847,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M459.834,540.271v-1.2c0-.168-.047-.252-.14-.252a.384.384,0,0,0-.224.14,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.753,5.753,0,0,1-2.212-2.535,9.421,9.421,0,0,1-.812-4.1,9.664,9.664,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.957,5.957,0,0,1,3.389-.994,5.554,5.554,0,0,1,2.184.393,6.593,6.593,0,0,1,1.736,1.091.711.711,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364h1.708c.205,0,.308.122.308.364v19.964a.548.548,0,0,1-.084.35.483.483,0,0,1-.364.1h-1.428A.346.346,0,0,1,459.834,540.271Zm-1.89-12.53a4.3,4.3,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.6,7.007,7.007,0,0,0-1.288,4.508,6.517,6.517,0,0,0,1.274,4.4,4.138,4.138,0,0,0,3.262,1.456,3.97,3.97,0,0,0,2-.518,4.092,4.092,0,0,0,1.427-1.3,2.821,2.821,0,0,0,.519-1.568V529.1A7.24,7.24,0,0,0,457.944,527.741Z"/>
        <path class="cls-3" d="M467.842,540.3V526.188c0-.244.1-.365.307-.365h1.624a.273.273,0,0,1,.309.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.129-2.491,4.339-2.492.981,0,.981.364v1.68c0,.186-.084.262-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.364,2.364,0,0,0-.645,1.539v9.268a.371.371,0,0,1-.42.42h-1.54Q467.842,540.663,467.842,540.3Z"/>
        <path class="cls-3" d="M482.43,522.631a.6.6,0,0,1-.113.406.5.5,0,0,1-.391.126H480.3c-.281,0-.42-.158-.42-.475v-2.353c0-.3.13-.448.391-.448h1.765a.381.381,0,0,1,.279.112.413.413,0,0,1,.113.308Zm-.113,17.557a.494.494,0,0,1-.111.377.6.6,0,0,1-.393.1h-1.427a.485.485,0,0,1-.336-.084.433.433,0,0,1-.084-.308V526.215q0-.392.336-.392h1.708c.2,0,.307.121.307.365Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M500.391,525.949a.3.3,0,0,1,.014.266l-5.684,14.028a.605.605,0,0,1-.644.42h-1.008a.681.681,0,0,1-.7-.392l-5.656-14.028a.3.3,0,0,1-.028-.139q0-.281.336-.281h1.736a.468.468,0,0,1,.448.308l4.4,11c.056.187.121.28.2.28s.158-.1.252-.308l4.368-10.976a.391.391,0,0,1,.42-.308h1.288A.278.278,0,0,1,500.391,525.949Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M506.159,536.659a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.87,6.87,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H505.753q-.252,0-.252.42A4.922,4.922,0,0,0,506.159,536.659Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.679,4.679,0,0,0-1.624,1.847,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M521.6,540.3V526.188c0-.244.1-.365.308-.365h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.585,4.585,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08v10.444q0,.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.9,2.9,0,0,0-2.2-.756,4.615,4.615,0,0,0-2.17.518,13.608,13.608,0,0,0-2.422,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C521.731,540.663,521.6,540.542,521.6,540.3Z"/>
        <path class="cls-3" d="M547.221,540.663a.372.372,0,0,1-.449-.448V520.279c0-.261.113-.392.337-.392h1.736c.186,0,.28.131.28.392v6.86c0,.206.046.308.14.308a.624.624,0,0,0,.28-.14,6.312,6.312,0,0,1,4.48-1.764,5.162,5.162,0,0,1,4.466,2.114,9.184,9.184,0,0,1,1.526,5.5,8.786,8.786,0,0,1-1.694,5.7,5.709,5.709,0,0,1-4.69,2.086,5.334,5.334,0,0,1-2.828-.686,5.974,5.974,0,0,1-1.736-1.526.225.225,0,0,0-.168-.112c-.075,0-.122.066-.14.2l-.14,1.4a.73.73,0,0,1-.154.35.488.488,0,0,1-.35.1Zm1.9-5.012a3.1,3.1,0,0,0,.574,1.806,4.167,4.167,0,0,0,1.5,1.316,4.066,4.066,0,0,0,1.932.49,3.945,3.945,0,0,0,3.234-1.512,7.13,7.13,0,0,0,1.218-4.536,7.6,7.6,0,0,0-1.092-4.41,3.63,3.63,0,0,0-3.164-1.554,4.987,4.987,0,0,0-2.338.561,5.913,5.913,0,0,0-1.862,1.511Z"/>
        <path class="cls-3" d="M564.413,545.228c-.243-.039-.365-.141-.365-.309v-1.092a.294.294,0,0,1,.155-.28,1.524,1.524,0,0,1,.63-.084H566.6a1.832,1.832,0,0,0,1.232-.49,3.7,3.7,0,0,0,1.2-2.618,1.674,1.674,0,0,0-.113-.672L563.573,526.3a.381.381,0,0,1-.029-.168c0-.2.113-.308.336-.308h1.737a.466.466,0,0,1,.447.308L570.237,537c.055.168.13.252.223.252.113,0,.206-.112.281-.336l4.115-10.78a.437.437,0,0,1,.393-.308h1.315a.28.28,0,0,1,.252.126.27.27,0,0,1,.029.266l-6.16,15.764a5.217,5.217,0,0,1-1.876,2.6,5.729,5.729,0,0,1-3.053.7A10.624,10.624,0,0,1,564.413,545.228Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M361.316,571.794a10.439,10.439,0,0,0,2.254-1.806V559.544q0-.391.448-.392h1.54a.347.347,0,0,1,.392.392l-.056,14.084q0,.364-.308.364h-1.624a.271.271,0,0,1-.308-.307v-1.653c0-.223-.084-.252-.252-.084a11.5,11.5,0,0,1-2.688,1.806,5.908,5.908,0,0,1-2.408.518,4.531,4.531,0,0,1-3.22-1.176,4.05,4.05,0,0,1-1.26-3.108V559.572a.371.371,0,0,1,.42-.42h1.568q.364,0,.364.392v9.828a3.138,3.138,0,0,0,.77,2.227,2.75,2.75,0,0,0,2.114.825A4.508,4.508,0,0,0,361.316,571.794Z"/>
        <path class="cls-3" d="M371.634,573.628V559.517c0-.244.1-.365.307-.365h1.681a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.407,15.407,0,0,1,2.673-1.764,5.658,5.658,0,0,1,2.479-.532,4.577,4.577,0,0,1,3.317,1.149,4.079,4.079,0,0,1,1.191,3.08v10.443q0,.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.012,3.012,0,0,0-.715-2.155,2.888,2.888,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75v10.584a.356.356,0,0,1-.42.42h-1.54Q371.634,573.992,371.634,573.628Z"/>
        <path class="cls-3" d="M399.55,573.6V572.4c0-.167-.047-.251-.14-.251a.381.381,0,0,0-.224.139,8.867,8.867,0,0,1-1.834,1.414,5.091,5.091,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.753,5.753,0,0,1-2.212-2.535,9.418,9.418,0,0,1-.812-4.1,9.668,9.668,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.955,5.955,0,0,1,3.389-.994,5.557,5.557,0,0,1,2.184.393,6.593,6.593,0,0,1,1.736,1.091.711.711,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364h1.708c.2,0,.307.122.307.364v19.964a.547.547,0,0,1-.084.351.484.484,0,0,1-.363.1h-1.429A.346.346,0,0,1,399.55,573.6Zm-1.89-12.531a4.3,4.3,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.6,7.009,7.009,0,0,0-1.289,4.508,6.517,6.517,0,0,0,1.275,4.4,4.135,4.135,0,0,0,3.262,1.456,3.97,3.97,0,0,0,2-.518,4.092,4.092,0,0,0,1.427-1.3,2.821,2.821,0,0,0,.519-1.568v-6.748A7.24,7.24,0,0,0,397.66,561.07Z"/>
        <path class="cls-3" d="M409.783,569.988a4.964,4.964,0,0,0,1.779,1.821,4.705,4.705,0,0,0,2.463.671,4.983,4.983,0,0,0,4.369-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.2.042l1.177.588c.148.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.871,7.871,0,0,1-3.612.77,7.108,7.108,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.288,8.288,0,0,1-.911-3.934,8.913,8.913,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.989,6.989,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.982,5.982,0,0,1,2.367,2.617,9.218,9.218,0,0,1,.839,4.074v.112a.7.7,0,0,1-.083.378.378.378,0,0,1-.336.126H409.378q-.252,0-.252.42A4.928,4.928,0,0,0,409.783,569.988Zm7.91-4.452a.7.7,0,0,0,.393-.07q.084-.069.084-.321a5.084,5.084,0,0,0-.519-2.283,4.1,4.1,0,0,0-1.511-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.679,4.679,0,0,0-1.624,1.847,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M425.226,573.628V559.517c0-.244.1-.365.307-.365h1.624a.273.273,0,0,1,.309.308v1.82c0,.113.032.178.1.2s.136-.019.21-.113q2.128-2.492,4.34-2.492.981,0,.981.364v1.68q0,.28-.252.225a7.75,7.75,0,0,0-1.26-.084,3.984,3.984,0,0,0-1.765.475,5,5,0,0,0-1.6,1.233,2.366,2.366,0,0,0-.645,1.539v9.268a.37.37,0,0,1-.42.42h-1.54C425.355,573.992,425.226,573.871,425.226,573.628Z"/>
        <path class="cls-3" d="M444.644,561.042a5.173,5.173,0,0,0-2.367-.518,4.429,4.429,0,0,0-2.519.659,1.992,1.992,0,0,0-.981,1.721,1.644,1.644,0,0,0,.491,1.162,4.137,4.137,0,0,0,1.778.911l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.422,3.422,0,0,1,.895,2.366,3.6,3.6,0,0,1-.783,2.283,5.07,5.07,0,0,1-2.156,1.54,9.085,9.085,0,0,1-6.945-.309,5.175,5.175,0,0,1-2.379-2.226.477.477,0,0,1-.084-.224.253.253,0,0,1,.139-.224l1.093-.616c.186-.111.326-.1.42.028a5.129,5.129,0,0,0,4.676,2.408,5.332,5.332,0,0,0,2.729-.686,1.963,1.963,0,0,0,1.078-1.722,1.647,1.647,0,0,0-.643-1.316,5.985,5.985,0,0,0-2.1-.951l-2.884-.813a5.982,5.982,0,0,1-2.925-1.484,3.292,3.292,0,0,1-.882-2.323,3.873,3.873,0,0,1,.714-2.3,4.546,4.546,0,0,1,2.015-1.555,8.31,8.31,0,0,1,6.3.154,5.367,5.367,0,0,1,2.254,1.9c.13.186.121.317-.028.391l-1.176.644a.422.422,0,0,1-.168.056.386.386,0,0,1-.252-.14A4.2,4.2,0,0,0,444.644,561.042Z"/>
        <path class="cls-3" d="M457.006,560.889c-.151,0-.225.084-.225.252v8.876a2.682,2.682,0,0,0,.42,1.735,1.747,1.747,0,0,0,1.372.477h1.933a.271.271,0,0,1,.307.307l-.028.952c0,.187-.111.308-.336.365a16.426,16.426,0,0,1-2.632.139,3.485,3.485,0,0,1-2.576-.826,3.62,3.62,0,0,1-.811-2.618V561.2c0-.205-.094-.307-.281-.307h-2.24c-.2,0-.307-.094-.307-.281v-1.175a.248.248,0,0,1,.28-.281h2.38c.13,0,.195-.074.195-.224l.309-4.451c0-.244.1-.365.307-.365h1.4c.223,0,.336.131.336.392v4.424a.2.2,0,0,0,.223.224h3.529c.2,0,.307.084.307.252v1.2c0,.187-.1.281-.307.281Z"/>
        <path class="cls-3" d="M468.961,574.272a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.963,10.963,0,0,1,5.474-1.513l2.184-.168c.317-.037.477-.177.477-.42v-.756a3.126,3.126,0,0,0-.939-2.393,3.545,3.545,0,0,0-2.534-.883,4.738,4.738,0,0,0-4.031,2.24.482.482,0,0,1-.168.183.279.279,0,0,1-.225-.015l-1.512-.5a.2.2,0,0,1-.139-.2.628.628,0,0,1,.084-.252,5.825,5.825,0,0,1,2.393-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.426,4.426,0,0,1,1.456,3.514v9.912a.485.485,0,0,1-.084.336.432.432,0,0,1-.307.084h-1.4c-.223,0-.354-.149-.391-.448l-.057-1.288c-.019-.13-.066-.195-.14-.195a.384.384,0,0,0-.252.139A6.974,6.974,0,0,1,468.961,574.272Zm4.929-7.839-1.821.168a9.085,9.085,0,0,0-4.017,1.007,2.587,2.587,0,0,0-1.414,2.325,2.329,2.329,0,0,0,.853,1.9,3.489,3.489,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.048,5.048,0,0,0,1.484-.84,4.11,4.11,0,0,0,.994-1.078,2.136,2.136,0,0,0,.351-1.107V566.88C474.31,566.582,474.169,566.433,473.89,566.433Z"/>
        <path class="cls-3" d="M482.064,573.628V559.517c0-.244.1-.365.309-.365h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.654,5.654,0,0,1,2.478-.532,4.579,4.579,0,0,1,3.318,1.149,4.081,4.081,0,0,1,1.189,3.08v10.443c0,.3-.139.448-.419.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.017,3.017,0,0,0-.714-2.155,2.894,2.894,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.608,13.608,0,0,0-2.422,1.75v10.584a.357.357,0,0,1-.42.42h-1.54Q482.064,573.992,482.064,573.628Z"/>
        <path class="cls-3" d="M509.98,573.6V572.4c0-.167-.046-.251-.139-.251a.381.381,0,0,0-.224.139,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.767,5.767,0,0,1-2.213-2.535,9.432,9.432,0,0,1-.811-4.1,9.668,9.668,0,0,1,.84-4.144,6.674,6.674,0,0,1,2.324-2.786,5.95,5.95,0,0,1,3.388-.994,5.557,5.557,0,0,1,2.184.393,6.593,6.593,0,0,1,1.736,1.091.711.711,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.307-.364h1.708c.206,0,.309.122.309.364v19.964a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1h-1.428A.347.347,0,0,1,509.98,573.6Zm-1.889-12.531a4.3,4.3,0,0,0-1.974-.462,4.173,4.173,0,0,0-3.416,1.6,7.009,7.009,0,0,0-1.288,4.508,6.517,6.517,0,0,0,1.274,4.4,4.137,4.137,0,0,0,3.262,1.456,3.97,3.97,0,0,0,2-.518,4.1,4.1,0,0,0,1.428-1.3,2.826,2.826,0,0,0,.517-1.568v-6.748A7.218,7.218,0,0,0,508.091,561.07Z"/>
        <path class="cls-3" d="M520.453,555.96a.606.606,0,0,1-.112.406.508.508,0,0,1-.392.126h-1.624c-.28,0-.421-.158-.421-.475v-2.353c0-.3.131-.448.393-.448h1.764a.383.383,0,0,1,.28.112.42.42,0,0,1,.112.308Zm-.112,17.557a.5.5,0,0,1-.112.378.606.606,0,0,1-.392.1h-1.428a.486.486,0,0,1-.336-.084.43.43,0,0,1-.085-.307V559.544c0-.261.113-.392.337-.392h1.708c.205,0,.308.121.308.365Z"/>
        <path class="cls-3" d="M526.08,573.628V559.517c0-.244.1-.365.309-.365h1.679a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084A15.334,15.334,0,0,1,531.3,559.4a5.649,5.649,0,0,1,2.477-.532,4.582,4.582,0,0,1,3.319,1.149,4.081,4.081,0,0,1,1.189,3.08v10.443q0,.448-.42.448H536.3a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.155,2.9,2.9,0,0,0-2.2-.757,4.63,4.63,0,0,0-2.17.518,13.607,13.607,0,0,0-2.421,1.75v10.584a.452.452,0,0,1-.1.323.446.446,0,0,1-.322.1h-1.54Q526.08,573.992,526.08,573.628Z"/>
        <path class="cls-3" d="M557.776,559.349v.979a.556.556,0,0,1-.126.406.528.528,0,0,1-.433.1,3.456,3.456,0,0,0-1.456.252c-.448.187-.635.4-.561.645a3.06,3.06,0,0,0,.168.419,3.516,3.516,0,0,1,.225.686,3.74,3.74,0,0,1,.084.8,3.992,3.992,0,0,1-1.555,3.248,7.033,7.033,0,0,1-4.521,1.261,8.627,8.627,0,0,0-2.857.336c-.578.223-.867.513-.867.867,0,.28.2.49.588.631a7.974,7.974,0,0,0,1.96.294l3.611.2a5.958,5.958,0,0,1,3.584,1.148,3.427,3.427,0,0,1,1.177,2.744,3.768,3.768,0,0,1-1.862,3.332,9.78,9.78,0,0,1-5.306,1.205,11.483,11.483,0,0,1-5.418-1.023,3.194,3.194,0,0,1-1.862-2.926q0-1.876,2.631-3.164c.206-.093.206-.2,0-.307a1.794,1.794,0,0,1-1.288-1.653,1.642,1.642,0,0,1,.575-1.175,4.3,4.3,0,0,1,1.637-.925c.094-.018.141-.065.141-.14a.2.2,0,0,0-.141-.2,4.667,4.667,0,0,1-1.819-1.554,3.953,3.953,0,0,1-.589-2.2,4.1,4.1,0,0,1,1.652-3.444,7.584,7.584,0,0,1,4.676-1.26,6.444,6.444,0,0,1,4.2,1.26.611.611,0,0,0,.281.14.6.6,0,0,0,.307-.14,5.209,5.209,0,0,1,1.246-.91,2.962,2.962,0,0,1,1.386-.378Q557.777,558.9,557.776,559.349Zm-10.639,12.823a2.345,2.345,0,0,0-1.149.294,3.114,3.114,0,0,0-1.092.91,2.084,2.084,0,0,0-.448,1.288,2.235,2.235,0,0,0,1.372,2.059,9.081,9.081,0,0,0,4.061.714q4.731,0,4.731-2.8a1.915,1.915,0,0,0-.658-1.54,3.617,3.617,0,0,0-2.141-.644Zm5.361-6.3a2.747,2.747,0,0,0,.967-2.268,2.931,2.931,0,0,0-.981-2.352,4.32,4.32,0,0,0-2.883-.84,4.4,4.4,0,0,0-2.884.855,2.862,2.862,0,0,0-1.036,2.337,2.694,2.694,0,0,0,1.021,2.254,4.613,4.613,0,0,0,2.9.8A4.525,4.525,0,0,0,552.5,565.872Z"/>
        <path class="cls-3" d="M561.192,577.884a.423.423,0,0,1,0-.308l1.26-3.136a.628.628,0,0,0,.084-.252c0-.13-.1-.2-.307-.2h-.365q-.365,0-.364-.336v-2.492c0-.205.094-.308.28-.308H564.1a.248.248,0,0,1,.281.28v2.3a.654.654,0,0,1-.084.336l-2.044,3.919a.587.587,0,0,1-.561.308h-.307A.2.2,0,0,1,561.192,577.884Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M382.75,592.608a.273.273,0,0,1,.028.266l-4.284,14.084a.5.5,0,0,1-.532.364h-1.344a.536.536,0,0,1-.588-.364L372.7,596.486c-.056-.168-.122-.252-.2-.252-.038,0-.084.084-.14.252L369.2,606.958a.5.5,0,0,1-.532.364h-1.428a.536.536,0,0,1-.588-.364l-4.4-14.056a.3.3,0,0,1-.028-.139q0-.28.336-.281h1.652a.468.468,0,0,1,.448.308l3.332,10.893c.056.186.121.279.2.279.056,0,.13-.1.224-.308l3.3-10.891a.369.369,0,0,1,.392-.281h1.316a.391.391,0,0,1,.42.308l3.444,10.893c.056.186.121.279.2.279s.14-.1.2-.308l3.22-10.864a.486.486,0,0,1,.476-.308h1.12A.283.283,0,0,1,382.75,592.608Z"/>
        <path class="cls-3" d="M389.666,589.29a.6.6,0,0,1-.113.406.5.5,0,0,1-.391.126h-1.624q-.422,0-.421-.475v-2.353c0-.3.131-.448.392-.448h1.764a.381.381,0,0,1,.28.112.417.417,0,0,1,.113.308Zm-.113,17.557a.494.494,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.427a.485.485,0,0,1-.336-.084.425.425,0,0,1-.085-.307V592.874q0-.391.337-.392h1.708q.308,0,.307.365Z"/>
        <path class="cls-3" d="M397.674,606.847a.5.5,0,0,1-.112.378.606.606,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.43.43,0,0,1-.085-.307l.029-19.993q0-.391.336-.392h1.68c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M405.766,606.847a.494.494,0,0,1-.113.378.6.6,0,0,1-.391.1h-1.456a.485.485,0,0,1-.336-.084.43.43,0,0,1-.085-.307l.029-19.993c0-.261.111-.392.336-.392h1.68c.223,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M413.941,589.29a.6.6,0,0,1-.112.406.505.505,0,0,1-.391.126h-1.625q-.42,0-.42-.475v-2.353c0-.3.13-.448.392-.448h1.764a.383.383,0,0,1,.28.112.416.416,0,0,1,.112.308Zm-.112,17.557a.494.494,0,0,1-.112.378.6.6,0,0,1-.392.1H411.9a.482.482,0,0,1-.335-.084.425.425,0,0,1-.085-.307V592.874q0-.391.336-.392h1.708c.206,0,.308.122.308.365Z"/>
        <path class="cls-3" d="M419.569,606.958V592.847q0-.364.308-.365h1.681a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.577,4.577,0,0,1,3.317,1.149,4.078,4.078,0,0,1,1.19,3.08v10.443c0,.3-.139.448-.42.448H429.79a.321.321,0,0,1-.364-.364v-10a3.014,3.014,0,0,0-.715-2.155,2.89,2.89,0,0,0-2.2-.757,4.624,4.624,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75V606.9a.356.356,0,0,1-.42.42h-1.54Q419.569,607.322,419.569,606.958Z"/>
        <path class="cls-3" d="M451.266,592.679v.979a.556.556,0,0,1-.126.406.531.531,0,0,1-.434.1,3.453,3.453,0,0,0-1.456.252c-.448.188-.635.4-.561.645a3.255,3.255,0,0,0,.168.42,3.483,3.483,0,0,1,.225.685,3.677,3.677,0,0,1,.084.8,3.992,3.992,0,0,1-1.555,3.248,7.034,7.034,0,0,1-4.522,1.261,8.628,8.628,0,0,0-2.856.336c-.578.223-.867.513-.867.867q0,.42.588.631a7.982,7.982,0,0,0,1.96.294l3.611.195a5.965,5.965,0,0,1,3.584,1.148,3.425,3.425,0,0,1,1.177,2.745,3.769,3.769,0,0,1-1.863,3.332,9.783,9.783,0,0,1-5.305,1.2A11.483,11.483,0,0,1,437.7,611.2a3.194,3.194,0,0,1-1.863-2.926q0-1.876,2.633-3.164c.2-.093.2-.2,0-.307a1.793,1.793,0,0,1-1.288-1.653,1.643,1.643,0,0,1,.573-1.175,4.329,4.329,0,0,1,1.639-.925c.093-.018.14-.065.14-.14a.2.2,0,0,0-.14-.2,4.656,4.656,0,0,1-1.82-1.554,3.961,3.961,0,0,1-.589-2.2,4.093,4.093,0,0,1,1.653-3.443,7.57,7.57,0,0,1,4.675-1.261,6.444,6.444,0,0,1,4.2,1.261.38.38,0,0,0,.588,0,5.146,5.146,0,0,1,1.246-.911,2.955,2.955,0,0,1,1.385-.378Q451.266,592.23,451.266,592.679ZM440.625,605.5a2.338,2.338,0,0,0-1.147.294,3.1,3.1,0,0,0-1.092.91,2.078,2.078,0,0,0-.448,1.288,2.235,2.235,0,0,0,1.372,2.059,9.074,9.074,0,0,0,4.06.714q4.731,0,4.732-2.8a1.916,1.916,0,0,0-.659-1.54,3.617,3.617,0,0,0-2.141-.644Zm5.362-6.3a2.749,2.749,0,0,0,.967-2.267,2.934,2.934,0,0,0-.98-2.353,4.326,4.326,0,0,0-2.885-.84,4.4,4.4,0,0,0-2.883.855,2.866,2.866,0,0,0-1.037,2.338,2.694,2.694,0,0,0,1.022,2.253,4.612,4.612,0,0,0,2.9.8A4.526,4.526,0,0,0,445.987,599.2Z"/>
        <path class="cls-3" d="M455.577,606.958V592.847q0-.364.308-.365h1.68a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.251.084a15.409,15.409,0,0,1,2.674-1.764,5.657,5.657,0,0,1,2.478-.532,4.578,4.578,0,0,1,3.318,1.149,4.078,4.078,0,0,1,1.19,3.08v10.443c0,.3-.139.448-.42.448H465.8a.321.321,0,0,1-.363-.364v-10a3.019,3.019,0,0,0-.715-2.155,2.891,2.891,0,0,0-2.2-.757,4.623,4.623,0,0,0-2.169.518,13.573,13.573,0,0,0-2.423,1.75V606.9a.452.452,0,0,1-.1.323.447.447,0,0,1-.323.1h-1.54Q455.578,607.322,455.577,606.958Z"/>
        <path class="cls-3" d="M475.555,603.318a4.969,4.969,0,0,0,1.778,1.821,4.71,4.71,0,0,0,2.464.672,4.984,4.984,0,0,0,4.369-2.325.407.407,0,0,1,.195-.126.339.339,0,0,1,.2.042l1.176.588c.149.094.168.224.057.392a6.835,6.835,0,0,1-2.6,2.45,7.872,7.872,0,0,1-3.613.771,7.114,7.114,0,0,1-3.668-.953,6.6,6.6,0,0,1-2.533-2.673,8.3,8.3,0,0,1-.911-3.935,8.913,8.913,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,7,7,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.98,5.98,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.376.376,0,0,1-.335.126H475.149q-.252,0-.252.42A4.929,4.929,0,0,0,475.555,603.318Zm7.91-4.452a.7.7,0,0,0,.392-.07c.057-.046.084-.153.084-.321a5.083,5.083,0,0,0-.518-2.283,4.1,4.1,0,0,0-1.512-1.679,4.21,4.21,0,0,0-2.309-.631,4.359,4.359,0,0,0-2.493.715,4.687,4.687,0,0,0-1.624,1.847,5.152,5.152,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M498.291,594.372a5.171,5.171,0,0,0-2.366-.518,4.43,4.43,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.647,1.647,0,0,0,.49,1.162,4.137,4.137,0,0,0,1.778.911l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.589,3.589,0,0,1-.784,2.283,5.07,5.07,0,0,1-2.156,1.54,9.071,9.071,0,0,1-6.944-.309,5.172,5.172,0,0,1-2.38-2.226.477.477,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.092-.616c.186-.111.326-.1.42.028a5.13,5.13,0,0,0,4.676,2.409,5.346,5.346,0,0,0,2.73-.687,1.964,1.964,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,5.988,5.988,0,0,0-2.1-.951l-2.884-.813a5.985,5.985,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.873,3.873,0,0,1,.714-2.3,4.549,4.549,0,0,1,2.016-1.555,8.308,8.308,0,0,1,6.3.154,5.361,5.361,0,0,1,2.254,1.9c.13.186.121.317-.028.391l-1.176.644a.422.422,0,0,1-.168.056.382.382,0,0,1-.252-.14A4.2,4.2,0,0,0,498.291,594.372Z"/>
        <path class="cls-3" d="M514.027,594.372a5.171,5.171,0,0,0-2.366-.518,4.43,4.43,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.647,1.647,0,0,0,.49,1.162,4.137,4.137,0,0,0,1.778.911l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.589,3.589,0,0,1-.784,2.283,5.07,5.07,0,0,1-2.156,1.54,9.071,9.071,0,0,1-6.944-.309,5.172,5.172,0,0,1-2.38-2.226.477.477,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.092-.616c.186-.111.326-.1.42.028A5.13,5.13,0,0,0,511.6,605.9a5.34,5.34,0,0,0,2.73-.687,1.963,1.963,0,0,0,1.079-1.722,1.645,1.645,0,0,0-.644-1.316,5.985,5.985,0,0,0-2.1-.951l-2.883-.813a5.985,5.985,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.879,3.879,0,0,1,.713-2.3,4.557,4.557,0,0,1,2.017-1.555,8.308,8.308,0,0,1,6.3.154,5.361,5.361,0,0,1,2.254,1.9c.13.186.121.317-.029.391l-1.175.644a.422.422,0,0,1-.168.056.382.382,0,0,1-.252-.14A4.2,4.2,0,0,0,514.027,594.372Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M533.808,594.219c-.15,0-.224.084-.224.252v8.876a2.674,2.674,0,0,0,.42,1.735,1.747,1.747,0,0,0,1.372.477h1.932a.272.272,0,0,1,.308.307l-.028.952c0,.187-.112.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.484,3.484,0,0,1-2.576-.826,3.616,3.616,0,0,1-.812-2.618v-9.352c0-.2-.094-.307-.28-.307h-2.24c-.206,0-.308-.094-.308-.281v-1.175a.248.248,0,0,1,.28-.281h2.38c.13,0,.2-.074.2-.224l.308-4.451c0-.244.1-.365.308-.365h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.2,0,.308.084.308.252v1.2c0,.187-.1.281-.308.281Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M544.267,606.637a6.525,6.525,0,0,1-2.492-2.7,9.335,9.335,0,0,1,.028-7.981,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.485,8.485,0,0,1-.9,3.948,6.718,6.718,0,0,1-2.506,2.716,6.936,6.936,0,0,1-3.682.981A7.079,7.079,0,0,1,544.267,606.637Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.071,4.071,0,0,0-3.4-1.61,4.137,4.137,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.045,4.045,0,0,0,551.435,604.256Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M367.812,626.483a7.077,7.077,0,0,1,2.31,2.52l.056.225c0,.112-.066.177-.2.2l-1.54.363-.084.028c-.093,0-.187-.074-.28-.223a7.611,7.611,0,0,0-1.722-1.765,3.289,3.289,0,0,0-1.89-.559,4.215,4.215,0,0,0-3.472,1.609,7.992,7.992,0,0,0,.014,8.723,4.3,4.3,0,0,0,3.514,1.6,4.157,4.157,0,0,0,2.31-.643,6.016,6.016,0,0,0,1.806-1.989c.056-.093.112-.144.168-.154a.331.331,0,0,1,.2.042l1.2.448c.13.056.168.178.112.365a7.29,7.29,0,0,1-2.38,2.645,6.291,6.291,0,0,1-3.612,1.023,6.9,6.9,0,0,1-3.64-.966,6.652,6.652,0,0,1-2.492-2.7,8.554,8.554,0,0,1-.9-3.977,8.8,8.8,0,0,1,.9-4.017,6.638,6.638,0,0,1,2.506-2.758,6.829,6.829,0,0,1,3.626-.981A6.191,6.191,0,0,1,367.812,626.483Z"/>
        <path class="cls-3" d="M377.248,639.966a6.525,6.525,0,0,1-2.492-2.7,9.335,9.335,0,0,1,.028-7.981,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.485,8.485,0,0,1-.9,3.948,6.712,6.712,0,0,1-2.506,2.716,6.936,6.936,0,0,1-3.682.981A7.079,7.079,0,0,1,377.248,639.966Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.071,4.071,0,0,0-3.4-1.609,4.135,4.135,0,0,0-3.443,1.609,8.123,8.123,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.045,4.045,0,0,0,384.416,637.585Z"/>
        <path class="cls-3" d="M395.42,639.966a6.525,6.525,0,0,1-2.492-2.7,9.335,9.335,0,0,1,.028-7.981,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.473,8.473,0,0,1-.9,3.948,6.7,6.7,0,0,1-2.5,2.716,6.936,6.936,0,0,1-3.682.981A7.076,7.076,0,0,1,395.42,639.966Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.071,4.071,0,0,0-3.4-1.609,4.135,4.135,0,0,0-3.443,1.609,8.123,8.123,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.047,4.047,0,0,0,402.588,637.585Z"/>
        <path class="cls-3" d="M413.41,626.231v1.176c0,.168.046.252.14.252a.384.384,0,0,0,.224-.139,14.038,14.038,0,0,1,1.036-.953,4.994,4.994,0,0,1,1.316-.7,5.67,5.67,0,0,1,2.044-.336,5.966,5.966,0,0,1,3.15.84,5.711,5.711,0,0,1,2.184,2.521,9.548,9.548,0,0,1,.8,4.116,10.4,10.4,0,0,1-.8,4.256,5.873,5.873,0,0,1-5.642,3.668,5.943,5.943,0,0,1-3.976-1.4c-.131-.13-.234-.2-.308-.2-.094,0-.14.122-.14.364v5.208c0,.242-.1.364-.308.364h-1.736c-.206,0-.308-.122-.308-.364V626.26a.492.492,0,0,1,.1-.336.448.448,0,0,1,.35-.112h1.484C413.279,625.812,413.41,625.951,413.41,626.231Zm1.834,12.531a4.372,4.372,0,0,0,2,.462,4.1,4.1,0,0,0,3.374-1.6,7.214,7.214,0,0,0,1.274-4.592,6.463,6.463,0,0,0-1.26-4.353,4.382,4.382,0,0,0-5.264-.953,4.011,4.011,0,0,0-1.414,1.247,2.618,2.618,0,0,0-.518,1.483v6.916A6.949,6.949,0,0,0,415.244,638.762Z"/>
        <path class="cls-3" d="M431.427,636.647a4.954,4.954,0,0,0,1.778,1.821,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.409.409,0,0,1,.2-.126.339.339,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.825,6.825,0,0,1-2.6,2.45,7.872,7.872,0,0,1-3.613.771,7.112,7.112,0,0,1-3.668-.953,6.605,6.605,0,0,1-2.534-2.673,8.306,8.306,0,0,1-.91-3.935,8.913,8.913,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.994,6.994,0,0,1,3.626-.952,6.861,6.861,0,0,1,3.542.9,5.974,5.974,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H431.021q-.251,0-.251.42A4.918,4.918,0,0,0,431.427,636.647Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.083,5.083,0,0,0-.518-2.283,4.11,4.11,0,0,0-1.512-1.679,4.212,4.212,0,0,0-2.309-.631,4.359,4.359,0,0,0-2.493.715,4.686,4.686,0,0,0-1.624,1.847,5.14,5.14,0,0,0-.532,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M446.869,640.287V626.176c0-.243.1-.364.308-.364H448.8a.272.272,0,0,1,.308.307v1.82c0,.113.032.178.1.2s.135-.019.21-.113q2.127-2.492,4.34-2.492.98,0,.98.365v1.679q0,.28-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.365,2.365,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C447,640.651,446.869,640.53,446.869,640.287Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M462.549,640.932a4.981,4.981,0,0,1-3.43-1.107,3.751,3.751,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.957,10.957,0,0,1,5.474-1.513l2.184-.168q.476-.056.476-.42v-.756a3.127,3.127,0,0,0-.938-2.393,3.547,3.547,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.747,7.747,0,0,1,3.682-.826,6.188,6.188,0,0,1,4.2,1.275,4.425,4.425,0,0,1,1.456,3.513v9.912a.485.485,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.195-.14-.195a.389.389,0,0,0-.252.139A6.979,6.979,0,0,1,462.549,640.932Zm4.928-7.84-1.82.168a9.079,9.079,0,0,0-4.018,1.008,2.588,2.588,0,0,0-1.414,2.324,2.326,2.326,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.936,4.936,0,0,0,1.708-.307,5.026,5.026,0,0,0,1.484-.84,4.129,4.129,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q467.9,633.092,467.477,633.092Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M479.573,627.548c-.15,0-.224.084-.224.252v8.876a2.674,2.674,0,0,0,.42,1.735,1.743,1.743,0,0,0,1.372.477h1.932a.272.272,0,0,1,.308.307l-.028.952c0,.187-.112.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618v-9.352c0-.205-.094-.307-.28-.307h-2.24c-.206,0-.308-.094-.308-.28v-1.176a.247.247,0,0,1,.28-.28h2.38c.13,0,.2-.075.2-.225l.308-4.451q0-.364.308-.365h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.225h3.528c.2,0,.308.084.308.251v1.205c0,.186-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M489.752,636.647a4.954,4.954,0,0,0,1.778,1.821,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.413.413,0,0,1,.2-.126.339.339,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.114,7.114,0,0,1-3.668-.953,6.605,6.605,0,0,1-2.534-2.673,8.294,8.294,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.994,6.994,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.986,5.986,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H489.346q-.252,0-.252.42A4.919,4.919,0,0,0,489.752,636.647Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.1,5.1,0,0,0-.518-2.283,4.1,4.1,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.693,4.693,0,0,0-1.624,1.847,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M516.254,640.932a4.981,4.981,0,0,1-3.43-1.107,3.751,3.751,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.957,10.957,0,0,1,5.474-1.513l2.184-.168q.476-.056.476-.42v-.756a3.127,3.127,0,0,0-.938-2.393,3.545,3.545,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.747,7.747,0,0,1,3.682-.826,6.188,6.188,0,0,1,4.2,1.275,4.425,4.425,0,0,1,1.456,3.513v9.912a.485.485,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.195-.14-.195a.389.389,0,0,0-.252.139A6.979,6.979,0,0,1,516.254,640.932Zm4.928-7.84-1.82.168a9.079,9.079,0,0,0-4.018,1.008,2.588,2.588,0,0,0-1.414,2.324,2.326,2.326,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.026,5.026,0,0,0,1.484-.84,4.112,4.112,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q521.6,633.092,521.182,633.092Z"/>
        <path class="cls-3" d="M529.357,640.287V626.176q0-.365.309-.364h1.68a.272.272,0,0,1,.308.307v1.624q0,.309.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.577,4.577,0,0,1,3.317,1.149,4.078,4.078,0,0,1,1.19,3.08V640.2c0,.3-.139.448-.419.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.018,3.018,0,0,0-.714-2.155,2.894,2.894,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75v10.584a.356.356,0,0,1-.419.42h-1.54Q529.357,640.651,529.357,640.287Z"/>
        <path class="cls-3" d="M557.273,640.26v-1.2c0-.168-.046-.252-.139-.252a.387.387,0,0,0-.224.139,8.833,8.833,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.575,6.09,6.09,0,0,1-3.192-.84,5.758,5.758,0,0,1-2.213-2.534,9.437,9.437,0,0,1-.811-4.1,9.66,9.66,0,0,1,.84-4.143,6.668,6.668,0,0,1,2.323-2.787,5.955,5.955,0,0,1,3.389-.994,5.561,5.561,0,0,1,2.184.393,6.613,6.613,0,0,1,1.736,1.092.717.717,0,0,0,.308.168c.093,0,.14-.121.14-.365v-6.58c0-.242.1-.364.307-.364h1.708c.206,0,.309.122.309.364V640.2a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1h-1.428A.346.346,0,0,1,557.273,640.26Zm-1.889-12.531a4.3,4.3,0,0,0-1.974-.461,4.171,4.171,0,0,0-3.416,1.6,7.01,7.01,0,0,0-1.288,4.508,6.527,6.527,0,0,0,1.273,4.4,4.143,4.143,0,0,0,3.263,1.456,3.971,3.971,0,0,0,2-.519,4.1,4.1,0,0,0,1.427-1.3,2.819,2.819,0,0,0,.518-1.568v-6.748A7.218,7.218,0,0,0,555.384,627.729Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M358.474,673.616V659.505c0-.243.1-.364.308-.364h1.624a.272.272,0,0,1,.308.307v1.821c0,.112.032.177.1.2s.135-.019.21-.112q2.127-2.493,4.34-2.493.979,0,.98.365V660.9q0,.281-.252.225a7.75,7.75,0,0,0-1.26-.084,3.965,3.965,0,0,0-1.764.476,4.983,4.983,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.539v9.269a.371.371,0,0,1-.42.419h-1.54C358.6,673.98,358.474,673.859,358.474,673.616Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M372.461,669.977a4.959,4.959,0,0,0,1.778,1.82,4.707,4.707,0,0,0,2.464.672,4.984,4.984,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.324.324,0,0,1,.2.042l1.176.587c.149.094.168.224.056.392a6.82,6.82,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.123,7.123,0,0,1-3.668-.952,6.614,6.614,0,0,1-2.534-2.674,8.294,8.294,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.637,6.637,0,0,1,2.534-2.729,6.985,6.985,0,0,1,3.626-.953,6.859,6.859,0,0,1,3.542.9,5.991,5.991,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.113a.684.684,0,0,1-.084.377.378.378,0,0,1-.336.126H372.055q-.252,0-.252.42A4.925,4.925,0,0,0,372.461,669.977Zm7.91-4.453a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.092,5.092,0,0,0-.518-2.282,4.106,4.106,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.689,4.689,0,0,0-1.624,1.848,5.133,5.133,0,0,0-.532,2.421Z"/>
        <path class="cls-3" d="M395.2,661.03a5.181,5.181,0,0,0-2.366-.517,4.43,4.43,0,0,0-2.52.658,1.993,1.993,0,0,0-.98,1.722,1.65,1.65,0,0,0,.49,1.162,4.148,4.148,0,0,0,1.778.91l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.42,3.42,0,0,1,.9,2.367,3.588,3.588,0,0,1-.784,2.282,5.055,5.055,0,0,1-2.156,1.54,9.071,9.071,0,0,1-6.944-.309,5.18,5.18,0,0,1-2.38-2.225.48.48,0,0,1-.084-.225.254.254,0,0,1,.14-.224l1.092-.616c.186-.111.326-.1.42.029a5.132,5.132,0,0,0,4.676,2.408,5.336,5.336,0,0,0,2.73-.687,1.961,1.961,0,0,0,1.078-1.721,1.647,1.647,0,0,0-.644-1.317,6,6,0,0,0-2.1-.951l-2.884-.813a5.985,5.985,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.873,3.873,0,0,1,.714-2.3,4.556,4.556,0,0,1,2.016-1.555,8.3,8.3,0,0,1,6.3.155,5.352,5.352,0,0,1,2.254,1.9c.131.186.121.317-.028.391l-1.176.644a.428.428,0,0,1-.168.057.384.384,0,0,1-.252-.141A4.2,4.2,0,0,0,395.2,661.03Z"/>
        <path class="cls-3" d="M405.963,659.561v1.175c0,.168.046.252.14.252.056,0,.13-.046.224-.139a14.038,14.038,0,0,1,1.036-.953,4.994,4.994,0,0,1,1.316-.7,5.67,5.67,0,0,1,2.044-.336,5.966,5.966,0,0,1,3.15.84,5.716,5.716,0,0,1,2.184,2.521,9.548,9.548,0,0,1,.8,4.116,10.4,10.4,0,0,1-.8,4.256,5.873,5.873,0,0,1-5.642,3.668,5.941,5.941,0,0,1-3.976-1.4c-.131-.13-.234-.2-.308-.2-.094,0-.14.122-.14.364v5.208q0,.365-.308.365h-1.736c-.206,0-.308-.122-.308-.365V659.589a.492.492,0,0,1,.1-.336.448.448,0,0,1,.35-.112h1.484C405.832,659.141,405.963,659.28,405.963,659.561Zm1.834,12.53a4.369,4.369,0,0,0,2,.462,4.1,4.1,0,0,0,3.374-1.6,7.212,7.212,0,0,0,1.274-4.592,6.463,6.463,0,0,0-1.26-4.353,4.38,4.38,0,0,0-5.264-.952,4,4,0,0,0-1.414,1.246,2.62,2.62,0,0,0-.518,1.483V670.7A6.949,6.949,0,0,0,407.8,672.091Z"/>
        <path class="cls-3" d="M423.98,669.977a4.959,4.959,0,0,0,1.778,1.82,4.707,4.707,0,0,0,2.464.672,4.984,4.984,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.324.324,0,0,1,.2.042l1.176.587c.149.094.168.224.056.392a6.82,6.82,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.123,7.123,0,0,1-3.668-.952,6.614,6.614,0,0,1-2.534-2.674,8.294,8.294,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.644,6.644,0,0,1,2.534-2.729,6.985,6.985,0,0,1,3.626-.953,6.859,6.859,0,0,1,3.542.9,5.991,5.991,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.113a.684.684,0,0,1-.084.377.378.378,0,0,1-.336.126H423.574q-.252,0-.252.42A4.935,4.935,0,0,0,423.98,669.977Zm7.91-4.453a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.689,4.689,0,0,0-1.624,1.848,5.133,5.133,0,0,0-.532,2.421Z"/>
        <path class="cls-3" d="M449.068,659.813a7.066,7.066,0,0,1,2.31,2.519l.056.225c0,.112-.065.177-.2.2l-1.54.363-.084.029c-.093,0-.187-.075-.28-.224a7.591,7.591,0,0,0-1.722-1.765,3.289,3.289,0,0,0-1.89-.559,4.213,4.213,0,0,0-3.472,1.609,7.992,7.992,0,0,0,.014,8.723,4.3,4.3,0,0,0,3.514,1.6,4.149,4.149,0,0,0,2.31-.643,6.022,6.022,0,0,0,1.806-1.988c.056-.094.112-.145.168-.155a.331.331,0,0,1,.2.042l1.2.449c.13.055.168.177.112.364a7.29,7.29,0,0,1-2.38,2.645,6.291,6.291,0,0,1-3.612,1.023,6.9,6.9,0,0,1-3.64-.966,6.652,6.652,0,0,1-2.492-2.7,8.551,8.551,0,0,1-.9-3.977,8.8,8.8,0,0,1,.9-4.017,6.638,6.638,0,0,1,2.506-2.758,6.838,6.838,0,0,1,3.626-.981A6.186,6.186,0,0,1,449.068,659.813Z"/>
        <path class="cls-3" d="M459.918,660.877c-.15,0-.224.084-.224.252v8.876a2.674,2.674,0,0,0,.42,1.735,1.743,1.743,0,0,0,1.372.477h1.932a.272.272,0,0,1,.308.307l-.028.953q0,.279-.336.364a16.322,16.322,0,0,1-2.632.139,3.484,3.484,0,0,1-2.576-.826,3.616,3.616,0,0,1-.812-2.618v-9.351c0-.206-.094-.308-.28-.308h-2.24c-.206,0-.308-.094-.308-.28v-1.176a.247.247,0,0,1,.28-.28h2.38c.13,0,.2-.075.2-.225l.308-4.451c0-.243.1-.364.308-.364h1.4q.336,0,.336.391v4.424a.2.2,0,0,0,.224.225h3.528c.2,0,.308.084.308.252v1.2c0,.186-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M483.438,659.421v1.2c0,.168-.094.253-.28.253h-3.192c-.15,0-.224.084-.224.252v12.46q0,.391-.336.391H477.7c-.206,0-.308-.121-.308-.364V661.1c0-.15-.066-.224-.2-.224H474.45c-.187,0-.28-.085-.28-.253v-1.2c0-.187.1-.28.308-.28h2.66c.168,0,.252-.094.252-.281v-2.044q0-3.612,3.5-3.612h2.324q.364,0,.364.336v1.093c0,.186-.1.279-.308.279h-1.96q-1.625,0-1.624,2.017v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,483.438,659.421Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M489.583,673.3a6.525,6.525,0,0,1-2.492-2.7,9.335,9.335,0,0,1,.028-7.981A7.1,7.1,0,0,1,497,659.854a6.687,6.687,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.485,8.485,0,0,1-.9,3.948,6.712,6.712,0,0,1-2.506,2.716,6.927,6.927,0,0,1-3.682.981A7.079,7.079,0,0,1,489.583,673.3Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.07,4.07,0,0,0-3.4-1.609,4.137,4.137,0,0,0-3.444,1.609,8.129,8.129,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.045,4.045,0,0,0,496.751,670.914Z"/>
        <path class="cls-3" d="M505.249,673.616V659.505c0-.243.1-.364.308-.364h1.624a.272.272,0,0,1,.308.307v1.821c0,.112.032.177.1.2s.135-.019.21-.112q2.127-2.493,4.34-2.493.98,0,.98.365V660.9q0,.281-.252.225a7.75,7.75,0,0,0-1.26-.084,3.965,3.965,0,0,0-1.764.476,4.983,4.983,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.539v9.269a.371.371,0,0,1-.42.419h-1.54C505.379,673.98,505.249,673.859,505.249,673.616Z"/>
        <path class="cls-3" d="M528.433,674.261a4.977,4.977,0,0,1-3.43-1.107,3.751,3.751,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.527,10.974,10.974,0,0,1,5.475-1.513l2.184-.168q.476-.055.476-.42v-.756a3.129,3.129,0,0,0-.938-2.393,3.547,3.547,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.46.46,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.825,5.825,0,0,1,2.394-2.309,7.735,7.735,0,0,1,3.682-.827,6.188,6.188,0,0,1,4.2,1.275,4.425,4.425,0,0,1,1.456,3.513v9.913a.482.482,0,0,1-.084.335.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.389.389,0,0,0-.252.139A6.979,6.979,0,0,1,528.433,674.261Zm4.928-7.84-1.82.168a9.076,9.076,0,0,0-4.018,1.008,2.588,2.588,0,0,0-1.414,2.324,2.326,2.326,0,0,0,.854,1.9,3.485,3.485,0,0,0,2.282.7,4.936,4.936,0,0,0,1.708-.307,5.039,5.039,0,0,0,1.484-.84,4.129,4.129,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q533.781,666.421,533.361,666.421Z"/>
        <path class="cls-3" d="M543.917,673.505a.494.494,0,0,1-.113.378.6.6,0,0,1-.391.1h-1.456a.485.485,0,0,1-.336-.084.425.425,0,0,1-.085-.307l.028-19.992q0-.393.336-.393h1.681c.223,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M552.008,673.505a.494.494,0,0,1-.112.378.606.606,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.43.43,0,0,1-.084-.307l.028-19.992q0-.393.336-.393h1.68c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M559.9,673.532a.452.452,0,0,1-.112.351.6.6,0,0,1-.392.1h-1.96a.49.49,0,0,1-.321-.084.387.387,0,0,1-.1-.307v-2.408c0-.243.1-.365.308-.365h2.3c.186,0,.279.113.279.336Z"/>
      </g>
    </g>
  </g>
</g>
<g id="odpowiedzialnosc" class="our-values__text">
  <g>
    <path class="cls-2" d="M395.9,277.006c0,116.162,94.508,210.67,210.67,210.67s210.664-94.508,210.664-210.67-94.5-210.67-210.664-210.67S395.9,160.844,395.9,277.006Z"/>
    <g class="cls-4">
      <g class="cls-4">
        <path class="cls-3" d="M576.775,213.577a.431.431,0,0,1,.042.363l-5.768,19.853a.531.531,0,0,1-.2.322.73.73,0,0,1-.42.1h-1.708q-.533,0-.644-.392l-4.48-15.876c-.056-.206-.122-.2-.2.028l-4.536,15.82a.531.531,0,0,1-.2.322.732.732,0,0,1-.42.1h-1.736q-.533,0-.644-.392l-5.684-19.881a.3.3,0,0,1-.028-.139c0-.242.14-.364.42-.364h1.792a.432.432,0,0,1,.476.335l4.564,16.3c.056.205.112.308.168.308.074,0,.13-.093.168-.28l4.647-16.352a.479.479,0,0,1,.5-.308h1.6a.652.652,0,0,1,.336.07.45.45,0,0,1,.168.265l4.592,16.381q.084.252.168.252t.168-.252l4.62-16.408q.056-.308.476-.308h1.4A.4.4,0,0,1,576.775,213.577Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M581.955,230.209a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.981,4.981,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,6,6,0,0,1,2.366,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H581.549q-.252,0-.252.42A4.934,4.934,0,0,0,581.955,230.209Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.137,5.137,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M608.737,221.109c-.15,0-.224.084-.224.252v8.876a2.679,2.679,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.932a.273.273,0,0,1,.308.308l-.028.952q0,.28-.336.364a16.288,16.288,0,0,1-2.632.14,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618v-9.352c0-.205-.094-.308-.28-.308h-2.24c-.2,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.13,0,.195-.074.195-.225l.309-4.451q0-.364.308-.364h1.4q.336,0,.336.392v4.423a.2.2,0,0,0,.224.225h3.528c.205,0,.308.084.308.252v1.2c0,.187-.1.28-.308.28Z"/>
        <path class="cls-3" d="M620.692,234.493a4.978,4.978,0,0,1-3.429-1.106,3.754,3.754,0,0,1-1.246-2.954,4.049,4.049,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.183-.168q.477-.056.477-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.546,3.546,0,0,0-2.535-.883,4.74,4.74,0,0,0-4.031,2.241.47.47,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.646.646,0,0,1,.084-.251,5.828,5.828,0,0,1,2.394-2.31,7.734,7.734,0,0,1,3.681-.826,6.2,6.2,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.437.437,0,0,1-.308.084h-1.4c-.224,0-.355-.15-.392-.448l-.057-1.288c-.018-.13-.065-.2-.139-.2a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,620.692,234.493Zm4.929-7.84-1.82.168a9.1,9.1,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.493,3.493,0,0,0,2.282.7,4.941,4.941,0,0,0,1.708-.308,5,5,0,0,0,1.483-.84,4.1,4.1,0,0,0,.994-1.078,2.127,2.127,0,0,0,.351-1.106V227.1C626.041,226.8,625.9,226.653,625.621,226.653Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M646.676,233.961c0,.168-.1.252-.308.252h-1.96a.524.524,0,0,1-.28-.07.732.732,0,0,1-.224-.266l-4.228-7.252c-.037-.094-.084-.145-.14-.154s-.121.023-.2.1l-2.968,3a.76.76,0,0,0-.2.561v3.612a.491.491,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.428a.486.486,0,0,1-.336-.084.433.433,0,0,1-.084-.308V213.829c0-.262.1-.392.308-.392h1.708c.224,0,.336.122.336.364v12.992c0,.186.028.28.084.28s.121-.056.2-.168l6.916-7.2a.844.844,0,0,1,.588-.336h1.708c.224,0,.336.065.336.2a.827.827,0,0,1-.2.336l-4.424,4.508a.536.536,0,0,0-.113.252.489.489,0,0,0,.084.224l5.265,8.876A.276.276,0,0,1,646.676,233.961Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M652.4,230.209a4.964,4.964,0,0,0,1.78,1.82,4.694,4.694,0,0,0,2.463.672,4.984,4.984,0,0,0,4.369-2.324.4.4,0,0,1,.195-.126.325.325,0,0,1,.2.042l1.177.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.881,7.881,0,0,1-3.612.77,7.116,7.116,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.919,8.919,0,0,1,.923-4.159,6.66,6.66,0,0,1,2.536-2.729,6.978,6.978,0,0,1,3.625-.952,6.877,6.877,0,0,1,3.543.9,6,6,0,0,1,2.365,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H652q-.252,0-.252.42A4.944,4.944,0,0,0,652.4,230.209Zm7.911-4.452a.707.707,0,0,0,.392-.07c.057-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.125,5.125,0,0,0-.532,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M467.7,267.178V253.066c0-.243.1-.364.308-.364h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.127-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.224a7.865,7.865,0,0,0-1.26-.084,3.979,3.979,0,0,0-1.764.476,5,5,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.54v9.268a.371.371,0,0,1-.42.42h-1.54C467.832,267.542,467.7,267.421,467.7,267.178Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M481.688,263.538a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952A6.608,6.608,0,0,1,479.5,264.2a8.29,8.29,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H481.282q-.252,0-.252.42A4.934,4.934,0,0,0,481.688,263.538Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.106,4.106,0,0,0-1.512-1.68,4.224,4.224,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.7,4.7,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M504.424,254.592a5.169,5.169,0,0,0-2.366-.518,4.438,4.438,0,0,0-2.52.658,1.994,1.994,0,0,0-.98,1.722,1.647,1.647,0,0,0,.49,1.162,4.132,4.132,0,0,0,1.778.91l3.556,1.064a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.586,3.586,0,0,1-.784,2.282,5.055,5.055,0,0,1-2.156,1.54,9.076,9.076,0,0,1-6.944-.308,5.187,5.187,0,0,1-2.38-2.226.481.481,0,0,1-.084-.224.254.254,0,0,1,.14-.224l1.092-.616q.279-.168.42.028A5.13,5.13,0,0,0,502,266.114a5.333,5.333,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,6,6,0,0,0-2.1-.952l-2.884-.812a6,6,0,0,1-2.926-1.484,3.294,3.294,0,0,1-.882-2.324,3.876,3.876,0,0,1,.714-2.3,4.554,4.554,0,0,1,2.016-1.554,8.308,8.308,0,0,1,6.3.154,5.366,5.366,0,0,1,2.254,1.9c.131.187.121.318-.028.392l-1.176.644a.422.422,0,0,1-.168.056.386.386,0,0,1-.252-.14A4.186,4.186,0,0,0,504.424,254.592Z"/>
        <path class="cls-3" d="M515.19,253.122V254.3c0,.168.046.252.14.252.056,0,.13-.046.224-.14a14,14,0,0,1,1.036-.952,4.965,4.965,0,0,1,1.316-.7,5.649,5.649,0,0,1,2.044-.336,5.957,5.957,0,0,1,3.15.84,5.708,5.708,0,0,1,2.184,2.52,9.548,9.548,0,0,1,.8,4.116,10.4,10.4,0,0,1-.8,4.256,5.869,5.869,0,0,1-5.642,3.668,5.946,5.946,0,0,1-3.976-1.4c-.131-.13-.234-.2-.308-.2-.094,0-.14.122-.14.364V271.8c0,.242-.1.364-.308.364h-1.736c-.206,0-.308-.122-.308-.364V253.15a.5.5,0,0,1,.1-.336.448.448,0,0,1,.35-.112H514.8Q515.189,252.7,515.19,253.122Zm1.834,12.53a4.356,4.356,0,0,0,2,.462,4.1,4.1,0,0,0,3.374-1.6,7.214,7.214,0,0,0,1.274-4.592,6.464,6.464,0,0,0-1.26-4.354,4.384,4.384,0,0,0-5.264-.952,4,4,0,0,0-1.414,1.246,2.621,2.621,0,0,0-.518,1.484v6.916A6.922,6.922,0,0,0,517.024,265.652Z"/>
        <path class="cls-3" d="M533.487,266.856a6.52,6.52,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.718,6.718,0,0,1-2.506,2.716,6.931,6.931,0,0,1-3.682.98A7.072,7.072,0,0,1,533.487,266.856Zm7.168-2.38a8.31,8.31,0,0,0-.028-8.708,4.067,4.067,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,540.655,264.476Z"/>
        <path class="cls-3" d="M549.153,267.178V253.066q0-.364.308-.364h1.681a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.349,15.349,0,0,1,2.673-1.764,5.658,5.658,0,0,1,2.479-.532,4.581,4.581,0,0,1,3.317,1.148,4.08,4.08,0,0,1,1.19,3.08v10.444c0,.3-.139.448-.42.448h-1.567a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.715-2.156,2.889,2.889,0,0,0-2.2-.756,4.612,4.612,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75v10.584a.357.357,0,0,1-.42.42h-1.54Q549.154,267.542,549.153,267.178Z"/>
        <path class="cls-3" d="M574.2,254.592a5.176,5.176,0,0,0-2.367-.518,4.443,4.443,0,0,0-2.52.658,2,2,0,0,0-.98,1.722,1.648,1.648,0,0,0,.491,1.162,4.126,4.126,0,0,0,1.778.91l3.556,1.064a5.576,5.576,0,0,1,2.743,1.5,3.413,3.413,0,0,1,.9,2.366,3.586,3.586,0,0,1-.783,2.282,5.058,5.058,0,0,1-2.157,1.54,9.074,9.074,0,0,1-6.943-.308,5.177,5.177,0,0,1-2.38-2.226.481.481,0,0,1-.084-.224.253.253,0,0,1,.139-.224l1.093-.616q.279-.168.42.028a5.128,5.128,0,0,0,4.675,2.408,5.33,5.33,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.642,1.642,0,0,0-.644-1.316,5.987,5.987,0,0,0-2.1-.952l-2.883-.812a6,6,0,0,1-2.926-1.484,3.29,3.29,0,0,1-.883-2.324,3.882,3.882,0,0,1,.714-2.3,4.56,4.56,0,0,1,2.016-1.554,8.31,8.31,0,0,1,6.3.154,5.366,5.366,0,0,1,2.254,1.9c.13.187.121.318-.029.392l-1.176.644a.414.414,0,0,1-.167.056.386.386,0,0,1-.252-.14A4.194,4.194,0,0,0,574.2,254.592Z"/>
        <path class="cls-3" d="M585.105,249.51a.6.6,0,0,1-.112.406.505.505,0,0,1-.391.126h-1.624c-.281,0-.421-.158-.421-.476v-2.352c0-.3.131-.448.392-.448h1.764a.383.383,0,0,1,.28.112.416.416,0,0,1,.112.308Zm-.112,17.556a.491.491,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.427a.485.485,0,0,1-.336-.084.428.428,0,0,1-.085-.308V253.094q0-.392.337-.392h1.708q.307,0,.307.364Z"/>
        <path class="cls-3" d="M591.181,267.542a.371.371,0,0,1-.448-.448V247.158c0-.262.112-.392.336-.392H592.8c.187,0,.28.13.28.392v6.86c0,.206.046.308.14.308a.619.619,0,0,0,.28-.14,6.312,6.312,0,0,1,4.48-1.764,5.161,5.161,0,0,1,4.466,2.114,9.184,9.184,0,0,1,1.526,5.5,8.786,8.786,0,0,1-1.694,5.7,5.709,5.709,0,0,1-4.69,2.086,5.337,5.337,0,0,1-2.828-.686,5.987,5.987,0,0,1-1.736-1.526.225.225,0,0,0-.168-.112c-.075,0-.121.065-.14.2l-.14,1.4a.73.73,0,0,1-.154.35.488.488,0,0,1-.35.1Zm1.9-5.012a3.1,3.1,0,0,0,.574,1.806,4.175,4.175,0,0,0,1.5,1.316,4.066,4.066,0,0,0,1.932.49,3.945,3.945,0,0,0,3.234-1.512,7.13,7.13,0,0,0,1.218-4.536,7.6,7.6,0,0,0-1.092-4.41,3.626,3.626,0,0,0-3.164-1.554,4.989,4.989,0,0,0-2.338.56,5.927,5.927,0,0,0-1.862,1.512Z"/>
        <path class="cls-3" d="M611.369,249.51a.6.6,0,0,1-.112.406.508.508,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.764a.383.383,0,0,1,.28.112.416.416,0,0,1,.112.308Zm-.112,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.428a.486.486,0,0,1-.336-.084.433.433,0,0,1-.084-.308V253.094q0-.392.336-.392h1.708q.308,0,.308.364Z"/>
        <path class="cls-3" d="M619.377,267.066a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.433.433,0,0,1-.084-.308l.028-19.992c0-.262.112-.392.336-.392h1.68c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M627.553,249.51a.6.6,0,0,1-.112.406.508.508,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.764a.383.383,0,0,1,.28.112.416.416,0,0,1,.112.308Zm-.112,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.428a.486.486,0,0,1-.336-.084.433.433,0,0,1-.084-.308V253.094q0-.392.336-.392h1.708q.307,0,.308.364Z"/>
        <path class="cls-3" d="M637.1,254.438c-.15,0-.224.084-.224.252v8.876a2.679,2.679,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476H640.6a.272.272,0,0,1,.307.308l-.027.952q0,.281-.336.364a16.288,16.288,0,0,1-2.632.14,3.484,3.484,0,0,1-2.576-.826,3.616,3.616,0,0,1-.812-2.618v-9.352c0-.205-.094-.308-.28-.308h-2.24c-.206,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.13,0,.2-.074.2-.224l.308-4.452c0-.243.1-.364.308-.364h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.527q.309,0,.309.252v1.2c0,.187-.1.28-.309.28Z"/>
        <path class="cls-3" d="M644.885,272.106c-.243-.038-.364-.14-.364-.308v-1.092a.291.291,0,0,1,.154-.28,1.524,1.524,0,0,1,.63-.084h1.763a1.83,1.83,0,0,0,1.233-.49,3.694,3.694,0,0,0,1.2-2.618,1.69,1.69,0,0,0-.112-.672l-5.348-13.384a.369.369,0,0,1-.028-.168q0-.307.336-.308h1.736a.466.466,0,0,1,.447.308l4.173,10.864c.056.168.13.252.224.252q.168,0,.28-.336l4.115-10.78a.437.437,0,0,1,.393-.308h1.315a.283.283,0,0,1,.252.126.268.268,0,0,1,.028.266l-6.16,15.764a5.211,5.211,0,0,1-1.876,2.6,5.721,5.721,0,0,1-3.051.7A10.382,10.382,0,0,1,644.885,272.106Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M676.861,252.982v1.2c0,.168-.093.252-.28.252h-3.192c-.15,0-.224.084-.224.252v12.46q0,.392-.336.392h-1.708c-.206,0-.308-.121-.308-.364V254.662c0-.149-.066-.224-.2-.224h-2.744c-.186,0-.28-.084-.28-.252v-1.2c0-.186.1-.28.308-.28h2.661c.167,0,.251-.094.251-.28v-2.044q0-3.612,3.5-3.612h2.324q.365,0,.364.336v1.092c0,.187-.1.28-.308.28h-1.96q-1.624,0-1.624,2.016v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,676.861,252.982Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M683.007,266.856a6.52,6.52,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.718,6.718,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.069,7.069,0,0,1,683.007,266.856Zm7.168-2.38a8.307,8.307,0,0,0-.029-8.708,4.064,4.064,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,690.175,264.476Z"/>
        <path class="cls-3" d="M698.673,267.178V253.066q0-.364.307-.364H700.6a.273.273,0,0,1,.309.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.129-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.224a7.865,7.865,0,0,0-1.26-.084,3.979,3.979,0,0,0-1.764.476,5,5,0,0,0-1.6,1.232,2.368,2.368,0,0,0-.645,1.54v9.268a.371.371,0,0,1-.42.42h-1.54Q698.673,267.542,698.673,267.178Z"/>
        <path class="cls-3" d="M721.856,267.822a4.978,4.978,0,0,1-3.429-1.106,3.753,3.753,0,0,1-1.246-2.954,4.052,4.052,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.184-.168q.476-.055.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.55,3.55,0,0,0-2.535-.882,4.739,4.739,0,0,0-4.031,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.734,7.734,0,0,1,3.681-.826,6.2,6.2,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.437.437,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.057-1.288c-.018-.13-.065-.2-.139-.2a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,721.856,267.822Zm4.929-7.84-1.82.168a9.107,9.107,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,5.129,5.129,0,0,0,3.191-1.148,4.079,4.079,0,0,0,.994-1.078,2.124,2.124,0,0,0,.351-1.106V260.43C727.205,260.132,727.064,259.982,726.785,259.982Z"/>
        <path class="cls-3" d="M737.341,267.066a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.428.428,0,0,1-.085-.308l.028-19.992c0-.262.113-.392.336-.392h1.681c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M745.433,267.066a.491.491,0,0,1-.113.378.6.6,0,0,1-.391.1h-1.456a.485.485,0,0,1-.336-.084.428.428,0,0,1-.085-.308l.028-19.992c0-.262.112-.392.336-.392H745.1c.223,0,.336.122.336.364Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M468.948,300.187a6.528,6.528,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.711,6.711,0,0,1-2.506,2.716,6.931,6.931,0,0,1-3.682.98A7.078,7.078,0,0,1,468.948,300.187Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.067,4.067,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,476.116,297.806Z"/>
        <path class="cls-3" d="M491.908,298.674a10.463,10.463,0,0,0,2.254-1.806V286.424q0-.391.448-.392h1.54a.347.347,0,0,1,.392.392l-.056,14.084q0,.364-.308.364h-1.624a.272.272,0,0,1-.308-.308v-1.652q0-.336-.252-.084a11.5,11.5,0,0,1-2.688,1.806,5.925,5.925,0,0,1-2.408.518,4.531,4.531,0,0,1-3.22-1.176,4.051,4.051,0,0,1-1.26-3.108V286.452a.371.371,0,0,1,.42-.42h1.568c.242,0,.364.131.364.392v9.828a3.133,3.133,0,0,0,.77,2.227,2.75,2.75,0,0,0,2.114.825A4.508,4.508,0,0,0,491.908,298.674Z"/>
        <path class="cls-3" d="M502.226,300.508V286.4c0-.243.1-.364.308-.364h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.127-2.491,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,4.985,4.985,0,0,0-1.6,1.233,2.363,2.363,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C502.356,300.872,502.226,300.751,502.226,300.508Z"/>
        <path class="cls-3" d="M531.416,286.7a7.077,7.077,0,0,1,2.31,2.52l.056.224c0,.113-.066.178-.2.2l-1.54.363-.084.028c-.094,0-.187-.074-.28-.223a7.611,7.611,0,0,0-1.722-1.765,3.292,3.292,0,0,0-1.89-.56,4.211,4.211,0,0,0-3.472,1.61,7.991,7.991,0,0,0,.014,8.722,4.3,4.3,0,0,0,3.514,1.6,4.149,4.149,0,0,0,2.31-.644,6.013,6.013,0,0,0,1.806-1.988c.056-.094.112-.144.168-.154a.331.331,0,0,1,.2.042l1.2.448c.13.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646,6.291,6.291,0,0,1-3.612,1.022,6.9,6.9,0,0,1-3.64-.965,6.648,6.648,0,0,1-2.492-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.65,6.65,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.186,6.186,0,0,1,531.416,286.7Z"/>
        <path class="cls-3" d="M538.374,300.508V280.46c0-.242.1-.364.308-.364h1.651q.309,0,.309.336v7.476c0,.2.084.234.252.084a14.412,14.412,0,0,1,2.715-1.736,5.937,5.937,0,0,1,2.464-.5,4.525,4.525,0,0,1,3.319,1.148,4.175,4.175,0,0,1,1.161,3.108v10.416c0,.3-.139.448-.42.448h-1.568a.321.321,0,0,1-.363-.364v-10a3.021,3.021,0,0,0-.715-2.156,2.893,2.893,0,0,0-2.2-.756,4.456,4.456,0,0,0-2.143.518,15.088,15.088,0,0,0-2.421,1.75v10.584q0,.42-.449.42h-1.54A.321.321,0,0,1,538.374,300.508Z"/>
        <path class="cls-3" d="M558.6,300.187a6.524,6.524,0,0,1-2.493-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.833,8.833,0,0,1,.881,4,8.476,8.476,0,0,1-.9,3.948,6.716,6.716,0,0,1-2.5,2.716,6.937,6.937,0,0,1-3.683.98A7.077,7.077,0,0,1,558.6,300.187Zm7.167-2.381a8.31,8.31,0,0,0-.028-8.708,4.066,4.066,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.181,4.181,0,0,0,3.473,1.6A4.047,4.047,0,0,0,565.771,297.806Z"/>
        <path class="cls-3" d="M576.733,282.84a.6.6,0,0,1-.112.406.506.506,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.764a.383.383,0,0,1,.28.112.416.416,0,0,1,.112.308Zm-.112,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.428a.482.482,0,0,1-.335-.084.428.428,0,0,1-.085-.308V286.424q0-.391.336-.392h1.708c.206,0,.308.121.308.364Z"/>
        <path class="cls-3" d="M592.007,286.7a7.059,7.059,0,0,1,2.31,2.52l.057.224c0,.113-.066.178-.2.2l-1.54.363-.084.028c-.094,0-.187-.074-.281-.223a7.589,7.589,0,0,0-1.721-1.765,3.292,3.292,0,0,0-1.89-.56,4.215,4.215,0,0,0-3.473,1.61,6.782,6.782,0,0,0-1.288,4.354,6.709,6.709,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.514,1.6,4.144,4.144,0,0,0,2.309-.644,5.992,5.992,0,0,0,1.806-1.988c.057-.094.113-.144.168-.154a.333.333,0,0,1,.2.042l1.2.448c.131.056.168.178.113.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.613,1.022,6.9,6.9,0,0,1-3.64-.965,6.646,6.646,0,0,1-2.491-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.65,6.65,0,0,1,2.506-2.758,6.836,6.836,0,0,1,3.625-.98A6.181,6.181,0,0,1,592.007,286.7Z"/>
        <path class="cls-3" d="M601.163,296.868a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.981,4.981,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.3,8.3,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H600.757q-.252,0-.252.42A4.932,4.932,0,0,0,601.163,296.868Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.09,4.09,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.365,4.365,0,0,0-2.492.714,4.7,4.7,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M623.9,287.922a5.169,5.169,0,0,0-2.366-.518,4.433,4.433,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.647,1.647,0,0,0,.49,1.162,4.132,4.132,0,0,0,1.778.91l3.556,1.064a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.586,3.586,0,0,1-.784,2.282,5.047,5.047,0,0,1-2.156,1.54,9.076,9.076,0,0,1-6.944-.308,5.182,5.182,0,0,1-2.38-2.226.477.477,0,0,1-.084-.224.254.254,0,0,1,.14-.224l1.092-.616c.186-.112.327-.1.42.028a5.13,5.13,0,0,0,4.676,2.408,5.333,5.333,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,5.979,5.979,0,0,0-2.1-.951l-2.884-.813a5.994,5.994,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.324,3.87,3.87,0,0,1,.714-2.3,4.549,4.549,0,0,1,2.016-1.555,8.308,8.308,0,0,1,6.3.154,5.366,5.366,0,0,1,2.254,1.9c.131.187.121.318-.028.392l-1.176.644a.422.422,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.179,4.179,0,0,0,623.9,287.922Z"/>
        <path class="cls-3" d="M631.445,304.764a.423.423,0,0,1,0-.308l1.26-3.136a.645.645,0,0,0,.084-.252c0-.13-.1-.2-.308-.2h-.364q-.364,0-.364-.336v-2.492c0-.2.093-.308.28-.308h2.324a.248.248,0,0,1,.28.28v2.3a.638.638,0,0,1-.084.335l-2.044,3.92a.588.588,0,0,1-.56.308h-.308A.2.2,0,0,1,631.445,304.764Z"/>
        <path class="cls-3" d="M650.736,301.152a4.978,4.978,0,0,1-3.429-1.106,3.753,3.753,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.528,10.968,10.968,0,0,1,5.474-1.512l2.184-.168c.318-.037.476-.177.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.549,3.549,0,0,0-2.534-.882,4.741,4.741,0,0,0-4.032,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.836,5.836,0,0,1,2.395-2.31,7.728,7.728,0,0,1,3.681-.826,6.192,6.192,0,0,1,4.2,1.274,4.43,4.43,0,0,1,1.457,3.514v9.912a.492.492,0,0,1-.084.336.44.44,0,0,1-.309.084h-1.4q-.337,0-.393-.448l-.056-1.288c-.018-.13-.065-.2-.139-.2a.386.386,0,0,0-.253.14A6.978,6.978,0,0,1,650.736,301.152Zm4.928-7.839-1.82.167a9.107,9.107,0,0,0-4.018,1.008,2.591,2.591,0,0,0-1.414,2.325,2.325,2.325,0,0,0,.855,1.9,3.482,3.482,0,0,0,2.281.7,4.933,4.933,0,0,0,1.708-.308,5.024,5.024,0,0,0,1.484-.839,4.115,4.115,0,0,0,.994-1.079,2.122,2.122,0,0,0,.35-1.106V293.76Q656.084,293.313,655.664,293.313Z"/>
        <path class="cls-3" d="M673.485,286.7a7.071,7.071,0,0,1,2.311,2.52l.056.224c0,.113-.066.178-.2.2l-1.54.363-.084.028c-.094,0-.187-.074-.28-.223a7.611,7.611,0,0,0-1.722-1.765,3.292,3.292,0,0,0-1.89-.56,4.211,4.211,0,0,0-3.472,1.61,6.777,6.777,0,0,0-1.288,4.354,6.709,6.709,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.514,1.6,4.149,4.149,0,0,0,2.31-.644,6,6,0,0,0,1.806-1.988c.056-.094.112-.144.167-.154a.333.333,0,0,1,.2.042l1.2.448c.13.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646A6.293,6.293,0,0,1,670,301.152a6.9,6.9,0,0,1-3.641-.965,6.653,6.653,0,0,1-2.491-2.7,8.547,8.547,0,0,1-.9-3.976,8.811,8.811,0,0,1,.9-4.018,6.65,6.65,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.18,6.18,0,0,1,673.485,286.7Z"/>
        <path class="cls-3" d="M684.336,287.769q-.225,0-.225.252V296.9a2.685,2.685,0,0,0,.42,1.736,1.749,1.749,0,0,0,1.372.476h1.933a.273.273,0,0,1,.308.308l-.029.952c0,.187-.111.308-.336.364a16.271,16.271,0,0,1-2.632.14,3.485,3.485,0,0,1-2.576-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.205-.094-.307-.281-.307h-2.24c-.205,0-.307-.094-.307-.281v-1.175a.248.248,0,0,1,.28-.281h2.38c.13,0,.2-.074.2-.224l.309-4.452q0-.365.307-.364h1.4c.223,0,.336.131.336.392v4.424a.2.2,0,0,0,.223.224h3.529c.2,0,.307.084.307.252v1.2c0,.187-.1.281-.307.281Z"/>
        <path class="cls-3" d="M695.115,282.84a.6.6,0,0,1-.112.406.5.5,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.765a.381.381,0,0,1,.279.112.416.416,0,0,1,.112.308ZM695,300.4a.5.5,0,0,1-.111.378.6.6,0,0,1-.393.1h-1.428a.486.486,0,0,1-.336-.084.438.438,0,0,1-.084-.308V286.424c0-.261.113-.392.336-.392H694.7q.307,0,.308.364Z"/>
        <path class="cls-3" d="M703.25,300.187a6.522,6.522,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.476,8.476,0,0,1-.9,3.948,6.709,6.709,0,0,1-2.5,2.716,6.936,6.936,0,0,1-3.682.98A7.078,7.078,0,0,1,703.25,300.187Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.069,4.069,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.443,1.61,8.123,8.123,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.049,4.049,0,0,0,710.418,297.806Z"/>
        <path class="cls-3" d="M718.915,300.508V286.4q0-.364.309-.364H720.9a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084a15.334,15.334,0,0,1,2.674-1.764,5.649,5.649,0,0,1,2.477-.532,4.586,4.586,0,0,1,3.319,1.148,4.083,4.083,0,0,1,1.189,3.08v10.444q0,.449-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.025,3.025,0,0,0-.713-2.156,2.9,2.9,0,0,0-2.2-.756,4.618,4.618,0,0,0-2.17.518,13.607,13.607,0,0,0-2.421,1.75v10.584a.447.447,0,0,1-.1.322.442.442,0,0,1-.322.1h-1.54Q718.915,300.872,718.915,300.508Z"/>
        <path class="cls-3" d="M743.962,287.922a5.174,5.174,0,0,0-2.366-.518,4.434,4.434,0,0,0-2.521.659,1.992,1.992,0,0,0-.979,1.721,1.647,1.647,0,0,0,.49,1.162,4.128,4.128,0,0,0,1.777.91l3.557,1.064a5.585,5.585,0,0,1,2.743,1.5,3.414,3.414,0,0,1,.9,2.366,3.582,3.582,0,0,1-.785,2.282,5.041,5.041,0,0,1-2.156,1.54,9.074,9.074,0,0,1-6.943-.308,5.177,5.177,0,0,1-2.38-2.226.467.467,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.091-.616q.281-.168.42.028a5.13,5.13,0,0,0,4.676,2.408,5.337,5.337,0,0,0,2.731-.686,1.965,1.965,0,0,0,1.078-1.722,1.643,1.643,0,0,0-.645-1.316,5.981,5.981,0,0,0-2.1-.951l-2.884-.813a5.989,5.989,0,0,1-2.926-1.484,3.289,3.289,0,0,1-.883-2.324,3.87,3.87,0,0,1,.714-2.3,4.552,4.552,0,0,1,2.017-1.555,8.305,8.305,0,0,1,6.3.154,5.354,5.354,0,0,1,2.254,1.9c.131.187.121.318-.028.392l-1.176.644a.417.417,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.176,4.176,0,0,0,743.962,287.922Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M535.629,334.481a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.957,10.957,0,0,1,5.474-1.513l2.184-.168q.476-.056.476-.42v-.756a3.126,3.126,0,0,0-.938-2.393,3.545,3.545,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.735,7.735,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.485.485,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.195-.14-.195a.384.384,0,0,0-.252.139A6.978,6.978,0,0,1,535.629,334.481Zm4.928-7.839-1.82.168a9.094,9.094,0,0,0-4.018,1.007,2.589,2.589,0,0,0-1.414,2.325,2.327,2.327,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.048,5.048,0,0,0,1.484-.84,4.127,4.127,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q540.977,326.642,540.557,326.642Z"/>
        <path class="cls-3" d="M548.732,333.837V319.726c0-.244.1-.365.309-.365h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.581,4.581,0,0,1,3.317,1.148,4.08,4.08,0,0,1,1.19,3.081v10.443c0,.3-.139.448-.419.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.017,3.017,0,0,0-.714-2.155,2.894,2.894,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75v10.584a.356.356,0,0,1-.419.42h-1.54Q548.732,334.2,548.732,333.837Z"/>
        <path class="cls-3" d="M576.648,333.81v-1.2c0-.167-.046-.251-.139-.251a.381.381,0,0,0-.224.139,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.761,5.761,0,0,1-2.213-2.535,9.432,9.432,0,0,1-.811-4.1,9.668,9.668,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.955,5.955,0,0,1,3.389-.994,5.561,5.561,0,0,1,2.184.393,6.609,6.609,0,0,1,1.736,1.091.706.706,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.307-.364h1.708c.206,0,.309.122.309.364v19.964a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1h-1.428A.347.347,0,0,1,576.648,333.81Zm-1.889-12.531a4.3,4.3,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.6,7.009,7.009,0,0,0-1.288,4.508,6.522,6.522,0,0,0,1.273,4.4,4.14,4.14,0,0,0,3.263,1.456,3.97,3.97,0,0,0,2-.518,4.1,4.1,0,0,0,1.427-1.3,2.819,2.819,0,0,0,.518-1.568v-6.748A7.218,7.218,0,0,0,574.759,321.279Z"/>
        <path class="cls-3" d="M611.313,319.487a.273.273,0,0,1,.027.266l-4.283,14.084a.5.5,0,0,1-.533.364H605.18a.537.537,0,0,1-.588-.364l-3.332-10.472c-.055-.168-.121-.252-.2-.252-.037,0-.084.084-.14.252l-3.164,10.472a.5.5,0,0,1-.531.364H595.8a.537.537,0,0,1-.588-.364l-4.4-14.056a.305.305,0,0,1-.027-.139c0-.187.111-.281.336-.281h1.651a.469.469,0,0,1,.449.308l3.332,10.893c.055.186.121.279.195.279.057,0,.131-.1.225-.308l3.3-10.891a.369.369,0,0,1,.392-.281h1.316a.391.391,0,0,1,.42.308l3.445,10.893c.055.186.121.279.2.279s.14-.1.2-.308l3.219-10.864a.487.487,0,0,1,.476-.308h1.121A.285.285,0,0,1,611.313,319.487Z"/>
        <path class="cls-3" d="M618.271,333.516a6.524,6.524,0,0,1-2.493-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.833,8.833,0,0,1,.881,4,8.473,8.473,0,0,1-.9,3.948,6.716,6.716,0,0,1-2.5,2.716,6.937,6.937,0,0,1-3.683.98A7.077,7.077,0,0,1,618.271,333.516Zm7.167-2.381a8.31,8.31,0,0,0-.028-8.708,4.069,4.069,0,0,0-3.4-1.61,4.137,4.137,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.179,4.179,0,0,0,3.473,1.6A4.047,4.047,0,0,0,625.438,331.135Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M633.937,333.837V319.726c0-.244.1-.365.308-.365h1.624a.273.273,0,0,1,.308.308v1.82c0,.113.032.178.1.2s.135-.019.21-.113q2.127-2.491,4.34-2.492.981,0,.98.364v1.68q0,.281-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.365,2.365,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C634.067,334.2,633.937,334.08,633.937,333.837Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M655.86,333.81v-1.2c0-.167-.047-.251-.139-.251a.381.381,0,0,0-.224.139,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.767,5.767,0,0,1-2.213-2.535,9.432,9.432,0,0,1-.811-4.1,9.668,9.668,0,0,1,.84-4.144,6.674,6.674,0,0,1,2.324-2.786,5.95,5.95,0,0,1,3.388-.994,5.557,5.557,0,0,1,2.184.393,6.588,6.588,0,0,1,1.735,1.091.712.712,0,0,0,.309.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.307-.364h1.708c.206,0,.309.122.309.364v19.964a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1h-1.428A.347.347,0,0,1,655.86,333.81Zm-1.889-12.531a4.3,4.3,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.6,7.009,7.009,0,0,0-1.288,4.508,6.522,6.522,0,0,0,1.273,4.4,4.14,4.14,0,0,0,3.263,1.456,3.97,3.97,0,0,0,2-.518,4.1,4.1,0,0,0,1.428-1.3,2.826,2.826,0,0,0,.517-1.568v-6.748A7.218,7.218,0,0,0,653.971,321.279Z"/>
        <path class="cls-3" d="M671.163,321.251a5.174,5.174,0,0,0-2.366-.518,4.436,4.436,0,0,0-2.521.659,1.992,1.992,0,0,0-.979,1.721,1.647,1.647,0,0,0,.49,1.162,4.138,4.138,0,0,0,1.777.911l3.557,1.063a5.576,5.576,0,0,1,2.743,1.5,3.414,3.414,0,0,1,.9,2.366,3.591,3.591,0,0,1-.784,2.283,5.067,5.067,0,0,1-2.157,1.54,9.08,9.08,0,0,1-6.943-.309,5.177,5.177,0,0,1-2.38-2.226.467.467,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.092-.616c.186-.111.326-.1.419.028a5.13,5.13,0,0,0,4.676,2.408,5.337,5.337,0,0,0,2.731-.686,1.965,1.965,0,0,0,1.078-1.722,1.643,1.643,0,0,0-.645-1.316,5.973,5.973,0,0,0-2.1-.951l-2.884-.813A5.985,5.985,0,0,1,664,325.8a3.288,3.288,0,0,1-.883-2.323,3.88,3.88,0,0,1,.714-2.3,4.557,4.557,0,0,1,2.017-1.555,8.308,8.308,0,0,1,6.3.154,5.352,5.352,0,0,1,2.253,1.9c.131.186.122.317-.028.391l-1.176.644a.413.413,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.184,4.184,0,0,0,671.163,321.251Z"/>
        <path class="cls-3" d="M681.788,333.753a.455.455,0,0,1-.111.351.607.607,0,0,1-.393.1h-1.96a.488.488,0,0,1-.321-.084.387.387,0,0,1-.1-.307V331.4c0-.242.1-.364.309-.364h2.3q.279,0,.279.336Z"/>
      </g>
    </g>
  </g>
</g>
<g id="satysfakcja" class="our-values__text">
  <g>
    <path class="cls-2" d="M612.738,210.67c0,116.163,94.508,210.67,210.67,210.67s210.664-94.507,210.664-210.67S939.57,0,823.408,0,612.738,94.508,612.738,210.67Z"/>
    <g class="cls-4">
      <g class="cls-4">
        <path class="cls-3" d="M717.3,109.43a.431.431,0,0,1,.042.363l-5.768,19.853a.536.536,0,0,1-.2.322.73.73,0,0,1-.42.1H709.25q-.531,0-.645-.392L704.126,113.8c-.056-.206-.122-.2-.2.028l-4.536,15.82a.536.536,0,0,1-.2.322.732.732,0,0,1-.42.1h-1.736q-.531,0-.645-.392l-5.683-19.881a.3.3,0,0,1-.028-.139c0-.242.14-.364.419-.364H692.9a.432.432,0,0,1,.477.335l4.564,16.3c.056.2.112.308.167.308.076,0,.131-.093.168-.28l4.649-16.352a.476.476,0,0,1,.5-.308h1.595a.647.647,0,0,1,.336.07.436.436,0,0,1,.168.265l4.593,16.381c.056.168.111.252.168.252s.111-.084.168-.252l4.62-16.408q.056-.307.476-.308h1.4A.4.4,0,0,1,717.3,109.43Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M722.479,126.062a4.962,4.962,0,0,0,1.779,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.2.042l1.177.588q.223.141.056.391a6.823,6.823,0,0,1-2.6,2.451,7.881,7.881,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.656,6.656,0,0,1,2.534-2.729,6.984,6.984,0,0,1,3.626-.952,6.873,6.873,0,0,1,3.542.9,6,6,0,0,1,2.366,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126H722.074q-.252,0-.252.42A4.933,4.933,0,0,0,722.479,126.062Zm7.911-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.113,4.113,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.688,4.688,0,0,0-1.624,1.848,5.125,5.125,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M755.506,129.674v-1.2c0-.168-.047-.252-.14-.252a.384.384,0,0,0-.224.14,8.9,8.9,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.081,6.081,0,0,1-3.192-.84,5.745,5.745,0,0,1-2.212-2.534,9.421,9.421,0,0,1-.812-4.1,9.661,9.661,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.957,5.957,0,0,1,3.389-.994,5.576,5.576,0,0,1,2.184.392A6.6,6.6,0,0,1,755,116.43a.711.711,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364h1.708c.205,0,.307.122.307.364v19.964a.542.542,0,0,1-.084.35.479.479,0,0,1-.363.1H755.9A.346.346,0,0,1,755.506,129.674Zm-1.89-12.53a4.287,4.287,0,0,0-1.974-.462,4.169,4.169,0,0,0-3.416,1.6,7.007,7.007,0,0,0-1.288,4.508,6.519,6.519,0,0,0,1.274,4.4,4.137,4.137,0,0,0,3.262,1.456,3.981,3.981,0,0,0,2-.518,4.1,4.1,0,0,0,1.427-1.3,2.823,2.823,0,0,0,.519-1.568V118.5A7.259,7.259,0,0,0,753.616,117.144Z"/>
        <path class="cls-3" d="M765.739,126.062a4.962,4.962,0,0,0,1.779,1.82,4.7,4.7,0,0,0,2.463.672,4.983,4.983,0,0,0,4.369-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.195.042l1.177.588c.148.094.168.224.056.391a6.823,6.823,0,0,1-2.6,2.451,7.884,7.884,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.919,8.919,0,0,1,.923-4.159A6.658,6.658,0,0,1,766.1,115.9a6.983,6.983,0,0,1,3.625-.952,6.868,6.868,0,0,1,3.542.9,5.993,5.993,0,0,1,2.367,2.617,9.222,9.222,0,0,1,.84,4.075v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126H765.334q-.252,0-.252.42A4.933,4.933,0,0,0,765.739,126.062Zm7.91-4.452a.711.711,0,0,0,.393-.07c.056-.046.084-.154.084-.322a5.08,5.08,0,0,0-.519-2.282,4.1,4.1,0,0,0-1.511-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.688,4.688,0,0,0-1.624,1.848,5.125,5.125,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M781.182,129.7V115.59q0-.365.307-.364h1.624a.273.273,0,0,1,.309.308v1.82c0,.112.032.178.1.2s.135-.018.209-.112q2.129-2.492,4.34-2.492.981,0,.981.364v1.68c0,.186-.084.261-.252.224a7.865,7.865,0,0,0-1.26-.084,3.986,3.986,0,0,0-1.765.476,4.979,4.979,0,0,0-1.6,1.232,2.368,2.368,0,0,0-.645,1.539v9.269a.371.371,0,0,1-.42.42h-1.54Q781.182,130.066,781.182,129.7Z"/>
        <path class="cls-3" d="M795.77,112.034a.606.606,0,0,1-.113.406.507.507,0,0,1-.391.126h-1.624c-.281,0-.421-.158-.421-.476v-2.352c0-.3.131-.448.392-.448h1.764a.383.383,0,0,1,.28.112.42.42,0,0,1,.113.307Zm-.113,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.427a.485.485,0,0,1-.336-.084.428.428,0,0,1-.085-.308V115.618q0-.391.337-.392h1.708q.307,0,.307.364Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M813.731,115.352a.3.3,0,0,1,.014.266l-5.684,14.028a.6.6,0,0,1-.644.42h-1.008a.681.681,0,0,1-.7-.392l-5.656-14.028a.306.306,0,0,1-.028-.14c0-.186.112-.28.336-.28H802.1a.468.468,0,0,1,.448.308l4.4,11c.056.187.122.28.2.28s.158-.1.252-.308l4.368-10.976a.391.391,0,0,1,.42-.308h1.288A.276.276,0,0,1,813.731,115.352Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M819.5,126.062a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588q.223.141.056.391a6.823,6.823,0,0,1-2.6,2.451,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.989,5.989,0,0,1,2.365,2.617,9.194,9.194,0,0,1,.841,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H819.094q-.252,0-.252.42A4.934,4.934,0,0,0,819.5,126.062Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.513-1.68,4.21,4.21,0,0,0-2.309-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.15,5.15,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M843.859,133.888a4.052,4.052,0,0,1-3.037.938,9.815,9.815,0,0,1-1.526-.07q-.322-.071-.322-.294V133.23c0-.206.093-.308.28-.308l1.428-.084a1.812,1.812,0,0,0,1.3-.518,2.458,2.458,0,0,0,.407-1.611V115.618q0-.391.336-.392h1.708q.308,0,.308.364v15.287A4.294,4.294,0,0,1,843.859,133.888Zm.967-21.854q0,.532-.476.532H842.7c-.28,0-.421-.158-.421-.476v-2.352a.467.467,0,0,1,.113-.322.358.358,0,0,1,.28-.126h1.792a.338.338,0,0,1,.266.112.454.454,0,0,1,.1.307Z"/>
        <path class="cls-3" d="M852.96,129.38a6.519,6.519,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.83,8.83,0,0,1,.882,4,8.476,8.476,0,0,1-.9,3.948,6.715,6.715,0,0,1-2.5,2.716,6.936,6.936,0,0,1-3.682.98A7.069,7.069,0,0,1,852.96,129.38Zm7.168-2.38a8.31,8.31,0,0,0-.028-8.708,4.069,4.069,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.443,1.61,8.123,8.123,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.049,4.049,0,0,0,860.128,127Z"/>
        <path class="cls-3" d="M868.094,134.63c-.243-.038-.365-.14-.365-.308V133.23a.292.292,0,0,1,.155-.28,1.524,1.524,0,0,1,.63-.084h1.763a1.827,1.827,0,0,0,1.233-.491,3.444,3.444,0,0,0,.882-1.217,3.486,3.486,0,0,0,.322-1.4,1.7,1.7,0,0,0-.112-.673L867.254,115.7a.369.369,0,0,1-.028-.168q0-.308.336-.308H869.3a.466.466,0,0,1,.447.308l4.173,10.864c.056.168.13.252.224.252q.168,0,.28-.336l4.115-10.78a.437.437,0,0,1,.393-.308h1.315a.283.283,0,0,1,.252.126.268.268,0,0,1,.028.266l-6.16,15.764a5.211,5.211,0,0,1-1.876,2.6,5.721,5.721,0,0,1-3.051.7A10.573,10.573,0,0,1,868.094,134.63Z"/>
        <path class="cls-3" d="M900.069,115.506v1.2c0,.168-.093.253-.279.253H896.6c-.15,0-.224.084-.224.252v12.46q0,.391-.336.392H894.33q-.309,0-.309-.364V117.186c0-.149-.065-.224-.2-.224h-2.743c-.187,0-.28-.085-.28-.253v-1.2c0-.186.1-.28.307-.28h2.661c.168,0,.251-.094.251-.28V112.9q0-3.612,3.5-3.612h2.325c.242,0,.364.112.364.335v1.093c0,.187-.1.28-.309.28h-1.96q-1.625,0-1.624,2.016v1.96a.223.223,0,0,0,.252.252h3.221A.247.247,0,0,1,900.069,115.506Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M904.3,129.7V115.59q0-.365.307-.364h1.625a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.136-.018.21-.112q2.129-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.261-.252.224a7.865,7.865,0,0,0-1.26-.084,3.979,3.979,0,0,0-1.764.476,4.983,4.983,0,0,0-1.6,1.232,2.368,2.368,0,0,0-.645,1.539v9.269a.371.371,0,0,1-.419.42h-1.541Q904.3,130.066,904.3,129.7Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M918.563,129.38a6.519,6.519,0,0,1-2.492-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.843,8.843,0,0,1,.881,4,8.476,8.476,0,0,1-.9,3.948,6.724,6.724,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.072,7.072,0,0,1,918.563,129.38Zm7.168-2.38a8.31,8.31,0,0,0-.028-8.708,4.069,4.069,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,925.731,127Z"/>
        <path class="cls-3" d="M934.229,129.7V115.59c0-.243.1-.364.308-.364h1.68c.186,0,.28.1.28.308v1.624q0,.336.28.084a14.832,14.832,0,0,1,2.576-1.764,5.256,5.256,0,0,1,2.38-.532,4.481,4.481,0,0,1,2.59.7,3.487,3.487,0,0,1,1.386,1.764c.037.056.074.084.112.084a.613.613,0,0,0,.28-.14,13.983,13.983,0,0,1,2.618-1.82,5.355,5.355,0,0,1,2.45-.588,3.946,3.946,0,0,1,4.284,4.256v10.416q0,.449-.42.448h-1.568a.322.322,0,0,1-.364-.364v-10a3.141,3.141,0,0,0-.672-2.156,2.558,2.558,0,0,0-2.016-.757,4.246,4.246,0,0,0-2.1.547,11,11,0,0,0-1.9,1.358,1.63,1.63,0,0,0-.308.392.935.935,0,0,0-.084.42v10.108q0,.449-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10q0-2.912-2.688-2.913a4.178,4.178,0,0,0-2.058.519,14.294,14.294,0,0,0-2.338,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C934.359,130.066,934.229,129.945,934.229,129.7Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M676.8,163.675a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.971,10.971,0,0,1,5.474-1.512l2.184-.168q.476-.056.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.549,3.549,0,0,0-2.534-.882,4.737,4.737,0,0,0-4.031,2.24.47.47,0,0,1-.168.182.274.274,0,0,1-.225-.014l-1.512-.5a.2.2,0,0,1-.139-.2.628.628,0,0,1,.084-.252,5.819,5.819,0,0,1,2.393-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.426,4.426,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4c-.224,0-.354-.149-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.386.386,0,0,0-.252.14A6.974,6.974,0,0,1,676.8,163.675Zm4.929-7.84-1.821.168a9.1,9.1,0,0,0-4.017,1.008,2.587,2.587,0,0,0-1.414,2.324,2.33,2.33,0,0,0,.853,1.9,3.487,3.487,0,0,0,2.282.7,4.937,4.937,0,0,0,1.708-.308,5.024,5.024,0,0,0,1.484-.839,4.132,4.132,0,0,0,.994-1.079,2.131,2.131,0,0,0,.35-1.106v-2.324C682.147,155.985,682.007,155.835,681.728,155.835Z"/>
        <path class="cls-3" d="M699.549,149.227a7.066,7.066,0,0,1,2.309,2.52l.057.224c0,.112-.065.178-.2.2l-1.54.364-.084.028c-.094,0-.187-.074-.281-.224a7.582,7.582,0,0,0-1.721-1.764,3.292,3.292,0,0,0-1.89-.56,4.215,4.215,0,0,0-3.473,1.61,6.782,6.782,0,0,0-1.288,4.354,6.715,6.715,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.514,1.6,4.144,4.144,0,0,0,2.309-.644,6,6,0,0,0,1.807-1.988c.056-.094.112-.144.168-.154a.331.331,0,0,1,.2.042l1.2.448c.131.056.168.178.113.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.612,1.022,6.889,6.889,0,0,1-3.64-.966,6.632,6.632,0,0,1-2.492-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.65,6.65,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.186,6.186,0,0,1,699.549,149.227Z"/>
        <path class="cls-3" d="M710.4,150.292c-.149,0-.223.083-.223.251v8.876a2.673,2.673,0,0,0,.42,1.736,1.745,1.745,0,0,0,1.372.476H713.9a.272.272,0,0,1,.308.308l-.027.952c0,.187-.113.308-.336.364a16.3,16.3,0,0,1-2.632.14,3.482,3.482,0,0,1-2.576-.826,3.616,3.616,0,0,1-.813-2.618V150.6c0-.2-.093-.307-.279-.307H705.3c-.206,0-.309-.094-.309-.281v-1.176a.248.248,0,0,1,.28-.28h2.38c.131,0,.2-.074.2-.224l.307-4.452q0-.364.309-.364h1.4c.225,0,.336.131.336.392v4.424a.2.2,0,0,0,.225.224h3.527q.309,0,.309.252v1.2c0,.187-.1.281-.309.281Z"/>
        <path class="cls-3" d="M721.179,145.363a.6.6,0,0,1-.113.406.507.507,0,0,1-.391.126h-1.624c-.28,0-.421-.158-.421-.476v-2.352c0-.3.131-.448.392-.448h1.764a.383.383,0,0,1,.28.112.417.417,0,0,1,.113.308Zm-.113,17.556a.5.5,0,0,1-.112.378.594.594,0,0,1-.391.1h-1.428a.485.485,0,0,1-.336-.084.428.428,0,0,1-.085-.308V148.947q0-.392.337-.392h1.708q.308,0,.307.364Z"/>
        <path class="cls-3" d="M729.313,162.709a6.516,6.516,0,0,1-2.493-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.671,6.671,0,0,1,2.493,2.758,8.833,8.833,0,0,1,.881,4,8.488,8.488,0,0,1-.895,3.948,6.725,6.725,0,0,1-2.506,2.716,6.937,6.937,0,0,1-3.683.98A7.068,7.068,0,0,1,729.313,162.709Zm7.167-2.38a8.31,8.31,0,0,0-.028-8.708,4.065,4.065,0,0,0-3.4-1.61,4.137,4.137,0,0,0-3.445,1.61,8.134,8.134,0,0,0,0,8.694,4.181,4.181,0,0,0,3.473,1.6A4.046,4.046,0,0,0,736.48,160.329Z"/>
        <path class="cls-3" d="M744.979,163.031V148.919q0-.365.307-.364h1.681a.272.272,0,0,1,.307.308v1.624c0,.206.084.233.252.084a15.409,15.409,0,0,1,2.674-1.764,5.658,5.658,0,0,1,2.479-.532A4.581,4.581,0,0,1,756,149.423a4.08,4.08,0,0,1,1.191,3.08v10.444q0,.449-.42.448H755.2a.321.321,0,0,1-.363-.364v-10a3.017,3.017,0,0,0-.715-2.156,2.888,2.888,0,0,0-2.2-.756,4.615,4.615,0,0,0-2.17.518,13.609,13.609,0,0,0-2.423,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C745.108,163.4,744.979,163.274,744.979,163.031Z"/>
        <path class="cls-3" d="M761.834,167.287a.434.434,0,0,1,0-.308l1.261-3.136a.662.662,0,0,0,.084-.252c0-.131-.1-.2-.309-.2h-.363q-.364,0-.364-.336v-2.492c0-.205.092-.308.28-.308h2.323a.248.248,0,0,1,.28.28v2.3a.64.64,0,0,1-.084.336l-2.044,3.92a.587.587,0,0,1-.559.308h-.309A.2.2,0,0,1,761.834,167.287Z"/>
        <path class="cls-3" d="M796.722,148.681a.273.273,0,0,1,.028.266l-4.284,14.084a.5.5,0,0,1-.532.364H790.59a.536.536,0,0,1-.588-.364l-3.332-10.472c-.056-.168-.122-.252-.2-.252-.037,0-.084.084-.14.252l-3.164,10.472a.5.5,0,0,1-.532.364H781.21a.537.537,0,0,1-.588-.364l-4.4-14.056a.306.306,0,0,1-.028-.14c0-.186.112-.28.336-.28h1.652a.468.468,0,0,1,.448.308l3.332,10.892c.055.187.121.28.2.28.056,0,.13-.1.224-.308l3.3-10.892a.371.371,0,0,1,.393-.28H787.4a.39.39,0,0,1,.42.308l3.445,10.892c.055.187.121.28.2.28s.14-.1.2-.308l3.22-10.864a.486.486,0,0,1,.476-.308h1.12A.283.283,0,0,1,796.722,148.681Z"/>
        <path class="cls-3" d="M803.4,159.391a4.962,4.962,0,0,0,1.779,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.195.042l1.177.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.884,7.884,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.912,8.912,0,0,1,.923-4.158,6.655,6.655,0,0,1,2.535-2.73,6.984,6.984,0,0,1,3.626-.952,6.873,6.873,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.215,9.215,0,0,1,.84,4.074v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126H802.994q-.252,0-.252.42A4.933,4.933,0,0,0,803.4,159.391Zm7.911-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.113,4.113,0,0,0-1.512-1.68,4.224,4.224,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.688,4.688,0,0,0-1.624,1.848,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M835.907,149.227a7.068,7.068,0,0,1,2.31,2.52l.056.224c0,.112-.065.178-.2.2l-1.54.364-.084.028c-.094,0-.186-.074-.28-.224a7.6,7.6,0,0,0-1.722-1.764,3.289,3.289,0,0,0-1.889-.56,4.212,4.212,0,0,0-3.473,1.61,6.777,6.777,0,0,0-1.288,4.354,6.709,6.709,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.513,1.6,4.147,4.147,0,0,0,2.31-.644,6,6,0,0,0,1.806-1.988c.056-.094.113-.144.168-.154a.335.335,0,0,1,.2.042l1.2.448c.131.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.612,1.022,6.894,6.894,0,0,1-3.64-.966,6.646,6.646,0,0,1-2.492-2.7,8.534,8.534,0,0,1-.895-3.976,8.8,8.8,0,0,1,.895-4.018,6.664,6.664,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.186,6.186,0,0,1,835.907,149.227Z"/>
        <path class="cls-3" d="M846.478,163.675a4.979,4.979,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.052,4.052,0,0,1,1.889-3.528,10.968,10.968,0,0,1,5.474-1.512l2.185-.168q.476-.056.475-.42v-.756a3.129,3.129,0,0,0-.937-2.394,3.552,3.552,0,0,0-2.534-.882,4.741,4.741,0,0,0-4.033,2.24.449.449,0,0,1-.168.182.269.269,0,0,1-.223-.014l-1.513-.5a.2.2,0,0,1-.14-.2.662.662,0,0,1,.084-.252,5.836,5.836,0,0,1,2.395-2.31,7.732,7.732,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.432,4.432,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.438.438,0,0,1-.309.084h-1.4q-.337,0-.393-.448l-.055-1.288c-.019-.13-.066-.2-.14-.2a.39.39,0,0,0-.253.14A6.977,6.977,0,0,1,846.478,163.675Zm4.927-7.84-1.82.168a9.11,9.11,0,0,0-4.018,1.008,2.591,2.591,0,0,0-1.414,2.324,2.329,2.329,0,0,0,.855,1.9,3.482,3.482,0,0,0,2.281.7,4.929,4.929,0,0,0,1.708-.308,5.011,5.011,0,0,0,1.484-.839,4.137,4.137,0,0,0,1-1.079,2.129,2.129,0,0,0,.349-1.106v-2.324C851.825,155.985,851.686,155.835,851.405,155.835Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M859.582,163.031V148.919q0-.365.308-.364h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.136-.018.21-.112q2.128-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.224a7.865,7.865,0,0,0-1.26-.084,3.982,3.982,0,0,0-1.764.476,5,5,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.54v9.268a.371.371,0,0,1-.42.42h-1.54C859.712,163.4,859.582,163.274,859.582,163.031Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M873.568,159.391a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.465.672,4.981,4.981,0,0,0,4.367-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.057.392a6.837,6.837,0,0,1-2.605,2.45,7.881,7.881,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.533-2.674,8.278,8.278,0,0,1-.91-3.934,8.9,8.9,0,0,1,.923-4.158,6.646,6.646,0,0,1,2.534-2.73,6.988,6.988,0,0,1,3.627-.952,6.869,6.869,0,0,1,3.541.9,5.994,5.994,0,0,1,2.366,2.618,9.215,9.215,0,0,1,.841,4.074v.112a.705.705,0,0,1-.084.378.379.379,0,0,1-.336.126H873.162q-.252,0-.252.42A4.924,4.924,0,0,0,873.568,159.391Zm7.911-4.452a.691.691,0,0,0,.391-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.113,4.113,0,0,0-1.512-1.68,4.221,4.221,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.688,4.688,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M900.07,163.675a4.977,4.977,0,0,1-3.429-1.106,3.749,3.749,0,0,1-1.246-2.954,4.049,4.049,0,0,1,1.888-3.528,10.975,10.975,0,0,1,5.475-1.512l2.183-.168c.319-.037.477-.177.477-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.551,3.551,0,0,0-2.535-.882,4.739,4.739,0,0,0-4.031,2.24.463.463,0,0,1-.168.182.274.274,0,0,1-.225-.014l-1.511-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.734,7.734,0,0,1,3.681-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.338,0-.393-.448l-.057-1.288c-.017-.13-.064-.2-.138-.2a.382.382,0,0,0-.253.14A6.978,6.978,0,0,1,900.07,163.675Zm4.928-7.84-1.82.168a9.1,9.1,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.937,4.937,0,0,0,1.708-.308,5.024,5.024,0,0,0,1.484-.839,4.132,4.132,0,0,0,.994-1.079,2.131,2.131,0,0,0,.35-1.106v-2.324C905.418,155.985,905.277,155.835,905,155.835Z"/>
        <path class="cls-3" d="M913.621,163.4a.37.37,0,0,1-.447-.448V143.011c0-.261.111-.392.336-.392h1.736q.279,0,.279.392v6.86c0,.206.047.308.141.308a.622.622,0,0,0,.279-.14,6.313,6.313,0,0,1,4.481-1.764,5.162,5.162,0,0,1,4.466,2.114,9.191,9.191,0,0,1,1.526,5.5,8.786,8.786,0,0,1-1.694,5.7,5.71,5.71,0,0,1-4.691,2.086,5.339,5.339,0,0,1-2.828-.686,5.982,5.982,0,0,1-1.735-1.526.226.226,0,0,0-.168-.112c-.075,0-.122.066-.14.2l-.141,1.4a.711.711,0,0,1-.154.35.484.484,0,0,1-.349.1Zm1.9-5.012a3.1,3.1,0,0,0,.575,1.806,4.158,4.158,0,0,0,1.5,1.316,4.059,4.059,0,0,0,1.931.49,3.948,3.948,0,0,0,3.235-1.512,7.13,7.13,0,0,0,1.217-4.536,7.609,7.609,0,0,0-1.091-4.41,3.627,3.627,0,0,0-3.164-1.554,4.983,4.983,0,0,0-2.338.56,5.944,5.944,0,0,0-1.863,1.512Z"/>
        <path class="cls-3" d="M933.852,162.709a6.509,6.509,0,0,1-2.493-2.7,9.337,9.337,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.671,6.671,0,0,1,2.493,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.725,6.725,0,0,1-2.506,2.716,6.936,6.936,0,0,1-3.682.98A7.069,7.069,0,0,1,933.852,162.709Zm7.168-2.38a8.313,8.313,0,0,0-.029-8.708,4.067,4.067,0,0,0-3.4-1.61,4.132,4.132,0,0,0-3.444,1.61,8.123,8.123,0,0,0,0,8.694,4.178,4.178,0,0,0,3.471,1.6A4.051,4.051,0,0,0,941.02,160.329Z"/>
        <path class="cls-3" d="M956.812,161.2a10.435,10.435,0,0,0,2.253-1.806V148.947q0-.392.449-.392h1.539a.347.347,0,0,1,.392.392l-.056,14.084q0,.364-.307.364h-1.625a.273.273,0,0,1-.308-.308v-1.652q0-.336-.252-.084a11.537,11.537,0,0,1-2.688,1.806,5.918,5.918,0,0,1-2.408.518,4.529,4.529,0,0,1-3.219-1.176,4.048,4.048,0,0,1-1.261-3.108V148.975a.371.371,0,0,1,.42-.42h1.569c.242,0,.364.131.364.392v9.828a3.135,3.135,0,0,0,.769,2.226,2.749,2.749,0,0,0,2.114.826A4.512,4.512,0,0,0,956.812,161.2Z"/>
        <path class="cls-3" d="M971.049,150.292c-.15,0-.224.083-.224.251v8.876a2.679,2.679,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.932a.273.273,0,0,1,.308.308l-.028.952q0,.281-.336.364a16.271,16.271,0,0,1-2.632.14,3.485,3.485,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618V150.6c0-.2-.094-.307-.28-.307h-2.24c-.2,0-.308-.094-.308-.281v-1.176a.248.248,0,0,1,.281-.28h2.379c.131,0,.2-.074.2-.224l.308-4.452q0-.364.308-.364h1.4c.223,0,.336.131.336.392v4.424a.2.2,0,0,0,.223.224h3.528c.206,0,.308.084.308.252v1.2c0,.187-.1.281-.308.281Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M686.949,182.557a7.077,7.077,0,0,1,2.31,2.52l.055.224c0,.113-.064.178-.195.2l-1.54.363-.084.028c-.094,0-.186-.074-.28-.223a7.611,7.611,0,0,0-1.722-1.765,3.289,3.289,0,0,0-1.889-.56,4.211,4.211,0,0,0-3.473,1.61,7.991,7.991,0,0,0,.014,8.722,4.3,4.3,0,0,0,3.514,1.6,4.147,4.147,0,0,0,2.31-.644,6.013,6.013,0,0,0,1.806-1.988c.056-.094.112-.144.168-.154a.331.331,0,0,1,.2.042l1.2.448c.13.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646,6.293,6.293,0,0,1-3.612,1.022,6.9,6.9,0,0,1-3.641-.965,6.653,6.653,0,0,1-2.491-2.7,8.534,8.534,0,0,1-.895-3.976,8.8,8.8,0,0,1,.895-4.018,6.657,6.657,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.186,6.186,0,0,1,686.949,182.557Z"/>
        <path class="cls-3" d="M696.385,196.04a6.515,6.515,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.711,6.711,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.078,7.078,0,0,1,696.385,196.04Zm7.168-2.381a8.307,8.307,0,0,0-.029-8.708,4.065,4.065,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.444,1.61,8.123,8.123,0,0,0,0,8.694,4.179,4.179,0,0,0,3.471,1.6A4.051,4.051,0,0,0,703.553,193.659Z"/>
        <path class="cls-3" d="M712.051,196.361V182.25c0-.244.1-.365.307-.365h1.681c.186,0,.279.1.279.308v1.624c0,.225.094.252.281.084a14.849,14.849,0,0,1,2.575-1.764,5.26,5.26,0,0,1,2.381-.532,4.483,4.483,0,0,1,2.59.7,3.492,3.492,0,0,1,1.386,1.764c.037.056.074.084.112.084a.611.611,0,0,0,.279-.14,14.019,14.019,0,0,1,2.619-1.82,5.348,5.348,0,0,1,2.449-.588,3.944,3.944,0,0,1,4.284,4.256v10.416c0,.3-.139.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.136,3.136,0,0,0-.672-2.156,2.559,2.559,0,0,0-2.016-.756,4.232,4.232,0,0,0-2.1.547,10.943,10.943,0,0,0-1.9,1.357,1.638,1.638,0,0,0-.308.393.941.941,0,0,0-.084.419v10.108c0,.3-.139.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10q0-2.911-2.687-2.912a4.181,4.181,0,0,0-2.059.518,14.337,14.337,0,0,0-2.338,1.75v10.584a.357.357,0,0,1-.42.42h-1.54Q712.051,196.725,712.051,196.361Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M746.6,182.166v1.2c0,.168-.094.253-.28.253H743.13c-.15,0-.224.084-.224.252v12.459q0,.391-.336.392h-1.708c-.206,0-.308-.121-.308-.364V183.845c0-.149-.066-.223-.2-.223h-2.743c-.187,0-.28-.085-.28-.253v-1.2c0-.187.1-.281.308-.281h2.66c.168,0,.252-.093.252-.28v-2.044q0-3.612,3.5-3.612h2.324c.242,0,.364.112.364.336v1.092c0,.187-.1.28-.308.28h-1.96q-1.625,0-1.624,2.016v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,746.6,182.166Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M752.748,196.04a6.522,6.522,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.716,6.716,0,0,1-2.5,2.716,6.939,6.939,0,0,1-3.683.98A7.08,7.08,0,0,1,752.748,196.04Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.067,4.067,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.444,1.61,8.123,8.123,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,759.916,193.659Z"/>
        <path class="cls-3" d="M768.414,196.361V182.25c0-.244.1-.365.308-.365h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.129-2.492,4.34-2.492.98,0,.979.364v1.68c0,.186-.084.262-.252.225a7.745,7.745,0,0,0-1.259-.084,3.983,3.983,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.363,2.363,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C768.544,196.725,768.414,196.6,768.414,196.361Z"/>
        <path class="cls-3" d="M784.458,183.622c-.149,0-.224.084-.224.252v8.876a2.678,2.678,0,0,0,.42,1.735,1.746,1.746,0,0,0,1.371.476h1.933a.273.273,0,0,1,.308.308l-.028.952q0,.28-.336.364a16.288,16.288,0,0,1-2.632.14,3.486,3.486,0,0,1-2.577-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.205-.094-.307-.28-.307h-2.241c-.205,0-.307-.094-.307-.281v-1.175a.248.248,0,0,1,.28-.281h2.38c.13,0,.2-.074.2-.224l.308-4.452q0-.364.307-.364h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.205,0,.308.084.308.252v1.2c0,.187-.1.281-.308.281Z"/>
        <path class="cls-3" d="M803.834,197.005a4.979,4.979,0,0,1-3.43-1.106,3.749,3.749,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.528,10.974,10.974,0,0,1,5.474-1.512l2.184-.168q.477-.056.477-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.553,3.553,0,0,0-2.535-.882,4.738,4.738,0,0,0-4.031,2.24.482.482,0,0,1-.168.183.279.279,0,0,1-.225-.015l-1.512-.5a.2.2,0,0,1-.139-.2.645.645,0,0,1,.084-.252,5.825,5.825,0,0,1,2.393-2.31,7.738,7.738,0,0,1,3.682-.826,6.2,6.2,0,0,1,4.2,1.274,4.428,4.428,0,0,1,1.455,3.514v9.912a.481.481,0,0,1-.084.336.43.43,0,0,1-.307.084h-1.4q-.336,0-.392-.448l-.057-1.288c-.018-.13-.064-.2-.139-.2a.386.386,0,0,0-.253.14A6.974,6.974,0,0,1,803.834,197.005Zm4.928-7.839-1.821.167a9.1,9.1,0,0,0-4.017,1.008,2.587,2.587,0,0,0-1.414,2.325,2.329,2.329,0,0,0,.853,1.9,3.49,3.49,0,0,0,2.283.7,4.914,4.914,0,0,0,1.708-.308,5.036,5.036,0,0,0,1.484-.839,4.132,4.132,0,0,0,.994-1.079,2.138,2.138,0,0,0,.35-1.106v-2.324C809.182,189.315,809.041,189.166,808.762,189.166Z"/>
        <path class="cls-3" d="M816.938,196.361V182.25c0-.244.1-.365.307-.365h1.681a.272.272,0,0,1,.307.308v1.624c0,.206.084.233.252.084a15.409,15.409,0,0,1,2.674-1.764,5.654,5.654,0,0,1,2.478-.532,4.582,4.582,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08v10.444c0,.3-.139.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.893,2.893,0,0,0-2.2-.756,4.618,4.618,0,0,0-2.17.518,13.644,13.644,0,0,0-2.422,1.75v10.584a.358.358,0,0,1-.42.42h-1.54C817.067,196.725,816.938,196.6,816.938,196.361Z"/>
        <path class="cls-3" d="M844.854,196.333v-1.2q0-.252-.141-.252a.384.384,0,0,0-.224.14,8.841,8.841,0,0,1-1.834,1.414,5.084,5.084,0,0,1-2.561.574,6.091,6.091,0,0,1-3.193-.839,5.759,5.759,0,0,1-2.212-2.535,9.421,9.421,0,0,1-.812-4.1,9.664,9.664,0,0,1,.84-4.144,6.674,6.674,0,0,1,2.324-2.786,5.953,5.953,0,0,1,3.389-.994,5.549,5.549,0,0,1,2.183.393,6.6,6.6,0,0,1,1.737,1.091.709.709,0,0,0,.307.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364h1.708c.206,0,.308.122.308.364v19.964a.542.542,0,0,1-.084.35.482.482,0,0,1-.363.1h-1.429A.346.346,0,0,1,844.854,196.333Zm-1.891-12.53a4.3,4.3,0,0,0-1.974-.462,4.173,4.173,0,0,0-3.416,1.6,7.013,7.013,0,0,0-1.288,4.508,6.517,6.517,0,0,0,1.274,4.4,4.138,4.138,0,0,0,3.262,1.456,3.968,3.968,0,0,0,2-.518,4.1,4.1,0,0,0,1.428-1.3,2.829,2.829,0,0,0,.519-1.568v-6.748A7.244,7.244,0,0,0,842.963,183.8Z"/>
        <path class="cls-3" d="M862.787,196.04a6.522,6.522,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.5,8.5,0,0,1-.895,3.948,6.725,6.725,0,0,1-2.506,2.716,6.939,6.939,0,0,1-3.683.98A7.08,7.08,0,0,1,862.787,196.04Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.067,4.067,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.444,1.61,8.123,8.123,0,0,0,0,8.694,4.179,4.179,0,0,0,3.472,1.6A4.048,4.048,0,0,0,869.955,193.659Z"/>
        <path class="cls-3" d="M885.747,194.527A10.463,10.463,0,0,0,888,192.721V182.277q0-.392.448-.392h1.539a.347.347,0,0,1,.393.392l-.057,14.084c0,.243-.1.364-.306.364h-1.625a.272.272,0,0,1-.308-.308v-1.652c0-.223-.084-.252-.252-.084a11.525,11.525,0,0,1-2.688,1.806,5.906,5.906,0,0,1-2.409.518,4.526,4.526,0,0,1-3.218-1.176,4.048,4.048,0,0,1-1.261-3.108V182.305a.371.371,0,0,1,.42-.42h1.568c.242,0,.364.131.364.392v9.828a3.133,3.133,0,0,0,.77,2.227,2.751,2.751,0,0,0,2.113.825A4.506,4.506,0,0,0,885.747,194.527Z"/>
        <path class="cls-3" d="M896.064,196.361V182.25c0-.244.1-.365.309-.365H898a.273.273,0,0,1,.309.308v1.82c0,.112.032.178.1.2s.136-.018.211-.112q2.127-2.492,4.34-2.492c.652,0,.98.122.98.364v1.68c0,.186-.084.262-.252.225a7.75,7.75,0,0,0-1.26-.084,3.984,3.984,0,0,0-1.765.475,5,5,0,0,0-1.595,1.233,2.364,2.364,0,0,0-.645,1.539v9.268a.371.371,0,0,1-.42.42h-1.539Q896.064,196.725,896.064,196.361Z"/>
        <path class="cls-3" d="M918.115,196.04a6.522,6.522,0,0,1-2.492-2.7,9.328,9.328,0,0,1,.027-7.98,7.1,7.1,0,0,1,9.885-2.758,6.689,6.689,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.881,4,8.5,8.5,0,0,1-.895,3.948,6.718,6.718,0,0,1-2.506,2.716,6.934,6.934,0,0,1-3.683.98A7.077,7.077,0,0,1,918.115,196.04Zm7.168-2.381a8.313,8.313,0,0,0-.029-8.708,4.065,4.065,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.445,1.61,8.129,8.129,0,0,0,0,8.694,4.181,4.181,0,0,0,3.473,1.6A4.049,4.049,0,0,0,925.283,193.659Z"/>
        <path class="cls-3" d="M953.017,182.011a.275.275,0,0,1,.028.266l-4.284,14.084a.5.5,0,0,1-.532.364h-1.344a.535.535,0,0,1-.588-.364l-3.332-10.472c-.057-.168-.122-.252-.2-.252-.039,0-.084.084-.14.252l-3.164,10.472a.5.5,0,0,1-.532.364H937.5a.537.537,0,0,1-.588-.364l-4.4-14.056a.3.3,0,0,1-.029-.139q0-.28.336-.281h1.652a.468.468,0,0,1,.449.308l3.332,10.892c.055.187.121.28.2.28.056,0,.13-.1.224-.308l3.3-10.891a.371.371,0,0,1,.393-.281h1.315a.391.391,0,0,1,.42.308l3.445,10.892c.055.187.121.28.195.28s.141-.1.2-.308l3.219-10.864a.487.487,0,0,1,.477-.308h1.12A.283.283,0,0,1,953.017,182.011Z"/>
        <path class="cls-3" d="M957.469,196.361V182.25c0-.244.1-.365.307-.365h1.681a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.407,15.407,0,0,1,2.673-1.764,5.657,5.657,0,0,1,2.478-.532,4.582,4.582,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08v10.444c0,.3-.139.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.893,2.893,0,0,0-2.2-.756,4.618,4.618,0,0,0-2.17.518,13.644,13.644,0,0,0-2.422,1.75v10.584a.357.357,0,0,1-.42.42h-1.54Q957.469,196.725,957.469,196.361Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M692.4,217.1a5.176,5.176,0,0,0-2.367-.518,4.43,4.43,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.644,1.644,0,0,0,.491,1.162,4.132,4.132,0,0,0,1.777.911l3.557,1.063a5.585,5.585,0,0,1,2.743,1.5,3.418,3.418,0,0,1,.9,2.366,3.591,3.591,0,0,1-.784,2.283,5.057,5.057,0,0,1-2.156,1.54,9.083,9.083,0,0,1-6.944-.309,5.177,5.177,0,0,1-2.38-2.226.474.474,0,0,1-.083-.224.251.251,0,0,1,.139-.224l1.092-.616c.186-.111.326-.1.42.028a5.129,5.129,0,0,0,4.676,2.408,5.336,5.336,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,5.979,5.979,0,0,0-2.1-.951l-2.884-.813a5.977,5.977,0,0,1-2.925-1.484,3.288,3.288,0,0,1-.883-2.323,3.873,3.873,0,0,1,.714-2.3,4.549,4.549,0,0,1,2.016-1.555,8.308,8.308,0,0,1,6.3.154,5.361,5.361,0,0,1,2.254,1.905c.131.186.121.317-.028.391l-1.176.644a.417.417,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.2,4.2,0,0,0,692.4,217.1Z"/>
        <path class="cls-3" d="M704.477,230.334a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.527,10.964,10.964,0,0,1,5.475-1.513l2.184-.168q.476-.056.476-.42v-.756a3.126,3.126,0,0,0-.938-2.393,3.545,3.545,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.49.49,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.384.384,0,0,0-.252.139A6.978,6.978,0,0,1,704.477,230.334Zm4.928-7.839-1.82.168a9.094,9.094,0,0,0-4.018,1.007A2.589,2.589,0,0,0,702.153,226a2.33,2.33,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.936,4.936,0,0,0,1.708-.307,5.03,5.03,0,0,0,1.483-.84,4.132,4.132,0,0,0,1-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q709.825,222.5,709.405,222.5Z"/>
        <path class="cls-3" d="M721.5,216.951q-.225,0-.225.252v8.876a2.682,2.682,0,0,0,.42,1.735,1.75,1.75,0,0,0,1.372.477H725a.272.272,0,0,1,.308.307l-.029.952c0,.187-.111.308-.336.365a16.42,16.42,0,0,1-2.631.139,3.486,3.486,0,0,1-2.577-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.205-.094-.307-.28-.307H716.4c-.205,0-.307-.094-.307-.281V215.5a.248.248,0,0,1,.28-.281h2.38c.13,0,.2-.074.2-.224l.309-4.451c0-.244.1-.365.307-.365h1.4c.223,0,.336.131.336.392v4.424a.2.2,0,0,0,.223.224h3.529c.2,0,.307.084.307.252v1.2c0,.187-.1.281-.307.281Z"/>
        <path class="cls-3" d="M732.28,212.022a.6.6,0,0,1-.112.406.5.5,0,0,1-.392.126h-1.624q-.42,0-.42-.475v-2.353c0-.3.13-.448.392-.448h1.765a.381.381,0,0,1,.279.112.416.416,0,0,1,.112.308Zm-.112,17.557a.5.5,0,0,1-.111.378.609.609,0,0,1-.393.1h-1.428a.486.486,0,0,1-.336-.084.435.435,0,0,1-.084-.307V215.606c0-.261.113-.392.336-.392h1.708c.205,0,.308.121.308.365Z"/>
        <path class="cls-3" d="M745.2,217.1a5.169,5.169,0,0,0-2.366-.518,4.435,4.435,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.647,1.647,0,0,0,.49,1.162,4.137,4.137,0,0,0,1.778.911l3.556,1.063a5.575,5.575,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.6,3.6,0,0,1-.783,2.283,5.079,5.079,0,0,1-2.157,1.54,9.083,9.083,0,0,1-6.944-.309,5.182,5.182,0,0,1-2.38-2.226.477.477,0,0,1-.084-.224.254.254,0,0,1,.14-.224l1.093-.616c.185-.111.326-.1.419.028a5.13,5.13,0,0,0,4.676,2.408,5.333,5.333,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,5.979,5.979,0,0,0-2.1-.951l-2.884-.813a5.99,5.99,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.88,3.88,0,0,1,.714-2.3,4.554,4.554,0,0,1,2.016-1.555,8.31,8.31,0,0,1,6.3.154,5.373,5.373,0,0,1,2.254,1.905c.129.186.121.317-.029.391l-1.176.644a.417.417,0,0,1-.168.056.383.383,0,0,1-.251-.14A4.2,4.2,0,0,0,745.2,217.1Z"/>
        <path class="cls-3" d="M761.428,215.5v1.2c0,.168-.094.253-.28.253h-3.192c-.149,0-.224.084-.224.252v12.46c0,.26-.112.391-.336.391h-1.708c-.206,0-.308-.121-.308-.364V217.174c0-.149-.066-.223-.2-.223H752.44c-.187,0-.28-.085-.28-.253v-1.2c0-.187.1-.281.308-.281h2.66c.168,0,.252-.093.252-.28V212.89q0-3.612,3.5-3.612H761.2c.242,0,.364.112.364.336v1.093c0,.186-.1.279-.308.279H759.3q-1.624,0-1.624,2.016v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,761.428,215.5Z"/>
        <path class="cls-3" d="M769.3,230.334a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.963,10.963,0,0,1,5.474-1.513l2.184-.168c.317-.037.477-.177.477-.42v-.756a3.126,3.126,0,0,0-.939-2.393,3.545,3.545,0,0,0-2.534-.883,4.738,4.738,0,0,0-4.031,2.24.482.482,0,0,1-.168.183.279.279,0,0,1-.225-.015l-1.512-.5a.2.2,0,0,1-.139-.2.628.628,0,0,1,.084-.252,5.825,5.825,0,0,1,2.393-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.426,4.426,0,0,1,1.456,3.514v9.912a.485.485,0,0,1-.084.336.434.434,0,0,1-.307.084h-1.4q-.336,0-.391-.448l-.057-1.288c-.019-.13-.066-.2-.14-.2a.384.384,0,0,0-.252.139A6.974,6.974,0,0,1,769.3,230.334Zm4.929-7.839-1.821.168a9.085,9.085,0,0,0-4.017,1.007A2.587,2.587,0,0,0,766.973,226a2.329,2.329,0,0,0,.853,1.9,3.489,3.489,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.048,5.048,0,0,0,1.484-.84,4.127,4.127,0,0,0,.994-1.078,2.136,2.136,0,0,0,.351-1.107v-2.324C774.645,222.644,774.5,222.5,774.225,222.5Z"/>
        <path class="cls-3" d="M792.046,215.886a7.066,7.066,0,0,1,2.309,2.52l.057.224c0,.113-.065.178-.2.2l-1.54.363-.084.028c-.094,0-.187-.074-.28-.223a7.591,7.591,0,0,0-1.722-1.765,3.292,3.292,0,0,0-1.89-.56,4.214,4.214,0,0,0-3.472,1.61,7.985,7.985,0,0,0,.014,8.722,4.3,4.3,0,0,0,3.514,1.6,4.151,4.151,0,0,0,2.309-.643,6.008,6.008,0,0,0,1.807-1.989c.056-.093.112-.144.168-.154a.331.331,0,0,1,.2.042l1.2.448c.131.056.168.178.113.365a7.3,7.3,0,0,1-2.38,2.645,6.291,6.291,0,0,1-3.612,1.022,6.9,6.9,0,0,1-3.64-.965,6.645,6.645,0,0,1-2.492-2.7,8.542,8.542,0,0,1-.9-3.977,8.8,8.8,0,0,1,.9-4.018,6.641,6.641,0,0,1,2.506-2.757,6.829,6.829,0,0,1,3.626-.981A6.186,6.186,0,0,1,792.046,215.886Z"/>
        <path class="cls-3" d="M802.9,216.951c-.15,0-.224.084-.224.252v8.876a2.676,2.676,0,0,0,.42,1.735,1.745,1.745,0,0,0,1.372.477H806.4a.271.271,0,0,1,.307.307l-.027.952c0,.187-.113.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.482,3.482,0,0,1-2.576-.826,3.616,3.616,0,0,1-.813-2.618v-9.352c0-.205-.093-.307-.279-.307H797.8c-.206,0-.309-.094-.309-.281V215.5a.248.248,0,0,1,.28-.281h2.38c.131,0,.2-.074.2-.224l.307-4.451c0-.244.1-.365.309-.365h1.4c.225,0,.336.131.336.392v4.424a.2.2,0,0,0,.225.224h3.527q.309,0,.309.252v1.2c0,.187-.1.281-.309.281Z"/>
        <path class="cls-3" d="M813.676,212.022a.6.6,0,0,1-.113.406.507.507,0,0,1-.391.126h-1.624c-.28,0-.421-.158-.421-.475v-2.353c0-.3.131-.448.393-.448h1.763a.381.381,0,0,1,.28.112.417.417,0,0,1,.113.308Zm-.113,17.557a.5.5,0,0,1-.112.378.6.6,0,0,1-.391.1h-1.428a.486.486,0,0,1-.336-.084.425.425,0,0,1-.085-.307V215.606q0-.392.337-.392h1.708c.205,0,.307.121.307.365Z"/>
        <path class="cls-3" d="M821.81,229.369a6.521,6.521,0,0,1-2.493-2.7,9.34,9.34,0,0,1,.029-7.981,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.833,8.833,0,0,1,.882,4,8.473,8.473,0,0,1-.9,3.948,6.718,6.718,0,0,1-2.506,2.716,6.932,6.932,0,0,1-3.681.98A7.075,7.075,0,0,1,821.81,229.369Zm7.168-2.381a8.313,8.313,0,0,0-.029-8.708,4.071,4.071,0,0,0-3.4-1.61,4.134,4.134,0,0,0-3.443,1.61,8.123,8.123,0,0,0,0,8.694,4.175,4.175,0,0,0,3.471,1.6A4.051,4.051,0,0,0,828.978,226.988Z"/>
        <path class="cls-3" d="M837.475,229.69V215.579c0-.244.1-.365.308-.365h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.657,5.657,0,0,1,2.478-.532,4.579,4.579,0,0,1,3.318,1.149,4.082,4.082,0,0,1,1.19,3.08v10.443q0,.449-.42.448H847.7a.321.321,0,0,1-.364-.364v-10a3.017,3.017,0,0,0-.714-2.155,2.892,2.892,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.608,13.608,0,0,0-2.422,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C837.605,230.054,837.475,229.933,837.475,229.69Z"/>
        <path class="cls-3" d="M854.331,233.946a.423.423,0,0,1,0-.308l1.26-3.136a.645.645,0,0,0,.084-.252c0-.13-.1-.2-.308-.2H855q-.365,0-.364-.336v-2.492c0-.205.093-.308.28-.308h2.324a.248.248,0,0,1,.28.28v2.3a.645.645,0,0,1-.084.336L855.4,233.75a.588.588,0,0,1-.561.308h-.308A.2.2,0,0,1,854.331,233.946Z"/>
        <path class="cls-3" d="M873.623,230.334a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.957,10.957,0,0,1,5.474-1.513l2.184-.168c.318-.037.476-.177.476-.42v-.756a3.126,3.126,0,0,0-.938-2.393,3.547,3.547,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.474.474,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.735,7.735,0,0,1,3.682-.826,6.192,6.192,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.485.485,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448L879,228.318c-.019-.13-.065-.2-.14-.2a.388.388,0,0,0-.253.139A6.974,6.974,0,0,1,873.623,230.334Zm4.928-7.839-1.821.168a9.089,9.089,0,0,0-4.017,1.007A2.591,2.591,0,0,0,871.3,226a2.327,2.327,0,0,0,.854,1.9,3.485,3.485,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.061,5.061,0,0,0,1.484-.84,4.127,4.127,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q878.971,222.5,878.551,222.5Z"/>
        <path class="cls-3" d="M894.021,217.1a5.173,5.173,0,0,0-2.367-.518,4.429,4.429,0,0,0-2.519.659,1.99,1.99,0,0,0-.981,1.721,1.644,1.644,0,0,0,.491,1.162,4.131,4.131,0,0,0,1.778.911l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.422,3.422,0,0,1,.9,2.366,3.6,3.6,0,0,1-.783,2.283,5.07,5.07,0,0,1-2.156,1.54,9.085,9.085,0,0,1-6.945-.309,5.182,5.182,0,0,1-2.38-2.226.485.485,0,0,1-.083-.224.253.253,0,0,1,.139-.224l1.093-.616c.185-.111.326-.1.42.028a5.129,5.129,0,0,0,4.676,2.408,5.332,5.332,0,0,0,2.729-.686,1.963,1.963,0,0,0,1.078-1.722,1.647,1.647,0,0,0-.643-1.316,5.985,5.985,0,0,0-2.1-.951l-2.884-.813a5.982,5.982,0,0,1-2.925-1.484,3.292,3.292,0,0,1-.882-2.323,3.873,3.873,0,0,1,.714-2.3,4.546,4.546,0,0,1,2.015-1.555,8.31,8.31,0,0,1,6.3.154,5.373,5.373,0,0,1,2.254,1.905c.13.186.121.317-.028.391l-1.176.644a.422.422,0,0,1-.168.056.386.386,0,0,1-.252-.14A4.2,4.2,0,0,0,894.021,217.1Z"/>
        <path class="cls-3" d="M929.118,215.34a.268.268,0,0,1,.028.266l-4.284,14.084a.5.5,0,0,1-.532.364h-1.344a.537.537,0,0,1-.588-.364l-3.332-10.472c-.055-.168-.122-.252-.2-.252-.037,0-.084.084-.14.252l-3.164,10.472a.5.5,0,0,1-.532.364h-1.428a.535.535,0,0,1-.587-.364l-4.4-14.056a.319.319,0,0,1-.027-.139c0-.187.111-.281.336-.281h1.651a.467.467,0,0,1,.448.308l3.332,10.893c.056.186.121.279.2.279.055,0,.129-.1.223-.308l3.3-10.891a.37.37,0,0,1,.393-.281h1.315a.391.391,0,0,1,.42.308l3.444,10.893c.056.186.121.279.2.279s.14-.1.2-.308l3.22-10.864a.484.484,0,0,1,.475-.308h1.12A.28.28,0,0,1,929.118,215.34Z"/>
        <path class="cls-3" d="M935.8,226.05a4.962,4.962,0,0,0,1.778,1.821,4.706,4.706,0,0,0,2.464.671,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.867,7.867,0,0,1-3.613.77,7.112,7.112,0,0,1-3.667-.952,6.6,6.6,0,0,1-2.534-2.674,8.287,8.287,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.982,6.982,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.978,5.978,0,0,1,2.365,2.617,9.19,9.19,0,0,1,.841,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H935.39q-.252,0-.252.42A4.919,4.919,0,0,0,935.8,226.05Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.1,5.1,0,0,0-.518-2.283,4.105,4.105,0,0,0-1.513-1.679,4.21,4.21,0,0,0-2.309-.631,4.358,4.358,0,0,0-2.492.715,4.687,4.687,0,0,0-1.624,1.847,5.153,5.153,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M953.618,229.579a.5.5,0,0,1-.112.378.606.606,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.43.43,0,0,1-.085-.307l.029-19.993q0-.391.336-.392h1.68c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M961.71,229.579a.5.5,0,0,1-.112.378.608.608,0,0,1-.392.1H959.75a.486.486,0,0,1-.336-.084.425.425,0,0,1-.085-.307l.028-19.993c0-.261.113-.392.336-.392h1.681c.224,0,.336.122.336.364Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M678.73,263.663a4.978,4.978,0,0,1-3.429-1.106,3.754,3.754,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.184-.168c.317-.038.476-.177.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.546,3.546,0,0,0-2.535-.883,4.738,4.738,0,0,0-4.031,2.241.463.463,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.655.655,0,0,1,.084-.252,5.832,5.832,0,0,1,2.394-2.309,7.734,7.734,0,0,1,3.681-.826,6.2,6.2,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.44.44,0,0,1-.308.084h-1.4c-.224,0-.355-.15-.392-.448l-.057-1.289c-.018-.129-.065-.195-.139-.195a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,678.73,263.663Zm4.929-7.84-1.82.168A9.093,9.093,0,0,0,677.821,257a2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.493,3.493,0,0,0,2.282.7,5.129,5.129,0,0,0,3.191-1.148,4.116,4.116,0,0,0,1-1.078,2.134,2.134,0,0,0,.35-1.106v-2.324C684.079,255.973,683.938,255.823,683.659,255.823Z"/>
        <path class="cls-3" d="M699.129,250.433a5.174,5.174,0,0,0-2.366-.518,4.442,4.442,0,0,0-2.521.658,2,2,0,0,0-.979,1.722,1.649,1.649,0,0,0,.49,1.162,4.139,4.139,0,0,0,1.777.91l3.557,1.064a5.585,5.585,0,0,1,2.743,1.5,3.414,3.414,0,0,1,.9,2.366,3.584,3.584,0,0,1-.785,2.282,5.049,5.049,0,0,1-2.156,1.54,9.074,9.074,0,0,1-6.943-.308,5.182,5.182,0,0,1-2.38-2.226.469.469,0,0,1-.084-.225.251.251,0,0,1,.14-.223l1.091-.616q.28-.168.42.028a5.132,5.132,0,0,0,4.676,2.408,5.337,5.337,0,0,0,2.731-.686,1.965,1.965,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.645-1.317,6.018,6.018,0,0,0-2.1-.951l-2.884-.812a5.989,5.989,0,0,1-2.926-1.485,3.286,3.286,0,0,1-.883-2.323,3.876,3.876,0,0,1,.714-2.3,4.55,4.55,0,0,1,2.017-1.554,8.305,8.305,0,0,1,6.3.154,5.347,5.347,0,0,1,2.254,1.9c.131.187.121.318-.028.392l-1.176.643a.423.423,0,0,1-.168.057.388.388,0,0,1-.252-.141A4.174,4.174,0,0,0,699.129,250.433Z"/>
        <path class="cls-3" d="M718.91,250.279c-.149,0-.223.084-.223.252v8.876a2.68,2.68,0,0,0,.419,1.736,1.749,1.749,0,0,0,1.373.476h1.931a.273.273,0,0,1,.308.308l-.028.952c0,.186-.112.308-.336.364a16.281,16.281,0,0,1-2.631.14,3.49,3.49,0,0,1-2.577-.826,3.617,3.617,0,0,1-.812-2.619v-9.351c0-.2-.094-.308-.279-.308h-2.241c-.206,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.131,0,.2-.074.2-.225l.308-4.451c0-.243.1-.364.309-.364h1.4c.225,0,.336.131.336.392v4.423a.2.2,0,0,0,.224.225h3.528c.206,0,.308.084.308.252V250c0,.187-.1.28-.308.28Z"/>
        <path class="cls-3" d="M727.254,263.019V242.971c0-.242.1-.365.309-.365h1.651c.2,0,.308.113.308.336v7.477q0,.307.252.084a14.383,14.383,0,0,1,2.716-1.736,5.933,5.933,0,0,1,2.464-.5,4.523,4.523,0,0,1,3.318,1.148,4.172,4.172,0,0,1,1.162,3.108v10.416c0,.3-.14.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.02,3.02,0,0,0-.714-2.155,2.892,2.892,0,0,0-2.2-.757,4.453,4.453,0,0,0-2.142.519,15.088,15.088,0,0,0-2.422,1.75v10.584c0,.28-.15.42-.448.42h-1.54A.321.321,0,0,1,727.254,263.019Z"/>
        <path class="cls-3" d="M747.2,259.379a4.959,4.959,0,0,0,1.778,1.82,4.71,4.71,0,0,0,2.464.672,4.98,4.98,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.533-2.674,8.279,8.279,0,0,1-.911-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,6,6,0,0,1,2.366,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H746.8c-.168,0-.252.139-.252.42A4.934,4.934,0,0,0,747.2,259.379Zm7.91-4.452a.693.693,0,0,0,.392-.071c.057-.045.084-.153.084-.321a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.137,5.137,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M777.36,250.433a5.169,5.169,0,0,0-2.366-.518,4.438,4.438,0,0,0-2.52.658,1.994,1.994,0,0,0-.98,1.722,1.649,1.649,0,0,0,.49,1.162,4.144,4.144,0,0,0,1.778.91l3.556,1.064a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.588,3.588,0,0,1-.784,2.282,5.055,5.055,0,0,1-2.156,1.54,9.076,9.076,0,0,1-6.944-.308,5.182,5.182,0,0,1-2.38-2.226.48.48,0,0,1-.084-.225.251.251,0,0,1,.14-.223l1.092-.616q.279-.168.42.028a5.131,5.131,0,0,0,4.676,2.408,5.336,5.336,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.647,1.647,0,0,0-.644-1.317,6.015,6.015,0,0,0-2.1-.951l-2.884-.812a5.994,5.994,0,0,1-2.926-1.485,3.29,3.29,0,0,1-.882-2.323,3.882,3.882,0,0,1,.713-2.3,4.556,4.556,0,0,1,2.017-1.554,8.308,8.308,0,0,1,6.3.154,5.359,5.359,0,0,1,2.254,1.9q.2.281-.029.392l-1.175.643a.428.428,0,0,1-.168.057.384.384,0,0,1-.252-.141A4.184,4.184,0,0,0,777.36,250.433Z"/>
        <path class="cls-3" d="M789.441,263.663a4.978,4.978,0,0,1-3.429-1.106,3.754,3.754,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.183-.168c.318-.038.477-.177.477-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.546,3.546,0,0,0-2.535-.883,4.738,4.738,0,0,0-4.031,2.241.47.47,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.655.655,0,0,1,.084-.252,5.832,5.832,0,0,1,2.394-2.309,7.734,7.734,0,0,1,3.681-.826,6.2,6.2,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.442.442,0,0,1-.308.084h-1.4c-.224,0-.355-.15-.392-.448l-.057-1.289c-.018-.129-.065-.195-.139-.195a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,789.441,263.663Zm4.929-7.84-1.82.168A9.089,9.089,0,0,0,788.532,257a2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.493,3.493,0,0,0,2.282.7,4.941,4.941,0,0,0,1.708-.308,5,5,0,0,0,1.483-.84,4.1,4.1,0,0,0,.994-1.078,2.127,2.127,0,0,0,.351-1.106v-2.324C794.79,255.973,794.649,255.823,794.37,255.823Z"/>
        <path class="cls-3" d="M806.466,250.279c-.151,0-.225.084-.225.252v8.876a2.685,2.685,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.933a.272.272,0,0,1,.307.308l-.028.952c0,.186-.111.308-.336.364a16.271,16.271,0,0,1-2.632.14,3.489,3.489,0,0,1-2.576-.826,3.621,3.621,0,0,1-.811-2.619v-9.351c0-.2-.094-.308-.281-.308h-2.24c-.205,0-.307-.093-.307-.28v-1.176a.247.247,0,0,1,.28-.28h2.38c.13,0,.2-.074.2-.225l.309-4.451q0-.364.307-.364h1.4c.223,0,.336.131.336.392v4.423a.2.2,0,0,0,.223.225h3.528c.206,0,.308.084.308.252V250c0,.187-.1.28-.308.28Z"/>
        <path class="cls-3" d="M817.245,245.351a.6.6,0,0,1-.112.406.5.5,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.449.392-.449h1.765a.382.382,0,0,1,.279.113.414.414,0,0,1,.112.307Zm-.112,17.556a.491.491,0,0,1-.112.378.6.6,0,0,1-.392.1H815.2a.492.492,0,0,1-.336-.084.438.438,0,0,1-.084-.308V248.935c0-.261.113-.392.336-.392h1.708q.307,0,.308.364Z"/>
        <path class="cls-3" d="M830.167,250.433a5.171,5.171,0,0,0-2.366-.518,4.44,4.44,0,0,0-2.52.658,1.994,1.994,0,0,0-.98,1.722,1.649,1.649,0,0,0,.49,1.162,4.137,4.137,0,0,0,1.778.91l3.556,1.064a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.588,3.588,0,0,1-.784,2.282,5.061,5.061,0,0,1-2.156,1.54,9.076,9.076,0,0,1-6.944-.308,5.187,5.187,0,0,1-2.38-2.226.48.48,0,0,1-.084-.225.253.253,0,0,1,.14-.223l1.092-.616c.186-.112.327-.1.42.028a5.132,5.132,0,0,0,4.676,2.408,5.333,5.333,0,0,0,2.73-.686,1.965,1.965,0,0,0,1.078-1.722,1.647,1.647,0,0,0-.644-1.317,6.024,6.024,0,0,0-2.1-.951l-2.884-.812A6,6,0,0,1,823,254.982a3.29,3.29,0,0,1-.882-2.323,3.876,3.876,0,0,1,.714-2.3,4.547,4.547,0,0,1,2.016-1.554,8.308,8.308,0,0,1,6.3.154,5.359,5.359,0,0,1,2.254,1.9q.2.281-.028.392l-1.176.643a.428.428,0,0,1-.168.057.384.384,0,0,1-.252-.141A4.177,4.177,0,0,0,830.167,250.433Z"/>
        <path class="cls-3" d="M846.393,248.823v1.2c0,.168-.094.253-.28.253h-3.192c-.15,0-.224.084-.224.252v12.46q0,.392-.336.392h-1.708c-.206,0-.308-.121-.308-.364V250.5c0-.149-.066-.224-.2-.224h-2.743c-.187,0-.28-.085-.28-.253v-1.2c0-.186.1-.28.308-.28h2.66c.168,0,.252-.094.252-.28v-2.044q0-3.612,3.5-3.613h2.324c.242,0,.364.113.364.336v1.093c0,.187-.1.279-.308.279h-1.96q-1.625,0-1.624,2.017v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,846.393,248.823Z"/>
        <path class="cls-3" d="M854.261,263.663a4.981,4.981,0,0,1-3.43-1.106,3.754,3.754,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.89-3.528,10.964,10.964,0,0,1,5.473-1.512l2.185-.168c.317-.038.475-.177.475-.42v-.756a3.129,3.129,0,0,0-.937-2.394,3.548,3.548,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.033,2.241.462.462,0,0,1-.167.182.272.272,0,0,1-.224-.014l-1.513-.5a.2.2,0,0,1-.139-.2.655.655,0,0,1,.084-.252,5.832,5.832,0,0,1,2.394-2.309,7.732,7.732,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.443.443,0,0,1-.309.084h-1.4c-.225,0-.356-.15-.393-.448l-.055-1.289c-.019-.129-.066-.195-.14-.195a.39.39,0,0,0-.253.14A6.974,6.974,0,0,1,854.261,263.663Zm4.927-7.84-1.82.168A9.088,9.088,0,0,0,853.351,257a2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.488,3.488,0,0,0,2.281.7,4.929,4.929,0,0,0,1.708-.308,5.009,5.009,0,0,0,1.485-.84,4.146,4.146,0,0,0,.994-1.078,2.132,2.132,0,0,0,.349-1.106v-2.324C859.608,255.973,859.469,255.823,859.188,255.823Z"/>
        <path class="cls-3" d="M877.01,249.215a7.065,7.065,0,0,1,2.31,2.519l.056.225c0,.112-.065.178-.195.2l-1.54.364-.084.028c-.094,0-.188-.074-.281-.224a7.622,7.622,0,0,0-1.721-1.764,3.3,3.3,0,0,0-1.891-.56,4.215,4.215,0,0,0-3.472,1.609,7.992,7.992,0,0,0,.014,8.723,4.3,4.3,0,0,0,3.515,1.6,4.146,4.146,0,0,0,2.309-.644,6.012,6.012,0,0,0,1.806-1.988c.057-.094.112-.145.168-.154a.33.33,0,0,1,.2.042l1.2.448c.13.056.168.178.113.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.613,1.022,6.889,6.889,0,0,1-3.64-.966,6.636,6.636,0,0,1-2.491-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.629,6.629,0,0,1,2.5-2.758,6.842,6.842,0,0,1,3.626-.98A6.181,6.181,0,0,1,877.01,249.215Z"/>
        <path class="cls-3" d="M887.86,250.279c-.149,0-.223.084-.223.252v8.876a2.673,2.673,0,0,0,.42,1.736,1.745,1.745,0,0,0,1.372.476h1.931a.273.273,0,0,1,.308.308l-.027.952c0,.186-.113.308-.336.364a16.3,16.3,0,0,1-2.632.14,3.486,3.486,0,0,1-2.576-.826,3.617,3.617,0,0,1-.813-2.619v-9.351c0-.2-.094-.308-.279-.308h-2.24c-.206,0-.309-.093-.309-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.131,0,.2-.074.2-.225l.307-4.451q0-.364.309-.364h1.4c.225,0,.336.131.336.392v4.423a.2.2,0,0,0,.225.225h3.527q.309,0,.309.252V250c0,.187-.1.28-.309.28Z"/>
        <path class="cls-3" d="M898.641,245.351a.6.6,0,0,1-.113.406.507.507,0,0,1-.391.126h-1.624c-.281,0-.421-.158-.421-.476v-2.352c0-.3.131-.449.392-.449h1.764a.384.384,0,0,1,.28.113.415.415,0,0,1,.113.307Zm-.113,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1H896.6a.49.49,0,0,1-.336-.084.428.428,0,0,1-.085-.308V248.935q0-.392.337-.392h1.708q.308,0,.307.364Z"/>
        <path class="cls-3" d="M906.774,262.7a6.519,6.519,0,0,1-2.492-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.84,8.84,0,0,1,.881,4,8.476,8.476,0,0,1-.9,3.948,6.724,6.724,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.072,7.072,0,0,1,906.774,262.7Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.07,4.07,0,0,0-3.4-1.609,4.137,4.137,0,0,0-3.444,1.609,8.13,8.13,0,0,0,0,8.695,4.179,4.179,0,0,0,3.472,1.6A4.045,4.045,0,0,0,913.942,260.316Z"/>
        <path class="cls-3" d="M922.439,263.019V248.907q0-.365.309-.364h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.255,15.255,0,0,1,2.674-1.764,5.64,5.64,0,0,1,2.478-.532,4.583,4.583,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.189,3.08v10.444c0,.3-.139.448-.419.448H932.66a.321.321,0,0,1-.364-.364v-10a3.02,3.02,0,0,0-.714-2.155,2.894,2.894,0,0,0-2.2-.757,4.615,4.615,0,0,0-2.17.519,13.608,13.608,0,0,0-2.422,1.75v10.584a.358.358,0,0,1-.42.42h-1.54Q922.439,263.383,922.439,263.019Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M950.119,262.7a6.519,6.519,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.827,8.827,0,0,1,.882,4,8.5,8.5,0,0,1-.895,3.948,6.731,6.731,0,0,1-2.506,2.716,6.937,6.937,0,0,1-3.683.98A7.072,7.072,0,0,1,950.119,262.7Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.069,4.069,0,0,0-3.4-1.609,4.135,4.135,0,0,0-3.444,1.609,8.125,8.125,0,0,0,0,8.695,4.179,4.179,0,0,0,3.472,1.6A4.045,4.045,0,0,0,957.287,260.316Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M973.205,248.823v1.2c0,.168-.094.253-.28.253h-3.193c-.149,0-.223.084-.223.252v12.46c0,.261-.113.392-.336.392h-1.708c-.206,0-.308-.121-.308-.364V250.5c0-.149-.066-.224-.2-.224h-2.744c-.187,0-.28-.085-.28-.253v-1.2c0-.186.1-.28.308-.28h2.66c.168,0,.252-.094.252-.28v-2.044q0-3.612,3.5-3.613h2.323c.244,0,.365.113.365.336v1.093c0,.187-.1.279-.308.279h-1.96q-1.624,0-1.624,2.017v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,973.205,248.823Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M716.124,283.609c-.149,0-.224.084-.224.252v8.876a2.679,2.679,0,0,0,.42,1.736,1.748,1.748,0,0,0,1.372.476h1.932a.273.273,0,0,1,.308.308l-.028.952q0,.281-.336.364a16.281,16.281,0,0,1-2.631.14,3.485,3.485,0,0,1-2.577-.826,3.616,3.616,0,0,1-.812-2.618v-9.352c0-.2-.094-.308-.279-.308h-2.241c-.206,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.131,0,.2-.074.2-.225l.308-4.451c0-.243.1-.364.308-.364h1.4q.336,0,.336.392v4.423a.2.2,0,0,0,.224.225h3.528c.206,0,.308.084.308.252v1.2c0,.187-.1.28-.308.28Z"/>
        <path class="cls-3" d="M724.468,296.349V276.3c0-.242.1-.364.308-.364h1.652q.308,0,.308.335v7.477q0,.308.252.084A14.383,14.383,0,0,1,729.7,282.1a5.93,5.93,0,0,1,2.464-.5,4.524,4.524,0,0,1,3.318,1.148,4.171,4.171,0,0,1,1.161,3.108v10.416c0,.3-.139.448-.419.448H734.66a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.894,2.894,0,0,0-2.2-.757,4.453,4.453,0,0,0-2.142.519,15.088,15.088,0,0,0-2.422,1.75v10.584c0,.28-.15.42-.448.42h-1.54A.321.321,0,0,1,724.468,296.349Z"/>
        <path class="cls-3" d="M744.418,292.709a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.982,6.982,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.989,5.989,0,0,1,2.365,2.617,9.194,9.194,0,0,1,.841,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H744.012q-.252,0-.252.42A4.924,4.924,0,0,0,744.418,292.709Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.513-1.68,4.21,4.21,0,0,0-2.309-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.15,5.15,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M774.574,283.763a5.169,5.169,0,0,0-2.366-.518,4.438,4.438,0,0,0-2.52.658,1.994,1.994,0,0,0-.98,1.722,1.649,1.649,0,0,0,.49,1.162,4.126,4.126,0,0,0,1.778.91l3.556,1.064a5.576,5.576,0,0,1,2.743,1.5,3.414,3.414,0,0,1,.9,2.366,3.588,3.588,0,0,1-.784,2.282,5.058,5.058,0,0,1-2.157,1.54,9.074,9.074,0,0,1-6.943-.308,5.182,5.182,0,0,1-2.38-2.226.48.48,0,0,1-.084-.225.252.252,0,0,1,.14-.223l1.092-.616q.279-.168.42.028a5.13,5.13,0,0,0,4.675,2.408,5.337,5.337,0,0,0,2.731-.686,1.963,1.963,0,0,0,1.078-1.722,1.642,1.642,0,0,0-.645-1.316,5.98,5.98,0,0,0-2.1-.952l-2.884-.812a6,6,0,0,1-2.926-1.484,3.29,3.29,0,0,1-.883-2.324,3.882,3.882,0,0,1,.714-2.3,4.563,4.563,0,0,1,2.017-1.554,8.308,8.308,0,0,1,6.3.154,5.366,5.366,0,0,1,2.254,1.9c.13.187.121.318-.029.392l-1.175.643a.412.412,0,0,1-.168.057.384.384,0,0,1-.252-.141A4.184,4.184,0,0,0,774.574,283.763Z"/>
        <path class="cls-3" d="M790.31,294.515a10.435,10.435,0,0,0,2.253-1.806V282.265q0-.391.449-.392h1.54a.346.346,0,0,1,.391.392l-.055,14.084q0,.364-.308.364h-1.624a.273.273,0,0,1-.309-.308v-1.652q0-.336-.251-.084a11.557,11.557,0,0,1-2.688,1.806,5.925,5.925,0,0,1-2.408.518,4.528,4.528,0,0,1-3.22-1.177,4.045,4.045,0,0,1-1.261-3.107V282.293a.372.372,0,0,1,.42-.42h1.569c.242,0,.364.131.364.392v9.828a3.135,3.135,0,0,0,.769,2.226,2.749,2.749,0,0,0,2.115.826A4.508,4.508,0,0,0,790.31,294.515Z"/>
        <path class="cls-3" d="M800.627,296.349V282.237c0-.243.1-.364.309-.364h1.624a.272.272,0,0,1,.307.308V284c0,.112.032.178.1.2s.135-.018.21-.112q2.127-2.492,4.34-2.492.98,0,.979.364v1.68c0,.186-.084.261-.252.224a7.865,7.865,0,0,0-1.26-.084,3.975,3.975,0,0,0-1.763.476,5,5,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.539v9.269a.371.371,0,0,1-.419.42h-1.54Q800.627,296.713,800.627,296.349Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M812.752,296.349V282.237c0-.243.1-.364.308-.364h1.624a.273.273,0,0,1,.308.308V284c0,.112.032.178.1.2s.136-.018.21-.112q2.129-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.261-.252.224a7.865,7.865,0,0,0-1.26-.084,3.982,3.982,0,0,0-1.764.476,4.983,4.983,0,0,0-1.6,1.232,2.367,2.367,0,0,0-.644,1.539v9.269a.371.371,0,0,1-.42.42h-1.54C812.882,296.713,812.752,296.592,812.752,296.349Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M827.018,296.027a6.522,6.522,0,0,1-2.493-2.7,9.342,9.342,0,0,1,.03-7.98,7.1,7.1,0,0,1,9.883-2.758,6.668,6.668,0,0,1,2.492,2.758,8.83,8.83,0,0,1,.883,4,8.476,8.476,0,0,1-.9,3.948,6.731,6.731,0,0,1-2.506,2.716,6.935,6.935,0,0,1-3.681.98A7.073,7.073,0,0,1,827.018,296.027Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.07,4.07,0,0,0-3.4-1.609,4.133,4.133,0,0,0-3.443,1.609,8.125,8.125,0,0,0,0,8.695,4.176,4.176,0,0,0,3.471,1.6A4.046,4.046,0,0,0,834.186,293.646Z"/>
        <path class="cls-3" d="M849.979,294.515a10.459,10.459,0,0,0,2.253-1.806V282.265q0-.391.448-.392h1.54a.346.346,0,0,1,.391.392l-.055,14.084q0,.364-.308.364h-1.624a.272.272,0,0,1-.308-.308v-1.652q0-.336-.252-.084a11.537,11.537,0,0,1-2.688,1.806,5.925,5.925,0,0,1-2.408.518,4.528,4.528,0,0,1-3.22-1.177,4.047,4.047,0,0,1-1.26-3.107V282.293a.371.371,0,0,1,.42-.42h1.569q.363,0,.363.392v9.828a3.135,3.135,0,0,0,.769,2.226,2.751,2.751,0,0,0,2.115.826A4.514,4.514,0,0,0,849.979,294.515Z"/>
        <path class="cls-3" d="M860.3,296.349V282.237q0-.364.309-.364h1.679a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084a15.218,15.218,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.581,4.581,0,0,1,3.317,1.148,4.08,4.08,0,0,1,1.191,3.08v10.444c0,.3-.141.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.894,2.894,0,0,0-2.2-.757,4.613,4.613,0,0,0-2.17.519,13.609,13.609,0,0,0-2.423,1.75v10.584a.357.357,0,0,1-.419.42h-1.54Q860.3,296.713,860.3,296.349Z"/>
        <path class="cls-3" d="M888.211,296.321v-1.2c0-.168-.047-.252-.139-.252a.384.384,0,0,0-.224.14,8.928,8.928,0,0,1-1.834,1.414,5.092,5.092,0,0,1-2.563.574,6.08,6.08,0,0,1-3.191-.84,5.753,5.753,0,0,1-2.213-2.534,9.435,9.435,0,0,1-.811-4.1,9.661,9.661,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.954,5.954,0,0,1,3.388-.994,5.581,5.581,0,0,1,2.185.392,6.576,6.576,0,0,1,1.735,1.092.724.724,0,0,0,.309.168c.093,0,.14-.121.14-.364V276.3c0-.242.1-.364.307-.364h1.709c.2,0,.307.122.307.364v19.964a.555.555,0,0,1-.083.35.484.484,0,0,1-.365.1H888.6A.347.347,0,0,1,888.211,296.321Zm-1.889-12.53a4.287,4.287,0,0,0-1.974-.462,4.169,4.169,0,0,0-3.416,1.6,7,7,0,0,0-1.288,4.508,6.519,6.519,0,0,0,1.274,4.4,4.137,4.137,0,0,0,3.262,1.456,3.983,3.983,0,0,0,2-.518,4.11,4.11,0,0,0,1.427-1.3,2.822,2.822,0,0,0,.518-1.569v-6.748A7.255,7.255,0,0,0,886.322,283.791Z"/>
        <path class="cls-3" d="M898.684,278.681a.606.606,0,0,1-.112.406.508.508,0,0,1-.392.126h-1.623c-.282,0-.421-.158-.421-.476v-2.352c0-.3.13-.448.391-.448h1.765a.383.383,0,0,1,.28.112.42.42,0,0,1,.112.307Zm-.112,17.556a.5.5,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.427a.483.483,0,0,1-.336-.084.428.428,0,0,1-.085-.308V282.265q0-.391.337-.392h1.707q.308,0,.308.364Z"/>
        <path class="cls-3" d="M904.312,296.349V282.237q0-.364.307-.364H906.3a.272.272,0,0,1,.307.308v1.624c0,.206.084.233.252.084a15.292,15.292,0,0,1,2.674-1.764,5.661,5.661,0,0,1,2.479-.532,4.585,4.585,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08v10.444c0,.3-.14.448-.42.448h-1.569a.321.321,0,0,1-.363-.364v-10a3.017,3.017,0,0,0-.715-2.156,2.888,2.888,0,0,0-2.2-.757,4.615,4.615,0,0,0-2.17.519,13.572,13.572,0,0,0-2.422,1.75v10.584a.358.358,0,0,1-.42.42H904.7C904.441,296.713,904.312,296.592,904.312,296.349Z"/>
        <path class="cls-3" d="M936.008,282.069v.98a.558.558,0,0,1-.126.406.537.537,0,0,1-.435.1,3.454,3.454,0,0,0-1.455.252q-.672.281-.56.644a2.925,2.925,0,0,0,.168.42,3.494,3.494,0,0,1,.224.686,3.67,3.67,0,0,1,.084.8,3.991,3.991,0,0,1-1.554,3.248,7.042,7.042,0,0,1-4.522,1.26,8.652,8.652,0,0,0-2.856.336c-.58.224-.869.514-.869.867,0,.281.2.491.588.631a7.9,7.9,0,0,0,1.961.294l3.612.2a5.957,5.957,0,0,1,3.584,1.148,3.425,3.425,0,0,1,1.175,2.744,3.771,3.771,0,0,1-1.861,3.332,9.782,9.782,0,0,1-5.307,1.2,11.5,11.5,0,0,1-5.418-1.022,3.2,3.2,0,0,1-1.861-2.926q0-1.877,2.632-3.164c.205-.094.205-.2,0-.308a1.793,1.793,0,0,1-1.288-1.652,1.643,1.643,0,0,1,.574-1.176,4.329,4.329,0,0,1,1.638-.925c.093-.017.139-.064.139-.139a.2.2,0,0,0-.139-.2,4.654,4.654,0,0,1-1.82-1.553,3.963,3.963,0,0,1-.588-2.2,4.094,4.094,0,0,1,1.652-3.444,7.569,7.569,0,0,1,4.676-1.261,6.443,6.443,0,0,1,4.2,1.261.378.378,0,0,0,.588,0,5.15,5.15,0,0,1,1.246-.91,2.953,2.953,0,0,1,1.386-.378C935.83,281.621,936.008,281.771,936.008,282.069Zm-10.641,12.824a2.333,2.333,0,0,0-1.147.294,3.086,3.086,0,0,0-1.092.91,2.076,2.076,0,0,0-.448,1.288,2.233,2.233,0,0,0,1.372,2.058,9.061,9.061,0,0,0,4.059.714q4.732,0,4.733-2.8a1.92,1.92,0,0,0-.658-1.54,3.624,3.624,0,0,0-2.142-.643Zm5.362-6.3a2.752,2.752,0,0,0,.966-2.268,2.93,2.93,0,0,0-.979-2.352,4.321,4.321,0,0,0-2.884-.84,4.417,4.417,0,0,0-2.885.854,2.87,2.87,0,0,0-1.035,2.338,2.692,2.692,0,0,0,1.022,2.254,4.614,4.614,0,0,0,2.9.8A4.531,4.531,0,0,0,930.729,288.593Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M801.748,315.329a.268.268,0,0,1,.027.266l-4.283,14.084a.5.5,0,0,1-.532.364h-1.345a.539.539,0,0,1-.588-.364L791.7,319.207c-.054-.168-.121-.252-.2-.252q-.056,0-.141.252L788.2,329.679a.5.5,0,0,1-.531.364h-1.428a.537.537,0,0,1-.588-.364l-4.4-14.056a.294.294,0,0,1-.027-.14c0-.186.111-.28.336-.28h1.651a.468.468,0,0,1,.448.308l3.332,10.892c.056.187.121.28.2.28.056,0,.13-.1.224-.308l3.3-10.892a.368.368,0,0,1,.391-.28h1.317a.391.391,0,0,1,.42.308l3.444,10.892c.056.187.121.28.2.28s.14-.1.2-.308l3.22-10.864a.486.486,0,0,1,.476-.308h1.12A.285.285,0,0,1,801.748,315.329Z"/>
        <path class="cls-3" d="M808.705,329.357a6.52,6.52,0,0,1-2.492-2.7,9.337,9.337,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.668,6.668,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.883,4,8.488,8.488,0,0,1-.9,3.948,6.731,6.731,0,0,1-2.506,2.716,6.938,6.938,0,0,1-3.682.98A7.075,7.075,0,0,1,808.705,329.357Zm7.168-2.38a8.307,8.307,0,0,0-.027-8.708,4.069,4.069,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.443,1.61,8.123,8.123,0,0,0,0,8.694,4.178,4.178,0,0,0,3.472,1.6A4.045,4.045,0,0,0,815.873,326.977Z"/>
        <path class="cls-3" d="M824.371,329.679V315.567c0-.243.1-.364.309-.364H826.3a.272.272,0,0,1,.307.308v1.82c0,.112.034.178.1.2s.136-.018.21-.112q2.127-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.224a7.86,7.86,0,0,0-1.259-.084,3.979,3.979,0,0,0-1.765.476,5.012,5.012,0,0,0-1.6,1.232,2.37,2.37,0,0,0-.643,1.539v9.269a.371.371,0,0,1-.42.42h-1.54Q824.371,330.043,824.371,329.679Z"/>
        <path class="cls-3" d="M838.875,329.567a.5.5,0,0,1-.111.378.6.6,0,0,1-.393.1h-1.455a.485.485,0,0,1-.336-.084.428.428,0,0,1-.085-.308l.028-19.992c0-.262.112-.392.336-.392h1.68c.225,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M854.752,329.651v-1.2q0-.252-.141-.252a.383.383,0,0,0-.223.14,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.078,6.078,0,0,1-3.192-.84,5.745,5.745,0,0,1-2.212-2.534,9.42,9.42,0,0,1-.813-4.1,9.676,9.676,0,0,1,.84-4.144,6.681,6.681,0,0,1,2.324-2.786,5.957,5.957,0,0,1,3.389-.994,5.573,5.573,0,0,1,2.184.392,6.613,6.613,0,0,1,1.736,1.092.706.706,0,0,0,.308.168c.092,0,.139-.121.139-.364v-6.58c0-.242.1-.364.309-.364h1.707c.206,0,.309.122.309.364V329.6a.548.548,0,0,1-.084.35.483.483,0,0,1-.364.1h-1.428A.346.346,0,0,1,854.752,329.651Zm-1.891-12.53a4.293,4.293,0,0,0-1.973-.462,4.169,4.169,0,0,0-3.416,1.6,7.007,7.007,0,0,0-1.288,4.508,6.519,6.519,0,0,0,1.273,4.4,4.142,4.142,0,0,0,3.263,1.456,3.983,3.983,0,0,0,2-.518,4.1,4.1,0,0,0,1.427-1.3,2.831,2.831,0,0,0,.519-1.568v-6.748A7.226,7.226,0,0,0,852.861,317.121Z"/>
        <path class="cls-3" d="M864.943,329.6a.451.451,0,0,1-.111.35.6.6,0,0,1-.393.1h-1.96a.49.49,0,0,1-.321-.084.389.389,0,0,1-.1-.308v-2.408c0-.242.1-.364.307-.364h2.3q.279,0,.279.336Z"/>
      </g>
    </g>
  </g>
</g>
<g id="innowacyjnosc" class="our-values__text">
  <g>
    <path class="cls-2" d="M770.157,408.506c0,116.162,94.507,210.67,210.67,210.67s210.664-94.508,210.664-210.67-94.5-210.67-210.664-210.67S770.157,292.344,770.157,408.506Z"/>
    <g class="cls-4">
      <g class="cls-4">
        <path class="cls-3" d="M880.108,337.078a.428.428,0,0,1,.042.363l-5.768,19.853a.531.531,0,0,1-.2.322.73.73,0,0,1-.42.1h-1.708q-.533,0-.644-.392l-4.48-15.876c-.056-.206-.121-.2-.2.028l-4.536,15.82a.531.531,0,0,1-.2.322.732.732,0,0,1-.42.1H859.85q-.533,0-.644-.392l-5.684-19.881a.3.3,0,0,1-.028-.139c0-.242.14-.364.42-.364h1.792a.432.432,0,0,1,.476.335l4.564,16.3c.056.2.112.308.168.308.074,0,.13-.093.168-.28l4.647-16.352a.479.479,0,0,1,.5-.308h1.6a.652.652,0,0,1,.336.07.45.45,0,0,1,.168.265l4.592,16.381q.084.252.168.252t.168-.252l4.62-16.408q.056-.308.476-.308h1.4A.4.4,0,0,1,880.108,337.078Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M885.288,353.71a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.981,4.981,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,6,6,0,0,1,2.366,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H884.882q-.252,0-.252.42A4.934,4.934,0,0,0,885.288,353.71Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.137,5.137,0,0,0-.532,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M922.094,343.07v.98a.558.558,0,0,1-.126.406.534.534,0,0,1-.434.1,3.459,3.459,0,0,0-1.456.252q-.672.281-.56.644a2.925,2.925,0,0,0,.168.42,3.494,3.494,0,0,1,.224.686,3.67,3.67,0,0,1,.084.8,3.989,3.989,0,0,1-1.555,3.248,7.039,7.039,0,0,1-4.521,1.26,8.652,8.652,0,0,0-2.856.336c-.579.224-.868.514-.868.867q0,.421.588.631a7.885,7.885,0,0,0,1.96.294l3.612.2a5.957,5.957,0,0,1,3.584,1.148,3.426,3.426,0,0,1,1.176,2.744,3.769,3.769,0,0,1-1.862,3.332,9.778,9.778,0,0,1-5.306,1.2,11.5,11.5,0,0,1-5.418-1.022,3.194,3.194,0,0,1-1.862-2.926q0-1.876,2.632-3.164c.205-.094.205-.2,0-.308a1.791,1.791,0,0,1-1.288-1.652,1.643,1.643,0,0,1,.574-1.176,4.337,4.337,0,0,1,1.638-.925c.093-.017.14-.064.14-.139a.2.2,0,0,0-.14-.2,4.661,4.661,0,0,1-1.82-1.553,3.964,3.964,0,0,1-.589-2.2,4.1,4.1,0,0,1,1.653-3.444,7.573,7.573,0,0,1,4.676-1.261,6.443,6.443,0,0,1,4.2,1.261.378.378,0,0,0,.588,0,5.169,5.169,0,0,1,1.246-.91,2.956,2.956,0,0,1,1.386-.378C921.916,342.622,922.094,342.771,922.094,343.07Zm-10.64,12.824a2.339,2.339,0,0,0-1.148.294,3.094,3.094,0,0,0-1.092.91,2.076,2.076,0,0,0-.448,1.288,2.233,2.233,0,0,0,1.372,2.058,9.063,9.063,0,0,0,4.06.714q4.732,0,4.732-2.8a1.917,1.917,0,0,0-.659-1.54,3.611,3.611,0,0,0-2.141-.643Zm5.361-6.3a2.75,2.75,0,0,0,.967-2.268,2.93,2.93,0,0,0-.98-2.352,4.323,4.323,0,0,0-2.884-.84,4.412,4.412,0,0,0-2.884.854A2.868,2.868,0,0,0,910,347.326a2.692,2.692,0,0,0,1.022,2.254,4.614,4.614,0,0,0,2.9.8A4.529,4.529,0,0,0,916.815,349.594Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M928.688,357.028a6.519,6.519,0,0,1-2.492-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.677,6.677,0,0,1,2.493,2.758,8.84,8.84,0,0,1,.881,4,8.476,8.476,0,0,1-.9,3.948,6.724,6.724,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.072,7.072,0,0,1,928.688,357.028Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.07,4.07,0,0,0-3.4-1.609,4.137,4.137,0,0,0-3.444,1.609,8.13,8.13,0,0,0,0,8.695,4.179,4.179,0,0,0,3.472,1.595A4.045,4.045,0,0,0,935.856,354.647Z"/>
        <path class="cls-3" d="M952.222,357.714a.372.372,0,0,1-.449-.448V337.33c0-.262.113-.392.337-.392h1.736c.186,0,.28.13.28.392v6.859c0,.207.046.309.14.309a.618.618,0,0,0,.28-.141,6.315,6.315,0,0,1,4.48-1.763,5.161,5.161,0,0,1,4.466,2.114,9.184,9.184,0,0,1,1.526,5.5,8.786,8.786,0,0,1-1.694,5.7,5.709,5.709,0,0,1-4.69,2.086,5.337,5.337,0,0,1-2.828-.686,5.987,5.987,0,0,1-1.736-1.526.225.225,0,0,0-.168-.112c-.075,0-.122.065-.14.2l-.14,1.4a.73.73,0,0,1-.154.35.488.488,0,0,1-.35.1Zm1.9-5.012a3.1,3.1,0,0,0,.574,1.806,4.167,4.167,0,0,0,1.5,1.316,4.073,4.073,0,0,0,1.932.489,3.944,3.944,0,0,0,3.234-1.511,7.13,7.13,0,0,0,1.218-4.536,7.607,7.607,0,0,0-1.092-4.411,3.629,3.629,0,0,0-3.164-1.553,4.986,4.986,0,0,0-2.338.56,5.927,5.927,0,0,0-1.862,1.512Z"/>
        <path class="cls-3" d="M972.172,353.71a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.223.056.391a6.823,6.823,0,0,1-2.6,2.451,7.879,7.879,0,0,1-3.613.77,7.116,7.116,0,0,1-3.668-.952,6.606,6.606,0,0,1-2.533-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.982,6.982,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.989,5.989,0,0,1,2.365,2.617,9.194,9.194,0,0,1,.841,4.075v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H971.766q-.252,0-.252.42A4.924,4.924,0,0,0,972.172,353.71Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.108,4.108,0,0,0-1.513-1.68,4.21,4.21,0,0,0-2.309-.631,4.366,4.366,0,0,0-2.492.715,4.7,4.7,0,0,0-1.624,1.848,5.15,5.15,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M987.082,362.278c-.243-.038-.364-.14-.364-.308v-1.092a.293.293,0,0,1,.154-.28,1.531,1.531,0,0,1,.63-.084h1.764a1.826,1.826,0,0,0,1.232-.491,3.689,3.689,0,0,0,1.2-2.617,1.7,1.7,0,0,0-.112-.673l-5.348-13.383a.383.383,0,0,1-.028-.168q0-.308.336-.308h1.736a.465.465,0,0,1,.447.308l4.173,10.864c.056.168.13.252.224.252q.168,0,.28-.336l4.115-10.78a.437.437,0,0,1,.393-.308h1.315a.28.28,0,0,1,.252.126.27.27,0,0,1,.029.266l-6.16,15.764a5.211,5.211,0,0,1-1.876,2.6,5.727,5.727,0,0,1-3.052.7A10.573,10.573,0,0,1,987.082,362.278Z"/>
        <path class="cls-3" d="M1006.36,357.028a6.519,6.519,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.668,6.668,0,0,1,2.492,2.758,8.827,8.827,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.724,6.724,0,0,1-2.506,2.716,6.933,6.933,0,0,1-3.682.98A7.072,7.072,0,0,1,1006.36,357.028Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.07,4.07,0,0,0-3.4-1.609,4.137,4.137,0,0,0-3.444,1.609,8.13,8.13,0,0,0,0,8.695,4.179,4.179,0,0,0,3.472,1.595A4.045,4.045,0,0,0,1013.528,354.647Z"/>
        <path class="cls-3" d="M1022.025,357.35V343.238q0-.364.309-.364h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.255,15.255,0,0,1,2.674-1.764,5.64,5.64,0,0,1,2.478-.532,4.583,4.583,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.189,3.08v10.444c0,.3-.139.448-.42.448h-1.567a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.894,2.894,0,0,0-2.2-.757,4.615,4.615,0,0,0-2.17.519,13.608,13.608,0,0,0-2.422,1.75v10.584a.358.358,0,0,1-.42.42h-1.54Q1022.025,357.714,1022.025,357.35Z"/>
        <path class="cls-3" d="M1049.941,357.322v-1.2c0-.168-.046-.252-.139-.252a.384.384,0,0,0-.224.14,8.9,8.9,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.081,6.081,0,0,1-3.192-.84,5.759,5.759,0,0,1-2.213-2.534,9.435,9.435,0,0,1-.811-4.1,9.661,9.661,0,0,1,.84-4.144,6.674,6.674,0,0,1,2.324-2.786,5.95,5.95,0,0,1,3.388-.994,5.576,5.576,0,0,1,2.184.392,6.613,6.613,0,0,1,1.736,1.092.717.717,0,0,0,.308.168c.093,0,.14-.121.14-.364V337.3c0-.242.1-.364.307-.364h1.708c.206,0,.309.122.309.364v19.964a.548.548,0,0,1-.084.35.483.483,0,0,1-.364.1h-1.428A.347.347,0,0,1,1049.941,357.322Zm-1.889-12.53a4.287,4.287,0,0,0-1.974-.462,4.17,4.17,0,0,0-3.416,1.6,7.007,7.007,0,0,0-1.288,4.508,6.524,6.524,0,0,0,1.273,4.4,4.14,4.14,0,0,0,3.263,1.456,3.981,3.981,0,0,0,2-.518,4.114,4.114,0,0,0,1.428-1.3,2.829,2.829,0,0,0,.517-1.569v-6.748A7.237,7.237,0,0,0,1048.052,344.792Z"/>
        <path class="cls-3" d="M1069.29,344.61q-.225,0-.225.252v8.876a2.685,2.685,0,0,0,.42,1.736,1.749,1.749,0,0,0,1.372.476h1.933a.273.273,0,0,1,.308.308l-.029.952c0,.186-.111.308-.336.364a16.264,16.264,0,0,1-2.631.14,3.49,3.49,0,0,1-2.577-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.2-.094-.308-.28-.308h-2.241c-.2,0-.307-.093-.307-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.13,0,.195-.074.195-.225l.309-4.451q0-.365.307-.364h1.4c.223,0,.336.131.336.392v4.423a.2.2,0,0,0,.223.225h3.529c.2,0,.307.084.307.252v1.2c0,.187-.1.28-.307.28Z"/>
        <path class="cls-3" d="M1077.634,357.35V337.3c0-.242.1-.364.307-.364h1.653q.307,0,.307.335v7.477q0,.308.252.084a14.446,14.446,0,0,1,2.716-1.736,5.94,5.94,0,0,1,2.464-.5,4.521,4.521,0,0,1,3.318,1.148,4.172,4.172,0,0,1,1.162,3.108v10.416c0,.3-.139.448-.419.448h-1.569a.321.321,0,0,1-.363-.364v-10a3.017,3.017,0,0,0-.715-2.156,2.89,2.89,0,0,0-2.2-.757,4.46,4.46,0,0,0-2.143.519,15.177,15.177,0,0,0-2.422,1.75v10.584q0,.42-.448.42H1078A.321.321,0,0,1,1077.634,357.35Z"/>
        <path class="cls-3" d="M1097.583,353.71a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.411.411,0,0,1,.2-.126.328.328,0,0,1,.195.042l1.177.588c.148.094.168.223.055.391a6.814,6.814,0,0,1-2.6,2.451,7.884,7.884,0,0,1-3.612.77,7.108,7.108,0,0,1-3.668-.952,6.61,6.61,0,0,1-2.535-2.674,8.3,8.3,0,0,1-.91-3.934,8.92,8.92,0,0,1,.924-4.159,6.656,6.656,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.985,5.985,0,0,1,2.366,2.617,9.208,9.208,0,0,1,.84,4.075v.112a.687.687,0,0,1-.084.378.377.377,0,0,1-.336.126h-10.723q-.252,0-.252.42A4.933,4.933,0,0,0,1097.583,353.71Zm7.91-4.452a.711.711,0,0,0,.393-.07.433.433,0,0,0,.084-.322,5.08,5.08,0,0,0-.519-2.282,4.106,4.106,0,0,0-1.512-1.68,4.212,4.212,0,0,0-2.309-.631,4.366,4.366,0,0,0-2.492.715,4.688,4.688,0,0,0-1.624,1.848,5.138,5.138,0,0,0-.533,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M835.436,377.939c-.151,0-.225.084-.225.252v8.876a2.685,2.685,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.933a.272.272,0,0,1,.307.308l-.028.952c0,.187-.111.308-.336.364a16.271,16.271,0,0,1-2.632.14,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618v-9.352c0-.205-.093-.308-.28-.308h-2.24c-.205,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.281-.28h2.379c.13,0,.2-.074.2-.224l.308-4.452q0-.364.308-.364h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.205,0,.308.084.308.252v1.2c0,.187-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M845.613,387.039a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.981,4.981,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.148.094.168.224.057.392a6.837,6.837,0,0,1-2.6,2.45,7.881,7.881,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.533-2.674,8.278,8.278,0,0,1-.91-3.934,8.907,8.907,0,0,1,.923-4.159,6.649,6.649,0,0,1,2.534-2.729,6.988,6.988,0,0,1,3.627-.952,6.869,6.869,0,0,1,3.541.9,5.994,5.994,0,0,1,2.366,2.618,9.215,9.215,0,0,1,.841,4.074v.112a.705.705,0,0,1-.084.378.379.379,0,0,1-.336.126H845.207q-.252,0-.252.42A4.924,4.924,0,0,0,845.613,387.039Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.08,5.08,0,0,0-.519-2.282,4.1,4.1,0,0,0-1.511-1.68,4.221,4.221,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.688,4.688,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M861.055,390.679V376.567c0-.243.1-.364.308-.364h1.68c.186,0,.279.1.279.308v1.624q0,.336.282.084a14.755,14.755,0,0,1,2.575-1.764,5.259,5.259,0,0,1,2.38-.532,4.478,4.478,0,0,1,2.589.7,3.483,3.483,0,0,1,1.387,1.764q.056.084.111.084a.611.611,0,0,0,.281-.14,13.983,13.983,0,0,1,2.618-1.82,5.355,5.355,0,0,1,2.45-.588,3.946,3.946,0,0,1,4.284,4.256V390.6q0,.448-.42.448h-1.568a.322.322,0,0,1-.364-.364v-10a3.141,3.141,0,0,0-.672-2.156,2.559,2.559,0,0,0-2.017-.756,4.244,4.244,0,0,0-2.1.546,11.08,11.08,0,0,0-1.9,1.358,1.621,1.621,0,0,0-.307.392.935.935,0,0,0-.084.42V390.6q0,.448-.42.448h-1.568a.322.322,0,0,1-.365-.364v-10q0-2.911-2.687-2.912a4.18,4.18,0,0,0-2.058.518,14.294,14.294,0,0,0-2.339,1.75v10.584a.357.357,0,0,1-.42.42h-1.539Q861.056,391.043,861.055,390.679Z"/>
        <path class="cls-3" d="M890.146,376.623V377.8q0,.252.141.252a.384.384,0,0,0,.224-.14,13.8,13.8,0,0,1,1.036-.952,4.956,4.956,0,0,1,1.315-.7,5.656,5.656,0,0,1,2.044-.336,5.96,5.96,0,0,1,3.151.84,5.706,5.706,0,0,1,2.183,2.52,9.548,9.548,0,0,1,.8,4.116,10.4,10.4,0,0,1-.8,4.256,5.869,5.869,0,0,1-5.641,3.668,5.946,5.946,0,0,1-3.976-1.4c-.131-.13-.234-.2-.309-.2-.093,0-.14.122-.14.364V395.3c0,.242-.1.364-.307.364h-1.736c-.206,0-.309-.122-.309-.364V376.651a.5.5,0,0,1,.1-.336.452.452,0,0,1,.351-.112h1.483C890.016,376.2,890.146,376.343,890.146,376.623Zm1.834,12.53a4.359,4.359,0,0,0,2,.462,4.1,4.1,0,0,0,3.374-1.6,7.214,7.214,0,0,0,1.275-4.592,6.464,6.464,0,0,0-1.26-4.354,4.385,4.385,0,0,0-5.265-.952,4.007,4.007,0,0,0-1.414,1.246,2.628,2.628,0,0,0-.518,1.484v6.916A6.922,6.922,0,0,0,891.98,389.153Z"/>
        <path class="cls-3" d="M908.318,390.567a.491.491,0,0,1-.112.378.6.6,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.438.438,0,0,1-.084-.308l.029-19.992c0-.262.111-.392.336-.392h1.679c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M917.67,391.323a4.981,4.981,0,0,1-3.43-1.106,3.754,3.754,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.973,10.973,0,0,1,5.473-1.512l2.185-.168q.476-.056.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.546,3.546,0,0,0-2.534-.882,4.74,4.74,0,0,0-4.032,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.513-.5a.2.2,0,0,1-.138-.2.628.628,0,0,1,.084-.252,5.819,5.819,0,0,1,2.393-2.31,7.735,7.735,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.426,4.426,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.434.434,0,0,1-.307.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.386.386,0,0,0-.252.14A6.976,6.976,0,0,1,917.67,391.323Zm4.928-7.84-1.821.168a9.106,9.106,0,0,0-4.017,1.008,2.589,2.589,0,0,0-1.414,2.324,2.331,2.331,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.923,4.923,0,0,0,1.707-.308,5.009,5.009,0,0,0,1.485-.84,4.079,4.079,0,0,0,.994-1.078,2.131,2.131,0,0,0,.35-1.106v-2.324C923.018,383.633,922.878,383.483,922.6,383.483Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M934.693,377.939c-.15,0-.224.084-.224.252v8.876a2.679,2.679,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.932a.273.273,0,0,1,.308.308l-.028.952q0,.281-.336.364a16.288,16.288,0,0,1-2.632.14,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618v-9.352c0-.205-.094-.308-.28-.308H929.6c-.2,0-.308-.093-.308-.28v-1.176a.248.248,0,0,1,.28-.28h2.38c.13,0,.2-.074.2-.224l.308-4.452q0-.364.308-.364h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.2,0,.308.084.308.252v1.2c0,.187-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M944.872,387.039a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.981,4.981,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H944.466q-.252,0-.252.42A4.934,4.934,0,0,0,944.872,387.039Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.224,4.224,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.7,4.7,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M967.608,378.093a5.169,5.169,0,0,0-2.366-.518,4.438,4.438,0,0,0-2.52.658,1.994,1.994,0,0,0-.98,1.722,1.649,1.649,0,0,0,.49,1.162,4.132,4.132,0,0,0,1.778.91l3.556,1.064a5.584,5.584,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.588,3.588,0,0,1-.784,2.282,5.055,5.055,0,0,1-2.156,1.54,9.076,9.076,0,0,1-6.944-.308,5.182,5.182,0,0,1-2.38-2.226.481.481,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.092-.616q.279-.168.42.028a5.129,5.129,0,0,0,4.676,2.408,5.336,5.336,0,0,0,2.73-.686,1.963,1.963,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,6,6,0,0,0-2.1-.952l-2.884-.812a6,6,0,0,1-2.926-1.484,3.294,3.294,0,0,1-.882-2.324,3.882,3.882,0,0,1,.713-2.3,4.563,4.563,0,0,1,2.017-1.554,8.308,8.308,0,0,1,6.3.154,5.366,5.366,0,0,1,2.254,1.9q.2.281-.029.392l-1.175.644a.422.422,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.178,4.178,0,0,0,967.608,378.093Z"/>
        <path class="cls-3" d="M987.109,391.323a4.978,4.978,0,0,1-3.429-1.106,3.754,3.754,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.183-.168q.477-.056.477-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.545,3.545,0,0,0-2.535-.882,4.739,4.739,0,0,0-4.031,2.24.463.463,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.734,7.734,0,0,1,3.681-.826,6.2,6.2,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.437.437,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.057-1.288c-.018-.13-.065-.2-.139-.2a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,987.109,391.323Zm4.929-7.84-1.82.168a9.1,9.1,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.941,4.941,0,0,0,1.708-.308,5,5,0,0,0,1.483-.84,4.079,4.079,0,0,0,.994-1.078,2.124,2.124,0,0,0,.351-1.106v-2.324C992.458,383.633,992.317,383.483,992.038,383.483Z"/>
        <path class="cls-3" d="M1000.213,390.679V376.567c0-.243.1-.364.308-.364h1.68a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084a15.276,15.276,0,0,1,2.674-1.764,5.649,5.649,0,0,1,2.477-.532,4.582,4.582,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08V390.6q0,.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.892,2.892,0,0,0-2.2-.756,4.615,4.615,0,0,0-2.17.518,13.644,13.644,0,0,0-2.422,1.75v10.584a.449.449,0,0,1-.1.322.443.443,0,0,1-.322.1h-1.541Q1000.214,391.043,1000.213,390.679Z"/>
        <path class="cls-3" d="M1028.129,390.651v-1.2c0-.168-.047-.252-.14-.252a.383.383,0,0,0-.223.14,8.894,8.894,0,0,1-1.834,1.414,5.092,5.092,0,0,1-2.563.574,6.077,6.077,0,0,1-3.191-.84,5.753,5.753,0,0,1-2.213-2.534,9.435,9.435,0,0,1-.812-4.1,9.676,9.676,0,0,1,.84-4.144,6.681,6.681,0,0,1,2.324-2.786,5.954,5.954,0,0,1,3.388-.994,5.581,5.581,0,0,1,2.185.392,6.592,6.592,0,0,1,1.735,1.092.707.707,0,0,0,.309.168c.092,0,.139-.121.139-.364v-6.58c0-.242.1-.364.308-.364h1.708c.206,0,.308.122.308.364V390.6a.548.548,0,0,1-.084.35.48.48,0,0,1-.364.1h-1.428A.347.347,0,0,1,1028.129,390.651Zm-1.89-12.53a4.293,4.293,0,0,0-1.973-.462,4.169,4.169,0,0,0-3.416,1.6,7,7,0,0,0-1.288,4.508,6.519,6.519,0,0,0,1.273,4.4,4.139,4.139,0,0,0,3.263,1.456,3.986,3.986,0,0,0,2-.518,4.11,4.11,0,0,0,1.427-1.3,2.83,2.83,0,0,0,.518-1.568v-6.748A7.222,7.222,0,0,0,1026.239,378.121Z"/>
        <path class="cls-3" d="M1053.2,376.875a7.068,7.068,0,0,1,2.31,2.52l.056.224c0,.112-.065.178-.2.2l-1.54.364-.084.028c-.094,0-.186-.074-.28-.224a7.6,7.6,0,0,0-1.722-1.764,3.292,3.292,0,0,0-1.89-.56,4.211,4.211,0,0,0-3.472,1.61,7.991,7.991,0,0,0,.014,8.722,4.3,4.3,0,0,0,3.514,1.6,4.147,4.147,0,0,0,2.31-.644,6,6,0,0,0,1.806-1.988c.056-.094.113-.144.168-.154a.335.335,0,0,1,.2.042l1.2.448c.131.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.612,1.022,6.892,6.892,0,0,1-3.64-.966,6.646,6.646,0,0,1-2.492-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.664,6.664,0,0,1,2.506-2.758,6.837,6.837,0,0,1,3.626-.98A6.186,6.186,0,0,1,1053.2,376.875Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1060.133,390.679V376.567q0-.365.307-.364h1.624a.273.273,0,0,1,.309.308v1.82c0,.112.032.178.1.2s.135-.018.21-.112q2.128-2.492,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.252.224a7.865,7.865,0,0,0-1.26-.084,3.979,3.979,0,0,0-1.764.476,5,5,0,0,0-1.6,1.232,2.368,2.368,0,0,0-.645,1.539v9.269a.371.371,0,0,1-.42.42h-1.54Q1060.133,391.043,1060.133,390.679Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1074.119,387.039a4.959,4.959,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.98,4.98,0,0,0,4.368-2.324.413.413,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.057.392a6.837,6.837,0,0,1-2.605,2.45,7.878,7.878,0,0,1-3.612.77,7.113,7.113,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.533-2.674,8.279,8.279,0,0,1-.911-3.934,8.907,8.907,0,0,1,.924-4.159,6.649,6.649,0,0,1,2.534-2.729,6.987,6.987,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.377.377,0,0,1-.335.126h-10.725q-.252,0-.252.42A4.934,4.934,0,0,0,1074.119,387.039Zm7.91-4.452a.7.7,0,0,0,.392-.07c.057-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.221,4.221,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.7,4.7,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M1093.2,391.323a4.983,4.983,0,0,1-3.43-1.106,3.754,3.754,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.968,10.968,0,0,1,5.474-1.512l2.184-.168c.318-.037.476-.177.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.546,3.546,0,0,0-2.534-.882,4.741,4.741,0,0,0-4.032,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.735,7.735,0,0,1,3.682-.826,6.192,6.192,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.018-.13-.065-.2-.139-.2a.386.386,0,0,0-.253.14A6.978,6.978,0,0,1,1093.2,391.323Zm4.928-7.84-1.82.168a9.1,9.1,0,0,0-4.018,1.008,2.591,2.591,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.933,4.933,0,0,0,1.708-.308,5.017,5.017,0,0,0,1.484-.84,4.1,4.1,0,0,0,.994-1.078,2.122,2.122,0,0,0,.35-1.106v-2.324C1098.549,383.633,1098.409,383.483,1098.129,383.483Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1110.225,377.939c-.15,0-.224.084-.224.252v8.876a2.679,2.679,0,0,0,.42,1.736,1.746,1.746,0,0,0,1.372.476h1.932a.273.273,0,0,1,.308.308l-.028.952q0,.281-.336.364a16.271,16.271,0,0,1-2.632.14,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.813-2.618v-9.352c0-.205-.093-.308-.279-.308h-2.24c-.2,0-.309-.093-.309-.28v-1.176a.249.249,0,0,1,.282-.28h2.378c.131,0,.2-.074.2-.224l.307-4.452c0-.243.1-.364.309-.364h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.205,0,.308.084.308.252v1.2c0,.187-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1120.4,387.039a4.964,4.964,0,0,0,1.78,1.82,4.694,4.694,0,0,0,2.463.672,4.984,4.984,0,0,0,4.369-2.324.4.4,0,0,1,.2-.126.325.325,0,0,1,.195.042l1.177.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.881,7.881,0,0,1-3.612.77,7.116,7.116,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.919,8.919,0,0,1,.923-4.159,6.66,6.66,0,0,1,2.536-2.729,6.978,6.978,0,0,1,3.625-.952,6.877,6.877,0,0,1,3.543.9,6,6,0,0,1,2.365,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H1120q-.252,0-.252.42A4.944,4.944,0,0,0,1120.4,387.039Zm7.911-4.452a.707.707,0,0,0,.392-.07c.057-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.1,4.1,0,0,0-1.512-1.68,4.224,4.224,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.7,4.7,0,0,0-1.624,1.848,5.128,5.128,0,0,0-.532,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M814.253,424.009V409.9c0-.243.1-.364.308-.364h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.583,4.583,0,0,1,3.318,1.148,4.083,4.083,0,0,1,1.19,3.08v10.444q0,.448-.42.448h-1.568a.321.321,0,0,1-.364-.364v-10a3.021,3.021,0,0,0-.714-2.156,2.894,2.894,0,0,0-2.2-.756,4.615,4.615,0,0,0-2.17.518,13.645,13.645,0,0,0-2.423,1.75v10.584a.357.357,0,0,1-.419.42h-1.54C814.383,424.373,814.253,424.252,814.253,424.009Z"/>
        <path class="cls-3" d="M834.23,420.369a4.962,4.962,0,0,0,1.779,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.195.042l1.177.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.881,7.881,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.873,6.873,0,0,1,3.542.9,5.994,5.994,0,0,1,2.366,2.618,9.215,9.215,0,0,1,.84,4.074v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126H833.825q-.252,0-.252.42A4.931,4.931,0,0,0,834.23,420.369Zm7.911-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.113,4.113,0,0,0-1.512-1.68,4.224,4.224,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714,4.688,4.688,0,0,0-1.624,1.848,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M868.909,409.659a.273.273,0,0,1,.028.266l-4.284,14.084a.5.5,0,0,1-.532.364h-1.345a.538.538,0,0,1-.588-.364l-3.332-10.472c-.055-.168-.121-.252-.2-.252-.038,0-.084.084-.14.252l-3.165,10.472a.5.5,0,0,1-.531.364H853.4a.535.535,0,0,1-.587-.364l-4.4-14.056a.306.306,0,0,1-.028-.14c0-.186.112-.28.336-.28h1.652a.467.467,0,0,1,.448.308l3.332,10.892c.056.187.121.28.2.28.056,0,.13-.1.224-.308l3.3-10.892a.369.369,0,0,1,.392-.28h1.316a.391.391,0,0,1,.42.308l3.444,10.892c.056.187.121.28.2.28s.14-.1.2-.308l3.22-10.864a.486.486,0,0,1,.476-.308h1.12A.283.283,0,0,1,868.909,409.659Z"/>
        <path class="cls-3" d="M883.244,406.341a.6.6,0,0,1-.112.406.5.5,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.765a.381.381,0,0,1,.279.112.416.416,0,0,1,.112.308Zm-.112,17.556a.5.5,0,0,1-.111.378.6.6,0,0,1-.393.1H881.2a.486.486,0,0,1-.336-.084.438.438,0,0,1-.084-.308V409.925c0-.261.113-.392.336-.392h1.708q.308,0,.308.364Z"/>
        <path class="cls-3" d="M899.036,423.981v-1.2c0-.168-.047-.252-.14-.252-.056,0-.13.047-.224.14a8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.083,6.083,0,0,1-3.192-.84,5.745,5.745,0,0,1-2.212-2.534,9.421,9.421,0,0,1-.811-4.1,9.664,9.664,0,0,1,.839-4.144,6.666,6.666,0,0,1,2.324-2.786,5.952,5.952,0,0,1,3.388-.994,5.554,5.554,0,0,1,2.184.393,6.593,6.593,0,0,1,1.736,1.091.716.716,0,0,0,.308.168c.094,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364H901c.205,0,.308.122.308.364v19.964a.548.548,0,0,1-.084.35.483.483,0,0,1-.364.1h-1.428A.346.346,0,0,1,899.036,423.981Zm-1.89-12.53a4.3,4.3,0,0,0-1.974-.462,4.169,4.169,0,0,0-3.416,1.6,7.007,7.007,0,0,0-1.288,4.508,6.522,6.522,0,0,0,1.274,4.4A4.138,4.138,0,0,0,895,422.945a3.981,3.981,0,0,0,2-.518,4.114,4.114,0,0,0,1.428-1.3,2.827,2.827,0,0,0,.518-1.568v-6.748A7.24,7.24,0,0,0,897.146,411.451Z"/>
        <path class="cls-3" d="M909.271,420.369a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.411.411,0,0,1,.2-.126.33.33,0,0,1,.2.042l1.176.588c.15.094.168.224.057.392a6.837,6.837,0,0,1-2.605,2.45,7.875,7.875,0,0,1-3.612.77,7.116,7.116,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.533-2.674,8.278,8.278,0,0,1-.91-3.934,8.888,8.888,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.982,6.982,0,0,1,3.626-.952,6.873,6.873,0,0,1,3.542.9,6,6,0,0,1,2.365,2.618,9.187,9.187,0,0,1,.841,4.074v.112a.7.7,0,0,1-.084.378.379.379,0,0,1-.336.126H908.864q-.252,0-.252.42A4.923,4.923,0,0,0,909.271,420.369Zm7.91-4.452a.689.689,0,0,0,.391-.07c.057-.046.084-.154.084-.322a5.105,5.105,0,0,0-.517-2.282,4.108,4.108,0,0,0-1.513-1.68,4.221,4.221,0,0,0-2.31-.63,4.365,4.365,0,0,0-2.492.714A4.7,4.7,0,0,0,909.2,413.5a5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M928.352,424.653a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.052,4.052,0,0,1,1.889-3.528,10.975,10.975,0,0,1,5.475-1.512l2.184-.168q.476-.055.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.549,3.549,0,0,0-2.534-.882,4.74,4.74,0,0,0-4.032,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.492.492,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.386.386,0,0,0-.252.14A6.978,6.978,0,0,1,928.352,424.653Zm4.928-7.84-1.82.168a9.107,9.107,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.331,2.331,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.937,4.937,0,0,0,1.708-.308,5.006,5.006,0,0,0,1.483-.839,4.12,4.12,0,0,0,1-1.079,2.131,2.131,0,0,0,.35-1.106v-2.324C933.7,416.963,933.56,416.813,933.28,416.813Z"/>
        <path class="cls-3" d="M948.75,411.423a5.174,5.174,0,0,0-2.366-.518,4.442,4.442,0,0,0-2.521.658,2,2,0,0,0-.979,1.722,1.647,1.647,0,0,0,.49,1.162,4.128,4.128,0,0,0,1.777.91l3.557,1.064a5.581,5.581,0,0,1,2.743,1.5,3.414,3.414,0,0,1,.9,2.366,3.582,3.582,0,0,1-.785,2.282,5.049,5.049,0,0,1-2.156,1.54,9.074,9.074,0,0,1-6.943-.308,5.182,5.182,0,0,1-2.38-2.226.47.47,0,0,1-.084-.224.252.252,0,0,1,.14-.224l1.091-.616q.281-.168.42.028a5.13,5.13,0,0,0,4.676,2.408,5.337,5.337,0,0,0,2.731-.686,1.965,1.965,0,0,0,1.078-1.722,1.643,1.643,0,0,0-.645-1.316,5.973,5.973,0,0,0-2.1-.951l-2.884-.813a5.994,5.994,0,0,1-2.926-1.484,3.29,3.29,0,0,1-.883-2.324,3.877,3.877,0,0,1,.714-2.3,4.557,4.557,0,0,1,2.017-1.555,8.305,8.305,0,0,1,6.3.154,5.354,5.354,0,0,1,2.254,1.9c.131.187.121.318-.028.392l-1.176.644a.417.417,0,0,1-.168.056.391.391,0,0,1-.252-.14A4.176,4.176,0,0,0,948.75,411.423Z"/>
        <path class="cls-3" d="M967.075,406.341a.6.6,0,0,1-.112.406.5.5,0,0,1-.392.126h-1.624c-.28,0-.42-.158-.42-.476v-2.352c0-.3.13-.448.392-.448h1.765a.381.381,0,0,1,.279.112.416.416,0,0,1,.112.308Zm-.112,17.556a.5.5,0,0,1-.111.378.6.6,0,0,1-.393.1h-1.428a.486.486,0,0,1-.336-.084.438.438,0,0,1-.084-.308V409.925c0-.261.113-.392.336-.392h1.708q.308,0,.308.364Z"/>
        <path class="cls-3" d="M972.7,424.009V409.9q0-.364.309-.364h1.679a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.649,5.649,0,0,1,2.477-.532,4.586,4.586,0,0,1,3.319,1.148,4.083,4.083,0,0,1,1.189,3.08v10.444q0,.448-.42.448h-1.567a.321.321,0,0,1-.364-.364v-10a3.025,3.025,0,0,0-.714-2.156,2.9,2.9,0,0,0-2.2-.756,4.611,4.611,0,0,0-2.169.518,13.572,13.572,0,0,0-2.422,1.75v10.584a.447.447,0,0,1-.1.322.442.442,0,0,1-.322.1H973.1Q972.7,424.373,972.7,424.009Z"/>
        <path class="cls-3" d="M1001.8,411.27c-.15,0-.225.084-.225.251V420.4a2.685,2.685,0,0,0,.42,1.736,1.748,1.748,0,0,0,1.372.476h1.933a.273.273,0,0,1,.308.308l-.029.952c0,.187-.111.308-.336.364a16.271,16.271,0,0,1-2.632.14,3.485,3.485,0,0,1-2.576-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.2-.094-.307-.281-.307H996.7c-.2,0-.307-.094-.307-.281v-1.176a.247.247,0,0,1,.28-.28h2.38c.13,0,.195-.074.195-.224l.309-4.452q0-.365.307-.364h1.4c.223,0,.336.131.336.392v4.424a.2.2,0,0,0,.223.224h3.529c.205,0,.307.084.307.252v1.2c0,.187-.1.281-.307.281Z"/>
        <path class="cls-3" d="M1010.14,424.009V403.961c0-.242.1-.364.307-.364h1.653q.307,0,.307.336v7.476c0,.2.084.234.252.084a14.446,14.446,0,0,1,2.716-1.736,5.937,5.937,0,0,1,2.464-.5,4.523,4.523,0,0,1,3.318,1.148,4.172,4.172,0,0,1,1.162,3.108v10.416c0,.3-.139.448-.42.448h-1.568a.321.321,0,0,1-.363-.364v-10a3.021,3.021,0,0,0-.715-2.156,2.893,2.893,0,0,0-2.2-.756,4.456,4.456,0,0,0-2.143.518,15.177,15.177,0,0,0-2.422,1.75v10.584q0,.42-.448.42h-1.54A.321.321,0,0,1,1010.14,424.009Z"/>
        <path class="cls-3" d="M1030.089,420.369a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.406.406,0,0,1,.2-.126.328.328,0,0,1,.195.042l1.177.588c.148.094.168.224.055.392a6.818,6.818,0,0,1-2.6,2.45,7.885,7.885,0,0,1-3.613.77,7.111,7.111,0,0,1-3.668-.952,6.608,6.608,0,0,1-2.534-2.674,8.3,8.3,0,0,1-.91-3.934,8.913,8.913,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.868,6.868,0,0,1,3.542.9,5.982,5.982,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.377.377,0,0,1-.336.126h-10.723q-.252,0-.252.42A4.921,4.921,0,0,0,1030.089,420.369Zm7.91-4.452a.7.7,0,0,0,.393-.07.433.433,0,0,0,.084-.322,5.08,5.08,0,0,0-.519-2.282,4.113,4.113,0,0,0-1.512-1.68,4.22,4.22,0,0,0-2.309-.63,4.365,4.365,0,0,0-2.492.714,4.688,4.688,0,0,0-1.624,1.848,5.141,5.141,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M1056.591,424.653a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.964,10.964,0,0,1,5.473-1.512l2.185-.168q.476-.055.475-.42v-.756a3.129,3.129,0,0,0-.937-2.394,3.552,3.552,0,0,0-2.534-.882,4.738,4.738,0,0,0-4.032,2.24.463.463,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.513-.5a.2.2,0,0,1-.139-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.732,7.732,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.429,4.429,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.438.438,0,0,1-.309.084h-1.4c-.225,0-.355-.149-.393-.448l-.055-1.288c-.019-.13-.066-.2-.14-.2a.39.39,0,0,0-.253.14A6.974,6.974,0,0,1,1056.591,424.653Zm4.928-7.84-1.821.168a9.1,9.1,0,0,0-4.017,1.008,2.589,2.589,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.854,1.9,3.482,3.482,0,0,0,2.281.7,4.929,4.929,0,0,0,1.708-.308,5.029,5.029,0,0,0,1.485-.839,4.149,4.149,0,0,0,.994-1.079,2.129,2.129,0,0,0,.349-1.106v-2.324C1061.938,416.963,1061.8,416.813,1061.519,416.813Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1069.7,424.009V409.9c0-.243.1-.364.309-.364h1.624a.273.273,0,0,1,.308.308v1.82c0,.112.033.178.1.2s.136-.018.21-.112q2.127-2.491,4.34-2.492.98,0,.98.364v1.68c0,.186-.084.262-.251.224a7.88,7.88,0,0,0-1.26-.083,3.977,3.977,0,0,0-1.765.475,5,5,0,0,0-1.6,1.233,2.366,2.366,0,0,0-.643,1.539v9.268a.371.371,0,0,1-.42.42h-1.54Q1069.7,424.373,1069.7,424.009Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1083.682,420.369a4.951,4.951,0,0,0,1.778,1.82,4.7,4.7,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.832,6.832,0,0,1-2.6,2.45,7.885,7.885,0,0,1-3.613.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.3,8.3,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.87,6.87,0,0,1,3.542.9,5.988,5.988,0,0,1,2.366,2.618,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126h-10.724q-.252,0-.252.42A4.922,4.922,0,0,0,1083.682,420.369Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.079,5.079,0,0,0-.518-2.282,4.113,4.113,0,0,0-1.512-1.68,4.223,4.223,0,0,0-2.309-.63,4.366,4.366,0,0,0-2.493.714,4.688,4.688,0,0,0-1.624,1.848,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M1102.764,424.653a4.981,4.981,0,0,1-3.43-1.106,3.753,3.753,0,0,1-1.246-2.954,4.051,4.051,0,0,1,1.89-3.528,10.971,10.971,0,0,1,5.474-1.512l2.184-.168q.476-.055.476-.42v-.756a3.129,3.129,0,0,0-.938-2.394,3.549,3.549,0,0,0-2.534-.882,4.74,4.74,0,0,0-4.032,2.24.456.456,0,0,1-.168.182.272.272,0,0,1-.224-.014l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.828,5.828,0,0,1,2.394-2.31,7.738,7.738,0,0,1,3.682-.826,6.193,6.193,0,0,1,4.2,1.274,4.426,4.426,0,0,1,1.456,3.514v9.912a.486.486,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.2-.14-.2a.386.386,0,0,0-.252.14A6.976,6.976,0,0,1,1102.764,424.653Zm4.928-7.84-1.82.168a9.107,9.107,0,0,0-4.018,1.008,2.589,2.589,0,0,0-1.414,2.324,2.331,2.331,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.937,4.937,0,0,0,1.708-.308,5.024,5.024,0,0,0,1.484-.839,4.132,4.132,0,0,0,.994-1.079,2.131,2.131,0,0,0,.35-1.106v-2.324C1108.112,416.963,1107.972,416.813,1107.692,416.813Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1125.793,423.688a6.528,6.528,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.488,8.488,0,0,1-.9,3.948,6.716,6.716,0,0,1-2.505,2.716,6.939,6.939,0,0,1-3.683.98A7.08,7.08,0,0,1,1125.793,423.688Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.066,4.066,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.18,4.18,0,0,0,3.473,1.6A4.047,4.047,0,0,0,1132.961,421.307Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1148.88,409.813v1.2c0,.168-.094.253-.28.253h-3.192c-.15,0-.224.084-.224.251v12.46q0,.392-.336.392h-1.707c-.207,0-.309-.121-.309-.364V411.493c0-.149-.066-.223-.2-.223h-2.743c-.188,0-.282-.085-.282-.253v-1.2c0-.186.1-.28.309-.28h2.66c.168,0,.252-.094.252-.28v-2.044q0-3.612,3.5-3.612h2.324c.242,0,.364.112.364.336v1.092c0,.187-.1.28-.308.28h-1.96q-1.624,0-1.624,2.016v1.96a.223.223,0,0,0,.252.252h3.22A.248.248,0,0,1,1148.88,409.813Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M821.533,443.282v1.176c0,.168.046.252.14.252a.379.379,0,0,0,.224-.14,14,14,0,0,1,1.036-.952,4.994,4.994,0,0,1,1.316-.7,5.649,5.649,0,0,1,2.044-.336,5.957,5.957,0,0,1,3.15.84,5.708,5.708,0,0,1,2.184,2.52,9.556,9.556,0,0,1,.8,4.117,10.4,10.4,0,0,1-.8,4.255,5.869,5.869,0,0,1-5.642,3.668,5.943,5.943,0,0,1-3.976-1.4c-.131-.13-.234-.2-.308-.2-.094,0-.14.122-.14.364v5.208c0,.242-.1.364-.308.364h-1.736c-.205,0-.308-.122-.308-.364V443.311a.492.492,0,0,1,.1-.336.444.444,0,0,1,.35-.113h1.484Q821.533,442.862,821.533,443.282Zm1.834,12.531a4.371,4.371,0,0,0,2,.461,4.1,4.1,0,0,0,3.374-1.6,7.214,7.214,0,0,0,1.274-4.592,6.466,6.466,0,0,0-1.26-4.354,4.384,4.384,0,0,0-5.264-.952,4.007,4.007,0,0,0-1.414,1.246,2.621,2.621,0,0,0-.518,1.484v6.916A6.949,6.949,0,0,0,823.367,455.813Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M837.324,457.338V443.227c0-.244.1-.365.308-.365h1.624a.273.273,0,0,1,.308.308v1.82c0,.113.032.178.1.2s.135-.019.21-.113q2.129-2.492,4.34-2.492.98,0,.979.364v1.68q0,.281-.252.225a7.729,7.729,0,0,0-1.259-.084,3.983,3.983,0,0,0-1.764.475,5,5,0,0,0-1.6,1.233,2.37,2.37,0,0,0-.644,1.539v9.268a.371.371,0,0,1-.42.42h-1.54C837.454,457.7,837.324,457.581,837.324,457.338Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M851.59,457.017a6.528,6.528,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.675,6.675,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.485,8.485,0,0,1-.9,3.948A6.711,6.711,0,0,1,858.982,457a6.933,6.933,0,0,1-3.682.98A7.078,7.078,0,0,1,851.59,457.017Zm7.168-2.381a8.307,8.307,0,0,0-.029-8.708,4.064,4.064,0,0,0-3.4-1.61,4.135,4.135,0,0,0-3.444,1.61,8.129,8.129,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.048,4.048,0,0,0,858.758,454.636Z"/>
        <path class="cls-3" d="M877.42,457.311v-1.2c0-.168-.047-.252-.14-.252a.384.384,0,0,0-.224.14,8.867,8.867,0,0,1-1.834,1.414,5.088,5.088,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.839,5.753,5.753,0,0,1-2.212-2.535,9.418,9.418,0,0,1-.812-4.1,9.664,9.664,0,0,1,.84-4.144,6.672,6.672,0,0,1,2.323-2.786,5.957,5.957,0,0,1,3.389-.994,5.557,5.557,0,0,1,2.184.393,6.593,6.593,0,0,1,1.736,1.091.711.711,0,0,0,.308.168c.093,0,.14-.121.14-.364v-6.58c0-.242.1-.364.308-.364h1.708c.205,0,.308.122.308.364v19.964a.548.548,0,0,1-.084.35.483.483,0,0,1-.364.1h-1.428A.346.346,0,0,1,877.42,457.311Zm-1.89-12.531a4.3,4.3,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.6,7.009,7.009,0,0,0-1.288,4.508,6.517,6.517,0,0,0,1.274,4.4,4.138,4.138,0,0,0,3.262,1.456,3.97,3.97,0,0,0,2-.518,4.092,4.092,0,0,0,1.427-1.3,2.821,2.821,0,0,0,.519-1.568v-6.748A7.24,7.24,0,0,0,875.53,444.78Z"/>
        <path class="cls-3" d="M892.722,455.5a10.463,10.463,0,0,0,2.254-1.806V443.254q0-.392.448-.392h1.54a.346.346,0,0,1,.391.392l-.055,14.084q0,.364-.308.364h-1.624a.271.271,0,0,1-.308-.307v-1.653c0-.223-.084-.252-.252-.084a11.525,11.525,0,0,1-2.688,1.806,5.908,5.908,0,0,1-2.408.518,4.531,4.531,0,0,1-3.22-1.176,4.048,4.048,0,0,1-1.261-3.108V443.282a.372.372,0,0,1,.42-.42h1.569c.242,0,.364.131.364.392v9.828a3.133,3.133,0,0,0,.77,2.227,2.749,2.749,0,0,0,2.114.825A4.508,4.508,0,0,0,892.722,455.5Z"/>
        <path class="cls-3" d="M912.686,443.534a7.075,7.075,0,0,1,2.309,2.52l.057.224c0,.113-.066.178-.2.2l-1.54.363-.084.028c-.093,0-.186-.074-.28-.223a7.571,7.571,0,0,0-1.722-1.765,3.287,3.287,0,0,0-1.889-.56,4.215,4.215,0,0,0-3.473,1.61,6.782,6.782,0,0,0-1.288,4.354,6.715,6.715,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.514,1.6,4.154,4.154,0,0,0,2.309-.643,6.018,6.018,0,0,0,1.807-1.989c.055-.093.112-.144.168-.154a.332.332,0,0,1,.2.042l1.2.448c.131.056.168.178.112.364a7.307,7.307,0,0,1-2.379,2.646,6.292,6.292,0,0,1-3.613,1.022,6.9,6.9,0,0,1-3.639-.965,6.65,6.65,0,0,1-2.493-2.7,8.547,8.547,0,0,1-.895-3.976,8.811,8.811,0,0,1,.895-4.018,6.655,6.655,0,0,1,2.506-2.757,6.829,6.829,0,0,1,3.626-.981A6.187,6.187,0,0,1,912.686,443.534Z"/>
        <path class="cls-3" d="M923.536,444.6c-.15,0-.224.084-.224.252v8.876a2.683,2.683,0,0,0,.419,1.735,1.75,1.75,0,0,0,1.373.476h1.932a.273.273,0,0,1,.308.308l-.029.952c0,.187-.111.308-.336.365a16.42,16.42,0,0,1-2.631.139,3.488,3.488,0,0,1-2.577-.826,3.62,3.62,0,0,1-.811-2.618v-9.352c0-.2-.094-.307-.28-.307h-2.241c-.2,0-.307-.094-.307-.281v-1.175a.248.248,0,0,1,.28-.281h2.38c.13,0,.195-.074.195-.224l.309-4.451c0-.244.1-.365.308-.365H923q.336,0,.336.392v4.424a.2.2,0,0,0,.223.224h3.529c.205,0,.307.084.307.252v1.2c0,.187-.1.281-.307.281Z"/>
        <path class="cls-3" d="M939.146,444.752a5.173,5.173,0,0,0-2.367-.518,4.429,4.429,0,0,0-2.519.659,1.99,1.99,0,0,0-.981,1.721,1.644,1.644,0,0,0,.491,1.162,4.131,4.131,0,0,0,1.778.911l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.422,3.422,0,0,1,.9,2.366,3.591,3.591,0,0,1-.783,2.282,5.063,5.063,0,0,1-2.156,1.541,9.085,9.085,0,0,1-6.945-.309,5.182,5.182,0,0,1-2.38-2.226.485.485,0,0,1-.083-.224.253.253,0,0,1,.139-.224l1.093-.616c.185-.111.326-.1.42.028a5.129,5.129,0,0,0,4.676,2.408,5.332,5.332,0,0,0,2.729-.686,1.963,1.963,0,0,0,1.078-1.722,1.647,1.647,0,0,0-.643-1.316,5.985,5.985,0,0,0-2.1-.951l-2.884-.813a5.982,5.982,0,0,1-2.925-1.484,3.292,3.292,0,0,1-.882-2.323,3.871,3.871,0,0,1,.714-2.3,4.546,4.546,0,0,1,2.015-1.555,8.31,8.31,0,0,1,6.3.154,5.373,5.373,0,0,1,2.254,1.905c.13.186.121.317-.028.391l-1.176.644a.422.422,0,0,1-.168.056.386.386,0,0,1-.252-.14A4.2,4.2,0,0,0,939.146,444.752Z"/>
        <path class="cls-3" d="M946.691,461.594a.423.423,0,0,1,0-.308l1.26-3.136a.628.628,0,0,0,.084-.252c0-.13-.1-.2-.307-.2h-.365q-.365,0-.364-.336v-2.492c0-.205.094-.308.28-.308H949.6a.248.248,0,0,1,.28.28v2.3a.654.654,0,0,1-.084.336l-2.044,3.919a.587.587,0,0,1-.561.308h-.307A.2.2,0,0,1,946.691,461.594Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M966.264,444.6c-.151,0-.225.084-.225.252v8.876a2.682,2.682,0,0,0,.42,1.735,1.745,1.745,0,0,0,1.372.476h1.933a.271.271,0,0,1,.306.308l-.027.952c0,.187-.111.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.482,3.482,0,0,1-2.576-.826,3.616,3.616,0,0,1-.811-2.618v-9.352c0-.2-.094-.307-.281-.307h-2.24c-.206,0-.308-.094-.308-.281v-1.175a.248.248,0,0,1,.28-.281h2.381c.129,0,.2-.074.2-.224l.308-4.451c0-.244.1-.365.308-.365h1.4c.225,0,.336.131.336.392v4.424a.2.2,0,0,0,.225.224h3.527q.309,0,.309.252v1.2c0,.187-.1.281-.309.281Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M976.44,453.7a4.972,4.972,0,0,0,1.779,1.821,4.706,4.706,0,0,0,2.464.671,4.982,4.982,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.327.327,0,0,1,.2.042l1.177.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.868,7.868,0,0,1-3.612.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.29,8.29,0,0,1-.91-3.934,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.864,6.864,0,0,1,3.542.9,5.986,5.986,0,0,1,2.366,2.617,9.218,9.218,0,0,1,.84,4.074v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126H976.035q-.252,0-.252.42A4.928,4.928,0,0,0,976.44,453.7Zm7.911-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.092,5.092,0,0,0-.518-2.282,4.11,4.11,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.679,4.679,0,0,0-1.624,1.847,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M1001.528,443.534a7.071,7.071,0,0,1,2.311,2.52l.056.224c0,.113-.066.178-.2.2l-1.54.363-.084.028c-.094,0-.187-.074-.28-.223a7.611,7.611,0,0,0-1.722-1.765,3.292,3.292,0,0,0-1.89-.56,4.211,4.211,0,0,0-3.472,1.61,6.777,6.777,0,0,0-1.288,4.354,6.709,6.709,0,0,0,1.3,4.368,4.3,4.3,0,0,0,3.514,1.6,4.157,4.157,0,0,0,2.31-.643,6.014,6.014,0,0,0,1.805-1.989c.057-.093.113-.144.168-.154a.333.333,0,0,1,.2.042l1.2.448c.13.056.168.178.112.364a7.3,7.3,0,0,1-2.38,2.646,6.3,6.3,0,0,1-3.612,1.022,6.9,6.9,0,0,1-3.641-.965,6.653,6.653,0,0,1-2.491-2.7,8.535,8.535,0,0,1-.9-3.976,8.8,8.8,0,0,1,.9-4.018,6.641,6.641,0,0,1,2.506-2.757,6.829,6.829,0,0,1,3.626-.981A6.18,6.18,0,0,1,1001.528,443.534Z"/>
        <path class="cls-3" d="M1008.486,457.338V437.29c0-.242.1-.364.309-.364h1.651c.2,0,.309.112.309.336v7.476c0,.2.084.234.252.084a14.351,14.351,0,0,1,2.716-1.736,5.93,5.93,0,0,1,2.464-.5,4.526,4.526,0,0,1,3.318,1.148,4.175,4.175,0,0,1,1.161,3.108v10.416q0,.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.021,3.021,0,0,0-.713-2.155,2.9,2.9,0,0,0-2.2-.757,4.457,4.457,0,0,0-2.141.518,15.043,15.043,0,0,0-2.422,1.75v10.584c0,.281-.151.42-.448.42h-1.54A.322.322,0,0,1,1008.486,457.338Z"/>
        <path class="cls-3" d="M1026.21,457.338V443.227c0-.244.1-.365.309-.365h1.679a.273.273,0,0,1,.309.308v1.624c0,.206.084.233.252.084a15.334,15.334,0,0,1,2.674-1.764,5.649,5.649,0,0,1,2.477-.532,4.586,4.586,0,0,1,3.319,1.148,4.087,4.087,0,0,1,1.189,3.081v10.443q0,.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.021,3.021,0,0,0-.713-2.155,2.9,2.9,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.607,13.607,0,0,0-2.421,1.75v10.584a.358.358,0,0,1-.42.42h-1.54Q1026.21,457.7,1026.21,457.338Z"/>
        <path class="cls-3" d="M1046.469,457.017a6.515,6.515,0,0,1-2.492-2.7,9.333,9.333,0,0,1,.028-7.98,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.473,8.473,0,0,1-.9,3.948A6.7,6.7,0,0,1,1053.86,457a6.93,6.93,0,0,1-3.681.98A7.075,7.075,0,0,1,1046.469,457.017Zm7.168-2.381a8.313,8.313,0,0,0-.029-8.708,4.067,4.067,0,0,0-3.4-1.61,4.133,4.133,0,0,0-3.443,1.61,8.123,8.123,0,0,0,0,8.694,4.176,4.176,0,0,0,3.471,1.6A4.051,4.051,0,0,0,1053.637,454.636Z"/>
        <path class="cls-3" d="M1064.515,457.227a.49.49,0,0,1-.113.377.6.6,0,0,1-.391.1h-1.456a.485.485,0,0,1-.336-.084.425.425,0,0,1-.085-.307l.028-19.993q0-.391.336-.392h1.681c.223,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M1072.732,457.017a6.528,6.528,0,0,1-2.492-2.7,9.328,9.328,0,0,1,.029-7.98,7.1,7.1,0,0,1,9.883-2.758,6.671,6.671,0,0,1,2.493,2.758,8.846,8.846,0,0,1,.881,4,8.485,8.485,0,0,1-.895,3.948,6.725,6.725,0,0,1-2.506,2.716,6.937,6.937,0,0,1-3.683.98A7.083,7.083,0,0,1,1072.732,457.017Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.065,4.065,0,0,0-3.4-1.61,4.137,4.137,0,0,0-3.445,1.61,8.134,8.134,0,0,0,0,8.694,4.177,4.177,0,0,0,3.473,1.6A4.046,4.046,0,0,0,1079.9,454.636Z"/>
        <path class="cls-3" d="M1102.343,443.059v.979a.556.556,0,0,1-.126.406.533.533,0,0,1-.435.1,3.459,3.459,0,0,0-1.456.252c-.447.187-.635.4-.559.644a2.879,2.879,0,0,0,.168.42,3.494,3.494,0,0,1,.223.686,3.616,3.616,0,0,1,.084.8,3.988,3.988,0,0,1-1.554,3.248,7.036,7.036,0,0,1-4.522,1.261,8.617,8.617,0,0,0-2.855.336c-.58.223-.869.513-.869.867,0,.28.2.49.588.631a7.991,7.991,0,0,0,1.96.294l3.613.195a5.966,5.966,0,0,1,3.584,1.148,3.425,3.425,0,0,1,1.175,2.744,3.768,3.768,0,0,1-1.862,3.332,9.778,9.778,0,0,1-5.306,1.2,11.485,11.485,0,0,1-5.418-1.023,3.2,3.2,0,0,1-1.862-2.926q0-1.876,2.633-3.164.307-.141,0-.307a1.793,1.793,0,0,1-1.288-1.653,1.643,1.643,0,0,1,.573-1.176,4.328,4.328,0,0,1,1.639-.924c.092-.018.139-.065.139-.14a.2.2,0,0,0-.139-.2,4.668,4.668,0,0,1-1.821-1.554,3.969,3.969,0,0,1-.587-2.2,4.1,4.1,0,0,1,1.652-3.444,7.576,7.576,0,0,1,4.676-1.26,6.45,6.45,0,0,1,4.2,1.26.378.378,0,0,0,.588,0,5.15,5.15,0,0,1,1.246-.91,2.95,2.95,0,0,1,1.386-.378C1102.165,442.61,1102.343,442.76,1102.343,443.059ZM1091.7,455.882a2.333,2.333,0,0,0-1.147.294,3.1,3.1,0,0,0-1.092.91,2.078,2.078,0,0,0-.448,1.288,2.235,2.235,0,0,0,1.372,2.059,9.071,9.071,0,0,0,4.059.713q4.733,0,4.733-2.8a1.918,1.918,0,0,0-.658-1.54,3.626,3.626,0,0,0-2.142-.644Zm5.362-6.3a2.749,2.749,0,0,0,.966-2.268,2.931,2.931,0,0,0-.979-2.352,4.328,4.328,0,0,0-2.885-.84,4.408,4.408,0,0,0-2.884.855,2.867,2.867,0,0,0-1.036,2.337,2.693,2.693,0,0,0,1.023,2.254,4.6,4.6,0,0,0,2.9.8A4.532,4.532,0,0,0,1097.064,449.582Z"/>
        <path class="cls-3" d="M1109.118,439.67a.606.606,0,0,1-.112.406.508.508,0,0,1-.392.126h-1.624c-.28,0-.421-.158-.421-.475v-2.353c0-.3.131-.448.393-.448h1.764a.383.383,0,0,1,.28.112.42.42,0,0,1,.112.308Zm-.112,17.557a.494.494,0,0,1-.112.377.6.6,0,0,1-.392.1h-1.428a.486.486,0,0,1-.336-.084.43.43,0,0,1-.085-.307V443.254c0-.261.113-.392.337-.392h1.708c.2,0,.308.121.308.365Z"/>
        <path class="cls-3" d="M1116.972,453.7a4.962,4.962,0,0,0,1.778,1.821,4.706,4.706,0,0,0,2.464.671,4.982,4.982,0,0,0,4.368-2.324.409.409,0,0,1,.2-.126.331.331,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.825,6.825,0,0,1-2.6,2.45,7.872,7.872,0,0,1-3.613.77,7.111,7.111,0,0,1-3.668-.952,6.6,6.6,0,0,1-2.534-2.674,8.3,8.3,0,0,1-.91-3.934,8.913,8.913,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.984,6.984,0,0,1,3.626-.952,6.861,6.861,0,0,1,3.542.9,5.98,5.98,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126h-10.724q-.252,0-.252.42A4.919,4.919,0,0,0,1116.972,453.7Zm7.91-4.452a.7.7,0,0,0,.392-.07c.056-.046.084-.154.084-.322a5.079,5.079,0,0,0-.518-2.282,4.11,4.11,0,0,0-1.512-1.679,4.212,4.212,0,0,0-2.309-.631,4.359,4.359,0,0,0-2.493.715,4.679,4.679,0,0,0-1.624,1.847,5.14,5.14,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M1139.708,444.752a5.171,5.171,0,0,0-2.366-.518,4.433,4.433,0,0,0-2.52.659,1.991,1.991,0,0,0-.98,1.721,1.647,1.647,0,0,0,.49,1.162,4.137,4.137,0,0,0,1.778.911l3.556,1.063a5.58,5.58,0,0,1,2.744,1.5,3.418,3.418,0,0,1,.9,2.366,3.586,3.586,0,0,1-.784,2.282,5.063,5.063,0,0,1-2.156,1.541,9.083,9.083,0,0,1-6.944-.309,5.182,5.182,0,0,1-2.38-2.226.477.477,0,0,1-.084-.224.254.254,0,0,1,.14-.224l1.092-.616c.186-.111.327-.1.42.028a5.13,5.13,0,0,0,4.676,2.408,5.333,5.333,0,0,0,2.73-.686,1.965,1.965,0,0,0,1.078-1.722,1.645,1.645,0,0,0-.644-1.316,5.979,5.979,0,0,0-2.1-.951l-2.884-.813a5.99,5.99,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.871,3.871,0,0,1,.714-2.3,4.554,4.554,0,0,1,2.016-1.555,8.308,8.308,0,0,1,6.3.154,5.361,5.361,0,0,1,2.254,1.905c.13.186.121.317-.028.391l-1.176.644a.422.422,0,0,1-.168.056.386.386,0,0,1-.252-.14A4.187,4.187,0,0,0,1139.708,444.752Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M859.584,491.312a4.981,4.981,0,0,1-3.43-1.107,3.753,3.753,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.956,10.956,0,0,1,5.473-1.513l2.185-.168q.476-.056.476-.42v-.756a3.127,3.127,0,0,0-.938-2.393,3.548,3.548,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.474.474,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.513-.5a.2.2,0,0,1-.139-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.744,7.744,0,0,1,3.682-.826,6.188,6.188,0,0,1,4.2,1.275A4.425,4.425,0,0,1,867.2,480.7v9.912a.485.485,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.065-.195-.14-.195a.388.388,0,0,0-.253.139A6.975,6.975,0,0,1,859.584,491.312Zm4.928-7.84-1.821.168a9.089,9.089,0,0,0-4.017,1.007,2.589,2.589,0,0,0-1.414,2.325,2.327,2.327,0,0,0,.854,1.9,3.485,3.485,0,0,0,2.282.7,4.932,4.932,0,0,0,1.708-.307,5.039,5.039,0,0,0,1.484-.84,4.127,4.127,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q864.932,483.472,864.512,483.472Z"/>
        <path class="cls-3" d="M872.688,490.667V476.556q0-.364.307-.365h1.681a.272.272,0,0,1,.307.308v1.624c0,.206.084.233.252.084a15.409,15.409,0,0,1,2.674-1.764,5.655,5.655,0,0,1,2.479-.532,4.577,4.577,0,0,1,3.317,1.149,4.079,4.079,0,0,1,1.191,3.08v10.443q0,.448-.42.448h-1.569a.321.321,0,0,1-.363-.364v-10a3.014,3.014,0,0,0-.715-2.155,2.888,2.888,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.609,13.609,0,0,0-2.423,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C872.817,491.031,872.688,490.91,872.688,490.667Z"/>
        <path class="cls-3" d="M900.6,490.64v-1.2c0-.168-.047-.252-.14-.252-.057,0-.131.046-.225.139a8.841,8.841,0,0,1-1.834,1.414,5.084,5.084,0,0,1-2.561.575,6.094,6.094,0,0,1-3.193-.84,5.762,5.762,0,0,1-2.212-2.534,9.426,9.426,0,0,1-.811-4.1,9.653,9.653,0,0,1,.84-4.144,6.664,6.664,0,0,1,2.323-2.786,5.953,5.953,0,0,1,3.389-.994,5.549,5.549,0,0,1,2.183.393A6.576,6.576,0,0,1,900.1,477.4a.72.72,0,0,0,.307.167c.094,0,.141-.121.141-.364v-6.58c0-.242.1-.364.307-.364h1.708c.206,0,.308.122.308.364v19.964a.547.547,0,0,1-.084.351.487.487,0,0,1-.363.1H901A.345.345,0,0,1,900.6,490.64Zm-1.89-12.531a4.307,4.307,0,0,0-1.975-.462,4.173,4.173,0,0,0-3.416,1.6,7.016,7.016,0,0,0-1.288,4.508,6.517,6.517,0,0,0,1.275,4.4,4.136,4.136,0,0,0,3.261,1.457,3.966,3.966,0,0,0,2-.519,4.1,4.1,0,0,0,1.428-1.3,2.821,2.821,0,0,0,.519-1.568v-6.748A7.258,7.258,0,0,0,898.714,478.109Z"/>
        <path class="cls-3" d="M916.031,490.667V476.556q0-.364.308-.365h1.681c.186,0,.279.1.279.308v1.624c0,.225.094.252.28.084a14.851,14.851,0,0,1,2.576-1.764,5.253,5.253,0,0,1,2.38-.532,4.491,4.491,0,0,1,2.59.7,3.483,3.483,0,0,1,1.387,1.764c.037.057.074.084.111.084a.609.609,0,0,0,.28-.14,14.064,14.064,0,0,1,2.618-1.82,5.356,5.356,0,0,1,2.451-.588,3.947,3.947,0,0,1,4.283,4.256v10.416q0,.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.133,3.133,0,0,0-.672-2.155,2.558,2.558,0,0,0-2.016-.757,4.241,4.241,0,0,0-2.1.547,10.974,10.974,0,0,0-1.9,1.357,1.632,1.632,0,0,0-.308.393.946.946,0,0,0-.084.42v10.107c0,.3-.139.448-.42.448h-1.568a.322.322,0,0,1-.364-.364v-10q0-2.911-2.688-2.912a4.184,4.184,0,0,0-2.057.518,14.251,14.251,0,0,0-2.339,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C916.161,491.031,916.031,490.91,916.031,490.667Z"/>
        <path class="cls-3" d="M946.439,491.312a4.979,4.979,0,0,1-3.429-1.107,3.753,3.753,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.527,10.957,10.957,0,0,1,5.474-1.513l2.185-.168q.476-.056.475-.42v-.756a3.126,3.126,0,0,0-.937-2.393,3.549,3.549,0,0,0-2.535-.883,4.741,4.741,0,0,0-4.032,2.24.46.46,0,0,1-.168.183.274.274,0,0,1-.223-.015l-1.513-.5a.2.2,0,0,1-.14-.2.662.662,0,0,1,.084-.252,5.842,5.842,0,0,1,2.395-2.31,7.74,7.74,0,0,1,3.681-.826,6.19,6.19,0,0,1,4.2,1.275,4.429,4.429,0,0,1,1.456,3.513v9.912a.49.49,0,0,1-.084.336.438.438,0,0,1-.309.084h-1.4q-.337,0-.393-.448l-.056-1.288c-.018-.13-.065-.195-.139-.195a.388.388,0,0,0-.253.139A6.979,6.979,0,0,1,946.439,491.312Zm4.928-7.84-1.82.168a9.1,9.1,0,0,0-4.018,1.007,2.591,2.591,0,0,0-1.414,2.325,2.325,2.325,0,0,0,.855,1.9,3.482,3.482,0,0,0,2.281.7,4.932,4.932,0,0,0,1.708-.307,5.013,5.013,0,0,0,1.484-.84,4.149,4.149,0,0,0,1-1.078,2.133,2.133,0,0,0,.349-1.107v-2.324Q951.787,483.472,951.367,483.472Z"/>
        <path class="cls-3" d="M959.543,490.667V476.556q0-.364.308-.365h1.68a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.371,15.371,0,0,1,2.674-1.764,5.651,5.651,0,0,1,2.478-.532,4.579,4.579,0,0,1,3.318,1.149,4.082,4.082,0,0,1,1.19,3.08v10.443q0,.448-.42.448h-1.568a.322.322,0,0,1-.364-.364v-10a3.018,3.018,0,0,0-.714-2.155,2.891,2.891,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.608,13.608,0,0,0-2.422,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C959.673,491.031,959.543,490.91,959.543,490.667Z"/>
        <path class="cls-3" d="M980.935,491.312a4.981,4.981,0,0,1-3.43-1.107,3.753,3.753,0,0,1-1.246-2.954,4.05,4.05,0,0,1,1.889-3.527,10.964,10.964,0,0,1,5.475-1.513l2.184-.168q.476-.056.476-.42v-.756a3.127,3.127,0,0,0-.938-2.393,3.545,3.545,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.512-.5a.2.2,0,0,1-.14-.2.645.645,0,0,1,.084-.252,5.834,5.834,0,0,1,2.394-2.31,7.75,7.75,0,0,1,3.682-.826,6.188,6.188,0,0,1,4.2,1.275,4.425,4.425,0,0,1,1.456,3.513v9.912a.49.49,0,0,1-.084.336.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.195-.14-.195a.384.384,0,0,0-.252.139A6.979,6.979,0,0,1,980.935,491.312Zm4.928-7.84-1.82.168a9.094,9.094,0,0,0-4.018,1.007,2.589,2.589,0,0,0-1.414,2.325,2.33,2.33,0,0,0,.854,1.9,3.487,3.487,0,0,0,2.282.7,4.936,4.936,0,0,0,1.708-.307,5.008,5.008,0,0,0,1.483-.84,4.132,4.132,0,0,0,1-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q986.283,483.472,985.863,483.472Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1007.984,476.388v.979a.556.556,0,0,1-.126.406.531.531,0,0,1-.434.1,3.456,3.456,0,0,0-1.456.252c-.447.188-.635.4-.56.645a3.108,3.108,0,0,0,.168.42,3.483,3.483,0,0,1,.224.685,3.677,3.677,0,0,1,.084.8,3.991,3.991,0,0,1-1.554,3.248,7.038,7.038,0,0,1-4.522,1.261,8.62,8.62,0,0,0-2.856.336c-.579.223-.868.513-.868.867q0,.42.588.631a7.982,7.982,0,0,0,1.96.294l3.612.195a5.961,5.961,0,0,1,3.584,1.148A3.427,3.427,0,0,1,1007,491.4a3.771,3.771,0,0,1-1.862,3.332,9.79,9.79,0,0,1-5.306,1.2,11.483,11.483,0,0,1-5.418-1.023,3.2,3.2,0,0,1-1.862-2.926q0-1.876,2.632-3.164c.206-.093.206-.2,0-.307a1.793,1.793,0,0,1-1.288-1.653,1.641,1.641,0,0,1,.574-1.175,4.316,4.316,0,0,1,1.638-.925c.093-.018.14-.065.14-.14a.2.2,0,0,0-.14-.2,4.663,4.663,0,0,1-1.82-1.554,3.961,3.961,0,0,1-.588-2.2,4.1,4.1,0,0,1,1.652-3.443,7.573,7.573,0,0,1,4.676-1.261,6.441,6.441,0,0,1,4.2,1.261.38.38,0,0,0,.588,0,5.165,5.165,0,0,1,1.246-.911,2.956,2.956,0,0,1,1.386-.378Q1007.985,475.939,1007.984,476.388Zm-10.64,12.823a2.339,2.339,0,0,0-1.148.294,3.1,3.1,0,0,0-1.092.91,2.084,2.084,0,0,0-.448,1.288,2.235,2.235,0,0,0,1.372,2.059,9.074,9.074,0,0,0,4.06.714q4.731,0,4.732-2.8a1.915,1.915,0,0,0-.658-1.54,3.62,3.62,0,0,0-2.141-.644Zm5.362-6.3a2.749,2.749,0,0,0,.966-2.267,2.931,2.931,0,0,0-.98-2.353,4.325,4.325,0,0,0-2.884-.84,4.4,4.4,0,0,0-2.884.855,2.865,2.865,0,0,0-1.036,2.338,2.694,2.694,0,0,0,1.022,2.253,4.61,4.61,0,0,0,2.9.8A4.526,4.526,0,0,0,1002.706,482.911Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1014.3,487.027a4.962,4.962,0,0,0,1.778,1.821,4.71,4.71,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.409.409,0,0,1,.2-.126.339.339,0,0,1,.2.042l1.177.588c.148.094.168.224.055.392a6.82,6.82,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.112,7.112,0,0,1-3.668-.953,6.605,6.605,0,0,1-2.534-2.673,8.306,8.306,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.991,6.991,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.966,5.966,0,0,1,2.365,2.617,9.19,9.19,0,0,1,.841,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126h-10.723q-.252,0-.252.42A4.918,4.918,0,0,0,1014.3,487.027Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.083,5.083,0,0,0-.518-2.283,4.11,4.11,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.361,4.361,0,0,0-2.492.715,4.684,4.684,0,0,0-1.623,1.847,5.141,5.141,0,0,0-.534,2.422Z"/>
        <path class="cls-3" d="M1029.74,490.667V476.556c0-.243.1-.365.308-.365h1.681c.185,0,.279.1.279.308v1.624c0,.225.094.252.28.084a14.887,14.887,0,0,1,2.575-1.764,5.26,5.26,0,0,1,2.381-.532,4.491,4.491,0,0,1,2.59.7,3.489,3.489,0,0,1,1.387,1.764c.037.057.074.084.111.084a.607.607,0,0,0,.279-.14,14.067,14.067,0,0,1,2.619-1.82,5.352,5.352,0,0,1,2.45-.588,3.947,3.947,0,0,1,4.284,4.256v10.416q0,.448-.42.448h-1.567a.322.322,0,0,1-.366-.364v-10a3.128,3.128,0,0,0-.672-2.155,2.555,2.555,0,0,0-2.015-.757,4.238,4.238,0,0,0-2.1.547,10.937,10.937,0,0,0-1.9,1.357,1.666,1.666,0,0,0-.309.393.96.96,0,0,0-.084.42v10.107c0,.3-.138.448-.42.448h-1.567a.321.321,0,0,1-.364-.364v-10q0-2.911-2.688-2.912a4.188,4.188,0,0,0-2.058.518,14.294,14.294,0,0,0-2.338,1.75v10.584a.357.357,0,0,1-.42.42h-1.54C1029.87,491.031,1029.74,490.91,1029.74,490.667Z"/>
        <path class="cls-3" d="M1058.733,487.027a4.972,4.972,0,0,0,1.779,1.821,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.407.407,0,0,1,.195-.126.337.337,0,0,1,.2.042l1.177.588c.149.094.168.224.056.392a6.83,6.83,0,0,1-2.605,2.45,7.864,7.864,0,0,1-3.611.771,7.112,7.112,0,0,1-3.668-.953,6.6,6.6,0,0,1-2.534-2.673,8.294,8.294,0,0,1-.91-3.935,8.9,8.9,0,0,1,.923-4.158,6.648,6.648,0,0,1,2.535-2.73,6.991,6.991,0,0,1,3.626-.952,6.864,6.864,0,0,1,3.542.9,5.978,5.978,0,0,1,2.365,2.617,9.2,9.2,0,0,1,.841,4.074v.112a.7.7,0,0,1-.084.378.378.378,0,0,1-.336.126h-10.724q-.252,0-.252.42A4.928,4.928,0,0,0,1058.733,487.027Zm7.911-4.452a.691.691,0,0,0,.391-.07c.057-.046.084-.153.084-.321a5.1,5.1,0,0,0-.517-2.283,4.11,4.11,0,0,0-1.512-1.679,4.219,4.219,0,0,0-2.311-.631,4.361,4.361,0,0,0-2.492.715,4.684,4.684,0,0,0-1.623,1.847,5.141,5.141,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M1074.176,490.667V476.556c0-.243.1-.365.307-.365h1.681a.273.273,0,0,1,.308.308v1.624c0,.206.084.233.252.084a15.407,15.407,0,0,1,2.673-1.764,5.657,5.657,0,0,1,2.478-.532,4.578,4.578,0,0,1,3.318,1.149,4.082,4.082,0,0,1,1.19,3.08v10.443c0,.3-.139.448-.42.448H1084.4a.322.322,0,0,1-.365-.364v-10a3.018,3.018,0,0,0-.714-2.155,2.889,2.889,0,0,0-2.2-.757,4.63,4.63,0,0,0-2.17.518,13.644,13.644,0,0,0-2.422,1.75v10.584a.356.356,0,0,1-.42.42h-1.54Q1074.176,491.031,1074.176,490.667Z"/>
        <path class="cls-3" d="M1095.848,477.928c-.151,0-.225.084-.225.252v8.876a2.68,2.68,0,0,0,.42,1.735,1.749,1.749,0,0,0,1.373.477h1.932a.271.271,0,0,1,.307.307l-.028.952c0,.187-.111.308-.336.365a16.42,16.42,0,0,1-2.631.139,3.482,3.482,0,0,1-2.576-.826,3.616,3.616,0,0,1-.813-2.618v-9.352c0-.205-.093-.307-.279-.307h-2.24c-.206,0-.309-.094-.309-.281v-1.175a.249.249,0,0,1,.281-.281h2.38c.13,0,.195-.074.195-.224l.308-4.451c0-.244.1-.365.309-.365h1.4q.336,0,.336.392v4.424a.2.2,0,0,0,.224.224h3.528c.206,0,.308.084.308.252v1.2c0,.187-.1.281-.308.281Z"/>
        <path class="cls-3" d="M1106.348,490.583a.452.452,0,0,1-.113.351.6.6,0,0,1-.391.1h-1.961a.49.49,0,0,1-.321-.084.387.387,0,0,1-.1-.307v-2.409c0-.242.1-.364.308-.364h2.3q.28,0,.281.336Z"/>
      </g>
    </g>
  </g>
</g>
<g id="rozwoj" class="our-values__text">
  <g>
    <path class="cls-2" d="M738.483,639.96c0,116.162,94.508,210.67,210.67,210.67s210.664-94.508,210.664-210.67-94.5-210.67-210.664-210.67S738.483,523.8,738.483,639.96Z"/>
    <g class="cls-4">
      <g class="cls-4">
        <path class="cls-3" d="M836.339,619.725a.43.43,0,0,1,.042.363l-5.768,19.852a.539.539,0,0,1-.2.323.739.739,0,0,1-.42.1h-1.708c-.354,0-.569-.131-.644-.391l-4.48-15.876c-.056-.206-.122-.2-.2.027l-4.536,15.82a.534.534,0,0,1-.2.323.739.739,0,0,1-.42.1h-1.736c-.354,0-.569-.131-.644-.391l-5.684-19.881a.306.306,0,0,1-.028-.14c0-.242.14-.364.42-.364h1.792a.431.431,0,0,1,.476.336l4.564,16.3c.056.205.112.307.168.307.075,0,.13-.092.168-.28l4.648-16.351a.476.476,0,0,1,.5-.309h1.6a.646.646,0,0,1,.335.07.436.436,0,0,1,.168.266l4.593,16.381q.084.252.168.252t.168-.252l4.62-16.408c.037-.205.2-.309.476-.309h1.4A.4.4,0,0,1,836.339,619.725Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M841.519,636.356a4.954,4.954,0,0,0,1.778,1.821,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.409.409,0,0,1,.2-.126.339.339,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.112,7.112,0,0,1-3.668-.953,6.6,6.6,0,0,1-2.534-2.673,8.306,8.306,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,6.994,6.994,0,0,1,3.626-.952,6.861,6.861,0,0,1,3.542.9,5.986,5.986,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H841.113q-.252,0-.252.42A4.919,4.919,0,0,0,841.519,636.356Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.1,5.1,0,0,0-.518-2.283,4.11,4.11,0,0,0-1.512-1.679,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.686,4.686,0,0,0-1.624,1.847,5.128,5.128,0,0,0-.532,2.422Z"/>
        <path class="cls-3" d="M883.617,625.646a.273.273,0,0,1,.028.266L879.361,640a.5.5,0,0,1-.532.364h-1.345A.538.538,0,0,1,876.9,640l-3.332-10.472c-.055-.168-.121-.252-.195-.252-.038,0-.084.084-.14.252L870.064,640a.5.5,0,0,1-.531.364H868.1a.535.535,0,0,1-.587-.364l-4.4-14.056a.3.3,0,0,1-.028-.139q0-.28.336-.28h1.652a.466.466,0,0,1,.448.307l3.332,10.893c.056.186.121.279.2.279.056,0,.131-.1.224-.308l3.3-10.891a.368.368,0,0,1,.392-.28h1.316a.39.39,0,0,1,.42.307l3.444,10.893c.056.186.121.279.2.279s.14-.1.2-.308l3.22-10.864a.485.485,0,0,1,.476-.307h1.12A.284.284,0,0,1,883.617,625.646Z"/>
        <path class="cls-3" d="M891.708,640.641a4.981,4.981,0,0,1-3.43-1.107,3.751,3.751,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.963,10.963,0,0,1,5.474-1.513l2.184-.168c.317-.037.477-.177.477-.42V630.2a3.128,3.128,0,0,0-.939-2.393,3.545,3.545,0,0,0-2.534-.883,4.738,4.738,0,0,0-4.031,2.24.482.482,0,0,1-.168.183.279.279,0,0,1-.225-.015l-1.512-.5a.2.2,0,0,1-.139-.2.628.628,0,0,1,.084-.252,5.825,5.825,0,0,1,2.393-2.31,7.75,7.75,0,0,1,3.682-.826,6.188,6.188,0,0,1,4.2,1.275,4.422,4.422,0,0,1,1.456,3.513v9.912a.485.485,0,0,1-.084.336.432.432,0,0,1-.307.084h-1.4c-.223,0-.354-.149-.391-.448l-.057-1.288c-.019-.13-.065-.2-.14-.2a.389.389,0,0,0-.252.139A6.975,6.975,0,0,1,891.708,640.641Zm4.929-7.84-1.821.168a9.071,9.071,0,0,0-4.017,1.008,2.586,2.586,0,0,0-1.414,2.324,2.328,2.328,0,0,0,.853,1.9,3.49,3.49,0,0,0,2.283.7,4.94,4.94,0,0,0,1.708-.307,5.033,5.033,0,0,0,1.483-.84,4.1,4.1,0,0,0,.994-1.078,2.136,2.136,0,0,0,.351-1.107v-2.324C897.057,632.95,896.916,632.8,896.637,632.8Z"/>
        <path class="cls-3" d="M904.812,640V625.885c0-.243.1-.364.308-.364h1.68a.272.272,0,0,1,.308.307v1.624q0,.309.252.084a15.371,15.371,0,0,1,2.674-1.764,5.654,5.654,0,0,1,2.478-.532,4.579,4.579,0,0,1,3.318,1.149,4.082,4.082,0,0,1,1.19,3.08v10.443q0,.448-.42.448h-1.568a.321.321,0,0,1-.364-.364V630a3.018,3.018,0,0,0-.714-2.155,2.892,2.892,0,0,0-2.2-.757,4.627,4.627,0,0,0-2.17.518,13.608,13.608,0,0,0-2.422,1.75V639.94a.357.357,0,0,1-.42.42H905.2C904.942,640.36,904.812,640.239,904.812,640Z"/>
        <path class="cls-3" d="M926.484,627.257c-.15,0-.224.084-.224.252v8.876a2.674,2.674,0,0,0,.42,1.735,1.743,1.743,0,0,0,1.372.477h1.932a.272.272,0,0,1,.308.307l-.028.952c0,.187-.112.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.484,3.484,0,0,1-2.576-.826,3.62,3.62,0,0,1-.812-2.618v-9.352c0-.2-.094-.307-.28-.307h-2.24c-.205,0-.308-.094-.308-.28V625.8a.247.247,0,0,1,.28-.28h2.38c.13,0,.2-.075.2-.225l.308-4.451q0-.365.308-.365h1.4q.336,0,.336.392V625.3a.2.2,0,0,0,.224.225h3.528c.205,0,.308.083.308.251v1.2c0,.186-.1.28-.308.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M946.141,627.257c-.151,0-.225.084-.225.252v8.876a2.68,2.68,0,0,0,.42,1.735,1.742,1.742,0,0,0,1.372.477h1.933a.27.27,0,0,1,.306.307l-.027.952c0,.187-.111.308-.336.365a16.443,16.443,0,0,1-2.632.139,3.482,3.482,0,0,1-2.576-.826,3.616,3.616,0,0,1-.812-2.618v-9.352c0-.2-.093-.307-.28-.307h-2.24c-.206,0-.308-.094-.308-.28V625.8a.247.247,0,0,1,.28-.28h2.38c.13,0,.2-.075.2-.225l.308-4.451c0-.243.1-.365.308-.365h1.4c.225,0,.336.131.336.392V625.3a.2.2,0,0,0,.225.225H949.7c.206,0,.309.083.309.251v1.2c0,.186-.1.28-.309.28Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M956.6,639.675a6.521,6.521,0,0,1-2.493-2.7,9.335,9.335,0,0,1,.028-7.981,7.1,7.1,0,0,1,9.885-2.758,6.689,6.689,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.881,4,8.485,8.485,0,0,1-.9,3.948,6.712,6.712,0,0,1-2.506,2.716,6.934,6.934,0,0,1-3.682.981A7.072,7.072,0,0,1,956.6,639.675Zm7.168-2.381a8.316,8.316,0,0,0-.03-8.708,4.067,4.067,0,0,0-3.4-1.609,4.135,4.135,0,0,0-3.444,1.609,8.129,8.129,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.05,4.05,0,0,0,963.768,637.294Z"/>
        <path class="cls-3" d="M989.849,639.969v-1.2c0-.168-.047-.252-.14-.252-.057,0-.131.047-.225.139a8.781,8.781,0,0,1-1.834,1.414,5.081,5.081,0,0,1-2.561.575,6.1,6.1,0,0,1-3.193-.84,5.761,5.761,0,0,1-2.211-2.534,9.422,9.422,0,0,1-.812-4.1,9.645,9.645,0,0,1,.84-4.143,6.663,6.663,0,0,1,2.324-2.787,5.947,5.947,0,0,1,3.388-.994,5.557,5.557,0,0,1,2.184.393,6.6,6.6,0,0,1,1.736,1.092.727.727,0,0,0,.307.168c.094,0,.141-.122.141-.365v-6.58c0-.242.1-.364.308-.364h1.708c.205,0,.308.122.308.364v19.964a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1H990.24A.346.346,0,0,1,989.849,639.969Zm-1.89-12.531a4.3,4.3,0,0,0-1.975-.461,4.175,4.175,0,0,0-3.416,1.6,7.016,7.016,0,0,0-1.287,4.508,6.522,6.522,0,0,0,1.274,4.4,4.139,4.139,0,0,0,3.261,1.456,3.966,3.966,0,0,0,2-.519,4.087,4.087,0,0,0,1.428-1.3,2.821,2.821,0,0,0,.519-1.568V628.8A7.258,7.258,0,0,0,987.959,627.438Z"/>
        <path class="cls-3" d="M1000.082,636.356a4.957,4.957,0,0,0,1.779,1.821,4.706,4.706,0,0,0,2.463.672,4.985,4.985,0,0,0,4.369-2.325.409.409,0,0,1,.2-.126.334.334,0,0,1,.195.042l1.177.588c.149.094.168.224.055.392a6.818,6.818,0,0,1-2.6,2.45,7.868,7.868,0,0,1-3.612.771,7.114,7.114,0,0,1-3.668-.953,6.608,6.608,0,0,1-2.535-2.673,8.306,8.306,0,0,1-.91-3.935,8.913,8.913,0,0,1,.924-4.158,6.655,6.655,0,0,1,2.535-2.73,6.993,6.993,0,0,1,3.625-.952,6.862,6.862,0,0,1,3.543.9,5.978,5.978,0,0,1,2.365,2.617,9.19,9.19,0,0,1,.84,4.074v.112a.679.679,0,0,1-.084.378.377.377,0,0,1-.336.126H999.677q-.252,0-.252.42A4.938,4.938,0,0,0,1000.082,636.356Zm7.91-4.452a.7.7,0,0,0,.393-.07q.084-.069.084-.321a5.1,5.1,0,0,0-.518-2.283,4.1,4.1,0,0,0-1.513-1.679,4.212,4.212,0,0,0-2.309-.631,4.358,4.358,0,0,0-2.492.715,4.693,4.693,0,0,0-1.624,1.847,5.141,5.141,0,0,0-.533,2.422Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1027.859,625.646a.3.3,0,0,1,.014.266l-5.685,14.028a.6.6,0,0,1-.643.42h-1.008a.682.682,0,0,1-.7-.391l-5.656-14.029a.3.3,0,0,1-.028-.139q0-.28.336-.28h1.736a.467.467,0,0,1,.448.307l4.4,11c.056.188.121.28.2.28s.158-.1.252-.307l4.368-10.977a.39.39,0,0,1,.42-.307h1.288A.277.277,0,0,1,1027.859,625.646Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1033.626,636.356a4.954,4.954,0,0,0,1.778,1.821,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.325.413.413,0,0,1,.2-.126.339.339,0,0,1,.2.042l1.176.588c.149.094.168.224.056.392a6.827,6.827,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.114,7.114,0,0,1-3.668-.953,6.605,6.605,0,0,1-2.534-2.673,8.294,8.294,0,0,1-.91-3.935,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,6.991,6.991,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.978,5.978,0,0,1,2.365,2.617,9.19,9.19,0,0,1,.841,4.074v.112a.687.687,0,0,1-.084.378.378.378,0,0,1-.336.126H1033.22q-.252,0-.252.42A4.929,4.929,0,0,0,1033.626,636.356Zm7.91-4.452a.7.7,0,0,0,.392-.07q.084-.069.084-.321a5.1,5.1,0,0,0-.518-2.283,4.1,4.1,0,0,0-1.513-1.679,4.21,4.21,0,0,0-2.309-.631,4.358,4.358,0,0,0-2.492.715,4.693,4.693,0,0,0-1.624,1.847,5.153,5.153,0,0,0-.533,2.422Z"/>
        <path class="cls-3" d="M1051.448,639.885a.5.5,0,0,1-.112.378.606.606,0,0,1-.392.1h-1.456a.486.486,0,0,1-.336-.084.43.43,0,0,1-.085-.307l.029-19.992q0-.393.336-.393h1.68c.224,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M1059.666,639.675a6.525,6.525,0,0,1-2.492-2.7,9.335,9.335,0,0,1,.028-7.981,7.1,7.1,0,0,1,9.884-2.758,6.682,6.682,0,0,1,2.492,2.758,8.833,8.833,0,0,1,.882,4,8.473,8.473,0,0,1-.9,3.948,6.7,6.7,0,0,1-2.505,2.716,6.936,6.936,0,0,1-3.682.981A7.079,7.079,0,0,1,1059.666,639.675Zm7.168-2.381a8.31,8.31,0,0,0-.028-8.708,4.071,4.071,0,0,0-3.4-1.609,4.135,4.135,0,0,0-3.443,1.609,8.123,8.123,0,0,0,0,8.694,4.177,4.177,0,0,0,3.472,1.6A4.047,4.047,0,0,0,1066.834,637.294Z"/>
        <path class="cls-3" d="M1077.655,625.94v1.176q0,.252.141.252c.056,0,.13-.046.224-.139a13.839,13.839,0,0,1,1.036-.953,5,5,0,0,1,1.315-.7,5.68,5.68,0,0,1,2.045-.336,5.96,5.96,0,0,1,3.149.84,5.712,5.712,0,0,1,2.185,2.521,9.563,9.563,0,0,1,.8,4.116,10.421,10.421,0,0,1-.8,4.256,5.876,5.876,0,0,1-5.643,3.668,5.939,5.939,0,0,1-3.975-1.4c-.131-.13-.235-.2-.309-.2-.094,0-.139.122-.139.364v5.208c0,.242-.1.364-.308.364h-1.736c-.206,0-.309-.122-.309-.364V625.969a.492.492,0,0,1,.1-.336.445.445,0,0,1,.349-.112h1.485C1077.524,625.521,1077.655,625.66,1077.655,625.94Zm1.834,12.531a4.375,4.375,0,0,0,2,.462,4.1,4.1,0,0,0,3.374-1.6,7.208,7.208,0,0,0,1.275-4.592,6.463,6.463,0,0,0-1.26-4.353,4.384,4.384,0,0,0-5.265-.953,4.028,4.028,0,0,0-1.414,1.247,2.616,2.616,0,0,0-.517,1.483v6.916A6.945,6.945,0,0,0,1079.489,638.471Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M835.947,669.686a4.967,4.967,0,0,0,1.778,1.82,4.707,4.707,0,0,0,2.464.672,4.983,4.983,0,0,0,4.368-2.324.394.394,0,0,1,.2-.126.324.324,0,0,1,.2.042l1.176.587c.148.094.168.224.057.392a6.829,6.829,0,0,1-2.605,2.45,7.868,7.868,0,0,1-3.612.771,7.121,7.121,0,0,1-3.668-.952,6.605,6.605,0,0,1-2.533-2.674,8.282,8.282,0,0,1-.91-3.935,8.9,8.9,0,0,1,.923-4.158,6.646,6.646,0,0,1,2.534-2.73,7,7,0,0,1,3.627-.952,6.86,6.86,0,0,1,3.541.9,5.991,5.991,0,0,1,2.366,2.617,9.218,9.218,0,0,1,.841,4.074v.113a.7.7,0,0,1-.084.377.379.379,0,0,1-.336.126H835.541q-.252,0-.252.42A4.925,4.925,0,0,0,835.947,669.686Zm7.91-4.453a.693.693,0,0,0,.392-.07q.084-.069.084-.321a5.08,5.08,0,0,0-.519-2.282,4.1,4.1,0,0,0-1.511-1.68,4.214,4.214,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.682,4.682,0,0,0-1.624,1.848,5.133,5.133,0,0,0-.532,2.421Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M863.723,658.976a.294.294,0,0,1,.013.265l-5.683,14.029a.6.6,0,0,1-.645.419H856.4a.68.68,0,0,1-.7-.391l-5.656-14.028a.3.3,0,0,1-.027-.14c0-.187.111-.28.336-.28h1.735a.467.467,0,0,1,.448.307l4.4,11c.054.188.121.28.2.28s.158-.1.252-.307l4.368-10.977a.39.39,0,0,1,.42-.307h1.288A.277.277,0,0,1,863.723,658.976Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M869.491,669.686a4.962,4.962,0,0,0,1.779,1.82,4.7,4.7,0,0,0,2.463.672,4.985,4.985,0,0,0,4.369-2.324.392.392,0,0,1,.2-.126.322.322,0,0,1,.2.042l1.176.587c.149.094.168.224.057.392a6.829,6.829,0,0,1-2.605,2.45,7.865,7.865,0,0,1-3.612.771,7.123,7.123,0,0,1-3.668-.952,6.605,6.605,0,0,1-2.533-2.674,8.283,8.283,0,0,1-.911-3.935,8.9,8.9,0,0,1,.924-4.158,6.653,6.653,0,0,1,2.534-2.73,7,7,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.991,5.991,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.113a.693.693,0,0,1-.083.377.379.379,0,0,1-.336.126H869.085q-.252,0-.252.42A4.935,4.935,0,0,0,869.491,669.686Zm7.91-4.453a.7.7,0,0,0,.392-.07c.057-.046.084-.153.084-.321a5.092,5.092,0,0,0-.518-2.282,4.092,4.092,0,0,0-1.512-1.68,4.214,4.214,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.689,4.689,0,0,0-1.624,1.848,5.133,5.133,0,0,0-.532,2.421Z"/>
        <path class="cls-3" d="M884.933,673.325V659.214c0-.243.1-.364.308-.364h1.624a.272.272,0,0,1,.308.307v1.821c0,.112.032.177.1.2s.135-.019.21-.112q2.127-2.493,4.34-2.493.981,0,.98.365v1.679q0,.28-.252.225a7.75,7.75,0,0,0-1.26-.084,3.976,3.976,0,0,0-1.764.475,5.01,5.01,0,0,0-1.6,1.233,2.365,2.365,0,0,0-.644,1.539v9.269a.37.37,0,0,1-.42.419h-1.54C885.063,673.689,884.933,673.568,884.933,673.325Z"/>
        <path class="cls-3" d="M896.524,678.254c-.242-.038-.364-.141-.364-.309v-1.091a.3.3,0,0,1,.154-.281,1.549,1.549,0,0,1,.63-.084h1.765a1.828,1.828,0,0,0,1.231-.49,3.7,3.7,0,0,0,1.2-2.617,1.68,1.68,0,0,0-.113-.673l-5.347-13.384a.381.381,0,0,1-.029-.168c0-.205.113-.307.336-.307h1.737a.467.467,0,0,1,.448.307l4.172,10.864c.055.168.13.252.223.252.113,0,.205-.112.281-.335l4.116-10.781a.432.432,0,0,1,.391-.307h1.317a.283.283,0,0,1,.252.126.273.273,0,0,1,.028.265l-6.16,15.765a5.214,5.214,0,0,1-1.876,2.6,5.728,5.728,0,0,1-3.053.7A10.6,10.6,0,0,1,896.524,678.254Z"/>
        <path class="cls-3" d="M928.011,660.739a5.184,5.184,0,0,0-2.366-.517,4.43,4.43,0,0,0-2.52.658,1.993,1.993,0,0,0-.98,1.722,1.65,1.65,0,0,0,.49,1.162,4.148,4.148,0,0,0,1.778.91l3.556,1.063a5.584,5.584,0,0,1,2.744,1.5,3.419,3.419,0,0,1,.895,2.367,3.587,3.587,0,0,1-.783,2.282,5.061,5.061,0,0,1-2.156,1.54,9.071,9.071,0,0,1-6.944-.309,5.176,5.176,0,0,1-2.38-2.225.48.48,0,0,1-.084-.225.255.255,0,0,1,.139-.224l1.093-.616c.186-.111.326-.1.42.029a5.131,5.131,0,0,0,4.676,2.408,5.329,5.329,0,0,0,2.729-.687,1.961,1.961,0,0,0,1.078-1.721,1.646,1.646,0,0,0-.643-1.317,6,6,0,0,0-2.1-.951l-2.883-.813a5.985,5.985,0,0,1-2.926-1.484,3.292,3.292,0,0,1-.882-2.323,3.873,3.873,0,0,1,.714-2.3,4.546,4.546,0,0,1,2.015-1.555,8.3,8.3,0,0,1,6.3.155,5.352,5.352,0,0,1,2.254,1.9c.13.186.121.317-.028.391l-1.176.644a.428.428,0,0,1-.168.057.384.384,0,0,1-.252-.141A4.2,4.2,0,0,0,928.011,660.739Z"/>
        <path class="cls-3" d="M938.917,655.657a.6.6,0,0,1-.112.406.509.509,0,0,1-.392.126h-1.624c-.28,0-.421-.158-.421-.475v-2.353c0-.3.131-.448.393-.448h1.763a.385.385,0,0,1,.281.112.422.422,0,0,1,.112.308Zm-.112,17.557a.494.494,0,0,1-.113.378.6.6,0,0,1-.391.1h-1.428a.486.486,0,0,1-.336-.084.425.425,0,0,1-.085-.307V659.241q0-.391.337-.391H938.5q.308,0,.308.364Z"/>
        <path class="cls-3" d="M944.544,673.325V659.214c0-.243.1-.364.309-.364h1.679a.273.273,0,0,1,.309.307v1.624q0,.309.252.084a15.262,15.262,0,0,1,2.674-1.763,5.636,5.636,0,0,1,2.477-.533,4.582,4.582,0,0,1,3.319,1.149,4.085,4.085,0,0,1,1.189,3.08v10.443q0,.448-.42.448h-1.567a.322.322,0,0,1-.365-.364v-10a3.022,3.022,0,0,0-.713-2.155,2.9,2.9,0,0,0-2.2-.757,4.615,4.615,0,0,0-2.17.519,13.541,13.541,0,0,0-2.422,1.75V673.27a.355.355,0,0,1-.419.419h-1.54Q944.544,673.689,944.544,673.325Z"/>
        <path class="cls-3" d="M976.24,659.046v.979a.559.559,0,0,1-.126.407.528.528,0,0,1-.433.1,3.453,3.453,0,0,0-1.456.252c-.449.188-.635.4-.561.645a3.055,3.055,0,0,0,.168.42,3.483,3.483,0,0,1,.225.685,3.74,3.74,0,0,1,.084.8,3.992,3.992,0,0,1-1.555,3.248,7.034,7.034,0,0,1-4.522,1.261,8.624,8.624,0,0,0-2.856.336c-.578.223-.867.514-.867.867,0,.28.195.49.588.631a7.925,7.925,0,0,0,1.96.294l3.611.195a5.961,5.961,0,0,1,3.584,1.149,3.424,3.424,0,0,1,1.177,2.744,3.768,3.768,0,0,1-1.863,3.332,9.774,9.774,0,0,1-5.305,1.2,11.483,11.483,0,0,1-5.418-1.023,3.193,3.193,0,0,1-1.862-2.925q0-1.877,2.631-3.164.308-.141,0-.308a1.794,1.794,0,0,1-1.288-1.652,1.645,1.645,0,0,1,.574-1.176,4.324,4.324,0,0,1,1.638-.925c.094-.018.141-.064.141-.14a.2.2,0,0,0-.141-.2,4.66,4.66,0,0,1-1.819-1.554,3.953,3.953,0,0,1-.589-2.2,4.1,4.1,0,0,1,1.652-3.443,7.575,7.575,0,0,1,4.676-1.261,6.439,6.439,0,0,1,4.2,1.261.616.616,0,0,0,.281.139.606.606,0,0,0,.307-.139,5.182,5.182,0,0,1,1.246-.91,2.962,2.962,0,0,1,1.386-.378Q976.241,658.6,976.24,659.046ZM965.6,671.869a2.356,2.356,0,0,0-1.149.294,3.115,3.115,0,0,0-1.092.91,2.086,2.086,0,0,0-.448,1.288,2.235,2.235,0,0,0,1.372,2.059,9.067,9.067,0,0,0,4.061.714q4.731,0,4.731-2.8a1.92,1.92,0,0,0-.658-1.54,3.621,3.621,0,0,0-2.142-.644Zm5.361-6.3a2.749,2.749,0,0,0,.967-2.267,2.931,2.931,0,0,0-.981-2.353,4.327,4.327,0,0,0-2.884-.84,4.407,4.407,0,0,0-2.883.855,2.864,2.864,0,0,0-1.036,2.338,2.694,2.694,0,0,0,1.021,2.254,4.616,4.616,0,0,0,2.9.8A4.526,4.526,0,0,0,970.962,665.569Z"/>
        <path class="cls-3" d="M982.933,673.214a.494.494,0,0,1-.113.378.6.6,0,0,1-.391.1h-1.456a.485.485,0,0,1-.336-.084.425.425,0,0,1-.085-.307l.028-19.992q0-.393.336-.393H982.6c.223,0,.336.122.336.364Z"/>
        <path class="cls-3" d="M990.87,669.686a4.967,4.967,0,0,0,1.778,1.82,4.71,4.71,0,0,0,2.464.672,4.982,4.982,0,0,0,4.368-2.324.4.4,0,0,1,.2-.126.324.324,0,0,1,.2.042l1.176.587c.149.094.168.224.056.392a6.82,6.82,0,0,1-2.6,2.45,7.865,7.865,0,0,1-3.612.771,7.123,7.123,0,0,1-3.668-.952,6.605,6.605,0,0,1-2.533-2.674,8.283,8.283,0,0,1-.911-3.935,8.9,8.9,0,0,1,.924-4.158,6.646,6.646,0,0,1,2.534-2.73,7,7,0,0,1,3.626-.952,6.859,6.859,0,0,1,3.542.9,5.991,5.991,0,0,1,2.366,2.617,9.2,9.2,0,0,1,.84,4.074v.113a.684.684,0,0,1-.084.377.378.378,0,0,1-.336.126H990.464q-.252,0-.252.42A4.935,4.935,0,0,0,990.87,669.686Zm7.91-4.453a.7.7,0,0,0,.392-.07c.057-.046.084-.153.084-.321a5.092,5.092,0,0,0-.518-2.282,4.092,4.092,0,0,0-1.512-1.68,4.216,4.216,0,0,0-2.31-.631,4.358,4.358,0,0,0-2.492.715,4.689,4.689,0,0,0-1.624,1.848,5.133,5.133,0,0,0-.532,2.421Z"/>
        <path class="cls-3" d="M1023.9,673.3v-1.2c0-.168-.047-.252-.14-.252a.387.387,0,0,0-.224.139,8.872,8.872,0,0,1-1.834,1.415,5.1,5.1,0,0,1-2.562.574,6.09,6.09,0,0,1-3.192-.84,5.764,5.764,0,0,1-2.213-2.534,9.437,9.437,0,0,1-.811-4.1,9.66,9.66,0,0,1,.84-4.143,6.677,6.677,0,0,1,2.324-2.787,5.95,5.95,0,0,1,3.388-.994,5.554,5.554,0,0,1,2.184.393,6.6,6.6,0,0,1,1.736,1.092.723.723,0,0,0,.308.168c.093,0,.14-.121.14-.365v-6.58c0-.242.1-.364.307-.364h1.708c.207,0,.309.122.309.364v19.964a.553.553,0,0,1-.084.351.488.488,0,0,1-.364.1h-1.428A.346.346,0,0,1,1023.9,673.3Zm-1.89-12.53a4.287,4.287,0,0,0-1.974-.462,4.172,4.172,0,0,0-3.416,1.595,7.01,7.01,0,0,0-1.288,4.508,6.522,6.522,0,0,0,1.274,4.4,4.14,4.14,0,0,0,3.262,1.456,3.971,3.971,0,0,0,2-.519,4.1,4.1,0,0,0,1.428-1.3,2.827,2.827,0,0,0,.518-1.568v-6.748A7.21,7.21,0,0,0,1022.006,660.768Z"/>
        <path class="cls-3" d="M1035.544,673.97a4.977,4.977,0,0,1-3.43-1.107,3.751,3.751,0,0,1-1.246-2.954,4.048,4.048,0,0,1,1.89-3.527,10.966,10.966,0,0,1,5.473-1.513l2.185-.168q.476-.056.476-.42v-.756a3.129,3.129,0,0,0-.938-2.393,3.548,3.548,0,0,0-2.534-.883,4.74,4.74,0,0,0-4.032,2.24.467.467,0,0,1-.168.183.276.276,0,0,1-.224-.015l-1.513-.5a.2.2,0,0,1-.139-.2.645.645,0,0,1,.084-.252,5.825,5.825,0,0,1,2.394-2.309,7.733,7.733,0,0,1,3.682-.827,6.188,6.188,0,0,1,4.2,1.275,4.425,4.425,0,0,1,1.456,3.513v9.913a.482.482,0,0,1-.084.335.435.435,0,0,1-.308.084h-1.4q-.336,0-.392-.448l-.056-1.288c-.019-.13-.066-.195-.14-.195a.393.393,0,0,0-.253.139A6.975,6.975,0,0,1,1035.544,673.97Zm4.928-7.84-1.821.168a9.075,9.075,0,0,0-4.017,1.008,2.588,2.588,0,0,0-1.414,2.324,2.326,2.326,0,0,0,.854,1.9,3.484,3.484,0,0,0,2.281.7,4.924,4.924,0,0,0,1.708-.307,5.031,5.031,0,0,0,1.485-.84,4.112,4.112,0,0,0,.994-1.078,2.135,2.135,0,0,0,.35-1.107v-2.324Q1040.892,666.13,1040.472,666.13Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1048.116,678.254q-.364-.057-.364-.309v-1.091a.3.3,0,0,1,.154-.281,1.549,1.549,0,0,1,.63-.084h1.764a1.83,1.83,0,0,0,1.232-.49,3.694,3.694,0,0,0,1.2-2.617,1.7,1.7,0,0,0-.112-.673l-5.348-13.384a.383.383,0,0,1-.028-.168q0-.308.336-.307h1.736a.466.466,0,0,1,.448.307l4.172,10.864c.056.168.13.252.224.252q.168,0,.28-.335l4.116-10.781a.434.434,0,0,1,.392-.307h1.316a.283.283,0,0,1,.252.126.271.271,0,0,1,.028.265l-6.16,15.765a5.208,5.208,0,0,1-1.876,2.6,5.724,5.724,0,0,1-3.052.7A10.584,10.584,0,0,1,1048.116,678.254Z"/>
      </g>
      <g class="cls-4">
        <path class="cls-3" d="M1065.224,673.241a.452.452,0,0,1-.112.351.606.606,0,0,1-.392.1h-1.96a.491.491,0,0,1-.322-.084.387.387,0,0,1-.1-.307V670.89c0-.243.1-.365.308-.365h2.3c.187,0,.28.113.28.336Z"/>
      </g>
    </g>
  </g>
</g>
  // <g id="rozwoj" class="our-values__text">
  //   <g>
  //     <path class="cls-2" d="M797.3627,589.3816c0,116.1624,94.5077,210.67,210.67,210.67s210.6641-94.5077,210.6641-210.67-94.5023-210.67-210.6641-210.67S797.3627,473.22,797.3627,589.3816Z" transform="translate(-58.8798 50.5781)"/>
  //     <text class="cls-7" transform="translate(794.2975 640.3602)">K<tspan class="cls-8" x="20.2437" y="0">ażde</tspan><tspan class="cls-9" x="87.7236" y="0">g</tspan><tspan class="cls-8" x="105.7554" y="0">o dnia chcemy</tspan><tspan class="cls-8"><tspan x="68.459" y="33.3291">się </tspan><tspan class="cls-10" x="117.375" y="33.3291">r</tspan><tspan x="129.1353" y="33.3291">ozwijać.</tspan></tspan></text>
  //   </g>
  // </g>
  // <g id="partnerstwo-plus" class="our-values__btn">
  //   <circle class="cls-2" cx="517.7475" cy="538.8776" r="47.9496" transform="translate(-86.4324 1100.4344) rotate(-89.2846)"/>
  //   <polygon class="cls-3" points="461.141 568.994 456.594 568.994 456.594 587.182 438.406 587.182 438.406 591.729 456.594 591.729 456.594 609.917 461.141 609.917 461.141 591.729 479.329 591.729 479.329 587.182 461.141 587.182 461.141 568.994"/>
  // </g>
  // <g id="odpowiedzialnosc-plus" class="our-values__btn">
  //   <circle class="cls-2" cx="665.4461" cy="226.4276" r="47.9496" transform="translate(371.8477 939.5728) rotate(-89.2846)"/>
  //   <polygon class="cls-3" points="608.84 256.544 604.293 256.544 604.293 274.732 586.105 274.732 586.105 279.279 604.293 279.279 604.293 297.467 608.84 297.467 608.84 279.279 627.028 279.279 627.028 274.732 608.84 274.732 608.84 256.544"/>
  // </g>
  // <g id="satysfakcja-plus" class="our-values__btn">
  //   <path class="cls-2" d="M928.1576,160.0921a45.8725,45.8725,0,1,1-45.8724-45.8739A45.8723,45.8723,0,0,1,928.1576,160.0921Z" transform="translate(-58.8798 50.5781)"/>
  //   <polygon class="cls-3" points="825.679 190.208 821.132 190.208 821.132 208.397 802.944 208.397 802.944 212.944 821.132 212.944 821.132 231.132 825.679 231.132 825.679 212.944 843.867 212.944 843.867 208.397 825.679 208.397 825.679 190.208"/>
  // </g>
  // <g id="innowacyjnosc-plus" class="our-values__btn">
  //   <circle class="cls-2" cx="1039.7036" cy="357.9279" r="47.9496" transform="translate(609.942 1443.6595) rotate(-89.2846)"/>
  //   <polygon class="cls-3" points="983.097 388.044 978.55 388.044 978.55 406.232 960.362 406.232 960.362 410.78 978.55 410.78 978.55 428.968 983.097 428.968 983.097 410.78 1001.285 410.78 1001.285 406.232 983.097 406.232 983.097 388.044"/>
  // </g>
  // <g id="rozwoj-plus" class="our-values__btn">
  //   <path class="cls-2" d="M1053.9023,610.4458a45.8725,45.8725,0,1,1-45.8724-45.8739A45.8723,45.8723,0,0,1,1053.9023,610.4458Z" transform="translate(-58.8798 50.5781)"/>
  //   <polygon class="cls-3" points="951.424 640.562 946.876 640.562 946.876 658.75 928.688 658.75 928.688 663.297 946.876 663.297 946.876 681.485 951.424 681.485 951.424 663.297 969.612 663.297 969.612 658.75 951.424 658.75 951.424 640.562"/>
  // </g>
</svg>`; 
}